import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import '../../css/Personal.css';
import noPicture from '../../resources/img/no_foto.svg';
import del from '../../resources/icons/delete.png';
import tag from '../../resources/icons/tag.png';
import photo from '../../resources/icons/photo.png';
import tagpeople from '../../resources/icons/tagpeople.png';
import locationImg from '../../resources/icons/location.png';
import PeopleFollowedTag from './PeopleFollowedModalTag';
import Select from 'react-select';

Modal.setAppElement('#root');

const EditPhotoPost = ({ isOpen, onClose, userData, postData, renderAgain }) => {

  const langCode = useLangContext();
  const csrfToken = useCsrfToken();
  const [text, setText] = useState('');
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [textareaHeight, setTextareaHeight] = useState('38px');
  const [showPhotoArea, setShowPhotoArea] = useState(true);
  const [showPhotos, setShowPhotos] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showEnterLocation, setShowEnterLocation] = useState(false);
  const [geonamesData, setGeonamesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [location, setLocation] = useState(null);
  const [showAllContent, setShowAllContent] = useState(true);
  const [showTagPeople, setShowTagPeople] = useState(false);
  const [isPeopleFollowerModalOpen, setIsPeopleFollowerModalOpen] = useState(false);
  const [sendUserData, setSendUserData] = useState(userData.user.id);
  const [publishButton, setPublishButton] = useState(false);
  const [tagedPeople, setTagedPeople] = useState([]);
  const [textareaValue, setTextareaValue] = useState('');
  const [showTaggedPeople, setShowTaggedPeople] = useState(false);
  const [serverPhotos, setServerPhotos] = useState([]);

  const fetchServerPhotos = async () => {
    try {
      const serverPhotoPaths = [
        postData.photo_1_path,
        postData.photo_2_path,
        postData.photo_3_path,
        postData.photo_4_path,
        // Agrega otras rutas de fotos del servidor según sea necesario
      ].filter(Boolean);
  
      const serverPhotos = await Promise.all(
        serverPhotoPaths.map(async (photoPath) => {
          const response = await fetch(`https://www.skyrushcommunity.com/api/public/storage/posts/${photoPath}`);
          if (!response.ok) {
            throw new Error('Failed to fetch image');
          }
          const blob = await response.blob();
          return new File([blob], photoPath);
        })
      );

      setUploadedPhotos([]);
  
      setServerPhotos(serverPhotos);
      if (serverPhotos.length > 0) {
        setShowPhotoArea(true);
        setShowPhotos(true);
      }
      setUploadedPhotos((prevPhotos) => [...prevPhotos, ...serverPhotos]);
    } catch (error) {
      console.error('Error fetching server photos:', error);
    }
  };



  useEffect(() => {
    if (postData) {
      setLocation({    
          geonameId: postData.geonameId,
          adminCode1: postData.adminCode1,
          adminCodes1: postData.adminCodes1,
          name: postData.name,
          adminName1: postData.adminName1,
          countryName: postData.countryName,
          countryCode: postData.countryCode,
          countryId: postData.countryId,
          fcl: postData.fcl,
          fclName: postData.fclName,
          fcode: postData.fcode,
          fcodeName: postData.fcodeName,
          lat: postData.lat,
          lng: postData.lng,
          population: postData.population,
          label: `${postData.name}, ${postData.adminName1}, ${postData.countryName}`,  
      });
      setTagedPeople(postData.tagged_users || []);
      fetchServerPhotos();
    }    
    if(postData.geonameId !== null)
    {
      setShowLocation(true)
    }
    else{
      setShowLocation(false)
    }
  
  }, [postData]);

  const closePeopleFollowerModal = () => {
    setIsPeopleFollowerModalOpen(false)
  }

  const apiKey = 'wequestz';

  const handleTextAreaChange = (e) => {
    setTextareaHeight(`${e.target.scrollHeight}px`);
    setTextareaValue(e.target.value.trim());



    if (e.target.value.trim() === '' && uploadedPhotos.length === 0 && geonamesData.length === 0) {
      setPublishButton(true)
    }
    else {
      setPublishButton(false)
    }


  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const newUploadedPhotos = Array.from(files).slice(0, 4);
    setUploadedPhotos((prevPhotos) => [...prevPhotos, ...newUploadedPhotos]);
    setShowPhotoArea(false);
    setShowPhotos(true);
    setPublishButton(false)
  };


  const handleRemovePhoto = (index) => {
    const updatedPhotos = [...uploadedPhotos];
    updatedPhotos.splice(index, 1);
    setUploadedPhotos(updatedPhotos);
    if (updatedPhotos.length === 0) {
      setShowPhotoArea(true);
      setShowPhotos(false);
   /*   if (textareaValue === '' && geonamesData.length === 0) {
        setPublishButton(true)
      }*/
   

    }
  };

  const handleSelectChange = (selectedOption) => {
    setShowEnterLocation(false)
    setShowLocation(true)
    setPublishButton(false)
    setLocation(selectedOption)
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      fetch(`https://secure.geonames.org/searchJSON?q=${inputValue}&maxRows=10&username=${apiKey}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('La solicitud no tuvo éxito.');
          }
          return response.json();
        })
        .then((data) => {
          // Procesa los datos de Geonames y establece las opciones en el estado.
          const options = data.geonames.map((item) => ({
            value: item,
            label: `${item.name}, ${item.adminName1}, ${item.countryName} `


          }));
          setGeonamesData(options);
          callback(options);
        })
        .catch((error) => {
          console.error('Error al obtener datos de Geonames:', error);
        });
    }
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,

      fontSize: '12px',
      color: 'black',
      width: '100%',
      borderRadius: '5px',
      border: '1px solid #CCC',
      '&:focus-within': {
        borderColor: 'black', // Color de borde al enfocar
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '12px', // Estilo del texto en el menú
      color: 'black', // Color del texto en el menú
      backgroundColor: 'white', // Fondo del menú
      border: '1px solid #CCC', // Borde del menú
    }),
  };

  const addLocationFunction = () => {
    setShowEnterLocation(true)
  }


  const deleteLocation = (e) => {
    e.preventDefault();
    setShowLocation(false)
    setShowEnterLocation(false)
    setLocation(null)
    setGeonamesData([])


    if (textareaValue !== '' || uploadedPhotos.length !== 0) {
      setPublishButton(false)
    }
    else {
      setPublishButton(true)
    }
  }

  const tagPeople = (e) => {
    e.preventDefault();
    setIsPeopleFollowerModalOpen(true)
  }



  const manageTags = (tags) => {
    setTagedPeople(tags)
  }


  useEffect(() => {
    if (tagedPeople.length !== 0) {

      setShowTaggedPeople(true)
    }
    else {
      setShowTaggedPeople(false)
    }

  }, [tagedPeople]);


  const publish = async () => {
    // Crear un objeto FormData para agregar datos a la solicitud
    const tagedPeopleIds = tagedPeople.map(user => user.id);
    const formData = new FormData();
    const formattedContent = textareaValue.trim() !== '' ? textareaValue.replace(/\n/g, "<br>") : postData.content;


    formData.append('content', formattedContent);
    formData.append('location', JSON.stringify(location));
    formData.append('tagedPeople', JSON.stringify(tagedPeopleIds));
    formData.append('media_type', 'photo'); // Especificar el tipo de medio como 'photo'
    formData.append('postId',  JSON.stringify(postData.id)); // Especificar el tipo de medio como 'photo'

    // Agregar las imágenes en formato binario al FormData
    for (let i = 0; i < uploadedPhotos.length; i++) {
      formData.append(`photos[]`, uploadedPhotos[i]);
    }

    const token = localStorage.getItem('token');

    try {
      const response = await fetch("api/public/update-post", {
        method: "POST",
        headers: {
          // No es necesario establecer 'Content-Type' ya que FormData lo maneja automáticamente
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        },
        body: formData, // Usar FormData en lugar de JSON.stringify
      });

      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }
      renderAgain()
      onClose()

      // Procesar la respuesta del servidor si es necesario
    } catch (error) {
      // Manejar errores de la petición aquí
      console.error('Error en la petición fetch:', error);
    }
  };



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Renovar Subscripcion"
      style={{
        content: {
          width: '700px',
          height: '850px', // Altura ajustable si hay fotos cargadas
          margin: 'auto',
        },
      }}
    >
      <div className='row'>
        <div className='col-lg-1'>
          <a href='#' onClick={onClose}>
            <img
              src={close}
              style={{
                width: '10px',
                marginBottom: '10px'
              }}
            />
          </a>
        </div>
        <div className='col-lg-10'></div>
      </div>

      {showAllContent && (
        <div className='Personal' style={{ backgroundColor: 'white' }}>
          <div className='row'>
            <div className='col-lg-12' style={{ textAlign: 'center' }}>
              <h3 style={{ margin: '5px', fontSize: '14px', fontFamily: 'montserratbold' }}>
                {lang[langCode].posts.editPost}
              </h3>
            </div>
          </div>
          <hr></hr>

          <div className='row'>
            <div className='col-lg-12' style={{ textAlign: 'center' }}>
              <textarea
                style={{
                  minHeight: '38px',
                  height: textareaHeight,
                  width: '100%',
                  border: 'none',
                  outline: 'none',
                  fontFamily: 'montserratregular',
                  fontSize: '12px'
                }}
                placeholder={lang[langCode].posts.textAreaPlaceHolder}
                rows={1}
                onChange={handleTextAreaChange}
              >
                {postData.content}
              </textarea>
            </div>
          </div>
          {showTaggedPeople && (
            <div className='row' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {tagedPeople.map((person, index) => (
                <div className='col-lg-3' key={index} style={{ textAlign: 'center', padding: '2px', display: 'flex' }}>
                  {/* Contenido que deseas renderizar para cada elemento, con el enlace dentro del párrafo */}

                  <p
                    style={{
                      margin: '0px',
                      backgroundColor: '#C7DDF3',
                      borderRadius: '5px',
                      flex: '1',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px'
                    }}>
                    <img
                      style={{ width: '12px', marginLeft: '0px', marginRight: '3px', marginBottom: '3px' }}
                      src={tag}
                      alt=""
                    />
                    {person.name} {person.last_name}
                  </p>
                  {/* Agregar más contenido según sea necesario */}
                </div>
              ))}
            </div>
          )}
          {showEnterLocation && (

            <div className='row' style={{ paddingTop: '10px' }}>
              <div className='col-lg-12' style={{ textAlign: 'left' }}>
                <h6 style={{ fontSize: '12px', fontFamily: 'montserratbold' }}>{lang[langCode].personalDataProfile.locationTitle}</h6>
                <Select
                  styles={customStyles}
                  options={geonamesData}
                  value={selectedOption}
                  onInputChange={(inputValue) => loadOptions(inputValue, (options) => { })}
                  onChange={handleSelectChange}
                  placeholder={lang[langCode].personalDataProfile.locationPlaceHolder}
                  isClearable
                  isSearchable
                  cacheOptions
                />

              </div>
            </div>

          )}
          {showLocation && (
            <div className='row'>
              <div className='col-lg-12' style={{ cursor: 'pointer', paddingTop: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{ width: '17px', marginRight: '5px' }}
                      src={locationImg}
                      alt=""
                    />
                    <p style={{ margin: '0', flexGrow: 1 }}>{location.label}</p>
                  </div>
                  <a href='#' onClick={deleteLocation} style={{ margin: '0', width: '15px' }}>
                    <img
                      style={{ width: '14px', marginLeft: '0px' }}
                      src={del}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className='row'>
            {showPhotoArea && !showPhotos && (
              <div className='col-lg-12'>
                <div
                  style={{
                    marginTop: '20px',
                    border: '1px dashed #A5A5A5',
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '400px',
                  }}
                >
                  <p style={{ fontFamily: 'montserratregular' }}>
                    {lang[langCode].posts.loadFourPhotos}
                  </p>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='row'>
            {showPhotos && (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {uploadedPhotos.map((photo, index) => (
                  <div className='col-lg-6' style={{ height: '300px', width: '300px', position: 'relative' }}>
                    <div
                      key={index}
                      style={{
                        width: '100%',
                        height: '100%',
                        margin: '5px',
                        border: '1px solid #E9E9E9',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative', // Establecer posición relativa en el contenedor
                      }}
                    >
                      {/* Botón "X" para eliminar la foto */}
                      <button
                        style={{
                          width: '30px',
                          height: '30px',
                          backgroundColor: 'white',
                          color: 'black',
                          borderRadius: '50%',
                          border: '1px solid #E9E9E9',
                          position: 'absolute', // Establecer posición absoluta en relación con el contenedor
                          top: '0px', // Alineación superior
                          right: '0px', // Alineación derecha
                        }}
                        className="remove-photo-button"
                        onClick={() => handleRemovePhoto(index)}
                      >
                        <img
                          style={{
                            width: '10px',
                            objectFit: 'cover',
                          }}
                          src={close}

                        />
                      </button>
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        src={URL.createObjectURL(photo)}
                        alt={`Uploaded ${index + 1}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className='row' style={{ paddingTop: '10px' }}>
            <div className='col-lg-4' style={{ padding: '10px', textAlign: 'center' }}>
              <div
                className="custom-file-input"
                style={{
                  textAlign: 'center',
                  backgroundColor: '#E7E7E7',
                  border: '1px solid #E9E9E9',
                  borderRadius: '5px',
                  display: 'flex', // Alineación horizontal de elementos
                  alignItems: 'center', // Alineación vertical de elementos
                  padding: '2px', // Espacio interior
                  paddingLeft: '5px',
                  width: '100%', // Ocupa todo el espacio disponible
                }}
              >
                <label htmlFor="fileInput" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '100%' }}>
                  <img
                    style={{ width: '20px', marginRight: '5px' }}
                    src={photo}
                    alt=""
                  />
                  <p style={{ margin: '0px' }}>
                    {lang[langCode].posts.loadPhotos}
                  </p>
                </label>
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>
            </div>
            <div className='col-lg-4' style={{ padding: '10px', textAlign: 'center' }}>
              <div
                className="custom-file-input"
                style={{
                  textAlign: 'center',
                  backgroundColor: '#E7E7E7',
                  border: '1px solid #E9E9E9',
                  borderRadius: '5px',
                  display: 'flex', // Alineación horizontal de elementos
                  alignItems: 'center', // Alineación vertical de elementos
                  padding: '2px', // Espacio interior
                  paddingLeft: '5px',
                  width: '100%', // Ocupa todo el espacio disponible
                }}
              >
                <a href='#'
                  onClick={addLocationFunction}
                  style={{ padding: '0px' }}
                >
                  <label style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '100%' }}>
                    <img
                      style={{ width: '20px', marginRight: '5px' }}
                      src={locationImg}
                      alt=""
                    />
                    <p style={{ margin: '0px' }}>
                      {lang[langCode].posts.addLocation}
                    </p>
                  </label>
                </a>
              </div>
            </div>
            <div className='col-lg-4' style={{ padding: '10px', textAlign: 'center' }}>
              <div
                className="custom-file-input"
                style={{
                  textAlign: 'center',
                  backgroundColor: '#E7E7E7',
                  border: '1px solid #E9E9E9',
                  borderRadius: '5px',
                  display: 'flex', // Alineación horizontal de elementos
                  alignItems: 'center', // Alineación vertical de elementos
                  padding: '2px', // Espacio interior
                  paddingLeft: '5px',
                  width: '100%', // Ocupa todo el espacio disponible
                }}
              >
                <a href='#'
                  onClick={tagPeople}
                  style={{ padding: '0px' }}
                >
                  <label style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '100%' }}>
                    <img
                      style={{ width: '20px', marginRight: '5px' }}
                      src={tagpeople}
                      alt=""
                    />
                    <p style={{ margin: '0px' }}>
                      {lang[langCode].posts.addPeople}
                    </p>
                  </label>
                </a>
              </div>
            </div>
            <div className='col-lg-4'></div>
          </div>
          <PeopleFollowedTag
            isOpen={isPeopleFollowerModalOpen}
            onClose={closePeopleFollowerModal}
            userData={sendUserData}
            manageTags={manageTags}
            tagedPeopleFather={tagedPeople}
          />
        </div>


      )}
      <div className='row' style={{ paddingTop: '10px' }}>
        <div className='col-lg-12' style={{ padding: '10px', textAlign: 'center' }}>
          <button disabled={publishButton} onClick={publish} style={{
            fontFamily: 'montserratbold',
            fontSize: '13px',
            backgroundColor: publishButton ? '#E7E7E7' : '#0074EF', // Cambia el color del botón en función del estado
            color: publishButton ? '#A7A7A7' : 'white', // Cambia el color del botón en función del estado

          }}> {lang[langCode].posts.publish}</button>
        </div>
      </div>
    </Modal>
  );
}

export default EditPhotoPost;
import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../../Contexts/CsrfTokenContext.js';
import lang from '../../../resources/js/dictionary.js';
import StationStatus from './StationStatus.js';
import StationBasicInfoEdit from './StationBasicInfoEdit.js';
import StationLocationEdit from './StationLocationEdit.js';
import StationMultimedia from './StationMultimedia.js';
import StationQualityCert from './StationQualityCert.js';
import StationHistoricData from './StationHistoricData.js';


const StationUnique = ({ data }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();






    return (

        <div>
            <div >
                <div className='container' style={{ paddingBottom: '30px' }}>
                    <div className='row' style={{ paddingTop: '20px' }}>
                        <div className='col-lg-4' >
                        </div>
                        <div className='col-lg-4' style={{ textAlign: 'center' }}>
                            <h1 style={{ fontSize: '24px', paddingTop: '5px', paddingBottom:'10px' }}> {data.name}</h1>
                        </div>
                        <div className='col-lg-4' >
                        </div>
                    </div>

                    <div className='row' style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div className='col-lg-6' style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <StationStatus data={data} />
                        </div>
                        <div className='col-lg-6' style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <StationBasicInfoEdit data={data} />
                        </div>
                        <div className='col-lg-6' style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <StationQualityCert data={data} />
                        </div>
                        <div className='col-lg-6' style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <StationHistoricData data={data} />
                        </div>
                        <div className='col-lg-6' style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <StationLocationEdit data={data} />
                        </div>
                        <div className='col-lg-6' style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <StationMultimedia data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StationUnique;
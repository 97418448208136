import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../../components/Contexts/LangProvider.js';
import lang from '../../../resources/js/dictionary.js';
import red_light from '../../../resources/icons/red_light.png';
import green_light from '../../../resources/icons/green_light.png';
import yellow_light from '../../../resources/icons/yellow_light.png';
import warning_cert from '../../../resources/img/certs/warning_cert.png';
import info from '../../../resources/icons/info.png';
import WeatherlinkLiveInfo from '../../Modals/WeatherlinkLiveInfo.js';
import loader from '../../../resources/img/loader.svg';
import moment from 'moment-timezone';

const StationStatusWLLive = ({ data, setOnline }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [ligth, setLight] = useState(null);
    const [place, setPlace] = useState(null);
    const [status, setStatus] = useState(null);
    const [isWLLModalOpen, setIsWLLModalOpen] = useState(false);
    const [keyErrorMessage, setKeyErrorMessage] = useState('');
    const [IDErrorMessage, setIDErrorMessage] = useState('');
    const [ScretErrorMessage, setSecretErrorMessage] = useState('');
    const [ID, setID] = useState(null);
    const [key, setKey] = useState(null);
    const [secret, setSecret] = useState(null);
    const [activeButton, setActiveButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [normalInfo, setNormalInfo] = useState(true);
    const [showConnectionError, setShowConnectionError] = useState(false);
    const [localData, setLocalData] = useState(data);
    const [showNewData, setShowNewData] = useState(false);
    const [showEditData, setShowEditData] = useState(false);
    const [initial, setInitial] = useState(false);

    const saveParams = async (event) => {
        {
            setLoading(true)
            setNormalInfo(false)
            const finalData =
            {
                key: key,
                secret: secret,
                ID: ID,
                station_id: localData.id
            }

            const token = localStorage.getItem('token');

            try {
                const response = await fetch("api/public/weatherdisplayv2-try", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(finalData),
                });

                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                const data = await response.json();
                console.log(data)
                connectionSet(data.station.original);




            } catch (error) {

                console.error('Error en la petición fetch:', error);
                connectionError();
            }
        };
    }

    const connectionSet = (station) => {
        // Código de la función connectionSet
        console.log('Conexión exitosa');
        setOnline()
        setActiveButton(false)
        setLocalData(station)
        setLoading(false)
        setNormalInfo(true)

    };

    // Función para manejar errores connectionError
    const connectionError = () => {
        // Código de la función connectionError
        console.error('Error en la conexión');
        setShowConnectionError(true)
        setLoading(false)
        setNormalInfo(true)
        setActiveButton(false)

    };


    useEffect(() => {
        if (initial == false) {
            if (ID && key && secret && !IDErrorMessage && !keyErrorMessage && !ScretErrorMessage) {
                setActiveButton(true);
            } else {

                setActiveButton(false);
            }
        }
        else {
            setInitial(false)
        }
        // Lógica para activar o desactivar el botón según el estado de los tres inputs

    }, [ID, key, secret, IDErrorMessage, keyErrorMessage, ScretErrorMessage]);


    const closeWLLModal = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsWLLModalOpen(false)
    }

    const openWLLModal = (e) => {
        e.preventDefault();
        setIsWLLModalOpen(true)
    }

    useEffect(() => {

        if (localData.status === 'draft') {
            setLight(yellow_light)
            setStatus(lang[langCode].weather.draft)
        }
        else if (localData.status === 'online') {
            setLight(green_light)
            setStatus(lang[langCode].weather.online)
        }
        else if (localData.status === 'offline') {
            setLight(red_light)
            setStatus(lang[langCode].weather.offline)
        }
        else if (localData.status === 'warning') {
            setLight(warning_cert)
            setStatus(lang[langCode].weather.warning)
        }

        if (localData.connection_assets === null || Object.keys(localData.connection_assets).length === 0) {
            // No hay información de conexión
            setShowNewData(true)
            setShowEditData(false)

        } else {

            setInitial(true)
            setShowNewData(false)
            setShowEditData(true)
            setKey(localData.connection_assets.asset_2)
            setID(localData.connection_assets.asset_4)
            setSecret(localData.connection_assets.asset_3)

        }

    }, [localData]);


    const convertDate = (fechaUTC) => {

        // Obtener la zona horaria del navegador
        const navegadorZonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        // Convertir la fecha a la zona horaria del navegador
        const fechaEnUTC = moment.utc(fechaUTC);
        const fechaConvertida = fechaEnUTC.tz(navegadorZonaHoraria);
    
        // Formatear la fecha
        const formattedDate = fechaConvertida.format('HH:mm:ss DD-MM-YYYY');
    
        return formattedDate;
    };
    const handleKey = (event) => {
        const value = event.target.value;


        if (value.length !== 32) {
            setKeyErrorMessage(lang[langCode].weather.thertyTwoChars);
            setActiveButton(false)
        } else {
            setKeyErrorMessage('');
            if (showEditData == true) {
                setActiveButton(true)
            }
            setKey(value)
        }

    };



    const handleSecret = (event) => {
        const value = event.target.value;


        if (value.length !== 32) {
            setSecretErrorMessage(lang[langCode].weather.thertyTwoChars);
            setActiveButton(false)
        } else {
            setSecretErrorMessage('');
            if (showEditData == true) {
                setActiveButton(true)
            }
            setSecret(value)
        }

    };


    const handleID = (event) => {
        const value = event.target.value;


        if (value.length !== 12) {
            setIDErrorMessage(lang[langCode].weather.twelveChars);
            setActiveButton(false)

        } else {
            setIDErrorMessage('');
            if (showEditData == true) {
                setActiveButton(true)
            }
            setID(value)
        }

    };


    return (

        <div>


            <div>

                {status === 'Borrador' && (
                    <div style={{ paddingTop: '10px' }}>
                        <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>{lang[langCode].weather.draftInstruccionsWLLive}</p>
                    </div>
                )}
                {status === 'Dato erróneo detectado' && (
                    <div style={{ paddingTop: '10px' }}>
                        <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>{lang[langCode].weather.warningInstructións}</p>
                    </div>
                )}
                {status === 'Online' && (
                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>
                            {lang[langCode].weather.onlineInstruccions}{' '}
                            <strong>{convertDate(localData.current_weather_data.updated_at, localData.location.time_zone)}</strong>
                        </p>
                    </div>
                )}
                {status === 'Offline' && (
                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>
                            {lang[langCode].weather.oflineInstruccions}{' '}
                            <strong>{convertDate(localData.current_weather_data.updated_at, localData.location.time_zone)}</strong>
                        </p>
                    </div>
                )}

                {loading && (
                    <div className='row'>
                        <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={loader}
                                style={{
                                    width: '50px',
                                    marginTop: '30px '
                                }}
                            />
                        </div>
                    </div>
                )}
                {normalInfo && (
                    <div className='row' style={{ paddingTop: '0px' }}>
                        <div className='col-12'>
                            <div className='row' style={{ paddingTop: '0px' }}>
                                <div className='col-3' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.wlliveID}</p>
                                </div>
                                <div className='col-9' style={{ paddingRight: '20px' }} >
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.wlliveIDPH}
                                        defaultValue={localData.connection_assets && localData.connection_assets.asset_4 ? localData.connection_assets.asset_4 : ''}
                                        onChange={handleID}
                                        style={{
                                            border: `1px solid ${IDErrorMessage ? 'red' : '#CCC'}`,
                                            borderRadius: '5px',
                                            fontSize: '12px',
                                            fontFamily: 'montserratregular',
                                            width: '100%',
                                            height: '30px',  // Puedes ajustar la altura según tus necesidades
                                            outline: 'none',  // Evita el contorno al obtener el foco
                                        }}
                                    />
                                    {IDErrorMessage && <p style={{ color: 'red', margin: '0px' }}>{IDErrorMessage}</p>}
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-3' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.wlliveKey}</p>
                                </div>
                                <div className='col-9' style={{ paddingRight: '20px' }} >
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.wlliveKeyPH}
                                        defaultValue={localData.connection_assets && localData.connection_assets.asset_2 ? localData.connection_assets.asset_2 : ''}
                                        onChange={handleKey}
                                        style={{
                                            border: `1px solid ${keyErrorMessage ? 'red' : '#CCC'}`,
                                            borderRadius: '5px',
                                            fontSize: '12px',
                                            fontFamily: 'montserratregular',
                                            width: '100%',
                                            height: '30px',  // Puedes ajustar la altura según tus necesidades
                                            outline: 'none',  // Evita el contorno al obtener el foco
                                        }}
                                    />
                                    {keyErrorMessage && <p style={{ color: 'red', margin: '0px' }}>{keyErrorMessage}</p>}
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-3' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.wlliveSecret}</p>
                                </div>
                                <div className='col-9' style={{ paddingRight: '20px' }} >
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.wlliveSecretPH}
                                        defaultValue={localData.connection_assets && localData.connection_assets.asset_3 ? localData.connection_assets.asset_3 : ''}
                                        onChange={handleSecret}
                                        style={{
                                            border: `1px solid ${ScretErrorMessage ? 'red' : '#CCC'}`,
                                            borderRadius: '5px',
                                            fontSize: '12px',
                                            fontFamily: 'montserratregular',
                                            width: '100%',
                                            height: '30px',  // Puedes ajustar la altura según tus necesidades
                                            outline: 'none',  // Evita el contorno al obtener el foco
                                        }}
                                    />
                                    {ScretErrorMessage && <p style={{ color: 'red', margin: '0px' }}>{ScretErrorMessage}</p>}
                                </div>
                            </div>

                        </div>
                        <div className='col-12' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', paddingTop:'10px' }} >
                            <div style={{ display: 'flex', alignItems: 'center' }}  onClick={openWLLModal}>
                                <img
                                    style={{ width: '17px', marginRight: '3px' }}
                                    src={info} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                    alt=""
                                   
                                />
                                <p style={{ fontSize: '12px', fontFamily: 'montserratbold', textAlign: 'justify', margin: '0px', marginLeft: '5px', textDecoration: 'underline' }}>{lang[langCode].weather.findWlliveKey}</p>
                            </div>
                            {showConnectionError && (
                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>

                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', textAlign: 'justify', margin: '0px', marginLeft: '5px', color: 'red' }}>{lang[langCode].weather.wllerror}</p>
                                </div>
                            )}
                            {showNewData && (
                                <button
                                    style={{
                                        fontFamily: 'montserratregular',
                                        fontSize: '12px',
                                        backgroundColor: activeButton ? '#0074EF' : '#E7E7E7', // Cambia el color del texto según activeButton
                                        color: activeButton ? 'white' : '#A7A7A7', // Cambia el color del texto según activeButton
                                        marginTop: '10px', // Ajusta el margen superior según tus necesidades
                                    }}
                                    disabled={!activeButton} // Deshabilita el botón si activeButton es falso
                                    onClick={saveParams}
                                >
                                    {lang[langCode].weather.connectionText}
                                </button>
                            )}
                            {showEditData && (
                                <button
                                    style={{
                                        fontFamily: 'montserratregular',
                                        fontSize: '12px',
                                        backgroundColor: activeButton ? '#0074EF' : '#E7E7E7', // Cambia el color del texto según activeButton
                                        color: activeButton ? 'white' : '#A7A7A7', // Cambia el color del texto según activeButton
                                        marginTop: '15px', // Ajusta el margen superior según tus necesidades
                                    
                                    }}
                                    disabled={!activeButton} // Deshabilita el botón si activeButton es falso
                                    onClick={saveParams}
                                >
                                    {lang[langCode].weather.editConnectionText}
                                </button>


                            )}
                        </div>

                    </div>
                )}
                <WeatherlinkLiveInfo
                    isOpen={isWLLModalOpen}
                    onClose={closeWLLModal}

                />
            </div>
        </div>
    );
};

export default StationStatusWLLive;
import React, { useState, useEffect } from 'react';
import lang from '../../resources/js/dictionary.js';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';

const StationInfo = ({ getBasicInfo, activateButton, deactivateButton }) => {
  const langCode = useLangContext();
  const csrfToken = useCsrfToken();
  const [stations, setStations] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState('');
  const [selectedResetTime, setSelectedResetTime] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [nameInputValue, setNameInputValue] = useState('');
  const [infoData, setInfoData] = useState({
    name: '',
    description: '',
    connection: '',
    resetTime: '',
    model: '',
    brand: '',
  });

  useEffect(() => {
    getStations();
  }, []);

  useEffect(() => {
    checkData();

  }, [selectedConnection]);

  useEffect(() => {
    getBasicInfo(infoData);

  }, [infoData]);


  const getStations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch("api/public/station-type", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        },
      });

      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }

      const data = await response.json();
      setStations(data);
    } catch (error) {
      console.error('Error en la petición fetch:', error);
    }
  };

  const handleBrandChange = (event) => {
    const value = event.target.value;

    setInfoData((prev) => ({ ...prev, brand: value }));
    setSelectedBrand(value);
    // También puedes reiniciar el modelo cuando cambia la marca
    setSelectedModel(null);
  };

  const handleModelChange = (event) => {
    const value = event.target.value;

    setInfoData((prev) => ({ ...prev, model: value }));
    setSelectedModel(value);
    checkData();
  };

  const handleConnectionChange = (event) => {
    const value = event.target.value;

    setInfoData((prev) => ({ ...prev, connection: value }));
    setSelectedConnection(value);
    checkData();
  };

  const handleResetTimeChange = (event) => {
    const value = event.target.value;

    setInfoData((prev) => ({ ...prev, resetTime: value }));
    setSelectedResetTime(value);
    checkData();
  };

  const handleTextareaChange = (event) => {
    const value = event.target.value;

    setInfoData((prev) => ({ ...prev, description: value }));
    setTextareaValue(value);

    if (value.length > 100) {
      setErrorMessage(lang[langCode].weather.characterExceed);
    } else {
      setErrorMessage('');
    }
    checkData();
  };

  const handleNameInputChange = (event) => {
    const value = event.target.value;

    setInfoData((prev) => ({ ...prev, name: value }));
    setNameInputValue(value);

    if (value.length > 30) {
      setNameErrorMessage(lang[langCode].weather.nameExceed);
    } else {
      setNameErrorMessage('');
    }
    checkData();
  };

  const checkData = () => {

    const isNameValid = nameInputValue.trim() !== '' && nameInputValue.length <= 30;
    const isDescriptionValid = textareaValue.length <= 100;
    const isConnectionValid = selectedConnection !== '';  // Verifica si hay una conexión seleccionada
    const isResetTimeValid = selectedResetTime !== '';  // Verifica si selectedResetTime no está vacío

    if (isNameValid && isDescriptionValid && isConnectionValid && isResetTimeValid) {
      activateButton('info');
    } else {
      deactivateButton('info');
    }


  };


  return (
    <div>
      <div className='container' style={{ padding: '5px' }}>



        <div className='row' style={{ paddingTop: '0px' }} >
          <div className='col-3' style={{ paddingLeft: '15px', }} >
            <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.name}</p>
          </div>
          <div className='col-9' style={{ paddingRight: '20px' }} >
            <input
              type="text"
              placeholder={lang[langCode].weather.namePlaceholder}
              value={nameInputValue}
              onChange={handleNameInputChange}
              style={{
                border: `1px solid ${nameErrorMessage ? 'red' : '#CCC'}`,
                borderRadius: '5px',
                fontSize: '12px',
                fontFamily: 'montserratregular',
                width: '100%',
                height: '30px',  // Puedes ajustar la altura según tus necesidades
                outline: 'none',  // Evita el contorno al obtener el foco
              }}
            />
            {nameErrorMessage && <p style={{ color: 'red' }}>{nameErrorMessage}</p>}
          </div>
        </div>
        <div className='row' style={{ paddingTop: '10px' }}>
          <div className='col-3' style={{ paddingLeft: '15px' }}>
            <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '0px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.reset}</p>
          </div>
          <div className='col-9' style={{ paddingRight: '20px' }}>


            <div>
              <select
                onChange={handleResetTimeChange}
                value={selectedResetTime}
                style={{fontSize: '12px'}}

              >
                <option value="" disabled>{lang[langCode].weather.selectHour}</option>
                <option value="local">{lang[langCode].weather.local}</option>
                <option value="utc">UTC</option>
              </select>

            </div>

          </div>
        </div>
        <div className='row' style={{ paddingTop: '0px' }}>
          <div className='col-3' style={{ paddingLeft: '15px' }}>
            <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.description}</p>
          </div>
          <div className='col-9' style={{ paddingRight: '20px' }}>
            <textarea
              value={textareaValue}
              onChange={handleTextareaChange}
              style={{
                border: `1px solid ${errorMessage ? 'red' : '#CCC'}`,
                borderRadius: '5px',
                fontSize: '12px',
                fontFamily: 'montserratregular',
                width: '100%',
                height: '75px',
                outline: 'none',  // Evita el contorno al obtener el foco
              }}
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </div>
        </div>
        <div className='row' style={{ paddingTop: '5px' }} >
          <div className='col-3' style={{ paddingLeft: '15px' }} >
            <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.brand}</p>
          </div>
          <div className='col-9' style={{ paddingRight: '20px' }} >
            <select  style={{fontSize: '12px'}} onChange={handleBrandChange} value={selectedBrand || ''}>
              <option      value="" disabled>{lang[langCode].weather.brandPlaceHolder}</option>
              {Object.keys(stations).map((brand) => (
                <option key={brand} value={brand}>{brand}</option>
              ))}
            </select>
          </div>
        </div>

        <div className='row' style={{ paddingTop: '5px' }} >
          <div className='col-3' style={{ paddingLeft: '15px' }} >
            <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.model}</p>
          </div>
          <div className='col-9' style={{ paddingRight: '20px' }} >
            {selectedBrand && (
              <div>

                <select
                  onChange={handleModelChange}
                  value={selectedModel || ''}
                  disabled={!selectedBrand} // Deshabilita si no hay marca seleccionada
                  style={{fontSize: '12px'}}
                >
                  <option value="" disabled>{lang[langCode].weather.modelPlaceHolder}</option>
                  {stations[selectedBrand].map((model) => (
                    <option key={model.model} value={model.model}>{model.model}</option>
                  ))}
                </select>
              </div>
            )}

          </div>
        </div>
        <div className='row' style={{ paddingTop: '5px', paddingBottom:'10px' }}>
          <div className='col-3' style={{ paddingLeft: '15px' }}>
            <p style={{ fontSize: '13px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.connection}</p>
          </div>
          <div className='col-9' style={{ paddingRight: '20px' }}>

            {selectedBrand && selectedModel && (
              <div>
                <select
                  onChange={handleConnectionChange}
                  value={selectedConnection}
                  disabled={!selectedBrand || !selectedModel}
                  style={{fontSize: '12px'}}
                >
                  <option value="" disabled>{lang[langCode].weather.connectionPlaceHolder}</option>
                  {stations[selectedBrand].find(model => model.model === selectedModel)?.connections.map((connection) => (
                    <option key={connection} value={connection}>{connection}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>



      </div>





    </div>
  );
};

export default StationInfo;
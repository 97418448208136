import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import close from '../../resources/icons/cross.png';

Modal.setAppElement('#root');

const WsViewInfo = ({ isOpen, onClose, expirationDate }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();





    const [customStyles, setCustomStyles] = useState({
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 999,
          },
          content: {
            width: '95%',
            maxWidth: '100%',  // Set the maximum width to 100% of the screen
            maxHeight: '95vh',  // Set the maximum height to 95% of the viewport height
            height: '75%',     // Set the height to 'auto'
            margin: 'auto',
            left: '0',
            right: '0',
            top: '5%',
            bottom: '10%',
            overflow: 'auto',   // Enable scrolling if content exceeds the modal size
            padding: '5px',
            border: 'none',
            borderRadius: '8px',
            position: 'fixed',
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Renovar Subscripcion"
            style={customStyles}        >
            <div className='row'>
                <div className='col-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </a>
                </div>
                <div className='col-10'>

                </div>
            </div>
            <div style={{ backgroundColor: 'white' }}>
              
            </div>
        </Modal>
    )

}

export default WsViewInfo;

import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import close from '../../resources/icons/cross.png';
import loader from '../../resources/img/loader.svg';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import noPicture from '../../resources/img/no_foto.svg';
import PeopleButtons from '../People/PeopleButtons';
import view from '../../resources/icons/view.png';
import { useNavigate } from 'react-router-dom';
import del from '../../resources/icons/delete.png';


Modal.setAppElement('#root');

const PeopleFollowedTag = ({ isOpen, onClose, userData, manageTags,tagedPeopleFather }) => {

    const [customStyles, setCustomStyles] = useState({
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 999,
        },
        content: {
          width: '95%',
          maxWidth: '100%',  // Set the maximum width to 100% of the screen
          maxHeight: '95vh',  // Set the maximum height to 95% of the viewport height
          height: '90vh',     // Set the height to 'auto'
          margin: 'auto',
          left: '0',
          right: '0',
          top: '5%',
          bottom: '10%',
      //    overflow: 'auto',   // Enable scrolling if content exceeds the modal size
          padding: '5px',
          border: 'none',
          borderRadius: '8px',
          position: 'fixed',
        },
      });
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(false);
    const search = useRef(' ');
    const hasMoreRef = useRef(true); // Usamos una referencia para seguimiento
    const [searchParam, setSearchParam] = useState("");
    const page = useRef(1);
    const isFetchingData = useRef(false);
    const [hoveredLinkId, setHoveredLinkId] = useState(null);
    const [showTagedPeople, setShowTagedPeople] = useState(false);
    const [tagedPeople, setTagedPeople] = useState(tagedPeopleFather);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    let typingTimer;



    useEffect(() => {
        if (isOpen) {


            setPeople([]);
            hasMoreRef.current = true;
            page.current = 1;

            const loadAndAddScrollListener = () => {
                const modalContent = document.getElementById('followingModal');
                if (modalContent) {
                    modalContent.addEventListener('scroll', handleScroll);
                    loadMorePeople();
                } else {
                    setTimeout(loadAndAddScrollListener, 100);
                }
            };

            loadAndAddScrollListener();
        }

        return () => {
            const modalContent = document.getElementById('followingModal');
            if (modalContent) {
                modalContent.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isOpen]);

    const handleScroll = () => {
        const modalContent = document.getElementById('followingModal');
        if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 200) {
            loadMorePeople();
        }
    };

    const loadMorePeople = async () => {
        if (!hasMoreRef.current || isFetchingData.current) {
            return;
        }
        setLoading(true);
        isFetchingData.current = true;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`api/public/get-people?page=${page.current}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    search: searchParam,
                }),
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();

            if (data && Array.isArray(data.usuarios)) {
                const modalContent = document.querySelector('.Personal');
                if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 200) {

                    if (data.usuarios.length === 0) {
                        hasMoreRef.current = false;
                    } else {
                        setPeople((prevPeople) => [...prevPeople, ...data.usuarios]);
                        page.current += 1;
                    }
                }
            } else {
                hasMoreRef.current = false;
                console.error('La estructura de la respuesta del servidor es incorrecta');
            }
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        } finally {
            isFetchingData.current = false;
            setLoading(false);
        }
    };

    const viewProfile = (id) => {
        navigate('/profile-view', { state: { personId: id } });
        onClose()
    }


    const launchSearch = (event) => {



        clearTimeout(typingTimer);
        const { value } = event.target;
        typingTimer = setTimeout(() => {
            hasMoreRef.current = true
            setSearchParam(search.current.value);

        }, 1000); // 1000 milisegundos (1 segundo)

    }

    useEffect(() => {

        // Si searchParam ha cambiado y no está vacío, ejecuta loadMorePeople

        page.current = 1;
        setPeople([]);
        loadMorePeople();
        const modalContent = document.getElementById('followingModal');
        if (modalContent) {
            modalContent.removeEventListener('scroll', handleScroll);
        }

    }, [searchParam]);

    useEffect(() => {
  
        if (tagedPeople.length !== 0) {
            setShowTagedPeople(true)
        }
        else {
            setShowTagedPeople(false)
        }
    }, [tagedPeople]);


    const tagThisPerson = (person, e) => {
        e.preventDefault();
        // Verifica si la persona ya está etiquetada
        const isAlreadyTagged = tagedPeople.some((taggedPerson) => taggedPerson.id === person.id);

        if (isAlreadyTagged) {
            // Si ya está etiquetada, elimínala de la lista
            setTagedPeople((prevTagedPeople) => prevTagedPeople.filter((taggedPerson) => taggedPerson.id !== person.id));
        } else if (tagedPeople.length < 5) {
            // Si no está etiquetada y no se ha alcanzado el límite, agrégala a la lista
            setTagedPeople((prevTagedPeople) => [...prevTagedPeople, person]);
        } else {
            // Si se ha alcanzado el límite, muestra un mensaje de error
            setErrorMessage(lang[langCode].posts.maxTags);
        }
    };


    const deleteTagedUser = (person, e) => {
        e.preventDefault();
        setErrorMessage('');
       
        // Crea una nueva lista que excluye la persona que deseas eliminar
        setTagedPeople((prevTagedPeople) => prevTagedPeople.filter((taggedPerson) => taggedPerson.id !== person.id));


    };

    const sendTags = () =>
    {
        manageTags(tagedPeople)
        onClose();
    }

    //manageTags


    return (

        <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Modal Siguiendo" style={customStyles} id="followingModal">
            <div className='row'>
                <div className='col-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px',
                            }}
                        />
                    </a>
                </div>
                <div className='col-10'>
                    {/* Contenido adicional del modal */}
                </div>
            </div>
            <div className='Personal' style={{ backgroundColor: 'white' }}>
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-12' style={{ paddingLeft: '30px', textAlign: 'center' }}>
                        <h3>{lang[langCode].posts.tagPeople}</h3>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-8'>
                        <input
                            type="text"
                            placeholder={lang[langCode].mainPeople.lookForPeople}
                            ref={search}
                            onChange={launchSearch}
                        />
                    </div>
                
                    <div className='col-4'>
                        <button onClick={sendTags}>{lang[langCode].generic.ok}</button>
                    </div>
                </div>
                {showTagedPeople && (
                    <div className='row' style={{ paddingTop: '10px',paddingRight:'10px',paddingLeft:'10px' }}>
                        {tagedPeople.map((person, index) => (
                            <div className='col-6' key={index} style={{ textAlign: 'center', padding: '2px', display: 'flex' }}>
                                {/* Contenido que deseas renderizar para cada elemento, con el enlace dentro del párrafo */}
                                <p
                                    style={{
                                        margin: '0px',
                                        backgroundColor: '#C7DDF3',
                                        borderRadius: '5px',
                                        flex: '1',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '5px'
                                    }}>
                                    {person.name} {person.last_name}
                                    <a href='#' onClick={(event) => deleteTagedUser(person, event)} style={{ width: '14px', textAlign: 'center' }}>
                                        <img
                                            style={{ width: '12px', marginLeft: '5px', marginBottom: '3px' }}
                                            src={del}
                                            alt=""
                                        />
                                    </a>
                                </p>
                                {/* Agregar más contenido según sea necesario */}
                            </div>
                        ))}
                        {errorMessage && (
                            <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                                <p style={{ color: 'red', margin: '0px' }}>{errorMessage}</p>
                            </div>
                        )}
                    </div>


                )}
                <div className='row' style={{ paddingTop: '10px' }}>
                    {people.map((person, index) => (
                        <div key={index}>
                            <a href='#' onClick={(event) => tagThisPerson(person, event)}
                                style={{
                                    paddingTop: '7px', paddingBottom: '7px',
                                    display: 'inline-block',
                                    transition: 'background-color 0.1s', // Agrega una transición suave
                                    backgroundColor: hoveredLinkId === index ? '#E7E7E7' : 'white',
                                }}
                                className="custom-link"
                                onMouseEnter={() => setHoveredLinkId(index)} // Establece el enlace sobre el que se encuentra el mouse
                                onMouseLeave={() => setHoveredLinkId(null)} // Reinicia el enlace sobre el que se encuentra el mouse
                            >

                                <div className='row'>

                                    <div className='col-2' style={{ textAlign: 'center' }}>

                                        {person.picture === null ? (
                                            <img style={{ width: '35px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={noPicture} alt="" />

                                        ) :

                                            <img style={{ width: '35px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={"https://www.skyrushcommunity.com/api/public/user_pictures/" + person.picture} alt="" />}
                                    </div>
                                    <div className='col-10' style={{ textAlign: 'left', padding: '0px' }}>
                                        <p style={{ margin: '0px', fontFamily: 'montserratbold' }}>{person.name} {person.last_name}</p>
                                    </div>
                                
                                    {/* Agrega más campos según la estructura de tus datos */}

                                </div>
                            </a>
                        </div>

                    ))}
                </div>
                {loading && (
                    <div className='row'>
                        <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={loader}
                                style={{
                                    width: '50px',
                                    marginTop: '25%',
                                    marginBottom: '25%',
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Modal >
    );
};

export default PeopleFollowedTag;



import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import wididinfo from '../../resources/img/wlDIDInfo.png';
import wdclaveinfo from '../../resources/img/wdClaveInfo.png';
import wdsecretinfo from '../../resources/img/wdSecretInfo.png';


Modal.setAppElement('#root');


const WeratherlinkLiveInfo = ({ isOpen, onClose, expirationDate }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();





    const [customStyles, setCustomStyles] = useState({
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 999,
          },
          content: {
            width: '95%',
            maxWidth: '100%',  // Set the maximum width to 100% of the screen
            maxHeight: '95vh',  // Set the maximum height to 95% of the viewport height
            height: '75%',     // Set the height to 'auto'
            margin: 'auto',
            left: '0',
            right: '0',
            top: '5%',
            bottom: '10%',
            overflow: 'auto',   // Enable scrolling if content exceeds the modal size
            padding: '5px',
            border: 'none',
            borderRadius: '8px',
            position: 'fixed',
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Renovar Subscripcion"
            style={customStyles}        >
            <div className='row'>
                <div className='col-lg-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </a>
                </div>
                <div className='col-lg-10'>

                </div>
            </div>
            <div className='row' style={{ paddingTop: '0px' }}>
                <div className='col-lg-12' style={{ textAlign: 'center' }} >
                    <p style={{ fontSize: '16px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wllIDInstructionTitle} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12' style={{ textAlign: 'left' }} >
                    <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wllIDInstruction} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12'  >
                    <div style={{ backgroundColor: 'white' }}>
                        <img
                            style={{ width: '100%', marginRight: '3px' }}
                            src={wididinfo} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                            alt=""
                        />

                    </div>
                </div>
            </div>
            <hr style={{ color: '#0074EF', borderWidth: '5px' }}></hr>
            <div className='row' style={{ paddingTop: '0px' }}>
                <div className='col-lg-12' style={{ textAlign: 'center' }} >
                    <p style={{ fontSize: '16px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wllKeyInstructionTitle} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12' style={{ textAlign: 'left' }} >
                    <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wllKeyInstruction} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12'  >
                    <div style={{ backgroundColor: 'white' }}>
                        <img
                            style={{ width: '100%', marginRight: '3px' }}
                            src={wdclaveinfo} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                            alt=""
                        />

                    </div>
                </div>
            </div>
            <hr style={{ color: '#0074EF', borderWidth: '5px' }}></hr>
            <div className='row' style={{ paddingTop: '0px' }}>
                <div className='col-lg-12' style={{ textAlign: 'center' }} >
                    <p style={{ fontSize: '16px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wllSecretInstructionTitle} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12' style={{ textAlign: 'left' }} >
                    <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wllSecretInstruction} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12'  >
                    <div style={{ backgroundColor: 'white' }}>
                        <img
                            style={{ width: '100%', marginRight: '3px' }}
                            src={wdsecretinfo} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                            alt=""
                        />

                    </div>
                </div>
            </div>
            
        </Modal>
    )

}

export default WeratherlinkLiveInfo;

import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../../Contexts/CsrfTokenContext';
import no_cert from '../../../resources/img/certs/no_cert.png';
import gold_cert from '../../../resources/img/certs/gold_cert.png';
import termo_rain_cert from '../../../resources/img/certs/termo_rain_cert.png';
import termo_wind_cert from '../../../resources/img/certs/termo_wind_cert.png';
import termo_cert from '../../../resources/img/certs/termo_cert.png';
import warning_cert from '../../../resources/img/certs/warning_cert.png';
import lang from '../../../resources/js/dictionary.js';
import red_light from '../../../resources/icons/red_light.png';
import green_light from '../../../resources/icons/green_light.png';
import yellow_light from '../../../resources/icons/yellow_light.png';
import SkyConditions from './SkyConditions.js';
import rain from '../../../resources/weather_icons/fixed/rain.svg';
import wind from '../../../resources/weather_icons/fixed/wind.png';
import wind_dir from '../../../resources/weather_icons/fixed/wind_dir.png';
import humidity from '../../../resources/weather_icons/fixed/humidity.png';
import moment from 'moment-timezone';

const StationMiniCard = ({ data }) => {

    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [cert, setCert] = useState(data.quality_cert);
    const [certImg, setCertImg] = useState(null);
    const [certName, setCertName] = useState(null);
    const [place, setPlace] = useState(null);
    const [region, setRegion] = useState(null);
    const [ligth, setLight] = useState(null);
    const [status, setStatus] = useState(null);
    const [cardinalDirection, setCardinalDirection] = useState('');

    const determineCardinalDirection = (degrees) => {
        const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
        const index = Math.round(degrees / 45) % 8;
        setCardinalDirection(directions[index]);
    };

    const getRotationAngle = () => {
        // Calculate the rotation angle based on the cardinal direction
        const directionAngles = {
            N: 0,
            NE: 45,
            E: 90,
            SE: 135,
            S: 180,
            SW: 225,
            W: 270,
            NW: 315,
        };

        return directionAngles[cardinalDirection] || 0;
    };

    const convertDate = (fechaUTC) => {


        // Obtener la zona horaria del navegador
        const navegadorZonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        // Convertir la fecha a la zona horaria del navegador
        const fechaEnUTC = moment.utc(fechaUTC);
        const fechaConvertida = fechaEnUTC.tz(navegadorZonaHoraria);
    
        // Formatear la fecha
        const formattedDate = fechaConvertida.format('HH:mm:ss DD-MM-YYYY');
    
        return formattedDate;
    };


    useEffect(() => {

       
        if (data.location.city !== null) {
            setPlace(data.location.city)
        }
        else if (data.location.town !== null && data.location.city === null) {
            setPlace(data.location.town)
        }
        else if (data.location.town === null && data.location.village !== null && data.location.city === null) {
            setPlace(data.location.village)
        }
        else if (data.location.town === null && data.location.village === null && data.location.hamlet !== null && data.location.city === null) {
            setPlace(data.location.hamlet)
        }

        if (data.location.region !== null) {
            setRegion(data.location.region)
        }
        else if (data.location.state !== null) {
            setRegion(data.location.state)
        }
        else if (data.location.state_district !== null) {
            setRegion(data.location.state_district)
        }


        if (data.status === 'draft') {
            setLight(yellow_light)
            setStatus(lang[langCode].weather.draft)
        }
        else if (data.status === 'online') {
            setLight(green_light)
            setStatus(lang[langCode].weather.online)
        }
        else if (data.status === 'offline') {
            setLight(red_light)
            setStatus(lang[langCode].weather.offline)
        }
        else if (data.status === 'warning') {
            setLight(warning_cert)
            setStatus(lang[langCode].weather.warning)
        }

        determineCardinalDirection(data.current_weather_data.wind_dir);

    }, [data]);

    useEffect(() => {


        if (cert === 'no_cert') {
            setCertImg(no_cert)

            setCertName(lang[langCode].weather.noCertName)
        }
        else if (cert === 'gold_cert') {
            setCertImg(gold_cert)

            setCertName(lang[langCode].weather.avancedCertName)
        }
        else if (cert === 'termo_rain_cert') {
            setCertImg(termo_rain_cert)

            setCertName(lang[langCode].weather.termoRainCertName)
        }
        else if (cert === 'termo_wind_cert') {
            setCertImg(termo_wind_cert)

            setCertName(lang[langCode].weather.termoWindCertName)
        }
        else if (cert === 'termo_cert') {
            setCertImg(termo_cert)

            setCertName(lang[langCode].weather.termoCertName)
        }
        else if (cert === 'warning_cert') {
            setCertImg(warning_cert)

            setCertName(lang[langCode].weather.warningCertName)
        }


    }, [cert]);

    const transformAndRound = (data) => {
        const numericData = Number(data);
        if (!isNaN(numericData)) {
            const roundedData = Math.round(numericData * 10) / 10;
            return roundedData;
        } else {
            return data;
        }
    };


    const transformAndRoundZero = (data) => {
        const numericData = Number(data);

        if (!isNaN(numericData)) {
            const roundedData = Math.round(numericData);
            return roundedData;
        } else {
            return data;
        }
    };



    return (

        <div>
            <div className='container'
                style={{
                    paddingTop: '10px',
                    border: '1px solid #E9E9E9',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    marginTop: '10px',
                    marginLeft: '-1px', // Ajusta el margen izquierdo para superponer el borde izquierdo al fondo blanco
                    marginRight: '-1px', // Ajusta el margen derecho para superponer el borde derecho al fondo blanco
                }}>

                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <img
                            style={{ width: '15px', marginRight: '3px' }}
                            src={ligth} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                            alt=""
                        />
                        <h3 style={{ fontSize: '13px', margin: '0px' }}> {data.name} - {data.location.altitude}m </h3>
                    </div>

                </div>
                <div className='row' style={{ paddingTop: '7px' }}>
                    <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <img
                            style={{ width: '20px', marginBottom: '10px' }}
                            src={require(`../../../resources/country_flag/${data.location.country_iso.toLowerCase()}.svg`)} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                            alt=""
                        />
                        <h3 style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px' }}>{place}, {region} , {data.location.country}</h3>
                    </div>
                </div>
                {data.status === 'online' ? (
                    <div>
                        <div className='row' style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                            <div className='col-lg-12'>
                                <div className='row' style={{ paddingTop: '0 px', paddingLeft: '5%' }}>
                                    <div className='col-lg-2' style={{ textAlign: 'center ', padding: '0px' }}>
                                        <SkyConditions data={data}   imgSize={70}/>
                                        <div className='row' style={{ paddingTop: '0 px' }}>
                                            <div className='col-lg-12' style={{ textAlign: 'center ', padding: '0px' }}>
                                                <h3 style={{ fontSize: '18px', margin: '0px' }}> {transformAndRound(data.current_weather_data.temp)}°</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-2 d-flex flex-column align-items-center justify-content-center' style={{ textAlign: 'center' }}>
                                        <div className='row' style={{ paddingTop: '0px' }}>
                                            <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                                <p style={{ fontSize: '15px', margin: '0px', color: 'red' }}>{transformAndRound(data.current_weather_data.temp_max)}°</p>
                                            </div>
                                        </div>
                                        <div className='row' style={{ paddingTop: '0px' }}>
                                            <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                                <p style={{ fontSize: '15px', margin: '0px', color: '#1273EB' }}>{transformAndRound(data.current_weather_data.temp_min)}°</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 d-flex align-items-center justify-content-center' style={{ textAlign: 'center', borderLeft: '1px solid #E9E9E9' }}>
                                        <div className='row' style={{ paddingTop: '0px' }}>
                                            <div className='col-lg-3' style={{ textAlign: 'center', padding: '0px' }}>
                                                <img
                                                    style={{ width: '50px' }}
                                                    src={rain}
                                                    alt=""
                                                />
                                                <p style={{ fontSize: '12px', margin: '0px' }}>{transformAndRound(data.current_weather_data.daily_rain)} mm</p>
                                            </div>
                                            <div className='col-lg-3' style={{ textAlign: 'center', padding: '0px' }}>
                                                <div style={{ marginLeft: '13px' }}>
                                                    <img
                                                        style={{ width: '50px', marginTop: '7px' }}
                                                        src={wind}
                                                        alt=""
                                                    />
                                                    <p style={{ fontSize: '12px', margin: '0px' }}>{transformAndRound(data.current_weather_data.wind_speed)} km/h</p>
                                                </div>
                                            </div>
                                            <div className='col-lg-3' style={{ textAlign: 'center', padding: '0px' }}>
                                                <div style={{ marginLeft: '13px' }}>
                                                    <img
                                                        style={{
                                                            width: '30px',
                                                            transform: `rotate(${getRotationAngle()}deg)`,
                                                            marginTop: '10px',
                                                            marginLeft: '5px'

                                                        }}
                                                        src={wind_dir}
                                                        alt="Wind Direction"
                                                    />
                                                    <p style={{ fontSize: '12px', marginTop: '10px', marginLeft: '5px' }}>{cardinalDirection}</p>
                                                </div>
                                            </div>
                                            <div className='col-lg-3' style={{ textAlign: 'center', padding: '0px' }}>
                                                <div style={{ marginLeft: '13px' }}>
                                                    <img
                                                        style={{ width: '40px', marginTop: '10px' }}
                                                        src={humidity}
                                                        alt=""
                                                    />
                                                    <p style={{ fontSize: '12px', margin: '0px' }}>{transformAndRoundZero(data.current_weather_data.humidity)} %</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row' style={{ paddingTop: '10px' }}>
                                    <div className='col-lg-6' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <img
                                            style={{ width: '13px', marginBottom: '1px' }}
                                            src={certImg} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                            alt=""
                                        />
                                        <h3 style={{ fontSize: '10px', fontFamily: 'montserratregular', padding: '5px', margin: '0px' }}>{certName}</h3>
                                    </div>
                                    <div className='col-lg-6' style={{ textAlign:'right' }}>
                                        <p style={{ fontSize: '8px', fontFamily: 'montserratregular', margin:'0px' }}>
                                            {lang[langCode].stations.lastUpdate}{' '}
                                            {convertDate(data.current_weather_data.updated_at, data.location.time_zone)}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row' style={{ paddingTop: '0px', paddingBottom: '5px' }}>
                        </div>
                    </div>
                ) : (
                    <div style={{ paddingTop: '7%', paddingBottom: '7%' }}>
                        <p>{lang[langCode].stations.dataError}</p>
                        {/* You can modify the text or HTML here as needed */}
                    </div>
                )}
            </div>

        </div>
    );
};

export default StationMiniCard;

import React, { useState } from 'react';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import google from '../../resources/img/google.png';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import ResetPasswordModal from '../Modals/ResetPassword';
import SingInModal from '../Modals/SingIn';


import '../../css/Main.css';


export const SingInComponent = () => {

  const csrfToken = useCsrfToken();
  const langCode = useLangContext();
  const [inputValue, setInputValue] = useState(''); // Estado para almacenar el valor del input
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isSingInModalOpen, setIsSingInModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(1);


  const openResetPasswordModal = () => {
    setIsResetPasswordModalOpen(true);
  };

  const closeResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false);
  };

  const singInButtonOnClick = () => {
    setIsSingInModalOpen(true);
  };

  const closeSingInModal = (e) => {
    e.stopPropagation();
    setIsSingInModalOpen(false);
    setModalKey(prevKey => prevKey + 1); 

  };

  const loginButtonClick = () => {
    let isValid = true;

    if (email.trim() === '') {
      setEmailError(lang[langCode].setButtons.mailValidation);


      isValid = false;
    } else {
      setEmailError('');
    }

    if (password.trim() === '') {
      setPasswordError(lang[langCode].setButtons.passwordValidation);
      isValid = false;
    } else {
      setPasswordError('');
    }

    if (isValid) {
      fetch("https://www.skyrushcommunity.com/api/public/login", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("La solicitud no fue exitosa");
          }
          return response.json();
        })
        .then(data => {
          var token = data.token;
          localStorage.setItem('token', token);
          window.location.reload(); // Usar window.location en lugar de location
        })
        .catch(error => {
          // Manejar errores de la petición aquí
          console.error('Error en la petición fetch:', error);
        });
    }
  };

  const googleLoginOnClick = (e) => {
    e.preventDefault();

    var googleLoginURL = 'https://www.skyrushcommunity.com/api/public/auth/google-login';

    // Define las dimensiones de la ventana emergente
    var width = 600;
    var height = 600;

    // Calcula la posición centrada en la pantalla
    var left = (window.innerWidth - width) / 2;
    var top = (window.innerHeight - height) / 2;

    // Opciones para la ventana emergente
    var options = 'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;

    // Abre la ventana emergente centrada
    window.open(googleLoginURL, '_blank', options);
  };






  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      <div className='container' style={{ width: '600px', height: '450px', border: '0.5px solid #CCC', borderRadius: '5px', backgroundColor: 'white', padding: '5%' }}>

        <div className='row'>
          <div className='col-lg-12' >
            <input
              type='text'
              placeholder={lang[langCode].startBody.emailPlaceholder}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: '80%',
                textAlign: 'left',
                borderColor: emailError ? 'red' : '',
              }}
            />
            <span style={{ display: 'block', textAlign: 'center' }}>
              {emailError &&
                <p style={{
                  color: 'red',
                  margin: '0',
                  textAlign: 'center',
                  marginTop: '5px',
                  fontFamily: 'montserratregular',

                }}>
                  {emailError}
                </p>}
            </span>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <input
              type='password'
              placeholder={lang[langCode].startBody.passwordPlaceholder}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: '80%',
                textAlign: 'left',
                marginTop: '10px',
                borderColor: passwordError ? 'red' : '',
              }}
            />
            <span style={{ display: 'block', textAlign: 'center' }}>
              {passwordError &&
                <p style={{
                  color: 'red',
                  margin: '0',
                  textAlign: 'center',
                  marginTop: '5px',
                  fontFamily: 'montserratregular',

                }}>
                  {passwordError}
                </p>}
            </span>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12' style={{ marginTop: '20px' }} >
            <a href='#' onClick={openResetPasswordModal}>
              {lang[langCode].startBody.forgotPasswordLink}
            </a>
            <ResetPasswordModal
              isOpen={isResetPasswordModalOpen}
              onClose={closeResetPasswordModal}

            />

          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <button
              onClick={loginButtonClick}
              style={{
                width: '60%',
                marginTop: '10px',
                marginBottom: '10px'
              }}>
              {lang[langCode].startBody.loginButton}

            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <p>{lang[langCode].startBody.otherLoginOption}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <a href='#' onClick={googleLoginOnClick}>
              <img
                src={google}
                style={{
                  width: '50px',
                  marginBottom: '10px'
                }}
              />
            </a>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <hr></hr>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <button
              onClick={singInButtonOnClick}
              style={{
                width: '60%',
                marginTop: '10px',
                marginBottom: '10px'
              }}>
              {lang[langCode].startBody.registerButton}
              <SingInModal
               key={modalKey}
              isOpen={isSingInModalOpen}
              onClose={closeSingInModal}

            />

            </button>
          </div>
        </div>
      
      </div>
    </div>
  );
};
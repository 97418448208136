import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import UniqueComment from './UniqueComment';
import { useNavigate } from 'react-router-dom';

const SinglePost = () => {
  const langCode = useLangContext();
  const { state } = useLocation();
  const csrfToken = useCsrfToken();
  const [commentData, setCommentData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [open, SetOpen] = useState(null);
  const [subElementId, setSubElementId] = useState(null);
  const [postId, setPostId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getPost();

    setUserData(state.userData)
    SetOpen(state.open)
    setSubElementId(state.subElementId)
    setPostId(state.postId)

  }, []);

  const getPost = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch("api/public/get-comment", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          comment_id: state.commentId,
        }),
      });

      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }

      const data = await response.json();

      setCommentData(data.comment);
    } catch (error) {
      console.error('Error en la petición fetch:', error);
    }
  };


  const goPost = (postId,e) => {
    e.preventDefault();
    navigate('/post', { state: { postId: postId, userData: userData } });

  }

  return (
    <div style={{ paddingTop: '100px', backgroundColor: 'white', paddingBottom: '30px' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4' style={{ textAlign: 'center' }}>
          </div>
          <div className='col-lg-4' style={{ textAlign: 'center', paddingLeft: '5%', paddingRight: '5%', cursor: 'pointer' }} onClick={(event) => goPost(postId, event)}>
            <p style={{ border: '1px solid #0075F3', fontSize: '12px', borderRadius: '5px', padding: '5px' }}>{lang[langCode].notifications.seePost}</p>
          </div>
        </div>
        <div className='row'>
        </div>
        <div className='row'>
          <div className='col-lg-2' style={{ textAlign: 'center' }}>
          </div>
          <div className='col-lg-8' style={{ padding: '0px' }}>
            {commentData !== null && <UniqueComment comment={commentData} userData={userData} open={open} subElementId={subElementId} />}
          </div>

        </div>
      </div>
    </div>
  );
};

export default SinglePost;
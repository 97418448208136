import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import SetNewDevice from './SetNewDevice.js';
import StationList from './StationList.js';


const MyStations = () => {
  const langCode = useLangContext();
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSetNewDevice, setShowSetNewDevice] = useState(false);
  const [showList, setShowList] = useState(true);

  const LaunchSetNewDevice = () => {
    setShowAddButton(false)
    setShowSetNewDevice(true)
    setShowList(false)
  }

  const goList = () => 
  {
    setShowAddButton(true)
    setShowSetNewDevice(false)
    setShowList(true)
  }

  const deactivateAdd = () => 
  {
    setShowAddButton(false)
  
  }

  const activateAdd = () => 
  {
    setShowAddButton(true)
  
  }


  return (

    <div>
      <div style={{ backgroundColor: '#FAFAFA' }}>
        <div className='container' style={{ paddingBottom: '30px' }}>
          {showAddButton && (
            <div className='row' style={{ paddingTop: '40px' }}>
              <div className='col-lg-5'>
              </div>
              <div className='col-lg-2'>
                <button onClick={LaunchSetNewDevice} style={{ fontFamily: 'montserratregular', fontSize: '13px' }}>{lang[langCode].weather.addDevice}</button>
              </div>
              <div className='col-lg-5'>
              </div>
            </div>
          )}
          {showSetNewDevice && (
            <div style={{ backgroundColor: '#FAFAFA' }}>
              <SetNewDevice goList={goList} />
            </div>
          )}
           {showList && (
            <div style={{ backgroundColor: '#FAFAFA' }}>
              <StationList activateAdd={activateAdd} deactivateAdd={deactivateAdd}/>
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default MyStations;
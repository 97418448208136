import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import like from '../../resources/icons/like.png';
import dislike from '../../resources/icons/dislike.png';
import lang from '../../resources/js/dictionary.js';
import loader from '../../resources/img/loader.svg';
import chat from '../../resources/icons/chat.png';
import share from '../../resources/icons/share.png';
import likeBlue from '../../resources/icons/likeBlue.png';
import PostLikes from '../Modals/PostLikes.js';
import Comments from './Comments.js';
import SharePost from '../Modals/SharePost';
import PostShared from '../Modals/PostShared.js';

const ShowLikes = ({ postData, userData }) => {

    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [likesLenght, setLikesLenght] = useState(0)
    const [iLike, setILike] = useState(1)
    const [showComments, setShowComments] = useState(false);
    const [commentCount, setCommentCount] = useState(0)
    const [isPostLikesModalOpen, setIsPostLikesModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isSharedPost, setIsSharedPost] = useState(true);

    const [isPostSharedModalOpen, setIsPostSharedModalOpen] = useState(false);
    const [totalShares, setTotalShares] = useState(true);

    const closePostLikesModal = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsPostLikesModalOpen(false)
    }

    const openPostLikeModal = (e) => {
        e.preventDefault();
        setIsPostLikesModalOpen(true)
    }

    const closeShareModal = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsShareModalOpen(false)
    }

    const openShareModal = (e) => {
        e.preventDefault();
        setIsShareModalOpen(true)
    }

    const openPostSharedModal = (e) => {
        e.preventDefault();
        setIsPostSharedModalOpen(true)
    }

    const closePostSharedModal = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsPostSharedModalOpen(false)
    }





    useEffect(() => {


        getLikes();
        getCommentNumber();
        getSharedNumber();   
        if (postData.sharepost_id !== null) {
            setIsSharedPost(false)
        }

    }, []);

    useEffect(() => {


    }, [likesLenght]);






    const getLikes = () => {

        const token = localStorage.getItem('token');
        fetch("api/public/get-post-likes?page=1", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    post: postData.id,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                return response.json(); // Parsea la respuesta JSON

            })
            .then(data => {
                setLikesLenght(data.total_likes);
                if (data.isMe === true) {
                    setILike(0)
                }
                else {
                    setILike(1)
                }



            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }


    const delLike = () => {


        const token = localStorage.getItem('token');
        fetch("api/public/remove-like", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    post: postData.id,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }

                getLikes();

            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }


    const getCommentNumber = () => {


        const token = localStorage.getItem('token');
        fetch("api/public/get-comment-count", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    post: postData.id,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                return response.json(); // Parsea la respuesta JSON

            })
            .then(data => {

                setCommentCount(data.comment_count)

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }

    const getSharedNumber = () => {


        const token = localStorage.getItem('token');
        fetch("api/public/get-shared?page=1", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    post_id: postData.id,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                return response.json(); // Parsea la respuesta JSON

            })
            .then(data => {

              
               setTotalShares(data.total_shares);    

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }





    const giveLike = () => {
        setILike(3)
        const token = localStorage.getItem('token');
        fetch("api/public/i-like", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    post: postData.id,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }

                getLikes();
            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }

    const whatchComments = (e) => {
        e.preventDefault();
        if (showComments === true) {
            setShowComments(false)
        }
        else {
            setShowComments(true)
        }

    }




    return (

        <div>
            <div className='row' style={{}}>
                <div className='col-lg-2' style={{ paddingTop: '5px' }}>

                </div>
                {iLike === 0 ? (
                    <div className={`col-lg-${isSharedPost ? '3' : '4'}`} onClick={openPostLikeModal} style={{ cursor: 'pointer', paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            src={likeBlue}
                            style={{
                                width: '19px',
                            }}
                        />
                        <p style={{
                            fontSize: '13px',
                            fontFamily: 'montserratbold',
                            margin: '0px',
                            paddingLeft: '5px',
                            color: '#0074EF'
                        }}>{lang[langCode].posts.youAnd} {likesLenght - 1} {lang[langCode].posts.youAndPartII}</p>
                    </div>

                ) : iLike === 1 ? (
                    <div className='col-lg-3' onClick={openPostLikeModal} style={{ cursor: 'pointer', paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            src={like}
                            style={{
                                width: '19px',
                            }}
                        />
                        <p style={{ fontSize: '13px', fontFamily: 'montserratbold', margin: '0px', paddingLeft: '5px' }}>{likesLenght}</p>
                    </div>
                ) :
                    <div className='col-lg-3' style={{ paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            src={loader}
                            style={{
                                width: '20px',
                                padding: '0px',
                                margin: '0px'
                            }}
                        />

                    </div>
                }

                <PostLikes
                    isOpen={isPostLikesModalOpen}
                    onClose={closePostLikesModal}
                    postData={postData.id}
                />

                <div  className={`col-lg-${isSharedPost ? '3' : '4'}`} onClick={whatchComments} style={{ cursor: 'pointer', paddingTop: '5px' }}>
                    {
                        commentCount === 1 ? (
                            <p style={{ fontSize: '13px', fontFamily: 'montserratbold', margin: '0px', paddingLeft: '5px' }}>{commentCount} {lang[langCode].posts.OneComment}</p>
                        ) :
                            <p style={{ fontSize: '13px', fontFamily: 'montserratbold', margin: '0px', paddingLeft: '5px' }}>{commentCount} {lang[langCode].posts.comments}</p>
                    }
                </div>
                {isSharedPost && (
                    <div className='col-lg-3' onClick={openPostSharedModal} style={{ cursor: 'pointer', paddingTop: '5px' }}>
                        {
                            commentCount === 1 ? (
                                <p style={{ fontSize: '13px', fontFamily: 'montserratbold', margin: '0px', paddingLeft: '5px' }}>{totalShares} {lang[langCode].posts.timesShared}</p>
                            ) :
                                <p style={{ fontSize: '13px', fontFamily: 'montserratbold', margin: '0px', paddingLeft: '5px' }}>{totalShares} {lang[langCode].posts.timesShared}</p>
                        }
                    </div>
                )}
                 <PostShared
                    isOpen={isPostSharedModalOpen}
                    onClose={closePostSharedModal}
                    postData={postData.id}
                    userData={userData}
                />
                <div className='col-lg-3' style={{ paddingTop: '5px' }}>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <hr style={{ margin: '5px' }}></hr>
                {
                    iLike === 0 ? (
                        // Contenido que se muestra cuando iLike es true
                        <div className={`col-lg-${isSharedPost ? '4' : '6'}`} onClick={delLike} style={{ paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>

                            <img
                                src={dislike}
                                style={{
                                    width: '19px',
                                }}
                            />
                            <p style={{ fontSize: '13px', fontFamily: 'montserratregular', margin: '0px', paddingLeft: '5px' }}>{lang[langCode].posts.dislike}</p>

                        </div>
                    ) : iLike === 1 ? (
                        // Contenido que se muestra cuando iLike es false
                        <div className={`col-lg-${isSharedPost ? '4' : '6'}`} onClick={giveLike} style={{ paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            {/* Contenido alternativo, como otro ícono o mensaje */}
                            <img
                                src={like}
                                style={{
                                    width: '19px',
                                }}
                            />

                            <p style={{ fontSize: '13px', fontFamily: 'montserratregular', margin: '0px', paddingLeft: '5px' }}>{lang[langCode].posts.iLike}</p>
                        </div>
                    ) : iLike === 3 ? (
                        <div className={`col-lg-${isSharedPost ? '4' : '6'}`} onClick={giveLike} style={{ paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            {/* Contenido alternativo, como otro ícono o mensaje */}
                            <img
                                src={loader}
                                style={{
                                    width: '20px',
                                    padding: '0px',
                                    margin: '0px'
                                }}
                            />


                        </div>

                    ) : null // Agrega un valor predeterminado o null si no se cumple ninguna de las condiciones
                }
                <div className={`col-lg-${isSharedPost ? '4' : '6'}`}  onClick={whatchComments} style={{ cursor: 'pointer', paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        src={chat}
                        style={{
                            width: '20px',
                            padding: '0px',
                            margin: '0px'
                        }}
                    />
                    <p style={{ fontSize: '13px', fontFamily: 'montserratregular', margin: '0px', paddingLeft: '5px' }}>{lang[langCode].posts.comment}</p>
                </div>
                {isSharedPost && (
                    <div className='col-lg-4' onClick={openShareModal} style={{ cursor: 'pointer', paddingTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            src={share}
                            style={{
                                width: '20px',
                                padding: '0px',
                                margin: '0px'
                            }}
                        />
                        <p style={{ fontSize: '13px', fontFamily: 'montserratregular', margin: '0px', paddingLeft: '5px' }}>{lang[langCode].posts.share}</p>
                    </div>
                )}
                <SharePost
                    isOpen={isShareModalOpen}
                    onClose={closeShareModal}
                    postData={postData}
                    userData={userData}
                />
            </div>
            {showComments && (

                <Comments
                    postData={postData}
                    userData={userData} />

            )}
        </div>
    );
};

export default ShowLikes;
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';


const CommentMenu = ({ commentId,fromCommentRecharge }) => {

    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [thisCommentId, setCommentId] = useState(commentId.id);

    const handleOpenEditPost = (e) => {
        e.preventDefault();
      //  openEditPost(); // Abre el modal desde UniquePost
    };


    useEffect(() => {
        console.log(commentId)

        /*     if (commentId.posted_user.isMe === true) {
                 setShowEdit(true)
             }
             if (commentId.sharepost_id !== null) {
     
                 setIsShared(false)
             }
     */
    }, []);

 
   

    const deleteComment = () => {
        console.log(commentId)
        const token = localStorage.getItem('token');
        fetch("api/public/delete-comment", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    comment_id: commentId,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                fromCommentRecharge()


            })
            .then(data => {

              //  onDeletePost()

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
     
            });

    }


    return (
        <div >



            <div className='row' >
                <div className='col-lg-12' style={{ textAlign: 'center',cursor:'pointer' }} onClick={deleteComment}>
                    <p style={{ fontFamily: 'montserratregular' }}>{lang[langCode].posts.delete}</p>
                </div>
            </div>


        </div>

    );
};

export default CommentMenu;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import wunderIdInfo from '../../resources/img/wunderIdInfo.png';
import apiKeyInfo from '../../resources/img/apiKeyInfo.png';


Modal.setAppElement('#root');


const WundergroundApiInfo = ({ isOpen, onClose, expirationDate }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();





    const [customStyles, setCustomStyles] = useState({
        content: {
            width: '850px',
            height: '725px',
            margin: 'auto',
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Renovar Subscripcion"
            style={customStyles}        >
            <div className='row'>
                <div className='col-lg-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </a>
                </div>
                <div className='col-lg-10'>

                </div>
            </div>
            <div className='row' style={{ paddingTop: '0px' }}>
                <div className='col-lg-12' style={{ textAlign: 'center' }} >
                    <p style={{ fontSize: '16px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wllIDInstructionTitle} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12' style={{ textAlign: 'left' }} >
                    <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wunApiIDInstruction} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12'  >
                    <div style={{ backgroundColor: 'white' }}>
                        <img
                            style={{ width: '100%', marginRight: '3px' }}
                            src={wunderIdInfo} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                            alt=""
                        />

                    </div>
                </div>
            </div>
            <hr style={{ color: '#0074EF', borderWidth: '5px' }}></hr>
            <div className='row' style={{ paddingTop: '0px' }}>
                <div className='col-lg-12' style={{ textAlign: 'center' }} >
                    <p style={{ fontSize: '16px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wunApiKeyInstructionTitle} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12' style={{ textAlign: 'left' }} >
                    <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {lang[langCode].weather.wunApiKeyInstruction} </p>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-lg-12'  >
                    <div style={{ backgroundColor: 'white' }}>
                        <img
                            style={{ width: '100%', marginRight: '3px' }}
                            src={apiKeyInfo} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                            alt=""
                        />

                    </div>
                </div>
            </div>
          
            
            
        </Modal>
    )

}

export default WundergroundApiInfo;

import React, { useState, useEffect, useRef } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../../components/Contexts/LangProvider.js';
import lang from '../../../resources/js/dictionary.js';
import no_cert from '../../../resources/img/certs/no_cert.png';
import gold_cert from '../../../resources/img/certs/gold_cert.png';
import termo_rain_cert from '../../../resources/img/certs/termo_rain_cert.png';
import termo_wind_cert from '../../../resources/img/certs/termo_wind_cert.png';
import termo_cert from '../../../resources/img/certs/termo_cert.png';
import warning_cert from '../../../resources/img/certs/warning_cert.png';
import GoAdvanced from '../../Modals/GoAdvanced.js';
import loader from '../../../resources/img/loader.svg';

const StationQualityCert = ({ data }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [cert, setCert] = useState(data.quality_cert);
    const [certImg, setCertImg] = useState(null);
    const [certText, setCertText] = useState(null);
    const [certName, setCertName] = useState(null);
    const [skyrushProfile, setSkyrushProfile] = useState(null);
    const [showGoPremium, setShowGoPremium] = useState(false);
    const [premiumPost, setPremiumPost] = useState(null);
    const [separation, setSeparation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [endLoad, setEndLoad] = useState(false);
    const [premiumButtonName, setPremiumButtonName] = useState(null);
    const [isGoAdvancedModalOpen, setIsGoAdvancedModalOpen] = useState(false);


    useEffect(() => {
        const getSkyrushData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch("api/public/get-skyrush-profile", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data) {

                        setSkyrushProfile(data);
                    } else {
                        console.error('Los datos de la respuesta están vacíos.');
                    }
                } else {
                    throw new Error('La solicitud no tuvo éxito.');
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };

        // Llama a la función getSkyrushData cuando el componente se monta
        getSkyrushData();

    }, []); // El array vacío como segundo argumento asegura que se ejecute solo una vez al montar el componente





    useEffect(() => {

   
        if (cert === 'no_cert') {
            setCertImg(no_cert)
            setCertText(lang[langCode].weather.noCert)
            setCertName(lang[langCode].weather.noCertName)
        }
        else if (cert === 'gold_cert') {
            setCertImg(gold_cert)
            setCertText(lang[langCode].weather.avancedCert)
            setCertName(lang[langCode].weather.avancedCertName)
        }
        else if (cert === 'termo_rain_cert') {
            setCertImg(termo_rain_cert)
            setCertText(lang[langCode].weather.termoRainCert)
            setCertName(lang[langCode].weather.termoRainCertName)
        }
        else if (cert === 'termo_wind_cert') {
            setCertImg(termo_wind_cert)
            setCertText(lang[langCode].weather.termoWindCert)
            setCertName(lang[langCode].weather.termoWindCertName)
        }
        else if (cert === 'termo_cert') {
            setCertImg(termo_cert)
            setCertText(lang[langCode].weather.termoCert)
            setCertName(lang[langCode].weather.termoCertName)
        }
        else if (cert === 'warning_cert') {
            setCertImg(warning_cert)
            setCertText(lang[langCode].weather.warningCert)
            setCertName(lang[langCode].weather.warningCertName)
        }


    }, [cert]);

    useEffect(() => {


        if (cert === 'no_cert') {

            if (skyrushProfile && skyrushProfile.user_skyrush.expiration_date) {
                setPremiumPost(lang[langCode].weather.getAudit);
                setShowGoPremium(true)
                setSeparation('30px')
                setPremiumButtonName(lang[langCode].weather.getAuditButton)
            } else {
                setPremiumPost(lang[langCode].weather.avancedUser);
                setShowGoPremium(true)
                setSeparation('10px')
                setPremiumButtonName(lang[langCode].weather.AdvanceButton)
            }
        }

    }, [skyrushProfile, cert]);


    const launchAudit = (type) => {
        if (type === 'Perfil Advance') {
            openGoAdvancedModal()
        }
        else {
            setShowGoPremium(false)

            setLoading(true)

            const token = localStorage.getItem('token');
            fetch("api/public/send-station-audit-mail", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    stationId: data.id,
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("La solicitud no fue exitosa");
                    }

                })
                .then(data => {
                    setLoading(false)
                    setEndLoad(true)
       
                })
                .catch(error => {
                    // Manejar errores de la petición aquí
                    console.error('Error en la petición fetch:', error);
                });


        }
    }




    const openGoAdvancedModal = () => {

        setIsGoAdvancedModalOpen(true)
    }
    const closeGoAdvancedModalOpen = () => {
        setIsGoAdvancedModalOpen(false)
    }



    return (

        <div>
            <div>
                <div className='container' style={{ backgroundColor: 'white', border: '1px solid #CCCCCC', borderRadius: '5px', paddingBottom: '10px' }}>
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-12' style={{ textAlign: 'left' }}>
                            <h3 style={{ fontSize: '14px' }}> {lang[langCode].weather.qualityCert}</h3>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: '5px' }}>
                        <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                            <img
                                style={{ width: '15px', marginRight: '3px' }}
                                src={certImg} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                alt=""
                            />
                            <p style={{ fontSize: '12px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {certName} </p>
                        </div>
                    </div>

                    <div className='row' style={{ paddingTop: '5px' }}>
                        <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>

                            <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify', marginBottom: '10px' }}>{certText}</p>
                        </div>
                    </div>
                    {showGoPremium && (
                        <div>
                            <hr style={{ margin: '0px' }}></hr>

                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>

                                    <p style={{fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify', margin: '0px' }} dangerouslySetInnerHTML={{ __html: premiumPost }} />
                                </div>
                            </div>

                            <div className='row' style={{ paddingTop: separation }}>
                                <div className='col-2' >
                                </div>
                                <div className='col-8' >
                                    <button
                                        style={{
                                            fontFamily: 'montserratregular',
                                            fontSize: '13px',
                                            paddingLeft: '30px',
                                            paddingRight: '30px',
                                        }}
                                        onClick={() => launchAudit(premiumButtonName)}>{premiumButtonName}</button>
                                </div>
                           
                            </div>
                            <GoAdvanced
                                isOpen={isGoAdvancedModalOpen}
                                onClose={closeGoAdvancedModalOpen}

                            />
                        </div>
                    )}
                    {loading && (
                        <div className='row'>
                            <div className='col-12' >
                                <img
                                    src={loader}
                                    style={{
                                        width: '50px',
                                      
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {endLoad && (
                        <div className='row' style={{ paddingTop: '10px' }}>
                            <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                <p style={{ fontSize: '12px', fontFamily: 'montserratbold', textAlign: 'justify', margin: '0px' }}>{lang[langCode].weather.auditRequested}</p>
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </div>
    );
};

export default StationQualityCert;
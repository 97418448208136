import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import close from '../../resources/icons/cross.png';
import loader from '../../resources/img/loader.svg';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import noPicture from '../../resources/img/no_foto.svg';
import PeopleButtons from '../People/PeopleButtons';
import view from '../../resources/icons/view.png';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const PeopleFollowing = ({ isOpen, onClose, userData }) => {
    const [customStyles, setCustomStyles] = useState({
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 999,
        },
        content: {
          width: '95%',
          maxWidth: '100%',  // Set the maximum width to 100% of the screen
          maxHeight: '95vh',  // Set the maximum height to 95% of the viewport height
          height: 'auto',     // Set the height to 'auto'
          margin: 'auto',
          left: '0',
          right: '0',
          top: '5%',
          bottom: '10%',
          overflow: 'auto',   // Enable scrolling if content exceeds the modal size
          padding: '5px',
          border: 'none',
          borderRadius: '8px',
          position: 'fixed',
        },
      });
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(false);
    const hasMoreRef = useRef(true); // Usamos una referencia para seguimiento
    const [searchParam, setSearchParam] = useState("");
    const page = useRef(1);
    const isFetchingData = useRef(false);
    const navigate = useNavigate();




    useEffect(() => {
        if (isOpen) {


            setPeople([]);
            hasMoreRef.current = true;
            page.current = 1;

            const loadAndAddScrollListener = () => {
                const modalContent = document.getElementById('followingModal');
                if (modalContent) {
                    modalContent.addEventListener('scroll', handleScroll);
                    loadMorePeople();
                } else {
                    setTimeout(loadAndAddScrollListener, 100);
                }
            };

            loadAndAddScrollListener();
        }

        return () => {
            const modalContent = document.getElementById('followingModal');
            if (modalContent) {
                modalContent.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isOpen]);

    const handleScroll = () => {
        const modalContent = document.getElementById('followingModal');
        if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 200) {
            loadMorePeople();
        }
    };

    const loadMorePeople = async () => {
        if (!hasMoreRef.current || isFetchingData.current) {
            return;
        }
        setLoading(true);
        isFetchingData.current = true;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`api/public/get-following-data-modal?page=${page.current}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userId: userData,
                }),
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();

            if (data && Array.isArray(data.following)) {
                const modalContent = document.querySelector('.Personal');
                if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 200) {
                    
                    if (data.following.length === 0) {
                        hasMoreRef.current = false;
                    } else {
                        setPeople((prevPeople) => [...prevPeople, ...data.following]);
                        page.current += 1;
                    }
                }
            } else {
                hasMoreRef.current = false;
                console.error('La estructura de la respuesta del servidor es incorrecta');
            }
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        } finally {
            isFetchingData.current = false;
            setLoading(false);
        }
    };

    const viewProfile = (id) =>{
        navigate('/profile-view', { state: {personId:id, goBack: true } });
        onClose()
    }

   


    return (

        <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Modal Siguiendo" style={customStyles} id="followingModal">
            <div className='row'>
                <div className='col-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px',
                            }}
                        />
                    </a>
                </div>
                <div className='col-10'>
                    {/* Contenido adicional del modal */}
                </div>
            </div>
            <div className='Personal' style={{ backgroundColor: 'white' }}>
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-12' style={{ paddingLeft: '30px' }}>
                        <h3>{lang[langCode].personalProfileViewComponent.followingPeople}</h3>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '10px' }}>
                    {people.map((person, index) => (
                        <div key={index}>
                            <div className='row' >
                                <div className='col-2'  onClick={person.isMe ? null : () => viewProfile(person.id)} style={{ textAlign: 'center' }}>

                                    {person.picture === null ? (
                                        <img style={{ width: '35px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={noPicture} alt="" />

                                    ) :

                                        <img style={{ width: '35px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={"https://www.skyrushcommunity.com/api/public/user_pictures/" + person.picture} alt="" />}
                                </div>
                                <div className='col-5'  onClick={person.isMe ? null : () => viewProfile(person.id)} style={{ textAlign: 'left', padding: '0px' }}>
                                    <p style={{ margin: '0px', fontFamily: 'montserratbold', fontSize:'12px' }}>{person.name} {person.last_name}</p>
                                </div>
                                <div className='col-5' style={{ textAlign: 'left', padding: '0px' }}>
                                    {person.isMe === false ? (
                                        <PeopleButtons
                                            youfollow={person.common_following}
                                            heFollow={person.isFollowedByCurrentUser}
                                            userData={person.id}
                                        />
                                    ) :
                                        null}
                                </div>
                               
                            </div>
                            {/* Agrega más campos según la estructura de tus datos */}
                            <hr style={{ margin: '5px' }}></hr>
                        </div>
                    ))}
                </div>
                {loading && (
                    <div className='row'>
                        <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={loader}
                                style={{
                                    width: '50px',
                                    marginTop: '25%',
                                    marginBottom: '25%',
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default PeopleFollowing;



import React, { useState, useEffect } from 'react';
import weatherdata from '../../resources/icons/weather-data.png';
import station from '../../resources/icons/station.png';
import map from '../../resources/icons/map.svg';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import MyStations from '../Weather/MyStations';


const Weather = () => {
  const langCode = useLangContext();
  const [activeData, setActiveData] = useState(false);
  const [activeStations, setActiveStations] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [showActiveData, setShowActiveData] = useState(false);
  const [showStations, setShowStations] = useState(false);


  const setData = (e) => {
    e.preventDefault();
    setActiveData(true)
    setActiveStations(false)
    setShowMap(false)
    setShowActiveData(true)
    setShowStations(false)

  }

  const setStations = (e) => {

    e.preventDefault();
    setActiveData(false)
    setActiveStations(true)
    setShowMap(false)
    setShowActiveData(false)
    setShowStations(true)
  }


  const setMap = (e) => {
    e.preventDefault();
    setActiveData(false)
    setActiveStations(false)
    setShowMap(true)
    setShowActiveData(false)
    setShowStations(false)

  }


  return (

    <div style={{ backgroundColor: '#FAFAFA' }}>
      <div className='container' style={{ paddingBottom: '30px' }}>

        <div className='row' style={{ paddingTop: '70px' }}>
          <div className='col-lg-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '42%', paddingRight: '40%' }}>



            <a href="#" onClick={setMap} style={{ borderBottom: showMap ? '2px solid black' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px' }}>
              <img
                style={{ width: '26px' }}
                src={map}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                &nbsp;{lang[langCode].weather.map}
              </p>
            </a>


            <a href="#" onClick={setData} style={{ borderBottom: activeData ? '2px solid black' : 'none', display: 'flex', alignItems: 'center', minWidth: '100px', justifyContent: 'center' }}>
              <img
                style={{ width: '29px' }}
                src={weatherdata}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                &nbsp;{lang[langCode].weather.data}
              </p>
            </a>




            <a href="#" onClick={setStations} style={{ borderBottom: activeStations ? '2px solid black' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minWidth: '130px', textAlign: 'left' }}>
              <img
                style={{ width: '28px' }}
                src={station}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                {lang[langCode].weather.myStations}
              </p>
            </a>


          </div>
        </div>
        {
          showMap && (
            <div />
          )

        }

        {showActiveData && (
          <div />
        )}

        {showStations && (
          <MyStations />
        )}


      </div>
    </div>
  );
};

export default Weather;
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLangContext } from '../../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../../Contexts/CsrfTokenContext.js';
import lang from '../../../resources/js/dictionary.js';
import Cropper from 'react-easy-crop';
import loader from '../../../resources/img/loader.svg';

const StationMultimedia = ({ data }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [showImageCrop, setShowImageCrop] = useState(false)
    const [showImageControls, setShowImageControls] = useState(false)
    const [showImagePreview, setshowImagePreview] = useState(true)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [showImage, setShowImage] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [showLoadButton, setShowLoadButton] = useState(true)
    const [personalPicture, setPersonalPicture] = useState('');
    const [stationImg, setStationImg] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const fileInputRef = useRef(null);


    useEffect(() => {
   
        if (data.img_path === null) {

            //  setPersonalPicture(noPicture)
            setShowImageCrop(false)
            setShowImageControls(false)

        } else {
            setShowImage(true)
            setshowImagePreview(false)
            setStationImg('https://www.skyrushcommunity.com/api/public/storage/station_pictures/' + data.img_path)
        }
    }, [data]);


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setShowImage(false)
        const canvas = document.createElement('canvas');
        const image = document.createElement('img');
        image.src = personalPicture;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            croppedAreaPixels.x * scaleX,
            croppedAreaPixels.y * scaleY,
            croppedAreaPixels.width * scaleX,
            croppedAreaPixels.height * scaleY,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );

        canvas.toBlob((blob) => {
            const objectUrl = URL.createObjectURL(blob);
            setCroppedImage(objectUrl);
        }, 'image/jpeg');
    }, [personalPicture]);


    const handleImageUpload = (e) => {

        setShowImageCrop(true)
        setShowImageControls(true)
        setShowSaveButton(true)
        setShowLoadButton(false)
        setshowImagePreview(false)
        const selectedFile = e.target.files[0]; // Obtiene el archivo seleccionado

        if (selectedFile) {
            const objectUrl = URL.createObjectURL(selectedFile);
            setPersonalPicture(objectUrl); // Actualiza el estado con la URL de la imagen seleccionada
        }
    };



    const handleSave = async () => {
        if (croppedImage) {
            try {
                const blob = await fetch(croppedImage).then((r) => r.blob());
                const file = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });

                const formData = new FormData();
                formData.append('croppedImage', file);
                formData.append('stationId', data.id);
                setStationImg(croppedImage)
                const response = await fetch('api/public/modify-station-picture', {
                    method: 'POST',
                    body: formData, // Cambia "data" a "body" para enviar el formulario correctamente
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'X-CSRF-TOKEN': csrfToken
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setShowSaveButton(false)
                    setShowLoadButton(true)
                    setShowImageCrop(false)
                    setShowImageControls(false)
                    setShowImage(true)

                }
            } catch (error) {
                console.error('Error al realizar la petición fetch:', error);
            }
        } else {
            console.error('No se ha seleccionado una imagen recortada.');
        }
    };







    return (
        <div>
            <div className='container' style={{ height: '650px', backgroundColor: 'white', border: '1px solid #CCCCCC', borderRadius: '5px' }}>
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-lg-12' style={{ textAlign: 'left' }}>
                        <h3 style={{ fontSize: '14px' }}> {lang[langCode].weather.photo}</h3>

                    </div>
                </div>
                {showLoader && (
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="crop-container-station" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: '60px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={loader} alt="" />
                            </div>
                        </div>
                    </div>

                )}


                {showImageCrop && (
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="crop-container-station" style={{ width: '570px', height: '500px', position: 'relative' }}>
                                <Cropper
                                    image={personalPicture}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 4}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                        </div>

                    </div>
                )}
                {showImagePreview && (
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-lg-12'>
                            <div className="crop-container-station" style={{ border: '2px dashed #000', display: 'flex', height: '500px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ fontSize: '13px' }}>{lang[langCode].modifyPersonalPicture.loadPicturePreview}</p>
                            </div>
                        </div>
                    </div>
                )}

                {showImage && (
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-lg-12'>
                            <img
                                style={{

                                    height: '505px',
                                    objectFit: 'cover',
                                }}
                                src={stationImg}

                            />
                        </div>
                    </div>

                )}
                {showImageControls && (
                    <div className='row'>
                        <div className='col-lg-12' style={{ marginTop: '20px' }}>

                            <input
                                type="range"
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e) => {
                                    setZoom(e.target.value)
                                }}
                                className="zoom-range"
                            />
                        </div>

                    </div>
                )}
                {showLoadButton && (
                    <div className='row' style={{ paddingTop: '40px' }}>
                        <div className='col-lg-12' >
                            <label htmlFor="fileInput" className="custom-file-input" >
                                <button
                                    type="button"
                                    className="custom-file-button"
                                    onClick={() => fileInputRef.current.click()} // Utiliza una referencia para hacer clic en el input file oculto
                                    style={{
                                        fontFamily: 'montserratregular',
                                        fontSize: '13px',
                                        paddingLeft: '30px',
                                        paddingRight: '30px',
                                    }}
                                >
                                    {lang[langCode].modifyPersonalPicture.loadPictureLink}
                                </button>
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload} // Agrega el controlador de eventos
                                style={{ display: 'none' }} // Oculta el input file
                                ref={fileInputRef} // Crea una referencia para el input file
                            />
                        </div>
                    </div>
                )}
                {showSaveButton && (
                    <div className='row' style={{ paddingTop: '0px' }}>
                        <div className='col-lg-4' >
                        </div>
                        <div className='col-lg-4' >
                            <button
                                style={{
                                    fontFamily: 'montserratregular',
                                    fontSize: '13px',
                                    paddingLeft: '30px',
                                    paddingRight: '30px',
                                }}
                                onClick={handleSave}>{lang[langCode].generic.save}</button>
                        </div>
                        <div className='col-lg-4' >
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};



export default StationMultimedia;
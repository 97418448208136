import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import PeopleButtons from '../People/PeopleButtons.js';
import loader from '../../resources/img/loader.svg';
import PeopleFollowing from '../Modals/PeopleFollowing.js';
import PeopleFollowedModal from '../Modals/PeopleFollowedModal.js';
import PeopleCommonFollowingModal from '../Modals/PeopleCommonFollowingModal.js';



const ProfileViewRelatedPeople = ({ userData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [followData, setFollowData] = useState(false);
    const [titlePartI, setTitlePartI] = useState('');
    const [titlePartII, setTitlePartII] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [followingLenght, setFollowingLenght] = useState(null);
    const [followersLength, setFollowersLength] = useState(null);
    const [commonFollowing, setCommonFollowing] = useState(null);
    const [isPeopleFollowingModalOpen, setIsPeopleFollowingModalOpen] = useState(false);
    const [isPeopleFollowedModalOpen, setIsPeopleFollowedModalOpen] = useState(false);
    const [isPeopleCommonFollowingModalOpen, setIsPeopleCommonFollowingModalOpen] = useState(false);


    const openPeopleFollowingModal = () => {
        setIsPeopleFollowingModalOpen(true);
    };

    const closePeopleFollowingModal = async (e) => {

        setIsPeopleFollowingModalOpen(false);

    };

    const openPeopleFollowedModal = () => {
        setIsPeopleFollowedModalOpen(true);
    };

    const closePeopleFollowedModal = async (e) => {

        setIsPeopleFollowedModalOpen(false);

    };

    const openPeopleCommonFollowedModal = () => {
        setIsPeopleCommonFollowingModalOpen(true);
    };

    const closePeopleCommonFollowingModal = async (e) => {

        setIsPeopleCommonFollowingModalOpen(false);

    };




    useEffect(() => {
        const getFollowData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch("api/public/get-follow-data", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({
                        "userId": userData.user.id,
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data) {
                        setFollowData(data);
                        setDataLoaded(true);
                    } else {
                        console.error('Los datos de la respuesta están vacíos.');
                    }
                } else {
                    throw new Error('La solicitud no tuvo éxito.');
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };

        // Llama a la función getUserdata cuando el componente se monta
        getFollowData();
    }, [csrfToken, userData]);

    useEffect(() => {

        if (followData.is_following === true) {
            setTitlePartI(lang[langCode].personalProfileViewComponent.youFollow);
        } else {
            setTitlePartI(lang[langCode].personalProfileViewComponent.youNotFollow);
        }
        if (followData.is_followed_by === true) {
            setTitlePartII(lang[langCode].personalProfileViewComponent.heFollow);
        } else {
            setTitlePartII(lang[langCode].personalProfileViewComponent.heNotFollow);
        }
        if (followData) {
            setFollowingLenght(followData.following.length)
            setFollowersLength(followData.followers.length)
            setCommonFollowing(followData.common_following.length)
        }

        // 
    }, [followData, langCode]);



    return (
        <div>
            {!dataLoaded && (
                <div style={{ textAlign: 'center' }}>
                    <img
                        src={loader}
                        style={{
                            width: '50px',
                            marginLeft: '10px',
                            marginTop: '25%',
                            marginBottom: '25%',
                        }}
                    />
                </div>
            )}

            {dataLoaded && (
                <div className='relation' style={{ paddingTop: '10px',paddingBottom: '10px' }}>
                    <div className='row' >
                        <div className='col-12' style={{ paddingLeft: '7px' }}>
                       
                            <h4 style={{ margin: '0px', marginLeft: '4px' }}>{lang[langCode].personalProfileViewComponent.relatedPeople} {userData.user.name}</h4>
                            <p style={{ fontFamily: 'montserratregular', margin: '0px' }}>{titlePartI} {userData.user.name} {titlePartII}</p>
                        </div>
                    </div>
                    <div className='row' >
                        <div className='col-12'>
                            <PeopleButtons
                                youfollow={followData.is_followed_by}
                                heFollow={followData.is_following}
                                userData={userData.user.id}

                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4' style={{ padding: '0px', textAlign: 'center' }}>
                            <a href="#" onClick={openPeopleFollowingModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                                <p style={{ margin: '0px', fontFamily: 'montserratbold', fontSize: '12px', paddingLeft: '0px' }}>
                                    {followingLenght} {lang[langCode].mainPeople.following}
                                </p>
                            </a>
                            <PeopleFollowing
                                isOpen={isPeopleFollowingModalOpen}
                                onClose={closePeopleFollowingModal}
                                userData={userData.user.id}


                            />
                        </div>
                        <div className='col-4' style={{ padding: '0px', textAlign: 'center' }}>
                            <a href="#" onClick={openPeopleFollowedModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                                <p style={{ margin: '0px', fontFamily: 'montserratbold', fontSize: '12px', paddingLeft: '0px' }}>
                                    {followersLength} {lang[langCode].mainPeople.followers}
                                </p>
                            </a>
                            <PeopleFollowedModal
                                isOpen={isPeopleFollowedModalOpen}
                                onClose={closePeopleFollowedModal}
                                userData={userData.user.id}
                            />

                        </div>
                        <div className='col-4' style={{ padding: '0px', textAlign: 'center' }}>
                            <a href="#" onClick={openPeopleCommonFollowedModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                                <p style={{ margin: '0px', fontFamily: 'montserratbold', fontSize: '12px', paddingLeft: '0px' }}>
                                    {commonFollowing} {lang[langCode].personalProfileViewComponent.onCommon}
                                </p>
                            </a>
                            <PeopleCommonFollowingModal
                                isOpen={isPeopleCommonFollowingModalOpen}
                                onClose={closePeopleCommonFollowingModal}
                                userData={userData.user.id}
                            />
                        </div>
                    </div>

                </div>

            )}
        </div>
    );
};

export default ProfileViewRelatedPeople;
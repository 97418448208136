import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../../components/Contexts/LangProvider.js';
import lang from '../../../resources/js/dictionary.js';
import red_light from '../../../resources/icons/red_light.png';
import green_light from '../../../resources/icons/green_light.png';
import yellow_light from '../../../resources/icons/yellow_light.png';
import warning_cert from '../../../resources/img/certs/warning_cert.png';
import StationStatusWSView from './StationStatusWSview.js';
import StationStatusWLLive from './StationStatusWLLive.js';
import StationStatusWunApi from './StationStatusWunApi.js';
import StationStatusWLDesktop from './StationStatusWLDesktop.js';

const StationStatus = ({ data }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [ligth, setLight] = useState(null);
    const [place, setPlace] = useState(null);
    const [status, setStatus] = useState(null);


    const setOnline = () => {
        setLight(green_light)
        setStatus(lang[langCode].weather.online)
    }




    useEffect(() => {

        if (data.location.town !== null) {
            setPlace(data.location.town)
        }
        else if (data.location.town === null && data.location.village !== null) {
            setPlace(data.location.village)
        }
        else if (data.location.town === null && data.location.village === null && data.location.hamlet !== null) {
            setPlace(data.location.hamlet)
        }

        if (data.status === 'draft') {
            setLight(yellow_light)
            setStatus(lang[langCode].weather.draft)
        }
        else if (data.status === 'online') {
            setLight(green_light)
            setStatus(lang[langCode].weather.online)
        }
        else if (data.status === 'offline') {
            setLight(red_light)
            setStatus(lang[langCode].weather.offline)
        }
        else if (data.status === 'warning') {
            setLight(warning_cert)
            setStatus(lang[langCode].weather.warning)
        }

    }, []);


    return (

        <div>
            <div>
                <div className='container' style={{  backgroundColor: 'white', border: '1px solid #CCCCCC', borderRadius: '5px', paddingBottom: '10px' }}>
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-12' style={{ textAlign: 'left' }}>
                            <h3 style={{ fontSize: '14px' }}> {lang[langCode].weather.status}</h3>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: '5px' }}>
                        <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                            <img
                                style={{ width: '15px', marginRight: '3px' }}
                                src={ligth} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                alt=""
                            />
                            <p style={{ fontSize: '12px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {status} </p>
                        </div>
                        {data.connection === 'WS View' && (
                            <StationStatusWSView data={data} />
                        )}
                        {data.connection === 'Weatherlink Live' && (
                            <StationStatusWLLive data={data} setOnline={setOnline} />
                        )}
                        {data.connection === 'Wunderground Api' && (
                            <StationStatusWunApi data={data} setOnline={setOnline} />
                        )}
                          {data.connection === 'Weatherlink Soft.' && (
                            <StationStatusWLDesktop data={data} setOnline={setOnline} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StationStatus;
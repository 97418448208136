
import React from 'react'
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import { SingInComponent } from './SingInComponent';
import '../../css/Main.css';

export const StartBodyComponent = () => {



  const langCode = useLangContext();

  console.log(langCode)

  const centerVertically = {
    minHeight: '80vh', // Cambiado a 100vh para ocupar toda la altura de la pantalla
    display: 'flex',
    flexDirection: 'column', // Centra verticalmente el contenido
    alignItems: 'center', // Centra horizontalmente el contenido
    justifyContent: 'center', // Centra horizontalmente el contenido
  };


  return (
    <div style={{ backgroundColor: '#FAFAFA', display: 'flex', alignItems: 'center', minHeight: '90vh' }}>
      <div className='container' style={centerVertically}>
        <div className='row'>
          <div style={{ textAlign: 'left',marginTop: '12%' }} className='col-lg-6'>
     
              <div className='col-lg-12'>
                <h1>{lang[langCode].startBody.welcomeTitle}</h1>
              </div>
  
    
              <div className='col-lg-12'>
                <h2>{lang[langCode].startBody.welcomeMessage}</h2>
              </div>
  
          </div>
          <div className='col-lg-6'>

            <SingInComponent />

          </div>

        </div>
      </div>
    </div>
  );
};
import React, { useState, useEffect } from 'react';
import unfollow from '../../resources/icons/unfollow.png';
import follow from '../../resources/icons/follow.png';
import lang from '../../resources/js/dictionary.js';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';

const PeopleButtons = ({ youfollow, heFollow, userData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [showAlsoFollow, setShowAlsoFollow] = useState(false);
    const [showFollow, setShowFollow] = useState(false);
    const [showUnfollow, setShowUnfollow] = useState(false);

 



    useEffect(() => {

        if (youfollow === true) {
            setShowAlsoFollow(false)
            setShowFollow(false)
            setShowUnfollow(true)
        }
        else {

            if (heFollow === true) {
                setShowAlsoFollow(true)
                setShowFollow(false)
                setShowUnfollow(false)

            }
            else {
                setShowAlsoFollow(false)
                setShowFollow(true)
                setShowUnfollow(false)
            }

        }
    }, [youfollow]);



    const setFollow = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        fetch("api/public/set-follow", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    userId: userData,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }

                setShowAlsoFollow(false)
                setShowFollow(false)
                setShowUnfollow(true)



            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }


    const setUnfollow = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        fetch("api/public/set-unfollow", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    userId: userData,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                if (heFollow === true) {
                    setShowAlsoFollow(true)
                    setShowFollow(false)
                    setShowUnfollow(false)

                }
                else {
                    setShowAlsoFollow(false)
                    setShowFollow(true)
                    setShowUnfollow(false)
                }



            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });


    }


    return (

        <div>
            {showFollow && (
                <a href="#" onClick={setFollow} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    <img
                        style={{ width: '14px' }}
                        src={follow}
                        alt=""
                    />
                    <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px', paddingLeft: '1px' }}>
                        <span>&nbsp;{lang[langCode].mainPeople.follow}</span>
                    </p>
                </a>
            )}
            {showAlsoFollow && (
                <a href="#" onClick={setFollow} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    <img
                        style={{ width: '14px' }}
                        src={follow}
                        alt=""
                    />
                    <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px', paddingLeft: '1px' }}>
                        <span>&nbsp;{lang[langCode].mainPeople.alsoFollow}</span>
                    </p>
                </a>
            )}
            {showUnfollow && (
                <a href="#" onClick={setUnfollow} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                    <img
                        style={{ width: '14px' }}
                        src={unfollow}
                        alt=""
                    />
                    <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px', paddingLeft: '1px' }}>
                        <span>&nbsp;{lang[langCode].mainPeople.stopFollow}</span>
                    </p>
                </a>
            )}

        </div>
    );
};

export default PeopleButtons;
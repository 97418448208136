import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import noPicture from '../../resources/img/no_foto.svg';
import view from '../../resources/icons/view.png';
import unfollow from '../../resources/icons/unfollow.png';
import follow from '../../resources/icons/follow.png';
import lang from '../../resources/js/dictionary.js';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useNavigate } from 'react-router-dom';


const PeopleCards = ({ userData, myUserData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [personalPicture, setPersonalPicture] = useState(noPicture);
    const [firstFollowingName, setFirstFollowingName] = useState('');
    const [firstFollowingSurname, setFirstFollowingSurname] = useState('');
    const [followingCount, setFollowingCount] = useState(userData.common_following - 1);
    const [commonText, setCommonText] = useState('');
    const [paddingBottom, setPaddingBottom] = useState('0px');

    const [showAlsoFollow, setShowAlsoFollow] = useState(false);
    const [showFollow, setShowFollow] = useState(false);
    const [showUnfollow, setShowUnfollow] = useState(false);
    const navigate = useNavigate();



    useEffect(() => {
      
        if (userData.picture === null) {
            setPersonalPicture(noPicture);
        } else {
            setPersonalPicture('https://www.skyrushcommunity.com/api/public/user_pictures/' + userData.picture);
        }

        if (userData.is_followed_by == 1 && userData.is_following !== 1) {
            setShowAlsoFollow(true)
            setShowFollow(false)
            setShowUnfollow(false)

        }
        else if (userData.is_following == 1) {
            setShowAlsoFollow(false)
            setShowFollow(false)
            setShowUnfollow(true)
        }
        else {
            setShowAlsoFollow(false)
            setShowFollow(true)
            setShowUnfollow(false)
        }

    
    }, [userData]);


    useEffect(() => {
        getFirstFollowing();
    }, [userData]);

    useEffect(() => {
        makeCommonText();
    }, [firstFollowingName, firstFollowingSurname, followingCount, langCode]);




    const getFirstFollowing = () => {
        if (userData.common_following >= 1) {
            const firstFollowing = userData.following.find(following => following.common_following === 1);
            if (firstFollowing) {
                setFirstFollowingName(firstFollowing.name);
                setFirstFollowingSurname(firstFollowing.last_name);
            }
        }
    }

    const makeCommonText = () => {
        if (userData.common_following === 0) {
            setCommonText('');
            if (userData.descripcion == null) {
                setPaddingBottom('28.5px')
            }
            else {
                setPaddingBottom('13.5px')
            }

        } else if (userData.common_following === 1) {
            setCommonText(lang[langCode].mainPeople.alsoFollowsI + firstFollowingName + " " + firstFollowingSurname);
            setPaddingBottom('15px')
        } else {
            if (userData.descripcion == null) {
                setPaddingBottom('15px')
            }
            setCommonText(lang[langCode].mainPeople.alsoFollowsI + firstFollowingName + " " + firstFollowingSurname + lang[langCode].mainPeople.alsoFollowsII + followingCount + lang[langCode].mainPeople.alsoFollowsIII);
        }
    }

    const profileView = () => {
        
   
        navigate('/profile-view', { state: {personId:userData.id } });

    }

    const setFollow = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        fetch("api/public/set-follow", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    userId: userData.id,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }

                setShowAlsoFollow(false)
                setShowFollow(false)
                setShowUnfollow(true)



            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });


    }

    const setUnfollow = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        fetch("api/public/set-unfollow", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    userId: userData.id,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                if (userData.is_followed_by == 1) {
                    setShowAlsoFollow(true)
                    setShowFollow(false)
                    setShowUnfollow(false)
        
                }
                else{
                    setShowAlsoFollow(false)
                    setShowFollow(true)
                    setShowUnfollow(false)
                }



            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });


    }

    return (
        <div className='container' style={{ border: '1px solid #CCC', borderRadius: '5px', padding: '3px', backgroundColor: 'white' }} >
            <div className='row' >
                <div className='col-lg-6' style={{ textAlign: 'left' }}>
                    <img style={{ width: '90px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={personalPicture} alt="" />
                </div>
                <div className='col-lg-6' style={{ textAlign: 'left', paddingTop: '25px' }}>
                    <a href="#" onClick={profileView} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            style={{ width: '15px' }}
                            src={view}
                            alt=""
                        />
                        <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '10px' }}>
                            &nbsp;{lang[langCode].mainPeople.viewProfile}
                        </p>
                    </a>
                    {showFollow && (
                        <a href="#" onClick={setFollow} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                style={{ width: '15px' }}
                                src={follow}
                                alt=""
                            />
                            <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '10px' }}>
                                <span>&nbsp;{lang[langCode].mainPeople.follow}</span>
                            </p>
                        </a>
                    )}
                    {showAlsoFollow && (
                        <a href="#" onClick={setFollow} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                style={{ width: '15px' }}
                                src={follow}
                                alt=""
                            />
                            <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '10px' }}>
                                <span>&nbsp;{lang[langCode].mainPeople.alsoFollow}</span>
                            </p>
                        </a>
                    )}
                    {showUnfollow && (
                        <a href="#" onClick={setUnfollow} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                style={{ width: '15px' }}
                                src={unfollow}
                                alt=""
                            />
                            <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '10px' }}>
                                <span>&nbsp;{lang[langCode].mainPeople.stopFollow}</span>
                            </p>
                        </a>
                    )}
                </div>
            </div>
            <div className='row' >
                <div className='col-lg-12' style={{ textAlign: 'left' }}>
                    <p style={{ paddingTop: '5px', margin: '0px' }}>{userData.name} {userData.last_name}</p>
                </div>
            </div>
            <div className='row' >
                <div className='col-lg-12' style={{ textAlign: 'left' }}>
                    <p style={{ padding: '0px', margin: '0px', fontFamily: 'montserratregular', fontSize: '10px', paddingBottom: paddingBottom }}>
                        {userData.descripcion}
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col-lg-12' style={{ textAlign: 'left' }}>
                    <p style={{ paddingTop: '10px', paddingLeft: '2px', margin: '0px', fontFamily: 'montserratregular', fontSize: '9px' }}>
                        {commonText}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PeopleCards;
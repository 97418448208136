import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../../Contexts/CsrfTokenContext.js';
import lang from '../../../resources/js/dictionary.js';
import StationLocation from '../StationLocation.js';


const StationLocationEdit = ({ data }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [stationLocationData, setStationLocationData] = useState(null);
    const [basicInfoData, setBasicInfoData] = useState(null);
    const [activeButton, setActiveButton] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    
    useEffect(() => {

        setBasicInfoData({stationId:data.id})

    }, []);

    const getLocation = (stationLocation) =>
    {
        setStationLocationData(stationLocation)
    }

    const activateButton = (origin) =>
    {
          // Desactiva el botón solo si no es el primer render
          if (!isFirstRender) {
            setActiveButton(true);
        } else {
            setIsFirstRender(false);
        }
    }

    const deactivateButton = (origin) =>
    {
        setActiveButton(false);
    }

    const modifyLocation = async () => {
        {
            const finalStationData =
            {
                basicInfo: basicInfoData,
                stationLocation: stationLocationData,
            }
       
            const token = localStorage.getItem('token');

            try {
                const response = await fetch("api/public/modify-station-location", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(finalStationData),
                });

                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                const data = await response.json();
                deactivateButton()
                
   
            } catch (error) {
            
                console.error('Error en la petición fetch:', error);
            }
        };
    }
   

    return (

        <div>
            <div>
                <div className='container' style={{ height: '650px', backgroundColor: 'white', border: '1px solid #CCCCCC', borderRadius: '5px' }}>
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-lg-12' style={{ textAlign: 'left' }}>
                            <h3 style={{ fontSize: '14px' }}> {lang[langCode].weather.location}</h3>
                            <StationLocation getLocation={getLocation} activateButton={activateButton} deactivateButton={deactivateButton} preLocation={[data.location.lat,data.location.long]}/>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: '0px', paddingBottom:'20px' }} >
                        <div className='col-lg-3'>
                        </div>
                        <div className='col-lg-6'>
                            <button
                                style={{
                                    fontFamily: 'montserratregular',
                                    fontSize: '13px',
                                    backgroundColor: activeButton ? '#0074EF' : '#E7E7E7', // Cambia el color del texto según activeButton
                                    color: activeButton ? 'white' : '#A7A7A7', // Cambia el color del texto según activeButton
                                }}
                                disabled={!activeButton} // Deshabilita el botón si activeButton es falso
                                onClick={modifyLocation}
                            >
                                {lang[langCode].weather.modifyLocation}
                            </button>
                        </div>


                        <div className='col-lg-3'>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    );
};

export default StationLocationEdit;
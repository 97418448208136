
import React from 'react'
import logo from '../../resources/img/logo.png';

export const StartBarComponent = () => {
    return (
      <div>
        <div className='container'>
            <div className='row'>
              <div style={{textAlign: 'left'}} className='col-lg-6'>
                <img style={{width: '200px'}} src={logo} alt="Logo" />
              </div>
              <div className='col-lg-6'>

              </div>
            </div>
        </div>  
      </div>
    );
  };
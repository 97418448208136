import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import { MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup as LeafletLayerGroup } from 'react-leaflet';
import L from 'leaflet';
import TempIcon from './StationMap/TempIcon.js';
import StationInfoModal from './StationMap/StationBelowModal.js';
import 'leaflet/dist/leaflet.css';
import StationBelowModal from './StationMap/StationBelowModal.js';


const StationMap = () => {

    const [userLocation, setUserLocation] = useState(null);
    const [position, setPosition] = useState(null);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [stationList, setStationList] = useState([]);
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const { BaseLayer, Overlay, LayerGroup } = LayersControl;
    const [currentTempLayer, setCurrentTempLayer] = useState([]);
    const [maxTempLayer, setMaxTempLayer] = useState([]);
    const [minTempLayer, setMinTempLayer] = useState([]);
    const [isStationModalOpen, setIsStationModalOpen] = useState(false);
    const [stationData, setStationData] = useState(null);

    useEffect(() => {
        getUserLocation();
        getStationList();
    }, []);


    const openStationModal = (station) => {
        setStationData(station)
        setIsStationModalOpen(true);
    }

    const closeStationModeal = () => {
        setIsStationModalOpen(false);
    }

    useEffect(() => {
        // Capa de temperatura actual
      
        const currentLayerGroup = stationList.map((station) => (
            <Marker
                key={station.id}
                position={[station.location.lat, station.location.long]}
                icon={TempIcon({ number: station.current_weather_data.temp })}
                eventHandlers={{
                    click: () => {
                        openStationModal(station); // llama a la función que quieres ejecutar al hacer click
                    }
                }}
            />
        ));
        setCurrentTempLayer(currentLayerGroup);

        // Capa de temperatura máxima
        const maxLayerGroup = stationList.map((station) => (
            <Marker
                key={station.id}
                position={[station.location.lat, station.location.long]}
                icon={TempIcon({ number: station.current_weather_data.temp_max })}>
              
            </Marker>
        ));
        setMaxTempLayer(maxLayerGroup);

        const minTempLayer = stationList.map((station) => (
            <Marker
                key={station.id}
                position={[station.location.lat, station.location.long]}
                icon={TempIcon({ number: station.current_weather_data.temp_min })}>
              
            </Marker>
        ));
        setMinTempLayer(minTempLayer);
    }, [stationList]);

    const getStationList = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch("api/public/get-station-current-conditions-list", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();
      
            setStationList(data);
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        }
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation([latitude, longitude]);
                    setPosition([latitude, longitude]);
                },
                (error) => {
                    console.error('Error getting user location:', error.message);
                    setUserLocation([40.4168, -3.7038]);
                    setPosition([40.4168, -3.7038]);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setUserLocation([40.4168, -3.7038]);
            setPosition([40.4168, -3.7038]);
        }
    };





    return (
        <div>
            <style>{`
                /* Añade estilos personalizados al control de capas */
                .leaflet-control-layers label {
                    font-size: 11px !important;
                    /* Otras reglas de estilo si es necesario */
                }
            `}</style>
            <MapContainer
                style={{ height: screenHeight * 0.7, width: '100%', zIndex: 0 }}
                center={userLocation}
                zoom={6}
                scrollWheelZoom={true}
                key={userLocation ? userLocation.join('_') : 'default'}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
                />

                <LayersControl position="topright">
                    <BaseLayer checked name="Temp" >

                        <LeafletLayerGroup>{currentTempLayer}</LeafletLayerGroup>

                    </BaseLayer>
                    <BaseLayer name="Max" >
                        <LeafletLayerGroup>{maxTempLayer}</LeafletLayerGroup>
                    </BaseLayer>

                    <BaseLayer name="Min" >
                        <LeafletLayerGroup>{minTempLayer}</LeafletLayerGroup>
                    </BaseLayer>

                </LayersControl>
            </MapContainer>
            {isStationModalOpen && stationData && (
                <StationBelowModal
                    isOpen={isStationModalOpen}
                    onClose={closeStationModeal}
                    station={stationData}
                />
            )}
        </div>
    );
};



export default StationMap;
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';

import red_light from '../../resources/icons/red_light.png';
import green_light from '../../resources/icons/green_light.png';
import yellow_light from '../../resources/icons/yellow_light.png';
import warning_cert from '../../resources/img/certs/warning_cert.png';

const StationCard = ({ data, goStation }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [place, setPlace] = useState(null);
    const [ligth, setLight] = useState(null);
    const [status, setStatus] = useState(null);
    const [region, setRegion] = useState(null);

    useEffect(() => {

      
        if (data.location.city !== null) {
            setPlace(data.location.city)
        }
        else if (data.location.town !== null && data.location.city === null) {
            setPlace(data.location.town)
        }
        else if (data.location.town === null && data.location.village !== null && data.location.city === null) {
            setPlace(data.location.village)
        }
        else if (data.location.town === null && data.location.village === null && data.location.hamlet !== null && data.location.city === null) {
            setPlace(data.location.hamlet)
        }

        if (data.location.region !== null) {
            setRegion(data.location.region)
        }
        else if (data.location.state !== null && data.location.region === null) {
            setRegion(data.location.state)
        }
        else if (data.location.state_district !== null && data.location.region === null  && data.location.state === null) {
            setRegion(data.location.state_district)
        }

        if (data.status === 'draft') {
            setLight(yellow_light)
            setStatus(lang[langCode].weather.draft)
        }
        else if (data.status === 'online') {
            setLight(green_light)
            setStatus(lang[langCode].weather.online)
        }
        else if (data.status === 'offline') {
            setLight(red_light)
            setStatus(lang[langCode].weather.offline)
        }
        else if (data.status === 'warning') {
            setLight(warning_cert)
            setStatus(lang[langCode].weather.warning)
        }

    }, [data]);





    const launchStation = () => {

        goStation(data)
    }

    return (

        <div>
            <div style={{ backgroundColor: '#FAFAFA' }}>
                <div className='container' onClick={launchStation} style={{ paddingBottom: '20px', backgroundColor: 'white', border: '1px solid #CCCCCC', borderRadius: '5px', cursor: 'pointer' }}>
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-12' style={{ textAlign: 'left' }}>
                            <h3 style={{ fontSize: '14px' }}> {data.name}</h3>
                        </div>
                        <div className='row' style={{ paddingTop: '2px' }}>
                            <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                <img
                                    style={{ width: '20px', marginBottom: '10px' }}
                                    src={require(`../../resources/country_flag/${data.location.country_iso.toLowerCase()}.svg`)} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                    alt=""
                                />
                                <h3 style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '0px', paddingLeft:'5px' }}>{place}, {region} , {data.location.country}</h3>
                            </div>
                        </div>
                        <div className='row' style={{ paddingTop: '2px' }}>
                            <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>

                                <p style={{ fontSize: '12px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', padding: '0px' }}> {lang[langCode].weather.status}: </p>
                                <img
                                    style={{ width: '15px', marginLeft: '5px' }}
                                    src={ligth} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                    alt=""
                                />
                                <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px', padding: '0px', marginLeft: '3px' }}> {status} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StationCard;
import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import edit from '../../resources/icons/edit.png';
import out from '../../resources/icons/out.png';
import view from '../../resources/icons/view.png';
import ProfileEdit from '../Profile/ProfileEdit.js';
import { useUserData } from '../../components/Contexts/UserData';
import ProfileView from '../Profile/ProfileView.js';

const Profile = () => {

  const langCode = useLangContext();
  const [activeEdit, setActiveEdit] = useState(false);
  const [activeView, setActiveView] = useState(true);
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [showProfileView, setShowProfileView] = useState(true);
  const { userData } = useUserData();
  

  const setEdit = (e) => {
    e.preventDefault();
    setActiveEdit(true)
    setActiveView(false)
    setShowProfileEdit(true)
    setShowProfileView(false)

  }

  const setView = (e) => {

    e.preventDefault();
    setActiveEdit(false)
    setActiveView(true)
    setShowProfileEdit(false)
    setShowProfileView(true)
  }


  const setLogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    window.location.reload();
  }





  return (

    <div style={{ backgroundColor: '#FAFAFA' }}>
      <div className='container'>
        <div className='row' style={{ paddingTop: '10px' }}>
          <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            
            
            <a href="#" onClick={setEdit} style={{ borderBottom: activeEdit ? '2px solid black' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                style={{ width: '15px' }}
                src={edit}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
              &nbsp;{lang[langCode].personalProfile.editLabel}
              </p>
            </a>


            <a href="#" onClick={setView} style={{ borderBottom: activeView ? '2px solid black' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                style={{ width: '20px' }}
                src={view}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
              &nbsp;{lang[langCode].personalProfile.viewLabel}
              </p>
            </a>


            <a href="#" onClick={setLogOut} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'center'}}>
              <img
                style={{ width: '16px' }}
                src={out}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
              {lang[langCode].personalProfile.logoutLabel}
              </p>
            </a>

          </div>
        </div>
      </div>

      {
        showProfileEdit && (
          <ProfileEdit userData={userData} />
        )
      }
        {
        showProfileView && (
          <ProfileView id={userData.user.id} isMe={true} />
        )
      }
    </div >
  );
};

export default Profile;
import React, { useState, useEffect, useRef } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import UniquePost from './UniquePost.js';
import loader from '../../resources/img/loader.svg';
import check from '../../resources/icons/check.png';

const GridPosts = ({userData}) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const page = useRef(1);
    const isFetching = useRef(false); // Para evitar solicitudes repetidas

    useEffect(() => {
        loadMorePosts(); // Cargar los primeros elementos
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const loadMorePosts = async () => {
        if (!hasMore || isFetching.current || loading) {
            return;
        }
        isFetching.current = true;
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            // Realiza una solicitud a tu endpoint (api/public/get-followed-posts)

            const response = await fetch("api/public/get-followed-posts?page=" + page.current, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();
            const newPosts = data.posts;

            if (newPosts.length === 0) {
                setHasMore(false);
                window.removeEventListener("scroll", handleScroll);
            } else {
                setPosts((prevPosts) => [...prevPosts, ...newPosts]);
                page.current += 1;
            }
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        } finally {
            isFetching.current = false;
            setLoading(false);
        }
    };

    const handleScroll = () => {
        if (!loading && hasMore && !isFetching.current && window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
            // Llegamos al final del scroll, carga más posts automáticamente
            loadMorePosts();
        }
    };

    let viewedPosts = false;

    return (
        <div>
            {posts.map((post, index) => (
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div key={post.id}>

                     
                        {post.viewed === true && viewedPosts === false && (
                            <div className='row' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <div className='col-lg-5' style={{ textAlign: 'center' }}>
                                    <hr></hr>
                                </div>
                                <div className='col-lg-2' style={{ textAlign: 'center' }}>
                                    <img
                                        src={check}
                                        style={{
                                            width: '25px',
                                            display: 'inline-block',  // Establecer como elemento en línea
                                            verticalAlign: 'middle',   // Alineación vertical al medio
                                        }}
                                    />
                                    <p style={{ fontSize: '15px', fontFamily: 'montserratbold', display: 'inline-block', marginLeft: '10px' }}>
                                        {lang[langCode].posts.upToDate}
                                    </p>
                                </div>
                                <div className='col-lg-5' style={{ textAlign: 'center' }}>
                                    <hr></hr>
                                </div>
                            </div>
                        )}
                        {post.viewed === true && !viewedPosts && (viewedPosts = true)}
                        <UniquePost postData={post} userData={userData} withLikes={true} />
                    </div>
                </div>
            ))}
            {loading && (
                // Renderiza un indicador de carga mientras se cargan los posts
                <img
                    src={loader}
                    style={{
                        width: '50px',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            )}
        </div>
    );
};

export default GridPosts;
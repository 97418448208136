import React, { useState, useEffect, useRef } from 'react';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import loader from '../../resources/img/loader.svg';
import erase from '../../resources/icons/delete.png';
import save from '../../resources/icons/save.svg';
import edit from '../../resources/icons/edit.png';
import plus from '../../resources/icons/plus.png';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useUserData } from '../Contexts/UserData.js';
import '../../css/Personal.css';


const ProfileProjectsEdit = ({ userData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const { reloadUserDataAsync } = useUserData();
    const [enabledNameInputs, setEnabledNameInputs] = useState({});
    const [nameErrors, setNameErrors] = useState({});

    const [nameImageSrcs, setNameImageSrcs] = useState({});
    const [nameDeleteImageSrcs, setNameDeleteImageSrcs] = useState({});
    const [showCross, setShowCross] = useState(true);
    const [showNewInput, setShowNewInput] = useState(false);
    const [newInputError, setNewInputError] = useState('');

    const [nameCount, setNameCount] = useState(userData.user.projects ? userData.user.projects.length : 0);
    const newInputvalue = useRef(null);

    const [newInputSaveSrc, setNewInputSaveSrc] = useState(save);
    const [lastNumber, setLastNumber] = useState(null);


    useEffect(() => {
        // Este efecto se ejecutará cada vez que newInputError cambie.
       
      }, [newInputError]);


    useEffect(() => {


        if (userData.user.projects == null) {
            setNameCount(0);
            setLastNumber(0);
            setShowNewInput(true)
        }
        else {
            setNameCount(userData.user.projects.length);
            setLastNumber(getLastNumber());

        }


    }, [userData.user.projects]);

    useEffect(() => {

        if (nameCount < 5) {
            setShowCross(true)
        }

    }, [nameCount, lastNumber]);


    const getLastNumber = () => {
        const studies = userData.user.projects;

        if (studies.length > 0) {
            let maxNameNumber = studies[0].project_number;

            for (let i = 1; i < studies.length; i++) {
                if (studies[i].project_number > maxNameNumber) {
                    maxNameNumber = studies[i].project_number;
                }
            }

            // Sumar 1 al máximo encontrado
            return maxNameNumber + 1;
        } else {
            // Si no hay elementos, el número es 1
            return 1;
        }
    };




    const reloadData = async (param) => {

        await reloadUserDataAsync();  
        if (param == 'saveNewInput') {
            setNewInputSaveSrc(save)
            newInputvalue.current.value = null;
            setShowNewInput(false)
        }

    }
 


    const editName = (e, inputId) => {
        e.preventDefault();
        setEnabledNameInputs((prevEnabledInputs) => ({
            ...prevEnabledInputs,
            [inputId]: true,
        }));
    }


    const saveName = (e, inputId) => {
        e.preventDefault();
        const name = document.querySelector(`input[name=input_${inputId}`).value;
        var nameOK = false;
        const emptyPattern = /^\s*$/;
        const lengthPattern = /^.{1,70}$/;
        if (!emptyPattern.test(name) && lengthPattern.test(name)) {
            // Name is valid, clear the error
            setNameErrors((prevErrors) => ({
                ...prevErrors,
                [`name_${inputId}`]: null,
            }));
            nameOK = true;
        } else {
            // Name is invalid, set the error message
            setNameErrors((prevErrors) => ({
                ...prevErrors,
                [`name_${inputId}`]: lang[langCode].personalProfesionalData.limitStudy,
            }));
        }      

        if (nameOK == true) {
            setNameImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [inputId]: loader
            }));
            lauchSaveRequest(name, inputId)
        }
    }

    const lauchSaveRequest = (name, inputId) => {
        const token = localStorage.getItem('token');
        fetch("api/public/save-project", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    name: name,
                    number: inputId                 
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                reloadNameData(inputId, "save");
            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });


    }


    const reloadNameData = async (id, param) => {

        await reloadUserDataAsync();
        if (param == "save") {
            setEnabledNameInputs((prevEnabledInputs) => ({
                ...prevEnabledInputs,
                [id]: false,
            }));

            setNameImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [id]: save
            }));
        }
        else if (param == "delete") {

            setNameDeleteImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [id]: erase
            }));

        }


    }



    const deleteName = (e, inputId) => {
        e.preventDefault();
        setNameDeleteImageSrcs(prevSrcs => ({
            ...prevSrcs,
            [inputId]: loader
        }));
        const token = localStorage.getItem('token');
        fetch("api/public/delete-project", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    number: inputId,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                reloadNameData(inputId, "delete");
                setShowCross(true)
            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }


    const addInput = (e) => {
        e.preventDefault();
        setShowCross(false)
        setShowNewInput(true)
    }


    const delNewInput = (e) => {
        e.preventDefault();
        setShowNewInput(false)
    }
    const saveNewInput = (e) => {

        e.preventDefault();
        const name = newInputvalue.current.value   
        var nameOK = false;   
        const emptyPattern = /^\s*$/;
        const lengthPattern = /^.{1,70}$/;   
        if (!emptyPattern.test(name) && lengthPattern.test(name)) {
            setNewInputError('')
            nameOK = true;
        } else {
            setNewInputError(lang[langCode].personalProfesionalData.limitStudy)         
        }
      
        if (nameOK == true) {
            setNewInputSaveSrc(loader)
            const token = localStorage.getItem('token');
            fetch("api/public/save-project", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body:
                    JSON.stringify({
                        name: name,
                        number: lastNumber
                   
                    }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("La solicitud no fue exitosa");
                    }
                    reloadData("saveNewInput");
                })
                .then(data => {

                })
                .catch(error => {
                    // Manejar errores de la petición aquí
                    console.error('Error en la petición fetch:', error);
                });

        }


    }


    return (
        <div className='Personal'>
            <div className='container'>
                <div className='row' style={{ paddingTop: '30px'}}>
                    <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    </div>
                    <div className='col-lg-10' style={{ textAlign: 'left' }}>
                        <h3>{lang[langCode].personalProfesionalData.projects}</h3>

                    </div>
                </div>
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    </div>
                    <div className='col-lg-7' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalProfesionalData.project}</h6>
                    </div>
                 
                </div>
                {userData.user.projects ? (
                    userData.user.projects.map((name, index) => (
                        <div key={name.project_number}>
                            <div className='row' style={{ paddingTop: '7px' }}>
                                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                                </div>
                                <div className='col-lg-7' style={{ textAlign: 'left' }}>
                                    {/* Renderiza los detalles de cada estudio aquí */}
                                    <input
                                        type="text"
                                        defaultValue={name.name}
                                        name={`input_${name.project_number}`}
                                        disabled={!enabledNameInputs[name.project_number]}
                                        onKeyUp={(e) => {

                                        }}
                                        style={{
                                            borderColor: nameErrors[`name_${name.project_number}`] ? 'red' : '',
                                        }}
                                    />
                                    {nameErrors[`name_${name.project_number}`] && (
                                        <p style={{
                                            color: 'red',
                                            margin: '0',
                                            textAlign: 'center',
                                            marginTop: '5px',
                                            fontFamily: 'montserratregular',
                                        }}>
                                            {nameErrors[`name_${name.project_number}`]}
                                        </p>
                                    )}
                                </div>                              
                                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                                    {enabledNameInputs[name.project_number] ? (
                                        <a href='#' onClick={(e) => saveName(e, name.project_number)} style={{}}>
                                            <img
                                                src={nameImageSrcs[name.project_number] || save}
                                                style={{
                                                    width: '20px',
                                                    marginLeft: '10px',
                                                    marginTop: '0px'
                                                }}
                                            />
                                        </a>
                                    ) : (
                                        <a href='#' onClick={(e) => editName(e, name.project_number)} style={{}}>
                                            <img
                                                src={edit}
                                                style={{
                                                    width: '20px',
                                                    marginLeft: '10px',
                                                    marginTop: '0px'
                                                }}
                                            />
                                        </a>
                                    )}
                                    <a href='#' onClick={(e) => deleteName(e, name.project_number)} style={{}}>
                                        <img
                                            src={nameDeleteImageSrcs[name.project_number] || erase}
                                            style={{
                                                width: '20px',
                                                marginLeft: '10px',
                                                marginTop: '0px'
                                            }}
                                        />
                                    </a>
                                </div>
                                {/* Agrega más detalles según la estructura de tu objeto 'name' */}

                            </div>
                        </div>
                    ))
                ) : (
                    <div></div>
                )}
                {showNewInput &&
                    <div className='row' style={{ paddingTop: '7px' }}>
                        <div className='col-lg-2' style={{ textAlign: 'left' }}>
                        </div>
                        <div className='col-lg-7' style={{ textAlign: 'left' }}>
                            <input
                                type="text"
                                placeholder={lang[langCode].personalProfesionalData.formation}
                                ref={newInputvalue}
                                onKeyUp={(e) => {

                                }}
                                style={{
                                    borderColor: newInputError ? 'red' : '',
                                }}
                            />
                            <span style={{ display: 'block', textAlign: 'center' }}>
                                {newInputError &&
                                    <p style={{
                                        color: 'red',
                                        margin: '0',
                                        textAlign: 'center',
                                        marginTop: '5px',
                                        fontFamily: 'montserratregular',

                                    }}>
                                        {newInputError}
                                    </p>}
                            </span>
                        </div>                 
                        <div className='col-lg-2' style={{ textAlign: 'left' }}>
                            <a href='#' onClick={saveNewInput} style={{}}>
                                <img
                                    src={newInputSaveSrc}
                                    style={{
                                        width: '20px',
                                        marginLeft: '10px',
                                        marginTop: '0px'
                                    }}
                                />
                            </a>
                            <a href='#' onClick={delNewInput} style={{}}>
                                <img
                                    src={erase}
                                    style={{
                                        width: '20px',
                                        marginLeft: '10px',
                                        marginTop: '0px'
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                }
                {showCross &&
                    <div className='row' style={{ paddingTop: '7px' }}>
                        <div className='col-lg-2' style={{ textAlign: 'left' }}>
                        </div>
                        <div className='col-lg-5' style={{ textAlign: 'left' }}>
                            <a href='#' onClick={addInput} style={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
                                <img
                                    src={plus}
                                    style={{
                                        width: '20px',
                                        marginLeft: '10px',
                                        marginTop: '0px',
                                    }}
                                />
                                <p style={{ margin: '0 0 0 0px', fontSize: '13px', fontFamily: 'montserratregular' }}>
                                    {lang[langCode].personalProfesionalData.add}
                                </p>
                            </a>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default ProfileProjectsEdit;
import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import lang from '../../resources/js/dictionary.js';
import StationLocation from './StationLocation.js';
import StationInfo from './StationInfo.js';

const SetNewDevice = ({goList}) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [activeButton, setActiveButton] = useState(false);
    const [infoOk, setInfoOk] = useState(false);
    const [locationOk, setLocationOk] = useState(false);
    const [stationLocationData, setStationLocationData] = useState(null);
    const [basicInfoData, setBasicInfoData] = useState(null);

    const getLocation = (stationLocation) => {

        setStationLocationData(stationLocation)
    }

    const getBasicInfo = (basicInfo) => {
        setBasicInfoData(basicInfo)
    }


    useEffect(() => {

        if (infoOk === true && locationOk === true) {
            setActiveButton(true)
        }
        else {
            setActiveButton(false)
        }

    }, [infoOk, locationOk]);


    const activateButton = (origin) => {

        console.log(origin)

        if (origin === 'info') {
            setInfoOk(true)
        }
        else if (origin === 'location') {
            setLocationOk(true)
        }
    }


    const deactivateButton = (origin) => {
        console.log(origin)
        if (origin === 'info') {
            setInfoOk(false)
        }
        else if (origin === 'location') {
            setLocationOk(false)
        }

    }



    const saveStation = async () => {
        {
            const finalStationData =
            {
                basicInfo: basicInfoData,
                stationLocation: stationLocationData,
            }
       
            const token = localStorage.getItem('token');

            try {
                const response = await fetch("api/public/save-station", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(finalStationData),
                });

                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                const data = await response.json();
   
                goList();
   
            } catch (error) {
            
                console.error('Error en la petición fetch:', error);
            }
        };
    }

    return (

        <div>
            <div style={{ backgroundColor: '#FAFAFA' }}>
                <div className='container' >
                    <div className='row' style={{ paddingTop: '20px' }} >
                        <div className='col-lg-12' style={{ padding: '0px' }} >
                            <h2 style={{ fontSize: '22px', fontFamily: 'montserratbold', padding: '5px' }}>{lang[langCode].weather.addDevice}</h2>
                        </div>
                    </div>

                    <div className='row' >
                        <div className='col-lg-6' style={{ padding: '10px' }}>
                            <div style={{ border: '1px solid #E9E9E9', backgroundColor: 'white', borderRadius: '5px' }}>
                                <h3 style={{ fontSize: '14px', fontFamily: 'montserratregular', padding: '5px' }}>{lang[langCode].weather.basicInfo}</h3>
                                <StationInfo getBasicInfo={getBasicInfo} activateButton={activateButton} deactivateButton={deactivateButton} />
                            </div>
                        </div>
                        <div className='col-lg-6' style={{ padding: '10px' }}>
                            <div style={{ border: '1px solid #E9E9E9', backgroundColor: 'white', borderRadius: '5px' }}>
                                <h3 style={{ fontSize: '14px', fontFamily: 'montserratregular', padding: '5px' }}>{lang[langCode].weather.location}</h3>
                                <StationLocation getLocation={getLocation} activateButton={activateButton} deactivateButton={deactivateButton} preLocation={[]}/>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: '40px' }} >
                        <div className='col-lg-4'>
                        </div>
                        <div className='col-lg-4'>
                            <button
                                style={{
                                    fontFamily: 'montserratregular',
                                    fontSize: '13px',
                                    backgroundColor: activeButton ? '#0074EF' : '#E7E7E7', // Cambia el color del texto según activeButton
                                    color: activeButton ? 'white' : '#A7A7A7', // Cambia el color del texto según activeButton
                                }}
                                disabled={!activeButton} // Deshabilita el botón si activeButton es falso
                                onClick={saveStation}
                            >
                                {lang[langCode].weather.addDevice}
                            </button>
                        </div>


                        <div className='col-lg-4'>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SetNewDevice;
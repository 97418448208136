import React, { useState, useEffect } from 'react';

import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import '../../css/StandarFooter.css';

const StandarFooter = () => {
  const langCode = useLangContext();
  const changeLang = useLangToggleContext();
  const [isEnglishSelected, setIsEnglishSelected] = useState(false);
  const [isSpanishSelected, setIsSpanishSelected] = useState(false);
  const [isAutoSelected, setIsAutoSelected] = useState(false);

  useEffect(() => {
    const language = localStorage.getItem("language")
 
    if (!language) {
      setIsAutoSelected(true)
      setIsSpanishSelected(false)
      setIsEnglishSelected(false)
    }
    else if(language=="es")
    {
      setIsAutoSelected(false)
      setIsSpanishSelected(true)
      setIsEnglishSelected(false)
    }
    else if(language=="en")
    {
      setIsAutoSelected(false)
      setIsSpanishSelected(false)
      setIsEnglishSelected(true)
    }
  }, []);


  const setEnglish = (e) => {
    e.preventDefault();
    changeLang("en");   
    setIsAutoSelected(false)
    setIsSpanishSelected(false)
    setIsEnglishSelected(true)

  };

  const setSpanish = (e) => {
    e.preventDefault();
    changeLang("es");  
    setIsAutoSelected(false)
    setIsSpanishSelected(true)
    setIsEnglishSelected(false) 
    
  };

  const setAuto = (e) => {
    e.preventDefault();
    changeLang("auto");   
    setIsAutoSelected(true)
    setIsSpanishSelected(false)
    setIsEnglishSelected(false)   
  };


  return (
    <div className='genericFooter'>
      <div className='container'>
        <div className='row' style={{ paddingTop: '2%', paddingBottom: '2%' }}>
          <div className='col-6'>
            <a href='#' id='needHelp' style={{fontSize:'10px'}}>
              {lang[langCode].footer.needHelp}
            </a> <br />
            <a href='#' id='privacity' style={{fontSize:'10px'}}>
              {lang[langCode].footer.dataPrivacy}
            </a><br />
            <a href='#' id='Contact' style={{fontSize:'10px'}}>
              {lang[langCode].footer.contact}
            </a>
          </div>
          <div className='col-6'>
            <a href='#'
             onClick={setEnglish}
             style={{
              fontWeight: isEnglishSelected  ? 'bold' : 'normal',
              textDecoration: isEnglishSelected  ? 'underline' : 'none',
              color: isEnglishSelected ? 'black' : '#959595',
              fontSize:'10px'
            }}>
              {lang[langCode].footer.englishVersion}
            </a><br />
            <a href='#' 
            onClick={setSpanish}
            style={{
              fontWeight: isSpanishSelected ? 'bold' : 'normal',
              textDecoration: isSpanishSelected ? 'underline' : 'none',
              color: isSpanishSelected ? 'black' : '#959595',
              fontSize:'10px'
            }}>
              {lang[langCode].footer.spanishVersion}
            </a><br />
            <a href='#'
             onClick={setAuto}
             style={{
              fontWeight: isAutoSelected ? 'bold' : 'normal',
              textDecoration: isAutoSelected ? 'underline' : 'none',
              color: isAutoSelected ? 'black' : '#959595',
              fontSize:'10px'
            }}>
              {lang[langCode].footer.autoLanguage}
            </a>
          </div>
         
        </div>
        <div className='row' style={{ paddingTop: '2%', paddingBottom: '2%' }}>
        <div className='col-12'>
            <p style={{fontSize:'10px'}}>{lang[langCode].footer.madeBy}</p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default StandarFooter;
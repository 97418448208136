import React, { useState, useEffect } from 'react';
import noPicture from '../../resources/img/no_foto.svg';
import verifiedTic from '../../resources/icons/verified.png';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import lang from '../../resources/js/dictionary.js';
import { useLocation } from 'react-router-dom';
import loader from '../../resources/img/loader.svg';
import PorfileViewRelatedPeople from './ProfileViewRelatedPeople';
import ProfilePosts from './PorfilePosts';
import StationList from './StationList';
import back from '../../resources/icons/back.png';
import NewPost from '../Posts/NewPost';
import { useNavigate } from 'react-router-dom';


const ProfileView = ({ id, isMe }) => {
  const navigate = useNavigate();
  const [localId, setLocalId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [personalPicture, setPersonalPicture] = useState(noPicture);
  const [countryFlag, setCountryFlag] = useState(null);
  const [regionFlag, setRegionFlag] = useState(null);
  const [showTic, setShowTic] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [jobProfile, setJobProfile] = useState([]);
  const [studiesProfile, setStudiesProfile] = useState([]);
  const [projectProfile, serProjecProfile] = useState([]);
  const [showLocation, setShowLocation] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showAssociation, setShowAssociation] = useState(false);
  const [showJobProfile, setShowJobProfile] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showStudies, setShowStudies] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const [showGoBack, setShowGoBack] = useState(false);
  const [skyrush, setSkyrush] = useState('');
  const [addRelatedPeopleComponent, setAddRelatedPeopleComponent] = useState(false);
  const [association, setAssociation] = useState('');
  const langCode = useLangContext();
  const csrfToken = useCsrfToken();
  const { state } = useLocation();
  const personId = state ? state.personId : null;
  const goBackConst = state ? state.goBack : null;

  useEffect(() => {

    if (goBackConst == true) {
      setShowGoBack(true)
    }
    else {
      setShowGoBack(false)
    }
  }, []);

  const goBack = () => {
    // Navigating back
    navigate(-1);
  };


  useEffect(() => {

    setShowLoader(true)
    if (state && state.personId || id) {
      if (isMe === true) {
        setLocalId(id);
        setAddRelatedPeopleComponent(false)
      } else {
        setLocalId(personId);
        setAddRelatedPeopleComponent(true)
      }
    }
  }, [state, id, isMe]);

  useEffect(() => {
    if (localId !== null) {
      const getUserdata = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch("api/public/user-data-id", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': csrfToken,
              'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
              "userId": localId,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            if (data) {
              setUserData(data);
            } else {
              console.error('Los datos de la respuesta están vacíos.');
            }
          } else {
            throw new Error('La solicitud no tuvo éxito.');
          }
        } catch (error) {
          console.error('Error en la solicitud:', error);
        }
      };

      // Llama a la función getUserdata cuando el componente se monta
      getUserdata();
    }
  }, [localId, csrfToken]);

  useEffect(() => {

    renderUserData();
  }, [userData]);

  const renderUserData = () => {
    if (userData && userData.user) {
      if (userData.user.picture === null) {
        setPersonalPicture(noPicture);
      } else {
        setPersonalPicture('https://www.skyrushcommunity.com/api/public/user_pictures/' + userData.user.picture);
      }
      if (userData.user.location.geonameId !== "") {
        setShowLocation(true);
        import(`../../resources/country_flag/${userData.user.location.countryCode.toLowerCase()}.svg`)
          .then((flag) => setCountryFlag(flag.default))
          .catch((error) => console.error('Error loading country flag:', error));
        import(`../../resources/img/flags/regional/${userData.user.location.region.replace(/\s/g, '')}.svg`)
          .then((flag) => setRegionFlag(flag.default))
          .catch((error) => console.error('Error loading regional flag:', error));
      }
      else {
        setShowLocation(false);
      }
      if (userData.user.skyrush !== null && userData.user.skyrush.verified === 1) {
        setShowTic(true);
        if (userData.user.skyrush.association !== null) {
          setShowAssociation(true);
          import(`../../resources/img/associations/${userData.user.skyrush.association}.png`)
            .then((flag) => setAssociation(flag.default))
            .catch((error) => console.error('Error loading association image:', error));
        }
      }
      if (userData.user.descripcion !== null) {
        setShowDescription(true);
      }
      else {
        setShowDescription(false);
      }
      if (userData.user.jobs !== null) {
        setShowJobProfile(true);
        setJobProfile(userData.user.jobs);
      }
      else {
        setShowJobProfile(false);
      }
      if (userData.user.studies !== null) {
        setShowStudies(true);
        setStudiesProfile(userData.user.studies);
      }
      else {
        setShowStudies(false);
      }
      if (userData.user.projects !== null) {
        setShowProjects(true);
        serProjecProfile(userData.user.projects);
      }
      else {
        setShowProjects(false);
      }

      setDataLoaded(true);
      setShowContent(true);
    }
    setLoadingComplete(true);
  }

  if (!dataLoaded) {
    // No se renderiza nada hasta que los datos estén cargados
    return (
      <div style={{ textAlign: 'center' }}>
        <img
          src={loader}
          style={{
            width: '50px',
            marginLeft: '10px',
            marginTop: '25%',
            marginBottom: '25%',
          }}

        /></div>
    );
  }



  return (

    <div className='Personal' style={{ backgroundColor: '#FAFAFA ' }}>
      {showLoader && !loadingComplete && (
        <div style={{ textAlign: 'center' }}>
          <img
            src={loader}
            style={{
              width: '50px',
              marginLeft: '10px',
              marginTop: '25%',
              marginBottom: '25%',
            }}

          /></div>
      )}

      {showContent && (
        <div className='container' style={{ paddingBottom: '30px', paddingTop: personId === undefined ? '0px' : '0px' }}>
          {showGoBack && (
            <div
              onClick={goBack}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1000,
                backgroundColor: '#ffffff',
                padding: '13px',
                border: 'none',
                cursor: 'pointer',
                color: 'black',
                width: '100%', // Ocupar toda la pantalla horizontalmente
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row', // Cambiado de 'column' a 'row',
                borderBottom: '1px solid #E9E9E9'
              }}
            >
              <img style={{ width: '15px', marginRight: '0px' }} src={back} alt="" />
              <p style={{ margin: 0 }}>{lang[langCode].generic.goBack}</p>
            </div>
          )}
          <div className='row' >
            <div className='col-12' style={{ marginTop: '0px', padding: '10px' }}>
              <div className='row' style={{
                paddingBottom: '10px',
                border: '1px solid #E9E9E9',
                borderRadius: '10px',
                backgroundColor: 'white',
                paddingTop: '20px',
                marginLeft: '-1px', // Ajusta el margen izquierdo para superponer el borde izquierdo al fondo blanco
                marginRight: '-1px', // Ajusta el margen derecho para superponer el borde derecho al fondo blanco
              }}>
                <div className='col-4' style={{ paddingTop: '0px' }}>
                  <img style={{ width: '90px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={personalPicture} alt="" />
                </div>
                <div className='col-8' style={{ paddingTop: '5px' }}>
                  <div className='row' >
                    <div className='col-12' style={{ textAlign: 'left', display: 'flex', alignItems: 'center', padding: '0px' }}>
                      <h2 style={{ margin: '0px', fontSize: '15px' }}>{userData.user.name} {userData.user.last_name}</h2>
                      {showTic && (
                        <img
                          style={{ width: '15px', marginLeft: '10px' }} // Añade margen izquierdo para separar la imagen del texto
                          src={verifiedTic}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  {showLocation && (
                    <div>
                      <div className='row' style={{ paddingTop: '5px' }}>
                        <div className='col-12' style={{ textAlign: 'left', display: 'flex', alignItems: 'center', padding: '0px' }}>
                          <img
                            style={{ width: '20px', border: '1px solid #E9E9E9' }} // Añade margen izquierdo para separar la imagen del texto
                            src={countryFlag}
                            alt=""
                          />

                          {userData.user.location.countryCode === 'ES' && (
                            <img
                              style={{ width: '20px', border: '1px solid #E9E9E9', marginLeft: '5px' }} // Añade margen izquierdo para separar la imagen del texto
                              src={regionFlag}
                              alt=""
                            />
                          )}

                        </div>
                      </div>
                      <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-12' style={{ textAlign: 'left', display: 'flex', alignItems: 'center', padding: '0px' }}>
                          <h4 style={{ fontSize: '12px' }}>{userData.user.location.name}, {userData.user.location.region}, {userData.user.location.countryName}</h4>
                        </div>
                      </div>
                    </div>
                  )}
                  {showAssociation && (
                    <div className='row' >
                      <div className='col-12' style={{ textAlign: 'left', display: 'flex', alignItems: 'center', padding: '0px' }}>
                        <h4 style={{ margin: '0px', fontSize: '10px', fontFamily: 'montserratregular' }}>{lang[langCode].generic.memberOfAssociation}</h4>
                        <img
                          style={{ width: '50px', marginLeft: '5px', marginBottom: '1.5px' }} // Añade margen izquierdo para separar la imagen del texto
                          src={association}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
                {showDescription && (
                  <div className='row' >
                    <div className='col-12' style={{ textAlign: 'left', marginTop: '10px' }}>
                      <h2 style={{ margin: '0px', fontSize: '13px' }}>{userData.user.descripcion}</h2>
                    </div>
                  </div>
                )}


              </div>


              <div className='row'
                style={{
                  border: '1px solid #E9E9E9',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  marginTop: '10px',
                  marginLeft: '-1px', // Ajusta el margen izquierdo para superponer el borde izquierdo al fondo blanco
                  marginRight: '-1px', // Ajusta el margen derecho para superponer el borde derecho al fondo blanco
                }}>
                <div className='col-12' style={{ textAlign: 'left', marginTop: '0px' }}>

                  {addRelatedPeopleComponent ? <PorfileViewRelatedPeople userData={userData} /> : null} {/* Agrega el componente hijo si la condición es verdadera */}
                </div>
              </div>



              {showJobProfile && (
                <div className='row' style={{
                  paddingTop: '10px',
                  border: '1px solid #E9E9E9',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  marginTop: '10px',
                  marginLeft: '-1px', // Ajusta el margen izquierdo para superponer el borde izquierdo al fondo blanco
                  marginRight: '-1px', // Ajusta el margen derecho para superponer el borde derecho al fondo blanco
                }} >
                  <div className='col-12' style={{ textAlign: 'left', marginTop: '0px' }}>
                    <h4 style={{ margin: '0px', fontSize: '13px' }}>{lang[langCode].personalProfesionalData.jobProfile}</h4>
                    <p style={{ paddingLeft: '0px', paddingTop: '5px', fontFamily: 'montserratregular', marginBottom: '5px', fontSize: '12px' }}>
                      {jobProfile.map((job, index) => (
                        <span key={index}>
                          {job.name} en  {job.location}
                          {index < jobProfile.length - 1 ? ' || ' : ''}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              )}
              {showStudies && (
                <div className='row' style={{
                  paddingTop: '10px',
                  border: '1px solid #E9E9E9',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  marginTop: '10px',
                  marginLeft: '-1px', // Ajusta el margen izquierdo para superponer el borde izquierdo al fondo blanco
                  marginRight: '-1px', // Ajusta el margen derecho para superponer el borde derecho al fondo blanco
                }}  >

                  <div className='col-12' style={{ textAlign: 'left', marginTop: '0px' }}>


                    <h4 style={{ margin: '0px', fontSize: '13px' }}>{lang[langCode].personalProfesionalData.schooling}</h4>
                    <p style={{ paddingLeft: '0px', paddingTop: '5px', fontFamily: 'montserratregular', marginBottom: '5px', fontSize: '12px' }}>
                      {studiesProfile.map((studies, index) => (
                        <span key={index}>
                          {studies.name} en  {studies.location}
                          {index < studiesProfile.length - 1 ? ' || ' : ''}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              )}
              {showProjects && (
                <div className='row' style={{
                  paddingTop: '10px',
                  border: '1px solid #E9E9E9',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  marginTop: '10px',
                  marginLeft: '-1px', // Ajusta el margen izquierdo para superponer el borde izquierdo al fondo blanco
                  marginRight: '-1px', // Ajusta el margen derecho para superponer el borde derecho al fondo blanco
                }}  >

                  <div className='col-12' style={{ textAlign: 'left', marginTop: '0px' }}>


                    <h4 style={{ margin: '0px', fontSize: '13px' }}>{lang[langCode].personalProfesionalData.projects}</h4>
                    <p style={{ paddingLeft: '0px', paddingTop: '5px', fontFamily: 'montserratregular', marginBottom: '5px', fontSize: '12px' }}>
                      {projectProfile.map((projects, index) => (
                        <span key={index}>
                          {projects.name}
                          {index < projectProfile.length - 1 ? ' || ' : ''}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              )}

              <hr style={{ marginTop: '10px', marginBottom: '0px' }}></hr>
              <div className='row' >
                <div className='col-12' style={{ textAlign: 'center ', paddingTop: '10px' }}>
                  <h2 style={{ margin: '0px', fontSize: '14px' }}>{lang[langCode].stations.stations}</h2>
                </div>
              </div>
              <div className='row' >
                <div className='col-12' style={{ textAlign: 'center ', paddingTop: '0px' }}>

                  <StationList key={userData.user.id} userId={localId} />
                </div>
              </div>


            </div>
          </div>

          <hr style={{ marginTop: '0px', marginBottom: '0px' }}></hr>
          <div className='row' >
            <div className='col-12' style={{ textAlign: 'center ', paddingTop: '10px' }}>
              <h2 style={{ margin: '0px', fontSize: '14px' }}>{lang[langCode].posts.publishes}</h2>
            </div>
          </div>
          <div className='col-12' style={{ backgroundColor: '#FAFAFA', paddingTop: '0px' }}>

            <div className='row' style={{ paddingTop: '0px', paddingBottom: '40px' }}>
              <div className='col-12' style={{ padding: '0px' }}  >
                <div className='posts'>
                  <ProfilePosts key={userData.user.id} userData={userData} />
                </div>
              </div>
            </div>
          </div>





        </div>
      )}
    </div >
  );
};

export default ProfileView;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import '../../css/Personal.css';


Modal.setAppElement('#root');


const GoAdvanced = ({ isOpen, onClose }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();

    const [showTitle, setShowTitle] = useState(true);
    const [showConfirmationTitle, setShowConfirmationTitle] = useState(false);
    const [showBody, setShowBody] = useState(true);
    const [showConfirmationBody, setShowConfirmationBody] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [showConfirmationButton, setShowConfirmationButton] = useState(false);

    const confirmationMessage = () => {

        setShowTitle(false)
        setShowConfirmationTitle(true)

        setShowBody(false)
        setShowConfirmationBody(true)

        setShowButton(false)
        setShowConfirmationButton(true)

        setCustomStyles({
            content: {
                width: '720px',
                height: '220px',
                margin: 'auto',
                // Otros estilos dinámicos aquí
            },
        });

    }


    const goAdvanced = () => {
        const token = localStorage.getItem('token');
        fetch("api/public/send-verify-skyrush-profile", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                confirmationMessage()
            })
            .then(data => {


            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });

    }



    const [customStyles, setCustomStyles] = useState({
        content: {
            width: '700px',
            height: '465px',
            margin: 'auto',
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Renovar Subscripcion"
            style={customStyles}        >
            <div className='row'>
                <div className='col-lg-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </a>
                </div>
                <div className='col-lg-10'>

                </div>
            </div>
            <div className='Personal' style={{ backgroundColor: 'white' }}>
                <div className='container' style={{ padding: '5%', paddingTop: '0px' }}>
                    <div className='row'>
                        {showTitle && (
                            <div className='col-lg-12' style={{ textAlign: 'left' }}>
                                <h3>{lang[langCode].skyrushProfileData.goPremiumButton}</h3>
                            </div>
                        )}
                        {showConfirmationTitle && (
                            <div className='col-lg-12' style={{ textAlign: 'left' }}>
                                <h3>{lang[langCode].skyrushProfileData.renewRequestSentTitle}</h3>
                            </div>
                        )}
                    </div>
                    {showBody && (
                        <div>
                             <div className='row'>
                                <div className='col-lg-12' style={{ textAlign: 'left' }}>
                                    <p style={{  margin: '0px' }}>{lang[langCode].skyrushProfileData.goAdvancedModalText}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12' style={{ textAlign: 'left', paddingTop: '10px' }}>
                                    <p style={{ color: '#909090', margin: '0px' }}>{lang[langCode].skyrushProfileData.verifiedProfileText}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <p style={{ color: '#909090', margin: '0px' }} dangerouslySetInnerHTML={{ __html: lang[langCode].skyrushProfileData.goAvancedContentTextII }} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <p style={{ color: '#909090', margin: '0px' }}>{lang[langCode].skyrushProfileData.goAvancedContentTextIII}</p>
                                </div>
                            </div>
                            <div className='row'  style={{ textAlign: 'left', paddingTop: '10px' }}>
                                <div className='col-lg-12'>
                                    <p style={{  margin: '0px' }} dangerouslySetInnerHTML={{ __html: lang[langCode].skyrushProfileData.goAdvanceContentFoot }} />
                                </div>
                            </div>
                        </div>
                    )}
                    {showConfirmationBody && (
                        <div className='row'>
                            <div className='col-lg-12' style={{ textAlign: 'left' }}>
                                <p className='grey-font-modal' style={{ margin: '0px' }}>{lang[langCode].skyrushProfileData.goAdvancedRequestSentText}</p>
                            </div>
                        </div>
                    )}
                    {showButton && (
                        <div className='row' style={{ paddingTop: '20px' }}>
                            <div className='col-lg-3'>
                            </div>
                            <div className='col-lg-6'>
                                <button onClick={goAdvanced}>{lang[langCode].skyrushProfileData.verifyMyProfileButton}</button>
                            </div>
                            <div className='col-lg-3'>
                            </div>
                        </div>
                    )}
                    {showConfirmationButton && (
                        <div className='row' style={{ paddingTop: '20px' }}>
                            <div className='col-lg-3'>
                            </div>
                            <div className='col-lg-6'>
                                <button onClick={onClose}>{lang[langCode].generic.close}</button>
                            </div>
                            <div className='col-lg-3'>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )

}

export default GoAdvanced;

import React, { useState, useEffect, useRef } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import noPicture from '../../resources/img/no_foto.svg';
import send from '../../resources/icons/send.png';
import loader from '../../resources/img/loader.svg';
import like from '../../resources/icons/like.png';
import likeBlue from '../../resources/icons/likeBlue.png';
import chat from '../../resources/icons/chat.png';
import dislike from '../../resources/icons/dislike.png';
import CommentLikes from '../Modals/CommentLikes.js';
import chatblue from '../../resources/icons/chatblue.png';
import SubComment from './SubComment.js';
import verifiedTic from '../../resources/icons/verified.png';
import menu from '../../resources/icons/menu.png';
import CommentMenu from '../Posts/CommentMenu.js';

const UniqueComment = ({ comment, userData, open, subElementId ,recharge}) => {

    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [comments, setComments] = useState([]);
    const isFetching = useRef(false); // Para evitar solicitudes repetidas
    const [isCommentLikesModalOpen, SetIsCommentLikesModalOpen] = useState({});
    const [currentCommentId, setCurrentCommentId] = useState(null);
    const [subcommentCounters, setSubcommentCounters] = useState({});
    const [iLike, setIlike] = useState(false);
    const [likeCount, setLikeCount] = useState(false);
    const [showTic, setShowTic] = useState(false);
    const [menuAbierto, setMenuAbierto] = useState(false);


    const fromCommentRecharge = () =>
    {
        recharge()
    }

  


    useEffect(() => {

        setIlike(comment.I_like)
        setLikeCount(comment.likeCount)
        if (comment.verified === 1) {
            setShowTic(true)
        }

    }, []);



    const openCommentLikesModal = (postId, e) => {
        e.preventDefault();
        SetIsCommentLikesModalOpen(prevState => ({
            ...prevState,
            [postId]: true
        }));
    };


    const closeCommentLikesModal = (postId, e) => {
        e.preventDefault();
        SetIsCommentLikesModalOpen(prevState => ({
            ...prevState,
            [postId]: false
        }));
    };


    const convertHour = (hour) => {
        const notificationTime = new Date(hour);
        const currentTime = new Date();
        const timeDifferenceInSeconds = (currentTime - notificationTime) / 1000;

        const timeTranslations = lang[langCode].time; // Acceder a las traducciones de tiempo

        if (langCode === 'es') {
            // Si el idioma es español, usa 'hace' al principio
            if (timeDifferenceInSeconds < 60) {
                return `${timeTranslations.hace} ${Math.floor(timeDifferenceInSeconds)} ${timeTranslations.seconds}`;
            } else if (timeDifferenceInSeconds < 3600) {
                const minutes = Math.floor(timeDifferenceInSeconds / 60);
                return `${timeTranslations.hace} ${minutes} ${minutes === 1 ? timeTranslations.minut : timeTranslations.minuts}`;
            } else if (timeDifferenceInSeconds < 86400) {
                const hours = Math.floor(timeDifferenceInSeconds / 3600);
                return `${timeTranslations.hace} ${hours} ${hours === 1 ? timeTranslations.hour : timeTranslations.hours}`;
            } else {
                const days = Math.floor(timeDifferenceInSeconds / 86400);
                return `${timeTranslations.hace} ${days} ${days === 1 ? timeTranslations.day : timeTranslations.days}`;
            }
        } else {
            // Si el idioma es inglés, usa 'ago' al final
            if (timeDifferenceInSeconds < 60) {
                return `${Math.floor(timeDifferenceInSeconds)} ${timeTranslations.seconds} ${timeTranslations.hace}`;
            } else if (timeDifferenceInSeconds < 3600) {
                const minutes = Math.floor(timeDifferenceInSeconds / 60);
                return `${minutes} ${minutes === 1 ? timeTranslations.minut : timeTranslations.minuts} ${timeTranslations.hace}`;
            } else if (timeDifferenceInSeconds < 86400) {
                const hours = Math.floor(timeDifferenceInSeconds / 3600);
                return `${hours} ${hours === 1 ? timeTranslations.hour : timeTranslations.hours} ${timeTranslations.hace}`;
            } else {
                const days = Math.floor(timeDifferenceInSeconds / 86400);
                return `${days} ${days === 1 ? timeTranslations.day : timeTranslations.days} ${timeTranslations.hace}`;
            }
        }
    }

    const showSubcomment = (commentId, post, e) => {
        setSubcommentCounters(prevCounters => ({
            ...prevCounters,
            [commentId]: (prevCounters[commentId] || 0) + 1,
        }));

        // Verificar si el subcomentario ya está abierto
        if (subcommentCounters[commentId] > 0) {
            // Si está abierto, establecer el contador a 0 para cerrarlo
            setSubcommentCounters(prevCounters => ({
                ...prevCounters,
                [commentId]: 0,
            }));
            setCurrentCommentId(null); // También puedes establecer el ID actual a null
        } else {
            setCurrentCommentId(commentId);
        }
    };


    const toggleMenu = () => {
        setMenuAbierto(!menuAbierto);
    };



    const revomeCommentLike = (id) => {


        const token = localStorage.getItem('token');
        fetch("api/public/remove-comment-like", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                comment_id: id,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                setIlike(false)
                setLikeCount(likeCount - 1)


            })
            .catch(error => {
                console.error('Error en la petición fetch:', error);
            });
    }

    const saveCommentLike = (id) => {

        const token = localStorage.getItem('token');
        fetch("api/public/save-comment-like", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                comment_id: id,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }

                setIlike(true)
                setLikeCount(likeCount + 1)

            })
            .catch(error => {
                console.error('Error en la petición fetch:', error);
            });
    };



    return (

        <div>

            <div style={{ backgroundColor: '#F3F3F3', margin: '5px', borderRadius: '5px', padding: '5px', paddingBottom: '10px', position: 'relative', marginTop: '15px' }}>

                <div className='row' style={{ paddingTop: '3px' }}>
                    <div className='col-lg-8' style={{ display: 'flex', alignItems: 'center' }}>
                        {comment.picture === null ? (
                            <img
                                style={{ width: '35px', borderRadius: '100%' }}
                                src={noPicture}
                                alt=""
                            />
                        ) : (
                            <img
                                style={{ width: '35px', borderRadius: '100%' }}
                                src={'https://www.skyrushcommunity.com/api/public/user_pictures/' + comment.picture}
                                alt=""
                            />
                        )}

                        <p style={{ margin: '0px', marginLeft: '5px', fontSize: '12px' }}>{comment.name} {comment.last_name}</p>
                        {showTic && (
                            <img
                                style={{ width: '12px', marginLeft: '5px' }} // Añade margen izquierdo para separar la imagen del texto
                                src={verifiedTic}
                                alt=""
                            />
                        )}


                    </div>

                    <div className='row' style={{ paddingTop: '3px', paddingRight: '0px' }}>
                        <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center', paddingRight: '0px', paddingLeft: '25px' }}>

                            <p style={{ margin: '0px', padding: '5px', paddingLeft: '0%', marginLeft: '0px', fontSize: '13px', fontFamily: 'montserratregular', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: comment.comment }}></p>
                            <p style={{ margin: '0px', fontSize: '12px', position: 'absolute', top: '5px', right: '5px', fontFamily: 'montserratRegular' }}>
                                {convertHour(comment.created_at)}
              
                                {comment.user_id === userData.user.id && (
                                    <img
                                        style={{
                                            width: '20px',
                                            marginLeft: '5px',
                                            borderRadius: '100%',
                                            marginRight: '5px',
                                            cursor: 'pointer'
                                        }}
                                        src={menu}
                                        alt=""
                                        onClick={toggleMenu}
                                    />
                                )}
                                {menuAbierto && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '20px',
                                            right: '10px',
                                            backgroundColor: 'white',
                                            border: '1px solid #E1E1E1',
                                            padding: '10px',
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                            borderRadius: '5px',
                                            zIndex: 999,
                                        }}

                                    >
                                        <CommentMenu commentId={comment.id} fromCommentRecharge={fromCommentRecharge} />
                                    </div>
                                )}
                            </p>

                        </div>

                    </div>

                    <div className='row' style={{ paddingTop: '3px', paddingRight: '0px' }}>
                        {iLike ? (
                            <div className='col-lg-1 d-flex align-items-center justify-content-start' style={{ cursor: 'pointer', }} onClick={(event) => openCommentLikesModal(comment.id, event)}>
                                <img
                                    src={likeBlue}
                                    style={{
                                        width: '18px',
                                        marginLeft: '10px'
                                    }}
                                />
                                <p style={{ fontSize: '12px', color: '#0074EF', fontFamily: 'montserratbold', margin: '0px', paddingLeft: '5px' }}>{likeCount}</p>
                            </div>
                        ) : (
                            <div className='col-lg-1 d-flex align-items-center justify-content-start' style={{ cursor: 'pointer', }} onClick={(event) => openCommentLikesModal(comment.id, event)}>
                                <img
                                    src={like}
                                    style={{
                                        width: '18px',
                                        marginLeft: '10px'
                                    }}
                                />
                                <p style={{ fontSize: '12px', fontFamily: 'montserratregular', margin: '0px', paddingLeft: '5px' }}>{likeCount}</p>
                            </div>
                        )}
                        <CommentLikes
                            isOpen={isCommentLikesModalOpen[comment.id]}
                            onClose={(event) => closeCommentLikesModal(comment.id, event)}
                            commentData={comment.id}
                        />
                        {comment.I_commented ? (
                            <div className='col-lg-1 d-flex align-items-center justify-content-start' style={{ cursor: 'pointer', paddingLeft: '0px' }} onClick={(event) => showSubcomment(comment.id, comment.post_id, event)}>
                                <img
                                    src={chatblue}
                                    style={{
                                        width: '18px',
                                        marginLeft: '10px'
                                    }}
                                />
                                <p style={{ fontSize: '12px', color: '#0074EF', fontFamily: 'montserratbold', margin: '0px', paddingLeft: '5px' }}>{comment.subCommentCount}</p>
                            </div>
                        ) : (
                            <div className='col-lg-1 d-flex align-items-center justify-content-start' style={{ cursor: 'pointer', paddingLeft: '0px' }} onClick={(event) => showSubcomment(comment.id, comment.post_id, event)}>
                                <img
                                    src={chat}
                                    style={{
                                        width: '18px',
                                        marginLeft: '10px'
                                    }}
                                />
                                <p style={{ fontSize: '12px', fontFamily: 'montserratregular', margin: '0px', paddingLeft: '5px' }}>{comment.subCommentCount}</p>
                            </div>
                        )}

                        <div className='col-lg-6' style={{ display: 'flex', alignItems: 'flex-start' }}>

                        </div>

                        {iLike ? (

                            <div className='col-lg-2 d-flex align-items-left justify-content-end'
                                style={{ cursor: 'pointer' }}
                                onClick={() => revomeCommentLike(comment.id)}>
                                <img
                                    src={dislike}
                                    style={{
                                        width: '19px',
                                        height: '19px',
                                        marginRight: '5px',
                                    }}
                                />
                                <p style={{ fontSize: '12px', fontFamily: 'montserratregular', margin: '0px' }}>{lang[langCode].posts.dislike}</p>
                            </div>

                        ) : (
                            <div className='col-lg-2 d-flex align-items-left justify-content-end'
                                style={{ cursor: 'pointer' }}
                                onClick={() => saveCommentLike(comment.id)}>
                                <img
                                    src={like}
                                    style={{
                                        width: '19px',
                                        height: '19px',
                                        marginRight: '5px',
                                    }}
                                />
                                <p style={{ fontSize: '12px', fontFamily: 'montserratregular', margin: '0px' }}>{lang[langCode].posts.iLike}</p>
                            </div>
                        )}

                        <div className='col-lg-2 d-flex align-items-center justify-content-end' onClick={(event) => showSubcomment(comment.id, comment.post_id, event)}>
                            <img
                                src={chat}
                                style={{
                                    width: '19px',
                                    height: '19px',
                                }}
                            />

                            <p style={{ fontSize: '12px', fontFamily: 'montserratregular', margin: '0px', paddingLeft: '5px', cursor: 'pointer' }}>{lang[langCode].posts.answer}</p>
                        </div>
                    </div>

                </div>



            </div>
            {(open || subcommentCounters[comment.id] > 0) && (
                <div style={{ paddingTop: '10px', backgroundColor: 'white' }}>
                    <SubComment
                        commentId={comment.id}
                        postId={comment.post_id}
                        userData={userData}
                        markComment={subElementId}
                    />
                </div>
            )}
        </div>
    );
};

export default UniqueComment;

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/Main.css';
import 'react-app-polyfill/stable';
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from './components/Contexts/CsrfTokenContext';
import { LangProvider } from './components/Contexts/LangProvider';
import { UserDataProvider, useUserData } from './components/Contexts/UserData';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import People from './components/Private/People';
import PeopleMobile from './components_m/Private/People';
import Profile from './components/Private/Profile';
import ProfileMobile from './components_m/Private/Profile';
import SwitchBar from './components/Bars/SwitchBar';
import MobileSwitchBar from './components_m/Bars/SwitchBar';
import ProfileView from './components/Profile/ProfileView';
import ProfileViewMobile from './components_m/Profile/ProfileView';
import { useWebSocket } from './components/Common/WebsocketProvider';
import Posts from './components/Private/Posts';
import PostsMobile from './components_m/Private/Posts';
import SinglePost from './components/Posts/SinglePost';
import SinglePostMobile from './components_m/Posts/SinglePost';
import SingleComment from './components/Posts/SingleComment';
import SingleCommentMobile from './components_m/Posts/SingleComment';
import StandarFooter from './components/Footers/StandarFooter';
import Weather from './components/Private/Weather';
import WeatherMobile from './components_m/Private/Weather';
import StationMain from './components_m/Weather/StationView/StationMain';

function App() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Ajusta el ancho según sea necesario
    };

    // Establece el valor inicial
    handleResize();

    // Agrega un event listener para el cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpia el event listener en el desmontaje del componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (


    <CsrfTokenProvider>
      <LangProvider>
        <UserDataProvider>
          <Router>
            {isMobile ? (
              // Usa la versión móvil de SwitchBar si es un dispositivo móvil
              <MobileSwitchBar />
            ) : (
              // Usa la versión de escritorio de SwitchBar si no es un dispositivo móvil
              <SwitchBar />
            )}

            {isMobile ? (
              <Routes>
                <Route path="/people" element={<PeopleMobile />} />
                <Route path="/profile" element={<ProfileMobile />} />
                <Route path="/profile-view" element={<ProfileViewMobile />} />
                <Route path="/posts" element={<PostsMobile />} />
                <Route path="/post" element={<SinglePostMobile />} />
                <Route path="/comment" element={<SingleCommentMobile />} />
                <Route path="/weather" element={<WeatherMobile />} />
                <Route path="/station/:stationName" element={<StationMain />} />
              </Routes>
            ) : (

              <Routes>
                <Route path="/people" element={<People />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile-view" element={<ProfileView />} />
                <Route path="/posts" element={<Posts />} />
                <Route path="/post" element={<SinglePost />} />
                <Route path="/comment" element={<SingleComment />} />
                <Route path="/weather" element={<Weather />} />

              </Routes>

            )}
          </Router>
          {isMobile ? (
            // Usa la versión móvil de SwitchBar si es un dispositivo móvil
            <div>

            </div>
          ) : (
            // Usa la versión de escritorio de SwitchBar si no es un dispositivo móvil
            <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
              <StandarFooter />
            </div>
          )}

        </UserDataProvider>
      </LangProvider>
    </CsrfTokenProvider>


  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useLangContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import location from '../../resources/icons/location.png';
import loader from '../../resources/img/loader.svg';

const StationLocation = ({ getLocation, activateButton, deactivateButton, preLocation }) => {
    const langCode = useLangContext();
    const [userLocation, setUserLocation] = useState(null);
    const [position, setPosition] = useState(null);
    const [finalLocation, setFinalLocation] = useState(null);
    const [formattedLocation, setFormattedLocation] = useState(null);
    const [altitudeErrorMessage, setAltitudeErrorMessage] = useState('');
    const [altitudeInputValue, setAltitudeInputValue] = useState('');
    const [showLoader, setShowLoader] = useState(true);
    const [mapLoaded, setMapLoaded] = useState(false);

    useEffect(() => {

        const getUserLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setUserLocation([latitude, longitude]);
                        setPosition([latitude, longitude]); // Inicializa la posición del marcador
                    },
                    (error) => {
                        console.error('Error getting user location:', error.message);
                        setUserLocation([40.4168, -3.7038]);
                        setPosition([40.4168, -3.7038]);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
                setUserLocation([40.4168, -3.7038]);
                setPosition([40.4168, -3.7038]);
            }
        };
        if (preLocation.length === 0) {
            getUserLocation();
        }
        else {
            setUserLocation([preLocation[0], preLocation[1]])
            setPosition([preLocation[0], preLocation[1]])
        }
    }, []);

    useEffect(() => {

        getLocation(finalLocation);
    }, [finalLocation]);


    useEffect(() => {

        if (preLocation.length > 0) {
            getPositionDetails(preLocation[0], preLocation[1])

        }

    }, []);



    const handleAltitudeInputChange = (event) => {
        const value = event.target.value;

        // Reset error messages
        setAltitudeErrorMessage('');

        // Check if the value is a non-empty string
        if (!value.trim()) {
            setAltitudeErrorMessage(lang[langCode].weather.altitudeError);
            deactivateButton('location')
            return;
        }
        else {
            activateButton('location')
        }

        // Check if the value contains only numbers
        if (!/^\d+$/.test(value)) {
            setAltitudeErrorMessage(lang[langCode].weather.altitudeError);
            deactivateButton('location');
            return;
        }
        else {
            activateButton('location')
        }

        // Check if the value is a valid number
        const altitudeValue = parseFloat(value);
        if (isNaN(altitudeValue) || !Number.isInteger(altitudeValue)) {
            setAltitudeErrorMessage(lang[langCode].weather.altitudeError);
            deactivateButton('location')
            return;
        }
        else {
            activateButton('location')
        }

        // Check if the value is within the allowed range
        if (altitudeValue < 0 || altitudeValue > 9999) {
            setAltitudeErrorMessage(lang[langCode].weather.altitudeError);
            deactivateButton('location')
            return;
        }
        else {
            activateButton('location')
        }

        // Update the input value
        setAltitudeInputValue(value);

        // Update finalLocation with the new altitude value
        setFinalLocation((prev) => ({
            ...prev,
            altitude: altitudeValue,
        }));
    };

    const customIcon = new L.Icon({
        iconUrl: location,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
    });

    const getPositionDetails = async (lat, lng) => {
        try {
            const response = await fetch(
                `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=d64bf0ffe0f244edbe3d15b317bd7a10`
            );
            const data = await response.json();

            if (data.results.length > 0) {
                const locationDetails = data.results[0];

                getLocation({
                    lat: lat,
                    lng: lng,
                    sunrise: locationDetails.annotations.sun.rise.civil,
                    sunset: locationDetails.annotations.sun.set.civil,
                    timezone: locationDetails.annotations.timezone.name,
                    country_iso: locationDetails.components['ISO_3166-1_alpha-2'],
                    country: locationDetails.components.country,
                    continent: locationDetails.components.continent,
                    details: locationDetails.components,
                });
                setFinalLocation({
                    lat: lat,
                    lng: lng,
                    sunrise: locationDetails.annotations.sun.rise.civil,
                    sunset: locationDetails.annotations.sun.set.civil,
                    timezone: locationDetails.annotations.timezone.name,
                    country_iso: locationDetails.components['ISO_3166-1_alpha-2'],
                    country: locationDetails.components.country,
                    continent: locationDetails.components.continent,
                    details: locationDetails.components,
                })
                activateButton('location')
                getEevation(lat, lng)
                setFormattedLocation(locationDetails.formatted)
            } else {
                console.error('No location details found');
            }
        } catch (error) {
            console.error('Error fetching location details:', error);
        }
    };

    const getEevation = async (lat, lng) => {
        try {
            const response = await fetch(
                `https://api.open-elevation.com/api/v1/lookup?locations=${lat},${lng}`
            );
            const data = await response.json();

            if (data.results.length > 0) {
                const elevation = data.results[0].elevation;

                // Update finalLocation with the new elevation value
                setFinalLocation((prev) => ({
                    ...prev,
                    altitude: elevation,
                }));
                setAltitudeErrorMessage('');
                activateButton('location')
                // Update altitudeInputValue with the new elevation value
                setAltitudeInputValue(elevation.toString());
            }
        } catch (error) {
            console.error('Error fetching elevation:', error);
        }
    };

    const DraggableMarker = () => {
        const [draggable, setDraggable] = useState(true);

        const toggleDraggable = () => {
            setDraggable((prev) => !prev);
        };

        const eventHandlers = {
            dragend: (event) => {
                const { lat, lng } = event.target.getLatLng();
                getPositionDetails(lat, lng);
                setPosition([lat, lng]); // Actualiza la posición del marcador
            },
        };

        return (
            <Marker
                position={position}
                draggable={draggable}
                eventHandlers={eventHandlers}
                icon={customIcon}
            >
                <Popup>
                    <span onClick={toggleDraggable}>
                        {draggable
                            ? 'El marcador se puede arrastrar'
                            : 'Haz clic aquí para hacer que el marcador sea arrastrable'}
                    </span>
                </Popup>
            </Marker>
        );
    };

    return (
        <div>
            <div>
                <div className='container' style={{ paddingBottom: '30px' }}>

                    {formattedLocation && (
                        <div>
                            <div className='row' style={{ paddingTop: '0px' }}>
                                <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <img
                                        style={{ width: '20px', marginBottom: '10px' }}
                                        src={require(`../../resources/country_flag/${finalLocation.country_iso.toLowerCase()}.svg`)} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                        alt=""
                                    />
                                    <h3 style={{ fontSize: '12px', fontFamily: 'montserratbold', padding: '5px' }}>{formattedLocation}</h3>
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '0px', paddingBottom: '10px' }}>
                                <div className='col-lg-2' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '13px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.altitude}</p>
                                </div>
                                <div className='col-lg-6' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.altitudePlaceHolder}
                                        value={altitudeInputValue}
                                        onChange={handleAltitudeInputChange}
                                        style={{
                                            border: `1px solid ${altitudeErrorMessage ? 'red' : '#CCC'}`,
                                            borderRadius: '5px',
                                            fontSize: '13px',
                                            fontFamily: 'montserratregular',
                                            width: '70px',
                                            height: '30px',
                                            outline: 'none',
                                            marginBottom: '5px', // Añadido margen inferior
                                        }}
                                    />
                                    {altitudeErrorMessage && <p style={{ color: 'red', fontSize: '12px', marginTop: '5px', marginBottom: '0' }}>{altitudeErrorMessage}</p>}
                                </div>
                            </div>
                        </div>
                    )}
                    {showLoader && !mapLoaded && (
                        // Renderiza el loader solo si aún no ha cargado el mapa
                        <div className='row' style={{ paddingTop: '40px' }}>
                            <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                <img
                                    src={loader}
                                    style={{
                                        paddingTop: '100px',
                                        width: '50px',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className='row' style={{ paddingTop: '0px' }}>
                        <div className='col-lg-12' style={{ width: '1300px', position: 'relative', zIndex: 0 }}>
                            {userLocation && (
                                <MapContainer
                                    style={{ height: '450px', width: '100%', zIndex: 0 }}
                                    center={userLocation}
                                    zoom={13}
                                    scrollWheelZoom={true}
                                    whenReady={() => setMapLoaded(true)}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                    />
                                    <DraggableMarker />
                                </MapContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StationLocation;
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';


const SubCommentMenu = ({ commentId,getSubComments }) => {

    const langCode = useLangContext();
    const csrfToken = useCsrfToken();

   

    const deleteComment = () => {
        console.log(commentId)
        const token = localStorage.getItem('token');
        fetch("api/public/delete-subcomment", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    sub_comment_id: commentId,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                getSubComments()


            })
            .then(data => {

              //  onDeletePost()

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
     
            });

    }


    return (
        <div >



            <div className='row' >
                <div className='col-lg-12' style={{ textAlign: 'center',cursor:'pointer' }} onClick={deleteComment}>
                    <p style={{ fontFamily: 'montserratregular' }}>{lang[langCode].posts.delete}</p>
                </div>
            </div>


        </div>

    );
};

export default SubCommentMenu;
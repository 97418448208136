import React, { useState, useEffect } from 'react';
import followers from '../../resources/icons/followers.svg';
import follow from '../../resources/icons/user_pending.svg';
import people from '../../resources/icons/user_people.svg';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import PeopleSearch from '../People/PeopleSearch';
import PeopleFollowers from '../People/PeopleFollowers';
import PeopleFollowing from '../People/PeopleFollowing';


const People = () => {
  const langCode = useLangContext();
  const [activeFollowers, setActiveFollowers] = useState(false);
  const [activeFollowing, setActiveFollowing] = useState(false);
  const [showSearchPeople, setShowSearchPeople] = useState(true);
  const [showFollowersPeople, setShowFollowersPeople] = useState(false);
  const [showFollowingPeople, setShowFollowingPeople] = useState(false);


  const setEdit = (e) => {
    e.preventDefault();
    setActiveFollowers(true)
    setActiveFollowing(false)
    setShowSearchPeople(false)
    setShowFollowersPeople(true)
    setShowFollowingPeople(false)

  }

  const setView = (e) => {

    e.preventDefault();
    setActiveFollowers(false)
    setActiveFollowing(true)
    setShowSearchPeople(false)
    setShowFollowersPeople(false)
    setShowFollowingPeople(true)
  }


  const setLogOut = (e) => {
    e.preventDefault();
    setActiveFollowers(false)
    setActiveFollowing(false)
    setShowSearchPeople(true)
    setShowFollowersPeople(false)
    setShowFollowingPeople(false)

  }


  return (

    <div style={{ backgroundColor: '#FAFAFA' }}>
      <div className='container' style={{ paddingBottom: '70px' }}>

        <div className='row' style={{ paddingTop: '10px' }}>
          <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href="#" onClick={setEdit} style={{ borderBottom: activeFollowers ? '2px solid black' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                style={{ width: '26px' }}
                src={followers}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                &nbsp;{lang[langCode].mainPeople.followers}
              </p>
            </a>


            <a href="#" onClick={setView} style={{ borderBottom: activeFollowing ? '2px solid black' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                style={{ width: '26px' }}
                src={follow}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                &nbsp;{lang[langCode].mainPeople.following}
              </p>
            </a>


            <a href="#" onClick={setLogOut} style={{ borderBottom: showSearchPeople ? '2px solid black' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '150px', textAlign: 'left' }}>
              <img
                style={{ width: '26px' }}
                src={people}
                alt=""
              />
              <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                {lang[langCode].mainPeople.lookForPeople}
              </p>
            </a>
          </div>
        </div>
        {
          showSearchPeople && (
            <PeopleSearch  />
          )

        }

        {showFollowersPeople && (
          <PeopleFollowers  />
        )}

        {showFollowingPeople && (
          <PeopleFollowing  />
        )}


      </div>
    </div>
  );
};

export default People;
import React, { useState, useEffect } from 'react';
import NewPost from '../Posts/NewPost';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import { useUserData } from '../../components/Contexts/UserData';
import GridPosts from '../Posts/GridPosts';
import AllPosts from '../Posts/AllPosts';
import lang from '../../resources/js/dictionary.js';

const Posts = () => {

    const { userData } = useUserData();
    const [isFollwedPost, setisFollwedPost] = useState(true);
    const langCode = useLangContext();
    const seeFollwedPosts = (e) => {
        e.preventDefault();
        setisFollwedPost(true)
    }

    const seePosts = (e) => {
        e.preventDefault();
        setisFollwedPost(false)
    }



    return (

        <div style={{ backgroundColor: '#FAFAFA', paddingTop:'10px' }}>
            <div className='container' >
                <div className='row' style={{padding: '0px 5px 0px 5px'}}>
              
                    <div className='col-12' style={{ textAlign: 'center', border: '1px solid #E9E9E9', borderRadius: '10px', backgroundColor: 'white' }}>
                        <NewPost userData={userData} />
                    </div>
                    
                </div>
                <div className='row' style={{ paddingTop: '20px' }}>
                  
                    <div className='col-6' style={{ textAlign: 'center' }} onClick={seeFollwedPosts}>
                        <h3 style={{ fontSize: '12px', fontFamily: 'montserratregular', cursor: 'pointer', borderBottom: isFollwedPost ? '2px solid black' : 'none', paddingBottom: '2px' }}>{lang[langCode].generic.userPosts}</h3>
                    </div>
                    <div className='col-6' style={{ textAlign: 'center' }} onClick={seePosts}>
                        <h3 style={{ fontSize: '12px', fontFamily: 'montserratregular', cursor: 'pointer', borderBottom: !isFollwedPost ? '2px solid black' : 'none', paddingBottom: '2px' }}>{lang[langCode].generic.TrendPosts}</h3>
                    </div>
                   
                </div>
                <div className='row' >
                   
                    <div className='col-12' style={{ padding: '0px', paddingBottom:'80px' }}>

                        {isFollwedPost && (
                            <GridPosts userData={userData} />
                        )}
                        {!isFollwedPost && (
                            <AllPosts userData={userData} />
                        )}
                    </div>

                  
                </div>
            </div>
        </div>
    );
};

export default Posts;


import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import { useCsrfToken } from '../Contexts/CsrfTokenContext';
import lang from '../../resources/js/dictionary.js';
import '../../css/People.css';
import noPicture from '../../resources/img/no_foto.svg';
import PersonalPicture from '../Modals/PersonalPicture.js';
import { useUserData } from '../../components/Contexts/UserData';
import loader from '../../resources/img/loader.svg';


const ProfilePictureChange = () => {

    const [personalPicture, setPersonalPicture] = useState(noPicture);
    const [modalKey, setModalKey] = useState(1);
    const [isPersonalPictureModal, setIsPersonalPictureModal] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(true);
    const [showDeleteLoader, setShowDeleteLoader] = useState(false);
    const { userData } = useUserData();
    const { reloadUserDataAsync } = useUserData();
    const csrfToken = useCsrfToken();

    const langCode = useLangContext();
    useEffect(() => {
        if (userData.user.picture === null) {

            setPersonalPicture(noPicture)
            setShowDeleteButton(false)
        } else {
            setPersonalPicture('https://www.skyrushcommunity.com/api/public/user_pictures/' + userData.user.picture)
        }
    }, [userData]);

    
    const deletePicture = async () => {
        setShowDeleteButton(false)
        setShowDeleteLoader(true)

        try {
            const token = localStorage.getItem('token');
            const response = await fetch("api/public/erase-picture", {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
              }
            });
      
            if (!response.ok) {
              throw new Error('La solicitud no tuvo éxito.');
            }
            const obtainResponse = await response.json();
            await reloadUserDataAsync();      
            setShowDeleteLoader(false)
          } catch (error) {
            console.error('Error en la solicitud:', error);
          }    
    }

    const openPersonalPictureModal = () => {
        setIsPersonalPictureModal(true);
    };

 

    const closePersonalPictureModal = async (e) => {

        try {

            await reloadUserDataAsync();       
            setShowDeleteButton(true)    

        } catch (error) {
            console.error('Error en la recarga de datos:', error);
        } finally {
            setIsPersonalPictureModal(false);
            setModalKey(prevKey => prevKey + 1);
        }
    };


    return (

        <div className='people'>
            <div className='container'>
                <div className='row' >
                    <div className='col-lg-12'>
                        <h2>{lang[langCode].personalProfile.personalDataTitle}</h2>

                    </div>
                </div>
                <div className='row' >
                    <div className='col-lg-12' style={{ paddingTop: '10px' }}>
                        <img style={{ width: '120px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={personalPicture} alt="Logo" />
                    </div>
                </div>
                <div className='row' >
                    <div className='col-lg-12' style={{ paddingTop: '10px' }}>
                        <hr></hr>
                    </div>
                </div>
                <div className='row' >
                    <div className='col-lg-6' style={{ padding: '0px' }} onClick={openPersonalPictureModal}>
                        <a href='#'>{lang[langCode].modifyPersonalPicture.loadPictureLink}</a>
                    </div>
                    {showDeleteButton && (
                    <div className='col-lg-6' style={{ padding: '0px' }}>
                        <a href='#' onClick={deletePicture}>{lang[langCode].modifyPersonalPicture.erasePictureLink}</a>
                    </div>
                    )}
                      {showDeleteLoader && (
                    <div className='col-lg-6' style={{ padding: '0px' }}>
                        <img style={{ width: '30px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={loader} alt="" />
                    </div>
                    )}
                </div>
            </div>
            <PersonalPicture
                key={modalKey}
                isOpen={isPersonalPictureModal}
                onClose={closePersonalPictureModal}
                userData={userData}

            />
        </div>
    );
};

export default ProfilePictureChange;
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../../components/Contexts/LangProvider.js';
import lang from '../../../resources/js/dictionary.js';
import red_light from '../../../resources/icons/red_light.png';
import green_light from '../../../resources/icons/green_light.png';
import yellow_light from '../../../resources/icons/yellow_light.png';
import warning_cert from '../../../resources/img/certs/warning_cert.png';
import info from '../../../resources/icons/info.png';
import WsViewInfo from '../../Modals/WsViewInfo.js';
import moment from 'moment-timezone';

const StationStatusWSView = ({ data }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [ligth, setLight] = useState(null);
    const [place, setPlace] = useState(null);
    const [status, setStatus] = useState(null);
    const [isWsViewModalOpen, setIsWsViewModalOpen] = useState(false);



    const closeWsViewModal = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsWsViewModalOpen(false)
    }

    const openWsViewMoal = (e) => {
        e.preventDefault();
        setIsWsViewModalOpen(true)
    }


    useEffect(() => {

        if (data.location.town !== null) {
            setPlace(data.location.town)
        }
        else if (data.location.town === null && data.location.village !== null) {
            setPlace(data.location.village)
        }
        else if (data.location.town === null && data.location.village === null && data.location.hamlet !== null) {
            setPlace(data.location.hamlet)
        }

        if (data.status === 'draft') {
            setLight(yellow_light)
            setStatus(lang[langCode].weather.draft)
        }
        else if (data.status === 'online') {
            setLight(green_light)
            setStatus(lang[langCode].weather.online)
        }
        else if (data.status === 'offline') {
            setLight(red_light)
            setStatus(lang[langCode].weather.offline)
        }
        else if (data.status === 'warning') {
            setLight(warning_cert)
            setStatus(lang[langCode].weather.warning)
        }

    }, []);


    const convertDate = (fechaUTC) => {


        // Obtener la zona horaria del navegador
        const navegadorZonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        // Convertir la fecha a la zona horaria del navegador
        const fechaEnUTC = moment.utc(fechaUTC);
        const fechaConvertida = fechaEnUTC.tz(navegadorZonaHoraria);
    
        // Formatear la fecha
        const formattedDate = fechaConvertida.format('HH:mm:ss DD-MM-YYYY');
    
        return formattedDate;
    };


    return (

        <div>


            <div>

                {status === 'Borrador' && (
                    <div style={{ paddingTop: '10px' }}>
                        <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>{lang[langCode].weather.draftInstruccions}</p>
                    </div>
                )}
                {status === 'Dato erróneo detectado' && (
                    <div style={{ paddingTop: '10px' }}>
                        <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>{lang[langCode].weather.warningInstructións}</p>
                    </div>
                )}
                {status === 'Online' && (
                    <div style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                        <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>
                            {lang[langCode].weather.onlineInstruccions}{' '}
                            <strong>{convertDate(data.current_weather_data.updated_at, data.location.time_zone)}</strong>
                        </p>
                    </div>
                )}
                {status === 'Offline' && (
                    <div style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                        <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>
                            {lang[langCode].weather.oflineInstruccions}{' '}
                            <strong>{convertDate(data.current_weather_data.updated_at, data.location.time_zone)}</strong>
                        </p>
                    </div>
                )}

                <div className='row' style={{ padding: '0px' }}>
                    <div className='col-12' >
                        <div className='row' style={{ paddingTop: '0px' }}>
                            <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                <h3 style={{ fontSize: '12px', margin: '0px', fontFamily: 'montserratregular' }}>Hostname:</h3>
                                <p style={{ fontSize: '12px', fontFamily: 'montserratbold', textAlign: 'justify', margin: '0px', marginLeft: '5px' }}>skyrushcommunity.com</p>
                            </div>
                        </div>
                        <div className='row' style={{ paddingTop: '5px' }}>
                            <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                <h3 style={{ fontSize: '12px', margin: '0px', fontFamily: 'montserratregular' }}>Path:</h3>
                                <p style={{ fontSize: '12px', fontFamily: 'montserratbold', textAlign: 'justify', margin: '0px', marginLeft: '5px' }}>/api/public/wsview-endpoint?</p>
                            </div>
                        </div>
                        <div className='row' style={{ paddingTop: '5px' }}>
                            <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                <h3 style={{ fontSize: '12px', margin: '0px', fontFamily: 'montserratregular' }}>Station ID:</h3>
                                <p style={{ fontSize: '12px', fontFamily: 'montserratbold', textAlign: 'justify', margin: '0px', marginLeft: '5px' }}>{data.connection_assets.asset_1}</p>
                            </div>
                        </div>
                        <div className='row' style={{ paddingTop: '5px' }}>
                            <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                <h3 style={{ fontSize: '12px', margin: '0px', fontFamily: 'montserratregular' }}>Station Key:</h3>
                                <p style={{ fontSize: '12px', fontFamily: 'montserratbold', textAlign: 'justify', margin: '0px', marginLeft: '5px' }}>{data.connection_assets.asset_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', paddingTop:'20px' }} onClick={openWsViewMoal}>
                        <img
                            style={{ width: '17px', marginRight: '3px' }}
                            src={info} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                            alt=""
                        />
                        <p style={{ fontSize: '12px', fontFamily: 'montserratbold', textAlign: 'justify', margin: '0px', marginLeft: '5px', textDecoration: 'underline' }}>{lang[langCode].weather.wsviewExample}</p>
                    </div>
                    <WsViewInfo
                        isOpen={isWsViewModalOpen}
                        onClose={closeWsViewModal}

                    />
                </div>
            </div>
        </div>

    );
};

export default StationStatusWSView;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import '../../css/Personal.css';
import noPicture from '../../resources/img/no_foto.svg';
import del from '../../resources/icons/delete.png';
import tag from '../../resources/icons/tag.png';
import video from '../../resources/icons/video.png';
import tagpeople from '../../resources/icons/tagpeople.png';
import locationImg from '../../resources/icons/location.png';
import PeopleFollowedTag from './PeopleFollowedModalTag';
import Select from 'react-select';

Modal.setAppElement('#root');

const EditVideoPost = ({ isOpen, onClose, userData, postData, renderAgain }) => {

  const langCode = useLangContext();
  const csrfToken = useCsrfToken();
  const [text, setText] = useState('');
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [textareaHeight, setTextareaHeight] = useState('38px');
  const [showPhotoArea, setShowPhotoArea] = useState(true);
  const [showPhotos, setShowPhotos] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showEnterLocation, setShowEnterLocation] = useState(false);
  const [geonamesData, setGeonamesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [location, setLocation] = useState(null);
  const [showAllContent, setShowAllContent] = useState(true);
  const [showTagPeople, setShowTagPeople] = useState(false);
  const [isPeopleFollowerModalOpen, setIsPeopleFollowerModalOpen] = useState(false);
  const [sendUserData, setSendUserData] = useState(userData.user.id);
  const [publishButton, setPublishButton] = useState(false);
  const [tagedPeople, setTagedPeople] = useState([]);
  const [textareaValue, setTextareaValue] = useState('');
  const [showTaggedPeople, setShowTaggedPeople] = useState(false);
  const [videoInfo, setVideoInfo] = useState(lang[langCode].posts.loadVideo);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);


  useEffect(() => {
    if (postData) {
      setLocation({
        geonameId: postData.geonameId,
        adminCode1: postData.adminCode1,
        adminCodes1: postData.adminCodes1,
        name: postData.name,
        adminName1: postData.adminName1,
        countryName: postData.countryName,
        countryCode: postData.countryCode,
        countryId: postData.countryId,
        fcl: postData.fcl,
        fclName: postData.fclName,
        fcode: postData.fcode,
        fcodeName: postData.fcodeName,
        lat: postData.lat,
        lng: postData.lng,
        population: postData.population,
        label: `${postData.name}, ${postData.adminName1}, ${postData.countryName}`,
      });
      setTagedPeople(postData.tagged_users || []);

    }
    if (postData.geonameId !== null) {
      setShowLocation(true)
    }
    else {
      setShowLocation(false)
    }
    if (postData.video_path !== null) {
      setShowPhotoArea(false);
      setShowPhotos(true);
      setVideoPreview(`https://www.skyrushcommunity.com/api/public/storage/posts/${postData.video_path}`);
    }
    if (postData.content !== null) {
      setTextareaValue(postData.content)
    }

  }, [postData]);

  const closePeopleFollowerModal = () => {
    setIsPeopleFollowerModalOpen(false)
  }

  const apiKey = 'wequestz';

  const handleTextAreaChange = (e) => {
    setTextareaHeight(`${e.target.scrollHeight}px`);
    setTextareaValue(e.target.value.trim());



    if (e.target.value.trim() === '' && uploadedPhotos.length === 0 && geonamesData.length === 0) {
      setPublishButton(true)
    }
    else {
      setPublishButton(false)
    }


  };

  const handleImageUpload = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const newVideoFile = files[0];

      if (newVideoFile.name.toLowerCase().endsWith(".mp4") && newVideoFile.size <= 30 * 1024 * 1024) {
        // Muestra el nuevo video en el preview
        setVideoPreview(URL.createObjectURL(newVideoFile));
        setShowPhotoArea(false);
        setShowPhotos(true);
        setPublishButton(false);

        // Limpia el estado del video cargado y la información del video

        setVideoInfo(null);
        setUploadedVideo(newVideoFile);
      } else {
        setUploadedVideo((prevVideo) => {
          setUploadedVideo(null);
          if (newVideoFile.name.toLowerCase().endsWith(".mp4")) {
            setVideoInfo(lang[langCode].posts.errorVideoSize);
          } else {
            setVideoInfo(lang[langCode].posts.errorVideo);
          }
          return newVideoFile;
        });

        // Limpia el estado del videoPreview
        setVideoPreview(null);
      }
    }
  };




  const handleSelectChange = (selectedOption) => {
    setShowEnterLocation(false)
    setShowLocation(true)
    setPublishButton(false)
    setLocation(selectedOption)
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      fetch(`https://secure.geonames.org/searchJSON?q=${inputValue}&maxRows=10&username=${apiKey}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('La solicitud no tuvo éxito.');
          }
          return response.json();
        })
        .then((data) => {
          // Procesa los datos de Geonames y establece las opciones en el estado.
          const options = data.geonames.map((item) => ({
            value: item,
            label: `${item.name}, ${item.adminName1}, ${item.countryName} `


          }));
          setGeonamesData(options);
          callback(options);
        })
        .catch((error) => {
          console.error('Error al obtener datos de Geonames:', error);
        });
    }
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,

      fontSize: '12px',
      color: 'black',
      width: '100%',
      borderRadius: '5px',
      border: '1px solid #CCC',
      '&:focus-within': {
        borderColor: 'black', // Color de borde al enfocar
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '12px', // Estilo del texto en el menú
      color: 'black', // Color del texto en el menú
      backgroundColor: 'white', // Fondo del menú
      border: '1px solid #CCC', // Borde del menú
    }),
  };

  const addLocationFunction = () => {
    setShowEnterLocation(true)
  }


  const deleteLocation = (e) => {
    e.preventDefault();
    setShowLocation(false)
    setShowEnterLocation(false)
    setLocation(null)
    setGeonamesData([])


    if (textareaValue !== '' || uploadedPhotos.length !== 0) {
      setPublishButton(false)
    }
    else {
      setPublishButton(true)
    }
  }

  const tagPeople = (e) => {
    e.preventDefault();
    setIsPeopleFollowerModalOpen(true)
  }



  const manageTags = (tags) => {
    setTagedPeople(tags)
  }


  useEffect(() => {
    if (tagedPeople.length !== 0) {

      setShowTaggedPeople(true)
    }
    else {
      setShowTaggedPeople(false)
    }

  }, [tagedPeople]);


  const publish = async () => {
    const tagedPeopleIds = tagedPeople.map(user => user.id);
    const formData = new FormData();
    const formattedContent = textareaValue.replace(/\n/g, "<br>");
    console.log(formattedContent)

    formData.append('content', formattedContent);
    formData.append('location', JSON.stringify(location));
    formData.append('tagedPeople', JSON.stringify(tagedPeopleIds));
    formData.append('media_type', 'video'); // Cambiar el tipo de medio a 'video'
    formData.append('postId', JSON.stringify(postData.id)); // Especificar el tipo de medio como 'photo'

    // Agregar el video en formato binario al FormData
    if (uploadedVideo) {
      formData.append('video', uploadedVideo);

    }

    const token = localStorage.getItem('token');

    try {
      const response = await fetch("api/public/edit-video-post", {
        method: "POST",
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }
      renderAgain()
      onClose()
      // Procesar la respuesta del servidor si es necesario
    } catch (error) {
      console.error('Error en la petición fetch:', error);
    }
  };



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Renovar Subscripcion"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 999,
        },
        content: {
          width: '95%',
          maxWidth: '100%',  // Set the maximum width to 100% of the screen
          maxHeight: '95vh',  // Set the maximum height to 95% of the viewport height
          height: 'auto',     // Set the height to 'auto'
          margin: 'auto',
          left: '0',
          right: '0',
          top: '5%',
          bottom: '10%',
          overflow: 'auto',   // Enable scrolling if content exceeds the modal size
          padding: '5px',
          border: 'none',
          borderRadius: '8px',
          position: 'fixed',
        },
      }}
    >
      <div className='row'>
        <div className='col-1'>
          <a href='#' onClick={onClose}>
            <img
              src={close}
              style={{
                width: '10px',
                marginBottom: '10px'
              }}
            />
          </a>
        </div>
        <div className='col-10'></div>
      </div>

      {showAllContent && (
        <div className='Personal' style={{ backgroundColor: 'white' }}>
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <h3 style={{ margin: '5px', fontSize: '14px', fontFamily: 'montserratbold' }}>
                {lang[langCode].posts.editPost}
              </h3>
            </div>
          </div>
          <hr style={{ margin: '3px' }}></hr>

          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <textarea
                style={{
                  minHeight: '38px',
                  height: textareaHeight,
                  width: '100%',
                  border: 'none',
                  outline: 'none',
                  fontFamily: 'montserratregular',
                  fontSize: '12px'
                }}
                placeholder={lang[langCode].posts.textAreaPlaceHolder}
                rows={1}
                onChange={handleTextAreaChange}
              >
                {postData.content}
              </textarea>
            </div>
          </div>
          {showTaggedPeople && (
            <div className='row' style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
              {tagedPeople.map((person, index) => (
                <div className='col-6' key={index} style={{ textAlign: 'center', padding: '2px', display: 'flex' }}>
                  {/* Contenido que deseas renderizar para cada elemento, con el enlace dentro del párrafo */}

                  <p
                    style={{
                      margin: '0px',
                      backgroundColor: '#C7DDF3',
                      borderRadius: '5px',
                      flex: '1',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px'
                    }}>
                    <img
                      style={{ width: '12px', marginLeft: '0px', marginRight: '3px', marginBottom: '3px' }}
                      src={tag}
                      alt=""
                    />
                    {person.name} {person.last_name}
                  </p>
                  {/* Agregar más contenido según sea necesario */}
                </div>
              ))}
            </div>
          )}
          {showEnterLocation && (

            <div className='row' style={{ paddingTop: '10px' }}>
              <div className='col-12' style={{ textAlign: 'left' }}>
                <h6 style={{ fontSize: '12px', fontFamily: 'montserratbold' }}>{lang[langCode].personalDataProfile.locationTitle}</h6>
                <Select
                  styles={customStyles}
                  options={geonamesData}
                  value={selectedOption}
                  onInputChange={(inputValue) => loadOptions(inputValue, (options) => { })}
                  onChange={handleSelectChange}
                  placeholder={lang[langCode].personalDataProfile.locationPlaceHolder}
                  isClearable
                  isSearchable
                  cacheOptions
                />

              </div>
            </div>

          )}
          {showLocation && (
            <div className='row'>
              <div className='col-12' style={{ cursor: 'pointer', paddingTop: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{ width: '17px', marginRight: '5px' }}
                      src={locationImg}
                      alt=""
                    />
                    <p style={{ margin: '0', flexGrow: 1 }}>{location.label}</p>
                  </div>
                  <a href='#' onClick={deleteLocation} style={{ margin: '0', width: '15px' }}>
                    <img
                      style={{ width: '14px', marginLeft: '0px' }}
                      src={del}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className='row'>
            {showPhotoArea && !showPhotos && (
              <div className='col-12'>
                <div
                  style={{
                    marginTop: '20px',
                    border: '1px dashed #A5A5A5',
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '200px',
                  }}
                >
                  <p style={{ fontFamily: 'montserratregular' }}>
                    {videoInfo}
                  </p>
                  <input
                    type="file"
                    id="fileInput"
                    accept="video/*"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='row'>
            {showPhotos && (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className='col-6' style={{ position: 'relative' }}>
                  <div
                    style={{
                      maxWidth: '100%',
                      maxHeight: 'calc(100vh - 20px)',
                      width: '100%',
                      height: '100%',
                      margin: '5px',
                      border: '1px solid #E9E9E9',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                    }}
                  >
                    {/* Elemento de video para previsualización */}
                    <video
                      key={videoPreview} // Añade un key para forzar la re-renderización
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                      controls

                    >
                      <source src={videoPreview} type="video/mp4" />
                      Tu navegador no admite el elemento de video.
                    </video>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='row' style={{ paddingTop: '10px' }}>
            <div className='col-6' style={{ paddingRight: '0px', textAlign: 'center' }}>
              <div
                className="custom-file-input"
                style={{
                  textAlign: 'center',
                  backgroundColor: '#E7E7E7',
                  border: '1px solid #E9E9E9',
                  borderRadius: '5px',
                  display: 'flex', // Alineación horizontal de elementos
                  alignItems: 'center', // Alineación vertical de elementos
                  padding: '2px', // Espacio interior
                  paddingLeft: '5px',
                  width: '100%', // Ocupa todo el espacio disponible
                }}
              >
                <label htmlFor="fileInput" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '100%' }}>
                  <img
                    style={{ width: '18px', marginRight: '5px' }}
                    src={video}
                    alt=""
                  />
                  <p style={{ margin: '0px', fontSize: '11px' }}>
                    {lang[langCode].posts.loadPTheVideo}
                  </p>
                </label>
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>
            </div>
            <div className='col-6' style={{ paddingRight: '10px', paddingLeft: '5px', textAlign: 'center' }}>
              <div
                className="custom-file-input"
                style={{
                  textAlign: 'center',
                  backgroundColor: '#E7E7E7',
                  border: '1px solid #E9E9E9',
                  borderRadius: '5px',
                  display: 'flex', // Alineación horizontal de elementos
                  alignItems: 'center', // Alineación vertical de elementos
                  padding: '2px', // Espacio interior
                  paddingLeft: '5px',
                  width: '100%', // Ocupa todo el espacio disponible
                }}
              >
                <a href='#'
                  onClick={addLocationFunction}
                  style={{ padding: '0px' }}
                >
                  <label style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '100%' }}>
                    <img
                      style={{ width: '18px', marginRight: '5px' }}
                      src={locationImg}
                      alt=""
                    />
                    <p style={{ margin: '0px', fontSize: '11px' }}>
                      {lang[langCode].posts.addLocation}
                    </p>
                  </label>
                </a>
              </div>
            </div>
            <div className='col-12' style={{ paddingTop: '10px', textAlign: 'center' }}>
              <div
                className="custom-file-input"
                style={{
                  textAlign: 'center',
                  backgroundColor: '#E7E7E7',
                  border: '1px solid #E9E9E9',
                  borderRadius: '5px',
                  display: 'flex', // Alineación horizontal de elementos
                  alignItems: 'center', // Alineación vertical de elementos
                  padding: '2px', // Espacio interior
                  paddingLeft: '5px',
                  width: '100%', // Ocupa todo el espacio disponible
                }}
              >
                <a href='#'
                  onClick={tagPeople}
                  style={{ padding: '0px' }}
                >
                  <label style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '100%' }}>
                    <img
                      style={{ width: '18px', marginRight: '5px' }}
                      src={tagpeople}
                      alt=""
                    />
                    <p style={{ margin: '0px', fontSize: '11px' }}>
                      {lang[langCode].posts.addPeople}
                    </p>
                  </label>
                </a>
              </div>
            </div>
            <div className='col-4'></div>
          </div>
          <PeopleFollowedTag
            isOpen={isPeopleFollowerModalOpen}
            onClose={closePeopleFollowerModal}
            userData={sendUserData}
            manageTags={manageTags}
            tagedPeopleFather={tagedPeople}
          />
        </div>


      )}
      <div className='row' style={{ paddingTop: '10px' }}>
        <div className='col-12' style={{ padding: '10px', textAlign: 'center' }}>
          <button disabled={publishButton} onClick={publish} style={{
            fontFamily: 'montserratbold',
            fontSize: '13px',
            backgroundColor: publishButton ? '#E7E7E7' : '#0074EF', // Cambia el color del botón en función del estado
            color: publishButton ? '#A7A7A7' : 'white', // Cambia el color del botón en función del estado

          }}> {lang[langCode].posts.publish}</button>
        </div>
      </div>
    </Modal>
  );
}

export default EditVideoPost;
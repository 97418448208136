import React, { useState, useEffect, useRef } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import noPicture from '../../resources/img/no_foto.svg';
import send from '../../resources/icons/send.png';
import loader from '../../resources/img/loader.svg';
import like from '../../resources/icons/like.png';
import likeBlue from '../../resources/icons/likeBlue.png';
import chat from '../../resources/icons/chat.png';
import dislike from '../../resources/icons/dislike.png';
import CommentLikes from '../Modals/CommentLikes.js';
import chatblue from '../../resources/icons/chatblue.png';
import SubComment from './SubComment.js';
import UniqueComment from './UniqueComment.js';

const Comments = ({ postData, userData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const mainComment = useRef(null);
    const maxTextAreaHeight = 200;
    const page = useRef(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [comments, setComments] = useState([]);
    const isFetching = useRef(false); // Para evitar solicitudes repetidas
    const [loadingImg, setLoadingImg] = useState(true);
  


    useEffect(() => {
        loadMoreComments();
    }, []);

    const recharge = () =>
    {
        mainComment.current.value = '';
        page.current = 1;
        setComments([])
        loadMoreComments();


    }

    useEffect(() => {
        const textarea = mainComment.current;

        // Set initial height
        textarea.style.height = '30px';

        // Add event listener for Enter key
        const handleKeyPress = (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevents adding a new line
                sendComment(); // Call your sendComment function
            }
        };

        textarea.addEventListener('keypress', handleKeyPress);

        // Cleanup: Remove event listener when component is unmounted
        return () => {
            textarea.removeEventListener('keypress', handleKeyPress);
        };
    }, []);

    const handleInputChange = (e) => {
        const textarea = mainComment.current;
        textarea.style.height = 'auto';
        const scrollMax = Math.min(textarea.scrollHeight, maxTextAreaHeight);
        textarea.style.height = `${scrollMax}px`;
        textarea.style.overflowY = scrollMax < maxTextAreaHeight ? 'hidden' : 'scroll';
    };

    const loadMoreComments = async () => {
        if (!hasMore || isFetching.current || loading) {
            return;
        }
        isFetching.current = true;
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch("api/public/get-comments?page=" + page.current, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    post: postData,
                }),
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();
            const newComments = data.comments;


            if (newComments.length === 0) {
                setHasMore(false);
            } else {
                setLoadingImg(false)
                setComments((prevComments) => [...prevComments, ...newComments]);
                page.current += 1;
            }
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        } finally {
            isFetching.current = false;
            setLoading(false);
        }
    };

    const sendComment = () => {
        setLoadingImg(true)

        const commentData = mainComment.current.value;
        const formattedContent = commentData.replace(/\n/g, "<br>");
        const token = localStorage.getItem('token');
        fetch("api/public/save-comment", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                post_id: postData.id,
                comment: formattedContent
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                mainComment.current.value = '';
                page.current = 1;
                setComments([])
                loadMoreComments();
                setHasMore(true)
            })
            .catch(error => {
                console.error('Error en la petición fetch:', error);
            });
    }; 

    const handleViewMoreClick = () => {
        loadMoreComments();
    };

  
    return (
        <div>
            <hr style={{ margin: '15px' }}></hr>
            <div className='row' style={{ paddingTop: '3px' }}>
                <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '10px' }}>
                        {userData === null ? (
                            <img
                                style={{ width: '30px', borderRadius: '100%' }}
                                src={noPicture}
                                alt=""
                            />
                        ) : (
                            <img
                                style={{ width: '30px', borderRadius: '100%' }}
                                src={'https://www.skyrushcommunity.com/api/public/user_pictures/' + userData.user.picture}
                                alt=""
                            />
                        )}
                    </div>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            ref={mainComment}
                            onChange={handleInputChange}
                            placeholder={lang[langCode].posts.writeComment}
                            style={{
                                resize: 'none',
                                padding: '5px',
                                overflow: 'hidden',
                                width: '100%',
                                border: '1px solid #CCC',
                                borderRadius: '5px',
                                color: 'black',
                                fontFamily: 'montserratregular',
                                fontSize: '13px',
                                paddingRight: '30px',
                            }}
                        />
                        <img
                            src={send}
                            onClick={sendComment}
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                bottom: '10px',
                                right: '5px',
                                width: '23px',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='row' style={{ paddingTop: '10px' }}>
                {comments.map((comment, index) => (
                    <div key={index}>
                        {/* Aquí se renderizarán los comentarios */}
                        { }
                       <UniqueComment userData={userData} comment={comment} recharge={recharge}/>
                    </div>
                ))}
            </div>
            {
                !loading && hasMore && (
                    <div className="row" style={{ justifyContent: "center", marginTop: "10px" }}>
                        <p onClick={handleViewMoreClick} style={{ cursor: 'pointer', marginLeft: '0px', padding: '0px', fontFamily: 'montserratregular', fontSize: '12px', color: 'black', textDecoration: 'underline' }}>{lang[langCode].generic.showMore}</p>
                    </div>
                )
            }
            {
                loadingImg && (
                    // Renderiza un indicador de carga mientras se cargan los posts
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-lg-12'>
                            <img
                                src={loader}
                                style={{
                                    paddingTop: '100px',
                                    width: '50px',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            />
                        </div>
                    </div>
                )
            }






        </div >
    );
};

export default Comments;
import React, { useState, useEffect, useRef } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import like from '../../resources/icons/like.png';
import dislike from '../../resources/icons/dislike.png';
import lang from '../../resources/js/dictionary.js';
import noPicture from '../../resources/img/no_foto.svg';
import send from '../../resources/icons/send.png';
import loader from '../../resources/img/loader.svg';
import SubCommentLikes from '../Modals/SubCommentLikes.js';
import likeBlue from '../../resources/icons/likeBlue.png';
import line from '../../resources/icons/line.png';
import verifiedTic from '../../resources/icons/verified.png';
import SubCommentMenu from '../Posts/SubCommentMenu.js';
import menu from '../../resources/icons/menu.png';

const SubComment = ({ commentId, postId, userData, markComment }) => {

  const mainComment = useRef(null);
  const maxTextAreaHeight = 200;
  const langCode = useLangContext();
  const csrfToken = useCsrfToken();
  const [loadingImg, setLoadingImg] = useState(true);
  const [subComments, setSubComments] = useState([]);
  const [textareaHeight, setTextareaHeight] = useState(30);
  const [isCommentLikesModalOpen, SetIsCommentLikesModalOpen] = useState({});
  const [markThisComment, setMarkThiscomment] = useState(null)
  const [menuAbierto, setMenuAbierto] = useState(false);
  const [menuAbiertoForComment, setMenuAbiertoForComment] = useState(null);
  const commentRefs = useRef([]);

  useEffect(() => {
    if (markThisComment !== null && commentRefs.current.length > 0) {
      const commentIndex = subComments.findIndex(comment => comment.id === markThisComment);
      if (commentIndex !== -1 && commentRefs.current[commentIndex]) {
        commentRefs.current[commentIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [markThisComment, subComments, commentRefs]);



  const openCommentLikesModal = (postId, e) => {
    e.preventDefault();
    SetIsCommentLikesModalOpen(prevState => ({
      ...prevState,
      [postId]: true
    }));
  };


  const closeCommentLikesModal = (postId, e) => {
    e.preventDefault();
    SetIsCommentLikesModalOpen(prevState => ({
      ...prevState,
      [postId]: false
    }));
  };

  const handleInputChange = () => {
    const textarea = mainComment.current;
    const scrollHeight = textarea.scrollHeight;

    // Ajusta la altura del textarea solo si es diferente de la altura actual
    if (scrollHeight !== textareaHeight) {
      const newHeight = Math.min(scrollHeight, maxTextAreaHeight);
      setTextareaHeight(newHeight);
    }
  };

  useEffect(() => {
    const textarea = mainComment.current;
    textarea.style.height = `${textareaHeight}px`;
  }, [textareaHeight]);

  useEffect(() => {

    setMarkThiscomment(markComment)

    getSubComments();


  }, []);


  const toggleMenu = (commentId) => {
    setMenuAbiertoForComment((prevCommentId) =>
      prevCommentId === commentId ? null : commentId
    );
  };


  const sendComment = () => {
    setSubComments([])
    setLoadingImg(true)

    const commentData = mainComment.current.value;
    const formattedContent = commentData.replace(/\n/g, "<br>");
    mainComment.current.value = '';

    setTextareaHeight(30)


    const token = localStorage.getItem('token');
    fetch("api/public/save-subcomment", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        post_id: postId,
        sub_comment: formattedContent,
        comment_id: commentId
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("La solicitud no fue exitosa");
        }

        getSubComments()


      })
      .catch(error => {
        console.error('Error en la petición fetch:', error);
      });
  };


  const getSubComments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch("api/public/get-subcomment", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          comment_id: commentId,
        }),
      });

      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }

      const data = await response.json();
      setSubComments(data.sub_comments);
      setLoadingImg(false)
    } catch (error) {
      console.error('Error en la petición fetch:', error);
    }
  };

  const revomeCommentLike = (id) => {


    const token = localStorage.getItem('token');
    fetch("api/public/remove-subcomment-like", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        sub_comment_id: id,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("La solicitud no fue exitosa");
        }
        setSubComments(prevComments => {
          return prevComments.map(comment => {
            if (comment.id === id) {
              return {
                ...comment,
                I_like: false,
                likeCount: comment.likeCount - 1
              };
            }
            return comment;
          });
        });

      })
      .catch(error => {
        console.error('Error en la petición fetch:', error);
      });
  }

  const saveCommentLike = (id) => {

    const token = localStorage.getItem('token');
    fetch("api/public/save-subcomment-like", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        sub_comment_id: id,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("La solicitud no fue exitosa");
        }
        setSubComments(prevComments => {
          return prevComments.map(comment => {
            if (comment.id === id) {
              return {
                ...comment,
                I_like: true,
                likeCount: comment.likeCount + 1
              }; // Cambiar el estado de 'I_like' a true
            }
            return comment;
          });
        });

      })
      .catch(error => {
        console.error('Error en la petición fetch:', error);
      });
  };

  const convertHour = (hour) => {
    const notificationTime = new Date(hour);
    const currentTime = new Date();
    const timeDifferenceInSeconds = (currentTime - notificationTime) / 1000;

    const timeTranslations = lang[langCode].time; // Acceder a las traducciones de tiempo

    if (langCode === 'es') {
      // Si el idioma es español, usa 'hace' al principio
      if (timeDifferenceInSeconds < 60) {
        return `${timeTranslations.hace} ${Math.floor(timeDifferenceInSeconds)} ${timeTranslations.seconds}`;
      } else if (timeDifferenceInSeconds < 3600) {
        const minutes = Math.floor(timeDifferenceInSeconds / 60);
        return `${timeTranslations.hace} ${minutes} ${minutes === 1 ? timeTranslations.minut : timeTranslations.minuts}`;
      } else if (timeDifferenceInSeconds < 86400) {
        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        return `${timeTranslations.hace} ${hours} ${hours === 1 ? timeTranslations.hour : timeTranslations.hours}`;
      } else {
        const days = Math.floor(timeDifferenceInSeconds / 86400);
        return `${timeTranslations.hace} ${days} ${days === 1 ? timeTranslations.day : timeTranslations.days}`;
      }
    } else {
      // Si el idioma es inglés, usa 'ago' al final
      if (timeDifferenceInSeconds < 60) {
        return `${Math.floor(timeDifferenceInSeconds)} ${timeTranslations.seconds} ${timeTranslations.hace}`;
      } else if (timeDifferenceInSeconds < 3600) {
        const minutes = Math.floor(timeDifferenceInSeconds / 60);
        return `${minutes} ${minutes === 1 ? timeTranslations.minut : timeTranslations.minuts} ${timeTranslations.hace}`;
      } else if (timeDifferenceInSeconds < 86400) {
        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        return `${hours} ${hours === 1 ? timeTranslations.hour : timeTranslations.hours} ${timeTranslations.hace}`;
      } else {
        const days = Math.floor(timeDifferenceInSeconds / 86400);
        return `${days} ${days === 1 ? timeTranslations.day : timeTranslations.days} ${timeTranslations.hace}`;
      }
    }
  }

  const divStyle = {
    width: '100%', // Ancho de tu div
    height: '100%', // Altura de tu div
    position: 'relative',
    margin: '0 auto', // Centra el div horizontalmente
  };

  const lineStyle = {
    content: '',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '50%', // Posiciona el borde en el centro horizontal del div
    borderLeft: '2px solid #ECECEC', // Ancho y color de la línea

  };

  const finishlineStyle = {
    content: '',
    position: 'absolute',
    top: 0,
    bottom: '50%',
    left: '50%', // Posiciona el borde en el centro horizontal del div
    borderLeft: '2px solid #ECECEC', // Ancho y color de la línea

  };

  const continuationStyle = {
    position: 'absolute',
    top: '50%', // Posiciona el inicio de la línea horizontal en el centro vertical del contenedor
    left: '50%', // Posiciona el inicio de la línea horizontal en el centro horizontal del contenedor
    borderTop: '2px solid #ECECEC', // Ancho y color de la línea horizontal
    width: '50%', // Ajusta la longitud de la línea horizontal

  };


  return (

    <div>
      <div className='row' style={{ paddingTop: '3px', paddingRight: '10px' }}>
        <div className='col-lg-2'>
          <div style={divStyle}>
            <div style={lineStyle}></div>
            {/* Contenido de tu componente */}
          </div>
        </div>
        <div className='col-lg-10' style={{ display: 'flex', alignItems: 'center', paddingLeft: '23px' }}>
          <div style={{ marginRight: '10px' }}>
            {userData === null ? (
              <img
                style={{ width: '25px', borderRadius: '100%' }}
                src={noPicture}
                alt=""
              />
            ) : (
              <img
                style={{ width: '25px', borderRadius: '100%' }}
                src={'https://www.skyrushcommunity.com/api/public/user_pictures/' + userData.user.picture}
                alt=""
              />
            )}
          </div>
          <div style={{ position: 'relative', width: '100%' }}>
            <textarea
              ref={mainComment}
              onInput={handleInputChange}
              placeholder={lang[langCode].posts.writeComment}
              style={{
                resize: 'none',
                padding: '5px',
                overflow: 'hidden',
                width: '100%',
                border: '1px solid #CCC',
                borderRadius: '5px',
                color: 'black',
                fontFamily: 'montserratregular',
                fontSize: '13px',
                paddingRight: '30px',
                height: `${textareaHeight}px`,
              }}
            />
            <img
              src={send}
              onClick={sendComment}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                bottom: '10px',
                right: '5px',
                width: '23px',
              }}
            />
          </div>
        </div>
      </div>
      <div className='row' style={{ paddingTop: '0px' }}>


        {subComments.map((comment, index) => (
          <div key={comment.id} ref={el => commentRefs.current[index] = el} >

            <div className='row' style={{ paddingTop: '3px', paddingRight: '5px' }}>
              <div className='col-lg-2' style={{ position: 'relative', paddingRight: '0', paddingLeft: '0' }}>
                <div style={divStyle}>
                  {index < subComments.length - 1 ? (
                    <>
                      <div style={lineStyle}></div>
                      <div style={continuationStyle}></div>
                    </>
                  ) : (
                    <>
                      <div style={finishlineStyle}></div>
                      <div style={continuationStyle}></div>
                    </>
                  )}
                </div>
              </div>
              <div className='col-lg-10'>
                <div
                  key={index}
                  style={{
                    backgroundColor: '#F3F3F3',
                    margin: '5px',
                    borderRadius: '5px',
                    padding: '5px',
                    paddingBottom: '5px',
                    position: 'relative',
                    marginTop: '5px',
                    border: markThisComment === comment.id ? '1px solid #0075F3' : 'none',
                  }}
                >

                  <div className='row' style={{ paddingTop: '3px' }}>

                    <div className='col-lg-8' style={{ display: 'flex', alignItems: 'center' }} >
                      {comment.picture === null ? (
                        <img
                          style={{ width: '25px', borderRadius: '100%' }}
                          src={noPicture}
                          alt=""
                        />
                      ) : (
                        <img
                          style={{ width: '25px', borderRadius: '100%' }}
                          src={'https://www.skyrushcommunity.com/api/public/user_pictures/' + comment.picture}
                          alt=""
                        />
                      )}

                      <p style={{ margin: '0px', marginLeft: '5px', fontSize: '12px' }}>{comment.name} {comment.last_name}</p>
                      {comment.verified === 1 && (
                        <img
                          style={{ width: '12px', marginLeft: '5px' }} // Añade margen izquierdo para separar la imagen del texto
                          src={verifiedTic}
                          alt=""
                        />
                      )}


                    </div>

                    <div className='row' style={{ paddingTop: '3px', paddingRight: '0px' }}>
                      <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center', paddingRight: '0px', paddingLeft: '25px' }}>

                        <p style={{ margin: '0px', padding: '5px', paddingLeft: '0%', marginLeft: '0px', fontSize: '13px', fontFamily: 'montserratregular', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: comment.sub_comment }}></p>
                        <p style={{ margin: '0px', fontSize: '12px', position: 'absolute', top: '5px', right: '5px', fontFamily: 'montserratRegular' }}>
                          {convertHour(comment.created_at)}
                          {comment.user_id === userData.user.id && (
                            <img
                              style={{
                                width: '20px',
                                marginLeft: '5px',
                                borderRadius: '100%',
                                marginRight: '5px',
                                cursor: 'pointer'
                              }}
                              src={menu}
                              alt=""
                              onClick={() => toggleMenu(comment.id)}
                            />
                          )}
                          {menuAbiertoForComment === comment.id && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '20px',
                                right: '10px',
                                backgroundColor: 'white',
                                border: '1px solid #E1E1E1',
                                padding: '10px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                borderRadius: '5px',
                                zIndex: 999,
                              }}
                            >
                              <SubCommentMenu commentId={comment.id} getSubComments={getSubComments} />
                            </div>
                          )}
                        </p>

                      </div>

                    </div>
                    <div className='row' style={{ paddingTop: '3px', paddingRight: '0px' }}>
                      {comment.I_like ? (
                        <div className='col-lg-1 d-flex align-items-center justify-content-start' style={{ cursor: 'pointer', }} onClick={(event) => openCommentLikesModal(comment.id, event)}>
                          <img
                            src={likeBlue}
                            style={{
                              width: '18px',
                              marginLeft: '10px'
                            }}
                          />
                          <p style={{ fontSize: '12px', color: '#0074EF', fontFamily: 'montserratbold', margin: '0px', paddingLeft: '5px' }}>{comment.likeCount}</p>
                        </div>
                      ) : (
                        <div className='col-lg-1 d-flex align-items-center justify-content-start' style={{ cursor: 'pointer', }} onClick={(event) => openCommentLikesModal(comment.id, event)}>
                          <img
                            src={like}
                            style={{
                              width: '18px',
                              marginLeft: '10px'
                            }}
                          />
                          <p style={{ fontSize: '12px', fontFamily: 'montserratregular', margin: '0px', paddingLeft: '5px' }}>{comment.likeCount}</p>
                        </div>
                      )}
                      <div className='col-lg-8' style={{ display: 'flex', alignItems: 'flex-start' }}>

                      </div>

                      {comment.I_like ? (

                        <div className='col-lg-3 d-flex align-items-left justify-content-end'
                          style={{ cursor: 'pointer' }}
                          onClick={() => revomeCommentLike(comment.id)}>
                          <img
                            src={dislike}
                            style={{
                              width: '19px',
                              height: '19px',
                              marginRight: '5px',
                            }}
                          />
                          <p style={{ fontSize: '12px', fontFamily: 'montserratregular', margin: '0px' }}>{lang[langCode].posts.dislike}</p>
                        </div>

                      ) : (
                        <div className='col-lg-3 d-flex align-items-left justify-content-end'
                          style={{ cursor: 'pointer' }}
                          onClick={() => saveCommentLike(comment.id)}>
                          <img
                            src={like}
                            style={{
                              width: '19px',
                              height: '19px',
                              marginRight: '5px',
                            }}
                          />
                          <p style={{ fontSize: '12px', fontFamily: 'montserratregular', margin: '0px' }}>{lang[langCode].posts.iLike}</p>
                        </div>
                      )}
                      <SubCommentLikes
                        isOpen={isCommentLikesModalOpen[comment.id]}
                        onClose={(event) => closeCommentLikesModal(comment.id, event)}
                        commentData={comment.id}
                      />
                    </div>
                    <div className='row' style={{ paddingTop: '3px', paddingRight: '0px' }}>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {
        loadingImg && (

          <div className='row' style={{ paddingTop: '10px' }}>
            <div className='col-lg-12'>
              <img
                src={loader}
                style={{
                  paddingTop: '100px',
                  width: '50px',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </div>
          </div>
        )
      }
    </div >
  );
};

export default SubComment;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import loader from '../../resources/img/loader.svg';


Modal.setAppElement('#root');

const SingInModal = ({ isOpen, onClose, onSignInModal }) => {

  const [customStyles, setCustomStyles] = useState({
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: 999,
    },
    content: {
      width: '95%',
      height: 'auto',  // Establecer la altura a 'auto'
      margin: 'auto',
      left: '0',
      right: '0',
      top: '10%',  // Ajustar según sea necesario
      bottom: 'auto', 
      overflow: 'auto',
      padding: '5px',  // O ajustar según sea necesario
      border: 'none',  // O ajustar según sea necesario
      borderRadius: '8px',  // O ajustar según sea necesario
      position: 'fixed',
    },
  });

  const langCode = useLangContext();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [userName, setUserName] = useState('');
  const [userNameError, setUserNameError] = useState('');

  const [surname, setSurname] = useState('');
  const [surnameError, setSurnameError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [showInputOne, setShowInputOne] = useState(true);
  const [showInputTwo, setShowInputTwo] = useState(true);
  const [showInputThree, setShowInputThree] = useState(true);
  const [showInputFour, setShowInputFour] = useState(true);
  const [showConditions, setShowConditions] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showHr, setShowHr] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState(lang[langCode].registerModalContent.registerTitle);
  const [dynamicBody, setDynamicBody] = useState(lang[langCode].registerModalContent.registerSubtitle);
  const csrfToken = useCsrfToken();

  useEffect(() => {
    setDynamicTitle(lang[langCode].registerModalContent.registerTitle);
    setDynamicBody(lang[langCode].registerModalContent.registerSubtitle);
  }, [langCode]);

  const viewConditions = () => {


  }

  const registerUser = () => {

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    var isEmailOk = false;
    const passwordPattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    var isPassOk = false;
    const emptyPattern = /^\s*$/;
    var isNameOk = false;
    var isSurnameOk = false;


    if (!emailPattern.test(email)) {
      setEmailError(lang[langCode].recoveryPassModal.invalidEmail);

    }
    else {
      setEmailError('');
      isEmailOk = true;
    }

    if (!passwordPattern.test(password)) {

      setPasswordError(lang[langCode].newPasswordModal.passwordRequirements);

    }
    else {
      setPasswordError('');
      isPassOk = true;

    }
    if (!emptyPattern.test(userName)) {
      setUserNameError('');
      isNameOk = true;
    }
    else {
      setUserNameError(lang[langCode].setButtons.nameValidation);
    }

    if (!emptyPattern.test(surname)) {
      setSurnameError('');
      isSurnameOk = true;
    }
    else {
      setSurnameError(lang[langCode].setButtons.surnameValidation);
    }


    if (isNameOk == true && isSurnameOk == true && isPassOk == true && isEmailOk == true) {
      fetch("https://www.skyrushcommunity.com/api/public/register", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
        },
        body: JSON.stringify({
          name: userName,
          last_name: surname,
          password: password,
          email: email,
          language: langCode
        })
      })
        .then(response => {

          if (!response.ok) {
            return response.json().then(errorData => {
              if (errorData.errors.email == "The email has already been taken.") {

                setShowInputOne(false)
                setShowInputTwo(false)
                setShowInputThree(false)
                setShowInputFour(false)
                setShowConditions(false)
                setShowButton(false)
                setShowHr(false)
                setShowCloseButton(true)
                setDynamicTitle(lang[langCode].registerConfirmationModal.registerEmailExistsTitle)
                setDynamicBody(lang[langCode].registerConfirmationModal.registerEmailExistsMessage)
               


              }
            });
          } else {

            setShowInputOne(false)
            setShowInputTwo(false)
            setShowInputThree(false)
            setShowInputFour(false)
            setShowConditions(false)
            setShowButton(false)
            setShowHr(false)
            setShowCloseButton(true)
            setDynamicTitle(lang[langCode].registerConfirmationModal.registerSuccessTitle)
            setDynamicBody(lang[langCode].registerConfirmationModal.registerSuccessMessage)
          

          }

        })
        .then(data => {


        })
        .catch(error => {
          alert("Error inesperado");

        });


    }







  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Sing Up"
      style={customStyles}
    >
      <div className='row'>
        <div className='col-lg-1'>
          <a href='#' onClick={onClose}>
            <img
              src={close}
              style={{
                width: '10px',
                marginBottom: '10px'
              }}
            />
          </a>
        </div>
        <div className='col-lg-10'>

        </div>
      </div>
      <div className='container' style={{ padding: '5%', paddingTop: '0px' }}>
        <div className='row'>
          <div className='col-12' style={{ textAlign: 'left' }}>
            <h3 style={{ fontSize: '18px' }}>{dynamicTitle}</h3>
          </div>
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'left' }}>
              <p className='grey-font-modal' style={{ margin: '0px', fontSize: '12px' }}>{dynamicBody}</p>
            </div>
          </div>
          {showLoader && (
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center', paddingTop: '50px' }}>
                <img
                  src={loader}
                  style={{
                    width: '50px',
                    marginBottom: '10px'
                  }}
                />
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              {showHr && (
                <hr ></hr>
              )}
            </div>

          </div>
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              {showInputOne && (
                <input
                  type="text"
                  placeholder={lang[langCode].registerModalContent.namePlaceholder}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  style={{
                    borderColor: userNameError ? 'red' : '',
                  }}
                />
              )}
              <span style={{ display: 'block', textAlign: 'center' }}>
                {userNameError &&
                  <p style={{
                    color: 'red',
                    margin: '0',
                    textAlign: 'center',
                    marginTop: '5px',
                    fontFamily: 'montserratregular',

                  }}>
                    {userNameError}
                  </p>}
              </span>
            </div>
          </div>
          <div className='row'  style={{ paddingTop: '8px' }}>
            <div className='col-12' style={{ textAlign: 'center' }}>
              {showInputTwo && (
                <input
                  type="text"
                  placeholder={lang[langCode].registerModalContent.surnamePlaceholder}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  style={{
                    borderColor: surnameError ? 'red' : '',
                  }}
                />
              )}
              <span style={{ display: 'block', textAlign: 'center' }}>
                {surnameError &&
                  <p style={{
                    color: 'red',
                    margin: '0',
                    textAlign: 'center',
                    marginTop: '5px',
                    fontFamily: 'montserratregular',

                  }}>
                    {surnameError}
                  </p>}
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center', paddingTop: '8px' }}>
              {showInputThree && (
                <input
                  type="text"
                  placeholder={lang[langCode].registerModalContent.registerEmailPlaceholder}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    borderColor: emailError ? 'red' : '',
                  }}
                />
              )}
              <span style={{ display: 'block', textAlign: 'center' }}>
                {emailError &&
                  <p style={{
                    color: 'red',
                    margin: '0',
                    textAlign: 'center',
                    marginTop: '5px',
                    fontFamily: 'montserratregular',

                  }}>
                    {emailError}
                  </p>}
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center', paddingTop: '8px' }}>
              {showInputFour && (
                <input
                  type="password"
                  placeholder={lang[langCode].registerModalContent.registerPasswordPlaceholder}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    borderColor: passwordError ? 'red' : '',
                  }}
                />
              )}
              <span style={{ display: 'block', textAlign: 'center' }}>
                {passwordError &&
                  <p style={{
                    color: 'red',
                    margin: '0',
                    textAlign: 'center',
                    marginTop: '5px',
                    fontFamily: 'montserratregular',

                  }}>
                    {passwordError}
                  </p>}
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'left', paddingTop: '15px' }}>
              {showConditions && (
                <p style={{ fontSize: '10px', color: '#A0A0A0', fontFamily: 'montserratregular' }}>{lang[langCode].registerModalContent.registerConditionsMessage}
                  <a href='#' style={{ fontSize: '10px', color: 'black' }} onClick={viewConditions}>{lang[langCode].registerModalContent.conditions}
                  </a>
                </p>
              )}



            </div>
          </div>
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center', paddingLeft: '30%', paddingRight: '30%', paddingTop: '10px', }}>
              {showButton && (
                <button style={{ fontFamily: 'montserratregular' }} onClick={registerUser}>{lang[langCode].registerModalContent.registerButton}</button>
              )}
              {showCloseButton && (
                <button style={{ fontFamily: 'montserratregular' }} onClick={onClose}>{lang[langCode].registerConfirmationModal.registerCloseButton}</button>
              )}
            </div>
          </div>
        </div>
      </div>

    </Modal>
  );
};

export default SingInModal;
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';


const PostMenu = ({ postId, onDeletePost, openEditPost }) => {

    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [thisPostId, setThisPostId] = useState(postId.id);
    const [reportWord, setReportWord] = useState(lang[langCode].posts.report);
    const [showEdit, setShowEdit] = useState(false);
    const [isShared, setIsShared] = useState(true);

    const handleOpenEditPost = (e) => {
        e.preventDefault();
        openEditPost(); // Abre el modal desde UniquePost
    };


    useEffect(() => {

        if (postId.posted_user.isMe === true) {
            setShowEdit(true)
        }
        if (postId.sharepost_id !== null) {

            setIsShared(false)
        }

    }, [thisPostId]);

    const report = () => {
        const token = localStorage.getItem('token');
        fetch("api/public/save-post-report", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    postId: thisPostId,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                setReportWord(lang[langCode].posts.reported)


            })
            .then(data => {
                setReportWord(lang[langCode].posts.reported)
            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
                setReportWord(lang[langCode].posts.reported)
            });

    }

    const deletePost = () => {
        const token = localStorage.getItem('token');
        fetch("api/public/delete-post", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    postId: thisPostId,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }



            })
            .then(data => {

                onDeletePost()

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
                setReportWord(lang[langCode].posts.reported)
            });

    }


    return (
        <div >
            <div className='row' style={{ paddingTop: '10px' }}  >
                <div className='col-lg-12' style={{ textAlign: 'center' }} onClick={report}>
                    <p style={{ fontFamily: 'montserratregular' }}>{reportWord}</p>
                </div>
            </div>
            {showEdit && (
                <div >
                    {isShared && (
                        <div className='row' >
                            <div className='col-lg-12' style={{ textAlign: 'center' }} onClick={handleOpenEditPost}>
                                <p style={{ fontFamily: 'montserratregular' }}>{lang[langCode].posts.edit}</p>
                            </div>
                        </div>
                    )}
                    <div className='row' >
                        <div className='col-lg-12' style={{ textAlign: 'center' }} onClick={deletePost}>
                            <p style={{ fontFamily: 'montserratregular' }}>{lang[langCode].posts.delete}</p>
                        </div>
                    </div>
                </div>
            )}

        </div>

    );
};

export default PostMenu;
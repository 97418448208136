import React, { useState, useEffect } from 'react';
import NewPost from '../Posts/NewPost';

import { useUserData } from '../../components/Contexts/UserData';
import GridPosts from '../Posts/GridPosts';
import AllPosts from '../Posts/AllPosts';

const Posts = () => {

    const { userData } = useUserData();
    const [isFollwedPost, setisFollwedPost] = useState(true);

    const seeFollwedPosts = (e) => {
        e.preventDefault();
        setisFollwedPost(true)
    }

    const seePosts = (e) => {
        e.preventDefault();
        setisFollwedPost(false)
    }



    return (

        <div style={{ paddingTop: '70px', backgroundColor: '#FAFAFA', paddingBottom: '30px' }}>
            <div className='container' >
                <div className='row'>
                    <div className='col-lg-2' style={{ textAlign: 'center' }}>
                    </div>
                    <div className='col-lg-8' style={{ textAlign: 'center', border: '1px solid #E9E9E9', borderRadius: '10px', backgroundColor: 'white' }}>
                        <NewPost userData={userData} />
                    </div>
                    <div className='col-lg-2' style={{ textAlign: 'center' }}>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '20px' }}>
                    <div className='col-lg-3' style={{ textAlign: 'center' }}>
                    </div>
                    <div className='col-lg-3' style={{ textAlign: 'center' }} onClick={seeFollwedPosts}>
                        <h3 style={{ fontSize: '13px', fontFamily: 'montserratregular', cursor: 'pointer', borderBottom: isFollwedPost ? '2px solid black' : 'none', paddingBottom: '2px' }}>Publicaciones de usuarios que sigues</h3>
                    </div>
                    <div className='col-lg-3' style={{ textAlign: 'center' }} onClick={seePosts}>
                        <h3 style={{ fontSize: '13px', fontFamily: 'montserratregular', cursor: 'pointer', borderBottom: !isFollwedPost ? '2px solid black' : 'none', paddingBottom: '2px' }}>Publicaciones destacadas</h3>
                    </div>
                    <div className='col-lg-3' style={{ textAlign: 'center' }}>
                    </div>
                </div>
                <div className='row' >
                    <div className='col-lg-2' style={{ textAlign: 'center' }}>
                    </div>
                    <div className='col-lg-8' style={{ padding: '0px' }}>

                        {isFollwedPost && (
                            <GridPosts userData={userData} />
                        )}
                        {!isFollwedPost && (
                            <AllPosts userData={userData} />
                        )}
                    </div>

                    <div className='col-lg-2' style={{ textAlign: 'center' }}>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Posts;


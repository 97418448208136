import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../../components/Contexts/LangProvider.js';
import lang from '../../../resources/js/dictionary.js';
import close from '../../../resources/icons/cross.png';
import green_light from '../../../resources/icons/green_light.png';
import no_cert from '../../../resources/img/certs/no_cert.png';
import gold_cert from '../../../resources/img/certs/gold_cert.png';
import termo_rain_cert from '../../../resources/img/certs/termo_rain_cert.png';
import termo_wind_cert from '../../../resources/img/certs/termo_wind_cert.png';
import termo_cert from '../../../resources/img/certs/termo_cert.png';
import warning_cert from '../../../resources/img/certs/warning_cert.png';
import SkyConditions from '../StationView/SkyConditions.js';
import rain from '../../../resources/weather_icons/fixed/rain.svg';
import wind from '../../../resources/weather_icons/fixed/wind.png';
import wind_dir from '../../../resources/weather_icons/fixed/wind_dir.png';
import humidity from '../../../resources/weather_icons/fixed/humidity.png';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';


Modal.setAppElement('#root');


const StationBelowModal = ({ isOpen, onClose, station }) => {
  const langCode = useLangContext();
  const navigate = useNavigate();
  const csrfToken = useCsrfToken();
  const [stationDataModal, setStationDataModal] = useState([]);
  const [stationLocation, setStationLocation] = useState([]);
  const [place, setPlace] = useState(null);
  const [ligth, setLight] = useState(null);
  const [status, setStatus] = useState(null);
  const [region, setRegion] = useState(null);
  const [certImg, setCertImg] = useState(null);
  const [certName, setCertName] = useState(null);
  const [cardinalDirection, setCardinalDirection] = useState('');


  const determineCardinalDirection = (degrees) => {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    const index = Math.round(degrees / 45) % 8;
    setCardinalDirection(directions[index]);
  };

  const getRotationAngle = () => {
    // Calculate the rotation angle based on the cardinal direction
    const directionAngles = {
      N: 180,
      NE: 225,
      E: 270,
      SE: 315,
      S: 0,
      SW: 45,
      W: 90,
      NW: 135,
    };
 

    return directionAngles[cardinalDirection] || 0;
  };


  useEffect(() => {
    determineCardinalDirection(station.current_weather_data.wind_dir)
    setStationDataModal(station)
    setStationLocation(station.location)
    if (station.quality_cert === 'no_cert') {
      setCertImg(no_cert)

      setCertName(lang[langCode].weather.noCertName)
    }
    else if (station.quality_cert === 'gold_cert') {
      setCertImg(gold_cert)

      setCertName(lang[langCode].weather.avancedCertName)
    }
    else if (station.quality_cert === 'termo_rain_cert') {
      setCertImg(termo_rain_cert)

      setCertName(lang[langCode].weather.termoRainCertName)
    }
    else if (station.quality_cert === 'termo_wind_cert') {
      setCertImg(termo_wind_cert)

      setCertName(lang[langCode].weather.termoWindCertName)
    }
    else if (station.quality_cert === 'termo_cert') {
      setCertImg(termo_cert)

      setCertName(lang[langCode].weather.termoCertName)
    }
    else if (station.quality_cert === 'warning_cert') {
      setCertImg(warning_cert)

      setCertName(lang[langCode].weather.warningCertName)
    }

  }, [station]);


  useEffect(() => {
    if (stationLocation.city !== null) {
      setPlace(stationLocation.city)
    }
    else if (stationLocation.town !== null && stationLocation.city === null) {
      setPlace(stationLocation.town)
    }
    else if (stationLocation.town === null && stationLocation.village !== null && stationLocation.city === null) {
      setPlace(stationLocation.village)
    }
    else if (stationLocation.town === null && stationLocation.village === null && stationLocation.hamlet !== null && stationLocation.city === null) {
      setPlace(stationLocation.hamlet)
    }

    if (stationLocation.region !== null) {
      setRegion(stationLocation.region)
    }
    else if (stationLocation.state !== null && stationLocation.region === null) {
      setRegion(stationLocation.state)
    }
    else if (stationLocation.state_district !== null && stationLocation.region === null && stationLocation.state === null) {
      setRegion(stationLocation.state_district)
    }
  }, [stationLocation]);


  const transformAndRound = (station) => {
    const numericData = Number(station);
    if (!isNaN(numericData)) {
      const roundedData = Math.round(numericData * 10) / 10;
      return roundedData;
    } else {
      return station;
    }
  };


  const transformAndRoundZero = (station) => {
    const numericData = Number(station);

    if (!isNaN(numericData)) {
      const roundedData = Math.round(numericData);
      return roundedData;
    } else {
      return station;
    }
  };


  const convertDate = (fechaUTC) => {


    // Obtener la zona horaria del navegador
    const navegadorZonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convertir la fecha a la zona horaria del navegador
    const fechaEnUTC = moment.utc(fechaUTC);
    const fechaConvertida = fechaEnUTC.tz(navegadorZonaHoraria);

    // Formatear la fecha
    const formattedDate = fechaConvertida.format('HH:mm:ss DD-MM-YYYY');

    return formattedDate;
  };


  const goStation = (station) =>
  {
   
    navigate('/station/' + station.name + '-' + station.id);
  }



  const [customStyles, setCustomStyles] = useState({
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: 999,
    },
    content: {
      width: '100%',
      maxWidth: '100%',
      height: '34%',
      margin: '0px',
      left: '0',
      right: '0',
      top: 'auto',
      bottom: '0%',
      overflow: 'auto',
      padding: '5px',
      border: 'none',
      borderRadius: '0px',
      position: 'fixed',
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Renovar Subscripcion"
      style={customStyles}     
    
      >

      <div style={{ backgroundColor: 'white' }}  onClick={() => goStation(station)} >
        <div className='container' style={{ padding: '3px', paddingTop: '5px' }} >
          <div className='row'>
            <div className='col-11' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
              <img
                style={{ width: '15px' }}
                src={green_light} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                alt=""
              />
              <h3 style={{ fontSize: '13px', margin: '0px', marginLeft: '5px' }}  > {station.name} - {station.location.altitude} m</h3>
            </div>
            <div className='col-1' >
              <img
                onClick={onClose}
                src={close}
                style={{
                  width: '10px',
                  marginBottom: '10px'
                }}
              />
            </div>
          </div>
          <div className='row' style={{ marginTop: '7px' }}>
            <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
              <img
                style={{ width: '20px' }}
                src={require(`../../../resources/country_flag/${station.location.country_iso.toLowerCase()}.svg`)} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                alt=""
              />
              <h3 style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '0px', paddingLeft: '5px', margin: '0px' }}>{place}, {region}, {stationLocation.country}</h3>
            </div>
          </div>
          <div className='row' style={{ paddingTop: '3px', paddingBottom: '0px' }}>
            <div className='col-12'>
              <div className='row' style={{ paddingTop: '0 px', paddingLeft: '5%' }}>
                <div className='col-2' style={{ textAlign: 'center ', padding: '0px' }}>
                  <SkyConditions data={station}   imgSize={70}/>
                  <div className='row' style={{ paddingTop: '0 px' }}>
                    <div className='col-12' style={{ textAlign: 'center ', padding: '0px' }}>
                      <h3 style={{ fontSize: '16px', margin: '0px' }}> {transformAndRound(station.current_weather_data.temp)}°</h3>
                    </div>
                  </div>
                </div><div className='col-2 d-flex flex-column align-items-center justify-content-center' style={{ textAlign: 'center' }}>
                  <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <p style={{ fontSize: '14px', margin: '0px', color: 'red' }}>{transformAndRound(station.current_weather_data.temp_max)}°</p>
                    </div>
                  </div>
                  <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <p style={{ fontSize: '14px', margin: '0px', color: '#1273EB' }}>{transformAndRound(station.current_weather_data.temp_min)}°</p>
                    </div>
                  </div>
                </div>
                <div className='col-8 d-flex align-items-center justify-content-center' style={{ textAlign: 'center', borderLeft: '1px solid #E9E9E9' }}>
                  <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-3' style={{ textAlign: 'center', padding: '0px' }}>
                      <img
                        style={{ width: '50px' }}
                        src={rain}
                        alt=""
                      />
                      <p style={{ fontSize: '11px', margin: '0px' }}>{transformAndRound(station.current_weather_data.daily_rain)} mm</p>
                    </div>
                    <div className='col-3' style={{ textAlign: 'center', padding: '0px' }}>
                      <div style={{ marginLeft: '13px' }}>
                        <img
                          style={{ width: '50px', marginTop: '7px' }}
                          src={wind}
                          alt=""
                        />
                        <p style={{ fontSize: '12px', margin: '0px' }}>{transformAndRound(station.current_weather_data.wind_speed)} km/h</p>
                      </div>
                    </div>
                    <div className='col-3' style={{ textAlign: 'center', padding: '0px' }}>
                      <div style={{ marginLeft: '13px' }}>
                        <img
                          style={{
                            width: '30px',
                            transform: `rotate(${getRotationAngle()}deg)`,
                            marginTop: '10px',
                            marginLeft: '5px'

                          }}
                          src={wind_dir}
                          alt="Wind Direction"
                        />
                        <p style={{ fontSize: '12px', marginTop: '10px', marginLeft: '5px' }}>{cardinalDirection}</p>
                      </div>
                    </div>
                    <div className='col-3' style={{ textAlign: 'center', padding: '0px' }}>
                      <div style={{ marginLeft: '13px' }}>
                        <img
                          style={{ width: '40px', marginTop: '10px' }}
                          src={humidity}
                          alt=""
                        />
                        <p style={{ fontSize: '12px', margin: '0px' }}>{transformAndRoundZero(station.current_weather_data.humidity)} %</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className='row' style={{ paddingTop: '10px' }}>
                <div className='col-8' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                  <img
                    style={{ width: '13px', marginBottom: '1px' }}
                    src={certImg} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                    alt=""
                  />
                  <h3 style={{ fontSize: '10px', fontFamily: 'montserratregular', padding: '5px', margin: '0px' }}>{certName}</h3>
                </div>
                <div className='col-4' style={{ textAlign: 'right', paddingTop: '5px' }}>
                  <p style={{ fontSize: '8px', fontFamily: 'montserratregular', margin: '0px' }}>
                    {lang[langCode].stations.lastUpdate}{' '}
                    {convertDate(station.current_weather_data.updated_at, station.location.time_zone)}
                  </p>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </Modal>
  )

}

export default StationBelowModal;

import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import StationMiniCard from '../Weather/StationView/StationMiniCard.js';

const StationList = ({userId}) => {

    const langCode = useLangContext();
    const csrfToken = useCsrfToken();

    const [list, setList] = useState(false);


    useEffect(() => {
        getStationList()
        
    }, []);

    const getStationList = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch("api/public/get-user-station-list", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    "user_id": userId,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setList(data)
            } else {
                throw new Error('La solicitud no tuvo éxito.');
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    };

    return (

        <div>
            {list && list.map((station) => (
                <div key={station.id}>
                   <StationMiniCard data={station}/>
                </div>
            ))}
        </div>
    );
};

export default StationList;
import React, { useState, useContext, useEffect, createContext } from "react";
import { useCsrfToken } from './CsrfTokenContext';

const UserDataContext = createContext();

export function UserDataProvider({ children }) {
  const csrfToken = useCsrfToken();
  const [userData, setUserData] = useState('');

  const reloadUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch("api/public/user-data", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        }
      });

      if (!response.ok) {
        throw new Error('La solicitud no tuvo éxito.');
      }
      const obtainUserData = await response.json();
      setUserData(obtainUserData);
    } catch (error) {
      console.error('Error en la solicitud:', error);
    }
  };

  const reloadUserDataAsync = async () => {
    await reloadUserData();
    return userData; // Devuelve los datos de usuario una vez que la recarga esté completa
  };

  useEffect(() => {
    reloadUserData();
  }, [csrfToken]);

  const contextValue = {
    userData,
    reloadUserDataAsync, // Nueva función que devuelve una promesa
  };

  return (
    <UserDataContext.Provider value={contextValue}>
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserData() {
  return useContext(UserDataContext);
}
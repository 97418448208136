import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import verifiedTic from '../../resources/icons/verified.png';
import noTic from '../../resources/icons/not_verified.png';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import RenewSubscription from '../Modals/RenewSubscription.js';
import GoAdvanced from '../Modals/GoAdvanced.js';
import '../../css/Personal-mobile.css';

const ProfileSkyrush = () => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [verified, setVerified] = useState('');
    const [association, setAssociation] = useState('');
    const [expirationDate, setExpirationDate] = useState('');

    const [showAdvancedProfile, setShowAdvancedProfile] = useState(false);
    const [showBasicProfile, setShowBasicProfile] = useState(false);
    const [showVerifiedProfile, setShowVerifiedProfile] = useState(false);
    const [showNoVerifiedProfile, setShowNoVerifiedProfile] = useState(false);
    const [showAuditoriesPremium, setShowAuditoriesPremium] = useState(false);
    const [showAuditoriesBasic, setShowAuditoriesBasic] = useState(false);
    const [showAssociation, setShowAssociation] = useState(false);
    const [showPremiumNoAssociation, setShowPremiumNoAssociation] = useState(false);
    const [showNoAssociation, setShowNoAssociation] = useState(false);
    const [showExpirationDate, setShowExpirationDate] = useState(false);
    const [showRenewButton, setShowRenewButton] = useState(false);
    const [showGoPremiumButton, SetshowGoPremiumButton] = useState(false);
    const [associatonPicture, setAssociatonPicture] = useState(null);

    const [isRenewSubscriptionOpen, setIsRenewSubscriptionOpen] = useState(false);
    const [isGoAdvancedModalOpen, setIsGoAdvancedModalOpen] = useState(false);
    const [modalKey, setModalKey] = useState(1);


    const closeRenewSubscriptionOpen = () => {
        setIsRenewSubscriptionOpen(false)
    }

    const openRenewSubscriptionModal = () => {

        setIsRenewSubscriptionOpen(true)
    }

    const openGoAdvancedModal = () => {

        setIsGoAdvancedModalOpen(true)
    }
    const closeGoAdvancedModalOpen = () => {
        setIsGoAdvancedModalOpen(false)
    }





    useEffect(() => {
        const getSkyrushData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch("api/public/get-skyrush-profile", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data) {
                        manageData(data);
                    } else {
                        console.error('Los datos de la respuesta están vacíos.');
                    }
                } else {
                    throw new Error('La solicitud no tuvo éxito.');
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };

        // Llama a la función getSkyrushData cuando el componente se monta
        getSkyrushData();

    }, []); // El array vacío como segundo argumento asegura que se ejecute solo una vez al montar el componente

    const convertDate = (date) => {

        var partesFecha = date.split('-'); // Dividir la cadena en partes
        var fecha = new Date(partesFecha[0], partesFecha[1] - 1, partesFecha[2]); // Restar 1 al mes ya que en JavaScript los meses van de 0 a 11

        // Obtener el día, mes y año
        var dia = fecha.getDate();
        var mes = fecha.getMonth() + 1; // Sumar 1 al mes para que sea de 1 a 12
        var anio = fecha.getFullYear();

        // Formatear la fecha como "DD/MM/AAAA"
        var fechaFormateada = (dia < 10 ? '0' : '') + dia + '/' + (mes < 10 ? '0' : '') + mes + '/' + anio;

        return fechaFormateada;


    }


    const manageData = (data) => {

        setVerified(data.user_skyrush.verified)
        setAssociation(data.user_skyrush.association)
        if (!data.user_skyrush.expiration_date) {

        }
        else {
            const expDate = convertDate(data.user_skyrush.expiration_date)
            setExpirationDate(expDate)

        }




        if (data.user_skyrush.verified === 1) {
            setShowAdvancedProfile(true)
            setShowVerifiedProfile(true)
            setShowAuditoriesPremium(true)
            setShowAssociation(true)
            setShowExpirationDate(true)
            setShowRenewButton(true)

            setShowBasicProfile(false)
            setShowNoVerifiedProfile(false)
            setShowAuditoriesBasic(false)
            setShowNoAssociation(false)
            SetshowGoPremiumButton(false)

            if (data.user_skyrush.association != null) {

                setAssociatonPicture('https://www.skyrushcommunity.com/vanilla/static/resources/img/associations/' + data.user_skyrush.association + '.png')
            }
            else {
                setShowPremiumNoAssociation(true)
                setShowAssociation(false)
            }
        }
        else {
            setShowAdvancedProfile(false)
            setShowVerifiedProfile(false)
            setShowAuditoriesPremium(false)
            setShowAssociation(false)
            setShowExpirationDate(false)
            setShowRenewButton(false)

            setShowBasicProfile(true)
            setShowNoVerifiedProfile(true)
            setShowAuditoriesBasic(true)
            setShowNoAssociation(true)
            SetshowGoPremiumButton(true)

        }



    }

    return (
        <div className='Personal-mobile'>
            <div className='container' style={{ padding: '0px' }}>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-lg-6' style={{ textAlign: 'left' }}>
                        <h2>{lang[langCode].skyrushProfileData.title}</h2>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-lg-2' style={{ textAlign: 'left' }}>

                    </div>
                    {showAdvancedProfile && (
                        <div className='col-lg-10' style={{ textAlign: 'left' }}>
                            <h3 style={{ textDecoration: 'underline' }}>{lang[langCode].skyrushProfileData.premiumProfile}</h3>
                        </div>
                    )}
                    {showBasicProfile && (
                        <div className='col-lg-10' style={{ textAlign: 'left' }}>
                            <h3 style={{ textDecoration: 'underline' }}>{lang[langCode].skyrushProfileData.basicProfile}</h3>
                        </div>
                    )}
                </div>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    </div>
                    {showVerifiedProfile && (
                        <div className='col-lg-10' style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                            <h4 style={{ margin: '0px', fontFamily: 'montserratregular' }}>{lang[langCode].skyrushProfileData.verified}</h4>
                            <img
                                style={{ width: '20px', marginLeft: '10px' }} // Añade margen izquierdo para separar la imagen del texto
                                src={verifiedTic}
                                alt=""
                            />
                        </div>
                    )}
                    {showNoVerifiedProfile && (
                        <div className='col-lg-10' style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                            <h4 style={{ fontFamily: 'montserratregular' }}>{lang[langCode].skyrushProfileData.notVerified}</h4>
                            <img
                                style={{ width: '15px', marginLeft: '5px', marginBottom: '9px' }} // Añade margen izquierdo para separar la imagen del texto
                                src={noTic}
                                alt=""
                            />
                        </div>
                    )}
                </div>
                <div className='row' >
                    <div className='col-lg-2' style={{ textAlign: 'left' }}>

                    </div>
                    {showAuditoriesPremium && (
                        <div className='col-lg-10' style={{ textAlign: 'left', paddingTop: '8px' }}>
                            <h4 style={{ fontFamily: 'montserratregular' }}>{lang[langCode].skyrushProfileData.premiumProfileStations}</h4>
                        </div>
                    )}
                    {showAuditoriesBasic && (
                        <div className='col-lg-10' style={{ textAlign: 'left' }}>
                            <h4 style={{ fontFamily: 'montserratregular' }}>{lang[langCode].skyrushProfileData.basicProfileStations}</h4>
                        </div>
                    )}
                </div>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    </div>
                    {showAssociation && (
                        <div className='col-lg-10' style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                            <h4 style={{ margin: '0px' }}>{lang[langCode].skyrushProfileData.asociationPremium}</h4>
                            <img
                                style={{ width: '70px', marginLeft: '5px', marginBottom: '4px' }} // Añade margen izquierdo para separar la imagen del texto
                                src={associatonPicture}
                                alt=""
                            />
                        </div>
                    )}
                    {showPremiumNoAssociation && (
                        <div className='col-lg-10' style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                            <h4 style={{ margin: '0px' }}>{lang[langCode].skyrushProfileData.asociationPremiumNoAssociation}</h4>
                        </div>
                    )}
                    {showNoAssociation && (
                        <div className='col-lg-10' style={{ textAlign: 'left' }}>
                            <h4>{lang[langCode].skyrushProfileData.asociationBasic}</h4>
                        </div>
                    )}
                </div>
                <div className='row' style={{ paddingTop: '15px', paddingBottom:'15px' }}>
                    <div className='col-lg-10' style={{ textAlign: 'left' }}>
                    </div>
                    {showRenewButton && (
                        <div className='col-lg-2' style={{ textAlign: 'center' }}>
                            <button style={{width:'60%'}} onClick={openRenewSubscriptionModal}>{lang[langCode].skyrushProfileData.renewButton}</button>
                        </div>

                    )}
                    <RenewSubscription
                        isOpen={isRenewSubscriptionOpen}
                        onClose={closeRenewSubscriptionOpen}
                        expirationDate={expirationDate}
                    />
                    {showGoPremiumButton && (
                        <div className='col-lg-2' style={{ textAlign: 'center', paddingTop: '5px' }}>
                            <button style={{ width: '60%' }} onClick={openGoAdvancedModal} >{lang[langCode].skyrushProfileData.goPremiumButton}</button>
                        </div>
                    )}
                    <GoAdvanced
                        isOpen={isGoAdvancedModalOpen}
                        onClose={closeGoAdvancedModalOpen}

                    />
                </div>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    </div>
                    {showExpirationDate && (
                        <div className='col-lg-10' style={{ textAlign: 'left' }}>
                            <p style={{ fontSize: '9px', fontStyle: 'italic', margin: '0px', padding: '0px' }}>{lang[langCode].skyrushProfileData.expirationDate} {expirationDate}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfileSkyrush;
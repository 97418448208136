import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import close from '../../resources/icons/cross.png';
import lang from '../../resources/js/dictionary.js';
import UniquePost from '../../components/Posts/UniquePost';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const SharePost = ({ isOpen, onClose, postData, userData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [textareaHeight, setTextareaHeight] = useState('38px');
    const [textareaValue, setTextareaValue] = useState('');
    const navigate = useNavigate();
    const customStyles = {
        content: {
            width: '950px',
            height: '520px',
            margin: 'auto',
            overflow: 'visible', // Permitir que el contenido se superponga
        },
    };

    const handleTextAreaChange = (e) => {
        setTextareaHeight(`${e.target.scrollHeight}px`);
        setTextareaValue(e.target.value.trim());
    };

    const goPost = (e) => {
        e.preventDefault();
        navigate('/post', { state: { postId: postData.id, userData: userData } });
    }


    const publish = (e) => {
        e.preventDefault(); 
        const formData = new FormData();
        const formattedContent = textareaValue.trim() !== '' ? textareaValue.replace(/\n/g, "<br>") : '#Shared';
        formData.append('content', formattedContent);
        formData.append('postId', JSON.stringify(postData.id));
        const token = localStorage.getItem('token');
        fetch("api/public/save-shared-post", {
            method: "POST",
            headers: {
            
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }

              
            })
            .then(data => {
                onClose()

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Sign Up"
            style={customStyles}
        >
            {/* Contenedor principal */}
            <div className="modal-container">
                <div className='row'>
                    <div className='col-lg-1'>
                        <a href='#' onClick={onClose}>
                            <img
                                src={close}
                                style={{
                                    width: '10px',
                                    marginBottom: '10px',
                                }}
                            />
                        </a>
                    </div>
                    <div className='col-lg-10'></div>
                </div>
                <div className='row'>
                    <div className='col-lg-12' style={{ textAlign: 'center' }}>
                        <textarea
                            style={{
                                minHeight: '38px',
                                height: textareaHeight,
                                width: '100%',
                                border: 'none',
                                outline: 'none',
                                fontFamily: 'montserratregular',
                                fontSize: '12px',
                            }}
                            placeholder={lang[langCode].posts.textAreaPlaceHolder}
                            rows={1}
                            onChange={handleTextAreaChange}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12' style={{ textAlign: 'center', cursor: 'pointer', overflowY: 'auto', maxHeight: '380px',paddingBottom:'20px' }} onClick={goPost}>
                        {/* Aquí añadí el estilo overflowY y maxHeight */}
                        <UniquePost postData={postData} userData={userData} />
                    </div>
                </div>
                {/* Botón fijo en la parte inferior */}
                <div className='row'>
                    <div className='col-lg-4' style={{ textAlign: 'center' }}>
                    </div>
                    <div className='col-lg-4' style={{ textAlign: 'center' }}>
                    <div className="fixed-button-container" style={{ margin: '10px' }}>
                            <button className="fixed-button" onClick={publish}>
                                {lang[langCode].posts.share}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SharePost;
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import StationLocation from './StationLocation.js';
import StationInfo from './StationInfo.js';
import back from '../../resources/icons/back.png';
import NewStationConfirmation from '../Modals/NewStationConfirmation.js';

const SetNewDevice = ({ goList }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [activeButton, setActiveButton] = useState(false);
    const [infoOk, setInfoOk] = useState(false);
    const [locationOk, setLocationOk] = useState(false);
    const [stationLocationData, setStationLocationData] = useState(null);
    const [basicInfoData, setBasicInfoData] = useState(null);
    const [isNewStationConfirmationModalOpen, setIsNewStationConfirmationModalOpen] = useState(false);

    const openNewStationConfirmation = () => {
        setIsNewStationConfirmationModalOpen(true);
    };

    const closeNewStationConfirmation = () => {
        setIsNewStationConfirmationModalOpen(false);
        goList()
    };



    const goBack = () => {
        goList();

    };

    const getLocation = (stationLocation) => {

        setStationLocationData(stationLocation)
    }

    const getBasicInfo = (basicInfo) => {
        setBasicInfoData(basicInfo)
    }


    useEffect(() => {

        if (infoOk === true && locationOk === true) {
            setActiveButton(true)
        }
        else {
            setActiveButton(false)
        }

    }, [infoOk, locationOk]);


    const activateButton = (origin) => {

        console.log(origin)

        if (origin === 'info') {
            setInfoOk(true)
        }
        else if (origin === 'location') {
            setLocationOk(true)
        }
    }


    const deactivateButton = (origin) => {
        console.log(origin)
        if (origin === 'info') {
            setInfoOk(false)
        }
        else if (origin === 'location') {
            setLocationOk(false)
        }

    }



    const saveStation = async () => {
        {
            const finalStationData =
            {
                basicInfo: basicInfoData,
                stationLocation: stationLocationData,
            }

            const token = localStorage.getItem('token');

            try {
                const response = await fetch("api/public/save-station", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(finalStationData),
                });

                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                const data = await response.json();
              //  goList();
                openNewStationConfirmation();
    
          


            } catch (error) {

                console.error('Error en la petición fetch:', error);
            }
        };
    }

    return (

        <div>
            <div style={{ backgroundColor: '#FAFAFA' }}>
                <div className='container' >

                    <div
                        onClick={goBack}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            zIndex: 1000,
                            backgroundColor: '#ffffff',
                            padding: '18px',
                            border: 'none',
                            cursor: 'pointer',
                            color: 'black',
                            width: '100%', // Ocupar toda la pantalla horizontalmente
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row', // Cambiado de 'column' a 'row',
                            borderBottom: '1px solid #E9E9E9'
                        }}
                    >
                        <img style={{ width: '15px', marginRight: '0px' }} src={back} alt="" />
                        <p style={{ margin: 0 }}>{lang[langCode].generic.goBack}</p>
                    </div>

                    <div className='row' style={{ paddingTop: '10px' }} >
                        <div className='col-12' style={{ padding: '0px' }} >
                            <h2 style={{ fontSize: '14px', fontFamily: 'montserratbold', padding: '5px' }}>{lang[langCode].weather.addDevice}</h2>
                        </div>
                    </div>

                    <div className='row' >
                        <div className='col-12' style={{ padding: '0px' }}>
                            <div style={{ border: '1px solid #E9E9E9', backgroundColor: 'white', borderRadius: '5px' }}>
                                <h3 style={{ fontSize: '13px', fontFamily: 'montserratregular', padding: '5px', margin: '0px' }}>{lang[langCode].weather.basicInfo}</h3>
                                <StationInfo getBasicInfo={getBasicInfo} activateButton={activateButton} deactivateButton={deactivateButton} />
                            </div>
                        </div>
                        <div className='col-12' style={{ padding: '0px', paddingTop: '20px' }}>
                            <div style={{ border: '1px solid #E9E9E9', backgroundColor: 'white', borderRadius: '5px' }}>
                                <h3 style={{ fontSize: '14px', fontFamily: 'montserratregular', padding: '5px' }}>{lang[langCode].weather.location}</h3>
                                <StationLocation getLocation={getLocation} activateButton={activateButton} deactivateButton={deactivateButton} preLocation={[]} />
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: '20px' }} >
                        <div className='col-2'>
                        </div>
                        <div className='col-8'>
                            <button
                                style={{
                                    fontFamily: 'montserratregular',
                                    fontSize: '13px',
                                    backgroundColor: activeButton ? '#0074EF' : '#E7E7E7', // Cambia el color del texto según activeButton
                                    color: activeButton ? 'white' : '#A7A7A7', // Cambia el color del texto según activeButton
                                }}
                                disabled={!activeButton} // Deshabilita el botón si activeButton es falso
                                onClick={saveStation}
                            >
                                {lang[langCode].weather.addDevice}
                            </button>
                        </div>


                        <div className='col-4'>
                        </div>
                    </div>
                </div>
            </div>
            <NewStationConfirmation
                isOpen={isNewStationConfirmationModalOpen}
                onClose={closeNewStationConfirmation}     

            />

        </div>
    );
};

export default SetNewDevice;
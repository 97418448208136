import React, { useEffect, useState } from 'react';
import { useLangContext } from '../../Contexts/LangProvider.js';
import conditions from '../../../resources/json/conditions.json';
import { DateTime, IANAZone } from "luxon";
import moment from 'moment-timezone';


const SkyConditions = ({ data,imgSize }) => {

    const langCode = useLangContext();
    const [weatherTranslation, setWeatherTranslation] = useState('Translation not found');
    const [iconCode, setIconCode] = useState(null);
    const [weatherIcon, setWeatherIcon] = useState(null);
    const [thisImgSize, setThisImgSize] = useState(imgSize);



    


    // Función para obtener la traducción de condiciones meteorológicas
    const getWeatherTranslation = (weatherCode) => {
        const condition = conditions.find((item) => item.code === parseInt(weatherCode, 10));

        if (condition) {
            const languageTranslation = condition.languages.find((lang) => lang.lang_iso === langCode);

            if (languageTranslation) {
                setWeatherTranslation(languageTranslation.day_text);
            }
        }
    };

    // Función para obtener el código del icono de condiciones meteorológicas
    const getWeatherIconCode = (weatherCode) => {
        const condition = conditions.find((item) => item.code === parseInt(weatherCode, 10));

        if (condition) {
            setIconCode(condition.icon);
        }
    };

    useEffect(() => {


        // Ejemplo: Obtener la traducción de un código de condiciones meteorológicas
        getWeatherTranslation(data.current_weather_data.sky_status);

        // Ejemplo: Obtener el código del icono de un código de condiciones meteorológicas
        getWeatherIconCode(data.current_weather_data.sky_status);



        // Cargar el icono del tiempo dinámicamente
        const loadWeatherIcon = async () => {
            if (iconCode) {
                const now = DateTime.local();
                const convertedTime = now.setZone(data.location.time_zone);
  

        
                // Obtener la hora de salida y puesta de sol sin necesidad de conversión
                const sunriseTime = DateTime.fromSeconds(parseInt(data.location.sunrise));
       
                const sunsetTime = DateTime.fromSeconds(parseInt(data.location.sunset));
    
        
                // Verificar si es de día o de noche
                const isDaytime = convertedTime > sunriseTime && convertedTime < sunsetTime;
        
                // Construir la ruta del icono según sea de día o de noche
                const iconTimeSuffix = isDaytime ? 'day' : 'night';
                const weatherIconModule = await import(`../../../resources/weather_icons/${iconTimeSuffix}/${iconCode}.svg`);
        
                setWeatherIcon(weatherIconModule.default);
            }
        };
        
        loadWeatherIcon();
    }, [data, langCode, iconCode]);





    return (
        <div>

            <div className='row' style={{ paddingTop: '0 px' }}>
                <div className='col-12' style={{ textAlign: 'center ', padding: '0px' }}>
                    {weatherIcon && (
                        <img
                            src={weatherIcon}
                            alt={`Weather Icon ${iconCode}`}
                            style={{ width: `${thisImgSize}px`, height: `${thisImgSize}px` }}
                        />
                    )}
                </div>
            </div>






        </div>


    );
};

export default SkyConditions;
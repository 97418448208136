import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import StationUnique from './StationSettingSections/StationUnique.js';
import lang from '../../resources/js/dictionary.js';
import loader from '../../resources/img/loader.svg';
import StationCard from './StationCard.js';
import back from '../../resources/icons/back.png';



const StationList = ({ activateAdd, deactivateAdd }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [list, setList] = useState([]);
    const [showList, setShowList] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [showStationUnique, setShowStationUnique] = useState(false);
    const [uniqueStationData, setUniqueStationData] = useState(null);


    useEffect(() => {

        getStationList()

    }, []);

    useEffect(() => {

        setShowList(true)
        setShowLoader(false)


    }, [list]);

    useEffect(() => {

        if (uniqueStationData) {
            setShowList(false);
            setShowLoader(false);
            setShowStationUnique(true);
            deactivateAdd()
        }

    }, [uniqueStationData]);

    const getStationList = async () => {

        const token = localStorage.getItem('token');
        try {


            const response = await fetch("api/public/get-user-stations", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();
            setList(data)
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        } finally {

        }
    };

    const goStation = (data) => {
        setUniqueStationData(data)
    }

    const goList = () => {
        setShowLoader(true)
        setUniqueStationData(null);
        setShowStationUnique(false)
        activateAdd()
        getStationList()
    }


    return (

        <div>
            <div style={{ backgroundColor: '#FAFAFA' }}>
                <div className='container' style={{ padding: '0px', paddingBottom: '30px' }}>
                    {showStationUnique && uniqueStationData && (
                        <div
                            onClick={goList}
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                zIndex: 1000,
                                backgroundColor: '#ffffff',
                                padding: '18px',
                                border: 'none',
                                cursor: 'pointer',
                                color: 'black',
                                width: '100%', // Ocupar toda la pantalla horizontalmente
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row', // Cambiado de 'column' a 'row',
                                borderBottom: '1px solid #E9E9E9'
                            }}
                        >
                            <img style={{ width: '15px', marginRight: '0px' }} src={back} alt="" />
                            <p style={{ margin: 0 }}>{lang[langCode].generic.goBack}</p>
                        </div>
                    )}
                    {showLoader && (
                        <div className='row' style={{ paddingTop: '10px' }}>
                            <div className='col-12' style={{ textAlign: 'center' }}>
                                <img
                                    src={loader}
                                    style={{
                                        paddingTop: '100px',
                                        width: '50px',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {showList && (
                        <div className='row' style={{ paddingTop: '10px' }}>

                            {list.map((station, index) => (
                                <div className='col-12' style={{ padding: '5px 0px 0px 0px' }}>
                                    <div key={index}>

                                        <StationCard data={station} goStation={goStation} />
                                    </div>
                                </div>
                            ))}

                        </div>
                    )}

                    {showStationUnique && uniqueStationData && (
                        <div >

                            <StationUnique data={uniqueStationData} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StationList;
import React, { useState, useEffect } from 'react';
import noPicture from '../../resources/img/no_foto.svg';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import photo from '../../resources/icons/photo.png';
import video from '../../resources/icons/video.png';
import text from '../../resources/icons/text.png';
import lang from '../../resources/js/dictionary.js';
import PhotoPost from '../Modals/PhotoPost';
import VideoPost from '../Modals/VideoPost';



const NewPost = ({ userData }) => {
    const [personalPicture, setPersonalPicture] = useState(noPicture);
    const [isPhotoPostOpen, setIsPhotoPostOpen] = useState(false);
    const [isVideoPostOpen, setIsVideoPostOpen] = useState(false);
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();

    const closePhotoPost = () =>
    {
        setIsPhotoPostOpen(false)
    }

    const closeVideoPost= () =>
    {
        setIsVideoPostOpen(false)
    }



    useEffect(() => {
        if (userData && userData.user) {
            if (userData.user.picture === null) {
                setPersonalPicture(noPicture);
            } else {
                setPersonalPicture('https://www.skyrushcommunity.com/api/public/user_pictures/' + userData.user.picture);
            }
        }
    }, [userData]);


    const newVideoPost = () => {
        setIsVideoPostOpen(true)
    }

    const newPhotoPost = () => {
        setIsPhotoPostOpen(true)

    }



  

    return (

        <div style={{ paddingBottom: '10px' }}>
            <div className='row' >
                <div className='col-lg-12' style={{ textAlign: 'left' }}>
                    <h3 style={{ margin: '5px', fontSize: '14px', fontFamily: 'montserratregular' }}>{lang[langCode].posts.newPost}</h3>
                </div>
            </div>
            <hr style={{ margin: '0px', marginBottom: '10px' }}></hr>
            <div className='row' >

                <div className='col-lg-4' style={{ textAlign: 'left' }}>
                    <a href="#" onClick={newVideoPost} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            style={{ width: '30px' }}
                            src={video}
                            alt=""
                        />
                        <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                            &nbsp;{lang[langCode].posts.video}
                        </p>
                    </a>
                    <VideoPost
                        key={isVideoPostOpen ? 'open' : 'closed'}
                        isOpen={isVideoPostOpen}
                        onClose={closeVideoPost}
                        userData={userData}
                    />
                </div>
                <div className='col-lg-4' style={{ textAlign: 'left' }}>
                    <a href="#" onClick={newPhotoPost} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            style={{ width: '30px' }}
                            src={photo}
                            alt=""
                        />
                        <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                            &nbsp;{lang[langCode].posts.photo}
                        </p>
                    </a>
                    <PhotoPost
                        key={isPhotoPostOpen ? 'open' : 'closed'}
                        isOpen={isPhotoPostOpen}
                        onClose={closePhotoPost}
                        userData={userData}
                    />
                </div>
                <div className='col-lg-4' style={{ textAlign: 'left' }}>
                    <a href="#" onClick={newPhotoPost} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            style={{ width: '30px' }}
                            src={text}
                            alt=""
                        />
                        <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px' }}>
                            &nbsp;{lang[langCode].posts.text}
                        </p>
                    </a>
                </div>

            </div>

        </div>
    );
};

export default NewPost;
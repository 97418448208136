import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import wsview from '../../resources/img/wsview-example.png';

Modal.setAppElement('#root');


const WsViewInfo = ({ isOpen, onClose, expirationDate }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();





    const [customStyles, setCustomStyles] = useState({
        content: {
            width: '450px',
            height: '725px',
            margin: 'auto',
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Renovar Subscripcion"
            style={customStyles}        >
            <div className='row'>
                <div className='col-lg-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </a>
                </div>
                <div className='col-lg-10'>

                </div>
            </div>
            <div style={{ backgroundColor: 'white' }}>
                <img
                    style={{ width: '100%', marginRight: '3px' }}
                    src={wsview} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                    alt=""
                />

            </div>
        </Modal>
    )

}

export default WsViewInfo;

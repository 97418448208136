import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../../css/Main.css';
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { MainBar } from '../../components/Bars/MainBar';
import { StartBarComponent } from '../Bars/StartBarComponent';
import { StartBodyComponent } from '../StartBody/StartBodyComponent';


const SwitchBar = () => {

    const [showMainBar, setShowMainBar] = useState(false);
    const [showStartBar, setShowStartBar] = useState(false);
    const [userData, setUserData] = useState(null);
    const csrfToken = useCsrfToken();


    useEffect(() => {


        const getTokenInfo = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch("api/public/validate-token", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    },
                });

                if (!response.ok) {
                    throw new Error('La solicitud no tuvo éxito.'); // Aquí lanzamos un error personalizado si la respuesta no está en el rango 200-299
                }
                const data = await response.json();
                setUserData(data);
                setShowMainBar(true)
                setShowStartBar(false)


            } catch (error) {
                console.error('Error en la solicitud:', error);
                setShowStartBar(true)
                setShowMainBar(false)

            }
        }


        getTokenInfo()
    }, []);



    return (

        <div >
            {showMainBar && (
                <MainBar userData={userData} />
            )}
            {showStartBar && (
                 <div className='App'>
                <StartBarComponent />
                <StartBodyComponent />   
                </div>
            )}
        </div>
    );
};

export default SwitchBar;
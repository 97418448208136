import React, { useState, useEffect } from 'react';
import logo from '../../resources/img/logo.png';
import homeIcon from '../../resources/icons/home.png';
import wuIcon from '../../resources/icons/weather.png';
import people from '../../resources/icons/people.png';
import news from '../../resources/icons/feed.png';
import chat from '../../resources/icons/chat.png';
import bell from '../../resources/icons/bell.png';
import noPicture from '../../resources/img/no_foto.svg';
import { useUserData } from '../../components/Contexts/UserData';
import { useNavigate } from 'react-router-dom';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import NotificationsData from '../Modals/Notifications';
import '../../css/NavBar.css'



export const MainBar = ({ userData }) => {
  const csrfToken = useCsrfToken();
  const [personalPicture, setPersonalPicture] = useState(noPicture);
  const [activeProfile, setActiveProfile] = useState(false);
  const [activeHome, setActiveHome] = useState(true);
  const [activeWu, setActiveWu] = useState(false);
  const [activePeople, setActivePeople] = useState(false);
  const [activeNews, setActiveNews] = useState(false);
  const [activeChat, setactiveChat] = useState(false);
  const [activeBell, setActiveBell] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationsDataOpen, setIsNotificationsDataOpen] = useState(false);
  const [showNotificationsData, setShowNotificationsData] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const closeNotificationsData = () => {
    setIsNotificationsDataOpen(false)
    showNotification()
   
  }

  const onClickBell = (e) => {
    e.preventDefault();
    setActiveProfile(false)
    setActiveHome(false)
    setActiveWu(false)
    setActivePeople(false)
    setActiveNews(false)
    setactiveChat(false)
    setActiveBell(true)

    setIsNotificationsDataOpen(true)
  }



  const options = {
    broadcaster: 'pusher',
    key: 'ABCDEFG',
    cluster: 'mt1',
    wsHost: 'www.skyrushcommunity.com',
    wsPort: 6001,
    wssPort: 6001,
    transports: ['websocket'],
    enabledTransports: ['ws', 'wss'],
    forceTLS: true,
    disableStats: true,
    authEndpoint: 'api/public/broadcasting/auth',
    auth: {
      headers: {
        'Authorization': 'Bearer ' + token,

        'X-CSRF-TOKEN': csrfToken,
      }
    }
  };




  useEffect(() => {
    window.Echo = new Echo(options);

    window.Echo.private('notifications.' + userData.user.id)
      .listen('NewFollowNotification', (e) => {
        showNotification()

      })
      .listenForWhisper('subscription-succeeded', (e) => {
        console.log('Respuesta del canal privado:', e);
      })
      .error((error) => {
        console.error('Error en la conexión al canal privado:', error);
      });

  }, []);

  useEffect(() => {

    showNotification()

  }, []);


  useEffect(() => {
    if (userData && userData.user) {
      if (userData.user.picture === null) {
        setPersonalPicture(noPicture);
      } else {
        setPersonalPicture('https://www.skyrushcommunity.com/api/public/user_pictures/' + userData.user.picture);
      }
    }
  }, [userData]);


  const showNotification = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch("api/public/get-notifications", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        },
      });

      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }

      const data = await response.json();

      // Manejar los datos de la respuesta aquí    
      setNotifications(data)
      const notificationsWithPending1 = data.filter(notification => notification.pending === 1);
      setNotificationCount(notificationsWithPending1.length);
      // Por ejemplo, puedes mostrar las notificaciones en tu aplicación
    } catch (error) {
      // Manejar errores de la petición aquí
      console.error('Error en la petición fetch:', error);
    }
  }

  const onClickProfile = (e) => {
    e.preventDefault();
    navigate('/profile', { state: { userData } });
    setActiveProfile(true)
    setActiveHome(false)
    setActiveWu(false)
    setActivePeople(false)
    setActiveNews(false)
    setactiveChat(false)
    setActiveBell(false)

  }
  const onClickHome = (e) => {
    e.preventDefault();

    setActiveProfile(false)
    setActiveHome(true)
    setActiveWu(false)
    setActivePeople(false)
    setActiveNews(false)
    setactiveChat(false)
    setActiveBell(false)
  }

  const onClickWu = (e) => {
    e.preventDefault();
    navigate('/weather', { state: { userData } });
    setActiveProfile(false)
    setActiveHome(false)
    setActiveWu(true)
    setActivePeople(false)
    setActiveNews(false)
    setactiveChat(false)
    setActiveBell(false)
  }

  const onClickPeople = (e) => {

    e.preventDefault();
    navigate('/people', { state: { userData } });
    setActiveProfile(false)
    setActiveHome(false)
    setActiveWu(false)
    setActivePeople(true)
    setActiveNews(false)
    setactiveChat(false)
    setActiveBell(false)
  }

  const onClickNews = (e) => {
    e.preventDefault();
    navigate('/posts', { state: { userData } });
    setActiveProfile(false)
    setActiveHome(false)
    setActiveWu(false)
    setActivePeople(false)
    setActiveNews(true)
    setactiveChat(false)
    setActiveBell(false)
  }

  const onClickChat = (e) => {
    e.preventDefault();
    setActiveProfile(false)
    setActiveHome(false)
    setActiveWu(false)
    setActivePeople(false)
    setActiveNews(false)
    setactiveChat(true)
    setActiveBell(false)
  }





  return (
    <div className='fixed-navbar' style={{ zIndex: '2', position: 'relative' }}>

      <div className='container'>
        <div className='row'>
          <div style={{ textAlign: 'left' }} className='col-lg-3'>
            <img style={{ width: '200px' }} src={logo} alt="Logo" />
          </div>
          <div className='col-lg-6'>
          </div>
          <div className="col-lg-3 d-flex align-items-center">
            <a href="#" style={{ borderBottom: activeHome ? '4px solid black' : 'none' }} onClick={onClickHome}>
              <img style={{ width: '25px', marginRight: '5px', marginLeft: '5px' }} src={homeIcon} alt="Logo" />
            </a>
            <a href="#" style={{ borderBottom: activeWu ? '4px solid black' : 'none' }} onClick={onClickWu}>
              <img style={{ width: '29px', marginLeft: '5px', marginRight: '5px' }} src={wuIcon} alt="Logo" />
            </a>
            <a href="#" style={{ borderBottom: activePeople ? '4px solid black' : 'none' }} onClick={onClickPeople}>
              <img style={{ width: '29px', marginLeft: '5px', marginRight: '5px' }} src={people} alt="Logo" />
            </a>
            <a href="#" style={{ borderBottom: activeNews ? '4px solid black' : 'none' }} onClick={onClickNews}>
              <img style={{ width: '29px', marginLeft: '5px', marginRight: '5px' }} src={news} alt="Logo" />
            </a>
            <a href="#" style={{ borderBottom: activeChat ? '4px solid black' : 'none' }} onClick={onClickChat}>
              <img style={{ width: '25px', marginLeft: '5px', marginRight: '5px' }} src={chat} alt="Logo" />
            </a>
            <a href="#" style={{ borderBottom: activeBell ? '4px solid black' : 'none' }} onClick={onClickBell}>
              <div style={{ position: 'relative' }}>
                <img style={{ width: '27px', marginLeft: '5px', marginRight: '5px' }} src={bell} alt="Logo" />
                {notificationCount > 0 && (
                  <div style={{
                    position: 'absolute',
                    top: '-6px', // Ajusta el valor según tu diseño
                    right: '-3px', // Ajusta el valor según tu diseño
                    width: '20px',
                    height: '20px',
                    background: 'red',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                  }}>
                    {notificationCount}
                  </div>
                )}
              </div>
            </a>
         

              <NotificationsData
                isOpen={isNotificationsDataOpen}
                onClose={closeNotificationsData}
                notifications={notifications}
                userData={userData}
              />
   
            <a href="#" style={{ borderBottom: activeProfile ? '4px solid black' : 'none', }} onClick={onClickProfile}>
              <img
                style={{ width: '31px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={personalPicture} alt="Logo" />
            </a>
          </div>
        </div>
      </div >
    </div >
  );
};
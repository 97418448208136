import React, { useState, useEffect, useRef } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../../components/Contexts/LangProvider.js';
import lang from '../../../resources/js/dictionary.js';


const StationBasicInfoEdit = ({ data }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const name = useRef(null);
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [nameInputValue, setNameInputValue] = useState('');
    const [activeButton, setActiveButton] = useState(false);
    const [newName, setNewName] = useState(false);


    useEffect(() => {

        name.current = data.name;
      
    }, []);

    const handleNameInputChange = (event) => {
        const value = event.target.value;


        if (value.length > 30) {
            setNameErrorMessage(lang[langCode].weather.nameExceed);
            setActiveButton(false)
        } else {
            setNameErrorMessage('');
            setActiveButton(true)
            setNewName(value)
        }
    
    };

    const saveName = async (event) => {
        {
            const value = event.target.value;
            const finalStationData =
            {
                stationId: data.id,
                newName: newName,
            }
       
            const token = localStorage.getItem('token');

            try {
                const response = await fetch("api/public/modify-station-name", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': csrfToken,
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(finalStationData),
                });

                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                const data = await response.json();
                setActiveButton(false)
                
   
            } catch (error) {
            
                console.error('Error en la petición fetch:', error);
            }
        };
    }

    return (

        <div>
            <div>
                <div className='container' style={{  backgroundColor: 'white', border: '1px solid #CCCCCC', borderRadius: '5px' }}>
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-12' style={{ textAlign: 'left' }}>
                            <h3 style={{ fontSize: '14px' }}> {lang[langCode].weather.basicInfo}</h3>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-12' style={{ paddingLeft: '15px', }} >
                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-3' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.name}</p>
                                </div>
                                <div className='col-9' style={{ paddingRight: '20px' }} >
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.namePlaceholder}
                                        defaultValue={data.name}
                                        onChange={handleNameInputChange}
                                        style={{
                                            border: `1px solid ${nameErrorMessage ? 'red' : '#CCC'}`,
                                            borderRadius: '5px',
                                            fontSize: '12px',
                                            fontFamily: 'montserratregular',
                                            width: '100%',
                                            height: '30px',  // Puedes ajustar la altura según tus necesidades
                                            outline: 'none',  // Evita el contorno al obtener el foco
                                        }}
                                    />
                                    {nameErrorMessage && <p style={{ color: 'red', margin: '0px' }}>{nameErrorMessage}</p>}
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-3' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.time}</p>
                                </div>
                                <div className='col-9' style={{ paddingRight: '20px' }} >
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.namePlaceholder}
                                        value={data.reset_time_zone}
                                        onChange={handleNameInputChange}
                                        disabled
                                        style={{
                                            border: `1px solid #CCC`,
                                            borderRadius: '5px',
                                            color: '#929292',
                                            fontSize: '12px',
                                            fontFamily: 'montserratregular',
                                            width: '100%',
                                            height: '30px',  // Puedes ajustar la altura según tus necesidades
                                            outline: 'none',  // Evita el contorno al obtener el foco
                                        }}
                                    />

                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-3' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.brand}</p>
                                </div>
                                <div className='col-9' style={{ paddingRight: '20px' }} >
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.namePlaceholder}
                                        value={data.brand}
                                        onChange={handleNameInputChange}
                                        disabled
                                        style={{
                                            border: `1px solid #CCC`,
                                            borderRadius: '5px',
                                            color: '#929292',
                                            fontSize: '12px',
                                            fontFamily: 'montserratregular',
                                            width: '100%',
                                            height: '30px',  // Puedes ajustar la altura según tus necesidades
                                            outline: 'none',  // Evita el contorno al obtener el foco
                                        }}
                                    />

                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-3' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.model}</p>
                                </div>
                                <div className='col-9' style={{ paddingRight: '20px' }} >
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.namePlaceholder}
                                        value={data.model}
                                        onChange={handleNameInputChange}
                                        disabled
                                        style={{
                                            border: `1px solid #CCC`,
                                            borderRadius: '5px',
                                            color: '#929292',
                                            fontSize: '12px',
                                            fontFamily: 'montserratregular',
                                            width: '100%',
                                            height: '30px',  // Puedes ajustar la altura según tus necesidades
                                            outline: 'none',  // Evita el contorno al obtener el foco
                                        }}
                                    />

                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-3' style={{ paddingLeft: '15px', }} >
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratbold', paddingRight: '45px', paddingTop: '5px', margin: '0px' }}>{lang[langCode].weather.connection}</p>
                                </div>
                                <div className='col-9' style={{ paddingRight: '20px' }} >
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].weather.namePlaceholder}
                                        value={data.connection}
                                        onChange={handleNameInputChange}
                                        disabled
                                        style={{
                                            border: `1px solid #CCC`,
                                            color: '#929292',
                                            borderRadius: '5px',
                                            fontSize: '12px',
                                            fontFamily: 'montserratregular',
                                            width: '100%',
                                            height: '30px',  // Puedes ajustar la altura según tus necesidades
                                            outline: 'none',  // Evita el contorno al obtener el foco
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='col-12' style={{paddingTop:'15px'}}>
                            <p style={{ fontSize: '12px', fontFamily: 'montserratregular', textAlign: 'justify' }}>{lang[langCode].weather.noModifyModel}</p>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: '0px', paddingBottom:'10px' }} >
                        <div className='col-2'>
                        </div>
                        <div className='col-8'>
                            <button
                                style={{
                                    fontFamily: 'montserratregular',
                                    fontSize: '12px',
                                    backgroundColor: activeButton ? '#0074EF' : '#E7E7E7', // Cambia el color del texto según activeButton
                                    color: activeButton ? 'white' : '#A7A7A7', // Cambia el color del texto según activeButton
                                }}
                                disabled={!activeButton} // Deshabilita el botón si activeButton es falso
                                onClick={saveName}
                            >
                                {lang[langCode].weather.modifyName}
                            </button>
                        </div>
                        <div className='col-4'>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default StationBasicInfoEdit;
import React, { useState, useEffect } from 'react';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import noPicture from '../../resources/img/no_foto.svg';
import { useNavigate } from 'react-router-dom';
import ViewPhotoDetails from '../Modals/ViewPhotoDetail.js';
import locationImg from '../../resources/icons/location.png';
import menu from '../../resources/icons/menu.png';
import { useLocation } from 'react-router-dom';
import tag from '../../resources/icons/tag.png';
import ShowLikes from './ShowLikes.js';
import verifiedTic from '../../resources/icons/verified.png';
import PostMenu from '../Posts/PostMenu.js';
import EditPhotoPost from '../Modals/EditPhotoPost.js';
import EditVideoPost from '../Modals/EditVideoPost.js';
import InsideSharedPost from '../Posts/InsideSharedPost.js';
import linkify from 'linkifyjs';
import ReactLinkify from 'react-linkify';
import { Link } from 'react-router-dom';

const UniquePost = ({ postData, userData, withLikes }) => {
  const langCode = useLangContext();
  const csrfToken = useCsrfToken();
  const navigate = useNavigate();
  const [showLocation, setShowLocation] = useState(false);
  const [personalPicture, setPersonalPicture] = useState(noPicture);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isViewPhotoDetailsModalOpen, setIsViewPhotoDetailsModalOpen] = useState(false);
  const [showTagedPeople, setShowTagedPeople] = useState(false);
  const [menuAbierto, setMenuAbierto] = useState(false);
  const [showTic, setShowTic] = useState(false);
  const [postDeleted, setPostDeleted] = useState(false);
  const [isEditPostOpen, setIsEditPostOpen] = useState(false);
  const [isEditVideoPostOpen, setIsEditVideoPostOpen] = useState(false);
  const [showShowLikes, setShowShowLikes] = useState(withLikes);
  const [localPostData, setLocalPostData] = useState(postData);
  const [isShared, setIsShared] = useState(false);



  const renderAgain = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch("api/public/get-post", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          post_id: postData.id,
        }),
      });

      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }

      const data = await response.json();
      // Actualiza el estado local con los nuevos datos
      setLocalPostData(data.post);

    } catch (error) {
      console.error('Error en la petición fetch:', error);
    }
  };

  const openEditPost = () => {
    setIsEditPostOpen(true);
    closeMenu(); // Cierra el menú al abrir el modal
  };

  const closeEditPost = () => {
    setIsEditPostOpen(false);
  };

  const openEditVideoPost = () => {

    setIsEditVideoPostOpen(true);
    closeMenu(); // Cierra el menú al abrir el modal
  };

  const closeEditVideoPost = () => {
    setIsEditVideoPostOpen(false);
  };


  const closeMenu = () => {
    setMenuAbierto(false);
  }

  const onDeletePost = () => {
    setPostDeleted(true);
  }

  const toggleMenu = () => {
    setMenuAbierto(!menuAbierto);
  };

  const openViewPhotoDetailsModal = (e) => {
    e.preventDefault();
    setIsViewPhotoDetailsModalOpen(true);
  }

  const closeViewPhotoDetailsModal = (e) => {
    e.preventDefault();
    setIsViewPhotoDetailsModalOpen(false);
  }

  useEffect(() => {
    if (localPostData.posted_user.picture === null) {
      setPersonalPicture(noPicture);
    } else {
      setPersonalPicture('https://www.skyrushcommunity.com/api/public/user_pictures/' + localPostData.posted_user.picture);
    }
    if (localPostData.geonameId !== null) {
      setShowLocation(true)
    }

    if (localPostData.tagged_users.length !== 0) {
      setShowTagedPeople(true)
    }

    if (localPostData.posted_user.verified === 1) {
      setShowTic(true)
    }

    if (localPostData.sharepost_id !== null) {

      setIsShared(true)
    }

  }, [localPostData]);

  const convertHour = (hour) => {
    const notificationTime = new Date(hour);
    const currentTime = new Date();
    const timeDifferenceInSeconds = (currentTime - notificationTime) / 1000;

    const timeTranslations = lang[langCode].time; // Acceder a las traducciones de tiempo

    if (langCode === 'es') {
      // Si el idioma es español, usa 'hace' al principio
      if (timeDifferenceInSeconds < 60) {
        return `${timeTranslations.hace} ${Math.floor(timeDifferenceInSeconds)} ${timeTranslations.seconds}`;
      } else if (timeDifferenceInSeconds < 3600) {
        const minutes = Math.floor(timeDifferenceInSeconds / 60);
        return `${timeTranslations.hace} ${minutes} ${minutes === 1 ? timeTranslations.minut : timeTranslations.minuts}`;
      } else if (timeDifferenceInSeconds < 86400) {
        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        return `${timeTranslations.hace} ${hours} ${hours === 1 ? timeTranslations.hour : timeTranslations.hours}`;
      } else {
        const days = Math.floor(timeDifferenceInSeconds / 86400);
        return `${timeTranslations.hace} ${days} ${days === 1 ? timeTranslations.day : timeTranslations.days}`;
      }
    } else {
      // Si el idioma es inglés, usa 'ago' al final
      if (timeDifferenceInSeconds < 60) {
        return `${Math.floor(timeDifferenceInSeconds)} ${timeTranslations.seconds} ${timeTranslations.hace}`;
      } else if (timeDifferenceInSeconds < 3600) {
        const minutes = Math.floor(timeDifferenceInSeconds / 60);
        return `${minutes} ${minutes === 1 ? timeTranslations.minut : timeTranslations.minuts} ${timeTranslations.hace}`;
      } else if (timeDifferenceInSeconds < 86400) {
        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        return `${hours} ${hours === 1 ? timeTranslations.hour : timeTranslations.hours} ${timeTranslations.hace}`;
      } else {
        const days = Math.floor(timeDifferenceInSeconds / 86400);
        return `${days} ${days === 1 ? timeTranslations.day : timeTranslations.days} ${timeTranslations.hace}`;
      }
    }
  }

  const profileView = () => {
    navigate('/profile-view', { state: { personId: localPostData.posted_user.id } });
  }

  const profileViewFromTag = (id) => {
    navigate('/profile-view', { state: { personId: id } });
  }

  const toggleExpansion = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  }

  const contentToDisplay = isExpanded ? localPostData.content : localPostData.content.slice(0, 1000);
  const photoPaths = [localPostData.photo_1_path, localPostData.photo_2_path, localPostData.photo_3_path, localPostData.photo_4_path].filter(photoPath => photoPath !== null);

  if (postDeleted) {
    return null;
  }



  const processContentWithLineBreaks = (content) => {
    const lines = content.split('<br>').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

    return (
      <React.Fragment>
        {lines}
      </React.Fragment>
    );
  };



  return (
    <div className='container' style={{ textAlign: 'center', border: '1px solid #E9E9E9', borderRadius: '10px', backgroundColor: 'white', paddingBottom: '10px' }}>
      <div className='row' style={{ paddingTop: '20px' }}>
        <div className='col-lg-8' style={{ textAlign: 'left', display: 'flex', alignItems: 'top' }}>
          <a href="#" onClick={profileView} style={{ display: 'contents' }}>
            <img style={{ width: '65px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={personalPicture} alt="" />
            <p style={{ fontSize: '13px', marginLeft: '5px' }}>{localPostData.posted_user.name} {localPostData.posted_user.last_name}
              {showTic && (
                <img
                  style={{ width: '15px', marginLeft: '5px' }} // Añade margen izquierdo para separar la imagen del texto
                  src={verifiedTic}
                  alt=""
                />
              )}
              <br></br>
              <span style={{ fontFamily: 'montserratregular', fontSize: '11px', color: '#3F3F3F' }}>{convertHour(localPostData.created_at)}</span>
            </p>
          </a>
        </div>
        <div className='col-lg-3' style={{ display: 'flex', alignItems: 'center' }}>
        </div>
        {showShowLikes && (
          <div className='col-lg-1'>
            <div
              style={{
                textAlign: 'right',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              <img
                style={{ width: '20px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }}
                src={menu}
                alt=""
                onClick={toggleMenu}
              />

              {menuAbierto && (
                <div
                  style={{
                    position: 'absolute',
                    top: '20px',
                    right: '10px',
                    backgroundColor: 'white',
                    border: '1px solid #E1E1E1',
                    padding: '10px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    borderRadius: '5px',
                    zIndex: 999,
                  }}
                >
                  {localPostData.media_type === 'photo' ? (
                    <PostMenu postId={localPostData} onDeletePost={onDeletePost} openEditPost={openEditPost} />
                  ) : localPostData.media_type === 'video' ? (
                    <PostMenu postId={localPostData} onDeletePost={onDeletePost} openEditPost={openEditVideoPost} />
                  ) : (
                    <PostMenu postId={localPostData} onDeletePost={onDeletePost} openEditPost={openEditPost} />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {showTagedPeople && (
        <div className='row' style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className='col-lg-12'>
            <hr style={{ margin: '10px' }}></hr>
          </div>
          <div className='col-lg-6' style={{ textAlign: 'left' }}>
            <img
              style={{ width: '20px', height: 'auto', marginLeft: '0px', marginRight: '3px', marginBottom: '3px' }}
              src={tag}
              alt=""
            />
            {localPostData.tagged_users.map((user, index) => (
              <div key={index} style={{ display: 'inline-block', marginRight: '5px', cursor: user.isMe ? 'default' : 'pointer' }} onClick={user.isMe ? null : () => profileViewFromTag(user.id)}>

                {user.picture === null ? (
                  <img
                    style={{ width: '20px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px', verticalAlign: 'middle' }}
                    src={noPicture}
                    alt=""
                  />
                ) : (
                  <img
                    style={{ width: '20px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px', verticalAlign: 'middle' }}
                    src={'https://www.skyrushcommunity.com/api/public/user_pictures/' + user.picture}
                    alt=""
                  />
                )}
                <p style={{ margin: '0px', display: 'inline-block', verticalAlign: 'middle' }}>
                  {user.name} {user.last_name}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      <hr style={{ margin: '10px' }}></hr>
      <div className='row' style={{ paddingTop: '0px' }}>
        <div className='col-lg-12' style={{ textAlign: 'left', display: 'flex', alignItems: 'top', paddingLeft: '%', paddingRight: '3%' }}>
          <p style={{ fontSize: '13px', marginLeft: '5px', fontFamily: 'montserratregular' }}>
            {/* Utiliza ReactLinkify para renderizar enlaces y processContentWithLineBreaks para los saltos de línea */}
            <ReactLinkify
          options={{
            format: (value, type) => {
              if (type === 'url') {
                return (
                  <a
                    href={value}
                    target="_blank"  // Abre el enlace en una nueva pestaña
                    rel="noopener noreferrer"
                  >
                    {value}
                  </a>
                );
              }
              return value;
            },
          }}
        >
          {processContentWithLineBreaks(contentToDisplay)}
        </ReactLinkify>
            {localPostData.content.length > 1000 && (
              localPostData.content.length > 1000 && contentToDisplay.length < localPostData.content.length && (
                <span>...</span>
              )
            )}
            {localPostData.content.length > 1000 && (
              <div style={{ paddingTop: '5px' }}>
                <a href="#" onClick={toggleExpansion} style={{ marginLeft: '0px', padding: '0px', fontFamily: 'montserratregular', fontSize: '12px', color: 'black', textDecoration: 'underline' }}>
                  {isExpanded ? lang[langCode].generic.showLess : lang[langCode].generic.showMore}
                </a>
              </div>
            )}
          </p>
        </div>
      </div>
      {showLocation && (
        <div className='row'>
          <div className='col-lg-12' style={{ paddingTop: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  style={{ width: '17px', marginRight: '5px' }}
                  src={locationImg}
                  alt=""
                />
                <p style={{ margin: '0', flexGrow: 1, fontSize: '13px' }}>{localPostData.name}, {localPostData.adminName1}, {localPostData.countryName}</p>
              </div>
            </div>
          </div>
        </div>
      )}


      {localPostData.media_type === 'photo' ? (
        <a href='#' onClick={openViewPhotoDetailsModal} style={{ display: 'inline-block', textDecoration: 'none' }}>
          <div className='row' style={{ paddingTop: '10px', paddingLeft: '5px', paddingRight: '5px' }}>
            {photoPaths.map((photoPath, index) => (
              <div key={index} className={`col-lg-${photoPaths.length === 1 ? '12' : '6'}`} style={{ padding: '1px', maxHeight: photoPaths.length === 1 ? 'auto' : '300px' }}>
                <img
                  style={{
                    width: photoPaths.length === 1 ? '100%' : '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  src={`https://www.skyrushcommunity.com/api/public/storage/posts/${photoPath}`}
                  alt={`Photo ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </a>
      ) : localPostData.media_type === 'video' ? (
        // Código para mostrar videos
        <div className='row' style={{ paddingTop: '10px', paddingLeft: '5px', paddingRight: '5px' }}>
          <video key={localPostData.video_path} controls autoPlay loop style={{ width: '100%', maxHeight: '600px' }}>
            <source src={`https://www.skyrushcommunity.com/api/public/storage/posts/${localPostData.video_path}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        // Código para otros tipos de medios o manejar el caso por defecto
        <div></div>
      )}





      {isShared && (
        <InsideSharedPost postDataId={localPostData.sharepost_id} userDataParam={userData} />
      )}
      {showShowLikes && (
        <ShowLikes postData={localPostData} userData={userData} />
      )}
      <ViewPhotoDetails
        isOpen={isViewPhotoDetailsModalOpen}
        onClose={closeViewPhotoDetailsModal}
        photoPaths={photoPaths}
      />
      <EditPhotoPost postData={localPostData}
        onDeletePost={onDeletePost}
        userData={userData}
        isOpen={isEditPostOpen}
        renderAgain={renderAgain}
        onClose={closeEditPost} />
      <EditVideoPost postData={localPostData}
        onDeletePost={onDeletePost}
        userData={userData}
        isOpen={isEditVideoPostOpen}
        renderAgain={renderAgain}
        onClose={closeEditVideoPost} />
    </div>

  );
};

export default UniquePost;
import React, { useState, useEffect, useRef } from 'react';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import loader from '../../resources/img/loader.svg';
import erase from '../../resources/icons/delete.png';
import save from '../../resources/icons/save.svg';
import edit from '../../resources/icons/edit.png';
import plus from '../../resources/icons/plus.png';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useUserData } from '../../components/Contexts/UserData.js';
import '../../css/Personal.css';


const ProfileStudiesEdit = ({ userData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const descriptionRef = useRef(null);
    const [descError, setDescError] = useState('');
    const [showDescSaveButton, setShowDescSaveButton] = useState(false);
    const { reloadUserDataAsync } = useUserData();
    const [descSaveSrc, setDescSaveSrc] = useState(save);
    const [descDeleteSrc, setDescDeleteSrc] = useState(erase);
    const [enabledStudyInputs, setEnabledStudyInputs] = useState({});
    const [studyErrors, setStudyErrors] = useState({});
    const [studyLocationErrors, setStudyLocationErrors] = useState({});
    const [enabledStudyLocationsInputs, setEnabledStudyLocationsInputs] = useState({});
    const [studyImageSrcs, setStudyImageSrcs] = useState({});
    const [studyDeleteImageSrcs, setStudyDeleteImageSrcs] = useState({});
    const [showCross, setShowCross] = useState(true);
    const [showNewInput, setShowNewInput] = useState(false);
    const [newInputError, setNewInputError] = useState('');
    const [newInputLocationError, setNewInputLocationError] = useState('');
    const [studyCount, setStudyCount] = useState(userData.user.studies ? userData.user.studies.length : 0);
    const newInputvalue = useRef(null);
    const newInputLocationValue = useRef(null);
    const [newInputSaveSrc, setNewInputSaveSrc] = useState(save);
    const [lastNumber, setLastNumber] = useState(null);






    useEffect(() => {
        descriptionRef.current.value = userData.user.descripcion

    }, []);


    useEffect(() => {


        if (userData.user.studies == null) {
            setStudyCount(0);
            setLastNumber(0);
            setShowNewInput(true)
        }
        else {
            setStudyCount(userData.user.studies.length);
            setLastNumber(getLastNumber());

        }


    }, [userData.user.studies]);

    useEffect(() => {

        if (studyCount < 5) {
            setShowCross(true)
        }

    }, [studyCount, lastNumber]);


    const getLastNumber = () => {
        const studies = userData.user.studies;

        if (studies.length > 0) {
            let maxStudyNumber = studies[0].study_number;

            for (let i = 1; i < studies.length; i++) {
                if (studies[i].study_number > maxStudyNumber) {
                    maxStudyNumber = studies[i].study_number;
                }
            }

            // Sumar 1 al máximo encontrado
            return maxStudyNumber + 1;
        } else {
            // Si no hay elementos, el número es 1
            return 1;
        }
    };


    const saveDesc = (e) => {
        e.preventDefault();
        var isDescOk = false;
        const lengthPattern = /^.{1,120}$/;
        if (lengthPattern.test(descriptionRef.current.value)) {
            setDescError('');
            isDescOk = true;
        }
        else {
            setDescError(lang[langCode].personalProfesionalData.limit);
        }

        if (isDescOk == true) {

            setDescSaveSrc(loader)
            const token = localStorage.getItem('token');
            fetch("api/public/save-description", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    desc: descriptionRef.current.value,
                })

            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("La solicitud no fue exitosa");
                    }
                    reloadData("saveDesc");
                })
                .then(data => {

                })
                .catch(error => {
                    // Manejar errores de la petición aquí
                    console.error('Error en la petición fetch:', error);
                });
        }
    }

    const reloadData = async (param) => {

        await reloadUserDataAsync();
        if (param == 'saveDesc') {
            setShowDescSaveButton(false)
            setDescSaveSrc(save)
        }
        else if (param == 'delDesc') {
            setDescDeleteSrc(erase)
            descriptionRef.current.value = null;
        }
        else if (param == 'saveNewInput') {
            setNewInputSaveSrc(save)
            newInputvalue.current.value = null;
            newInputLocationValue.current.value = null;
            setShowNewInput(false)
        }

    }



    const deleteDesc = (e) => {
        e.preventDefault();
        if (descriptionRef.current.value != '') {
            setDescDeleteSrc(loader)
            const token = localStorage.getItem('token');
            fetch("api/public/delete-description", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },

            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("La solicitud no fue exitosa");
                    }

                    reloadData("delDesc");
                })
                .then(data => {

                })
                .catch(error => {
                    // Manejar errores de la petición aquí
                    console.error('Error en la petición fetch:', error);
                });
        }
    }


    const editStudy = (e, inputId) => {
        e.preventDefault();
        setEnabledStudyInputs((prevEnabledInputs) => ({
            ...prevEnabledInputs,
            [inputId]: true,
        }));
    }


    const saveStudy = (e, inputId) => {
        e.preventDefault();
        const name = document.querySelector(`input[name=input_${inputId}`).value;
        const location = document.querySelector(`input[name=location_input_${inputId}`).value;

        var nameOK = false;
        var locationOK = false;
        const emptyPattern = /^\s*$/;
        const lengthPattern = /^.{1,70}$/;
        const lengthLocationPattern = /^.{0,30}$/;
        if (!emptyPattern.test(name) && lengthPattern.test(name)) {
            // Name is valid, clear the error
            setStudyErrors((prevErrors) => ({
                ...prevErrors,
                [`name_${inputId}`]: null,
            }));
            nameOK = true;
        } else {
            // Name is invalid, set the error message
            setStudyErrors((prevErrors) => ({
                ...prevErrors,
                [`name_${inputId}`]: lang[langCode].personalProfesionalData.limitStudy,
            }));
        }
        if (lengthLocationPattern.test(location)) {
            // Name is valid, clear the error
            setStudyLocationErrors((prevErrors) => ({
                ...prevErrors,
                [`location_${inputId}`]: null,
            }));
            locationOK = true;
        } else {
            // Name is invalid, set the error message
            setStudyLocationErrors((prevErrors) => ({
                ...prevErrors,
                [`location_${inputId}`]: lang[langCode].personalProfesionalData.limitStudyLocation,
            }));
        }

        if (nameOK == true && locationOK == true) {
            setStudyImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [inputId]: loader
            }));
            lauchSaveRequest(name, location, inputId)
        }
    }

    const lauchSaveRequest = (name, location, inputId) => {
        const token = localStorage.getItem('token');
        fetch("api/public/save-study", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    name: name,
                    number: inputId,
                    location: location,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                reloadStudyData(inputId, "save");
            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });


    }


    const reloadStudyData = async (id, param) => {

        await reloadUserDataAsync();
        if (param == "save") {
            setEnabledStudyInputs((prevEnabledInputs) => ({
                ...prevEnabledInputs,
                [id]: false,
            }));

            setStudyImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [id]: save
            }));
        }
        else if (param == "delete") {

            setStudyDeleteImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [id]: erase
            }));

        }


    }



    const deleteStudy = (e, inputId) => {
        e.preventDefault();
        setStudyDeleteImageSrcs(prevSrcs => ({
            ...prevSrcs,
            [inputId]: loader
        }));
        const token = localStorage.getItem('token');
        fetch("api/public/delete-study", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    number: inputId,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                reloadStudyData(inputId, "delete");
                setShowCross(true)
            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }


    const addInput = (e) => {
        e.preventDefault();
        setShowCross(false)
        setShowNewInput(true)
    }


    const delNewInput = (e) => {
        e.preventDefault();
        setShowNewInput(false)
    }
    const saveNewInput = (e) => {
        e.preventDefault();

        const name = newInputvalue.current.value
        const location = newInputLocationValue.current.value

        var nameOK = false;
        var locationOK = false;
        const emptyPattern = /^\s*$/;
        const lengthPattern = /^.{1,70}$/;
        const lengthLocationPattern = /^.{0,30}$/;
        if (!emptyPattern.test(name) && lengthPattern.test(name)) {
            setNewInputError('')
            nameOK = true;
        } else {

            setNewInputError(lang[langCode].personalProfesionalData.limitStudy)
        }
        if (lengthLocationPattern.test(location)) {
            setNewInputLocationError('')
            locationOK = true;
        } else {

            setNewInputLocationError(lang[langCode].personalProfesionalData.limitStudyLocation)
        }


        if (nameOK == true && locationOK == true) {
            setNewInputSaveSrc(loader)
            const token = localStorage.getItem('token');
            fetch("api/public/save-study", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body:
                    JSON.stringify({
                        name: name,
                        number: lastNumber,
                        location: location,
                    }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("La solicitud no fue exitosa");
                    }
                    reloadData("saveNewInput");
                })
                .then(data => {

                })
                .catch(error => {
                    // Manejar errores de la petición aquí
                    console.error('Error en la petición fetch:', error);
                });

        }


    }


    return (
        <div className='Personal'>
            <div className='container'>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-12' style={{ textAlign: 'left' }}>
                        <h2>{lang[langCode].personalProfesionalData.personalProfesionalDataTitle}</h2>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '2px' }}>

                    <div className='col-12' style={{ textAlign: 'left' }}>
                        <h3>{lang[langCode].personalProfesionalData.generalDescription}</h3>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '5px' }}>

                    <div className='col-10' style={{ textAlign: 'left' }}>
                        <input
                            type="text"
                            placeholder={lang[langCode].personalProfesionalData.generalDescription}
                            ref={descriptionRef}
                            onKeyUp={(e) => {
                                setShowDescSaveButton(true)
                            }}
                            style={{
                                borderColor: descError ? 'red' : '',
                            }}
                        />
                        <span style={{ display: 'block', textAlign: 'center' }}>
                            {descError &&
                                <p style={{
                                    color: 'red',
                                    margin: '0',
                                    textAlign: 'center',
                                    marginTop: '5px',
                                    fontFamily: 'montserratregular',

                                }}>
                                    {descError}
                                </p>}
                        </span>
                    </div>

                    <div className='col-2' style={{ textAlign: 'center', padding:'0px' }}>
                        {showDescSaveButton && (
                            <a href='#' onClick={saveDesc} style={{}}>
                                <img
                                    src={descSaveSrc}
                                    style={{
                                        width: '20px',
                                        marginLeft: '0px',
                                        marginTop: '0px'
                                    }}
                                />
                            </a>
                        )}
                        <a href='#' onClick={deleteDesc} style={{}}>
                            <img
                                src={descDeleteSrc}
                                style={{
                                    width: '20px',

                                    marginTop: '0px'
                                }}
                            />
                        </a>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '20px' }}>

                    <div className='col-12' style={{ textAlign: 'left' }}>
                        <h3>{lang[langCode].personalProfesionalData.schooling}</h3>

                    </div>
                </div>
                <div className='row' style={{ paddingTop: '3px' }}>

                    <div className='col-12' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalProfesionalData.formation}</h6>
                    </div>

                </div>
                {userData.user.studies ? (
                    userData.user.studies.map((study, index) => (
                        <div key={study.study_number}>

                            <div className='row' style={{ paddingTop: '3px' }}>
                                <div className='col-10' style={{ textAlign: 'left' }}>
                                    <div className='row' style={{ paddingTop: '10px' }}>

                                        <div className='col-12' style={{ textAlign: 'left' }}>
                                            {/* Renderiza los detalles de cada estudio aquí */}
                                            <input
                                                type="text"
                                                defaultValue={study.name}
                                                name={`input_${study.study_number}`}
                                                disabled={!enabledStudyInputs[study.study_number]}
                                                onKeyUp={(e) => {

                                                }}
                                                style={{
                                                    borderColor: studyErrors[`name_${study.study_number}`] ? 'red' : '',
                                                }}
                                            />
                                            {studyErrors[`name_${study.study_number}`] && (
                                                <p style={{
                                                    color: 'red',
                                                    margin: '0',
                                                    textAlign: 'center',
                                                    marginTop: '5px',
                                                    fontFamily: 'montserratregular',
                                                }}>
                                                    {studyErrors[`name_${study.study_number}`]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row' style={{ paddingTop: '5px' }}>
                                        <div className='col-12' style={{ textAlign: 'left' }}>
                                            {/* Renderiza los detalles de cada estudio aquí */}
                                            <input
                                                type="text"
                                                defaultValue={study.location}
                                                name={`location_input_${study.study_number}`}
                                                disabled={!enabledStudyInputs[study.study_number]}
                                                onKeyUp={(e) => {

                                                }}
                                                style={{
                                                    borderColor: studyLocationErrors[`location_${study.study_number}`] ? 'red' : '',
                                                }}
                                            />
                                            {studyLocationErrors[`location_${study.study_number}`] && (
                                                <p style={{
                                                    color: 'red',
                                                    margin: '0',
                                                    textAlign: 'center',
                                                    marginTop: '5px',
                                                    fontFamily: 'montserratregular',
                                                }}>
                                                    {studyLocationErrors[`location_${study.study_number}`]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2' style={{ textAlign: 'left', display: 'flex', alignItems: 'center', padding: '0px' }}>
                                    {enabledStudyInputs[study.study_number] ? (
                                        <a href='#' onClick={(e) => saveStudy(e, study.study_number)} style={{}}>
                                            <img
                                                src={studyImageSrcs[study.study_number] || save}
                                                style={{
                                                    width: '20px',

                                                    marginTop: '0px'
                                                }}
                                            />
                                        </a>
                                    ) : (
                                        <a href='#' onClick={(e) => editStudy(e, study.study_number)} style={{}}>
                                            <img
                                                src={edit}
                                                style={{
                                                    width: '20px',

                                                    marginTop: '0px'
                                                }}
                                            />
                                        </a>
                                    )}
                                    <a href='#' onClick={(e) => deleteStudy(e, study.study_number)} style={{}}>
                                        <img
                                            src={studyDeleteImageSrcs[study.study_number] || erase}
                                            style={{
                                                width: '20px',

                                                marginTop: '0px'
                                            }}
                                        />
                                    </a>
                                </div>
                                {/* Agrega más detalles según la estructura de tu objeto 'study' */}

                            </div>
                        </div>
                    ))
                ) : (
                    <div></div>
                )}
                {showNewInput &&
                    <div className='row' style={{ paddingTop: '7px' }}>
                        <div className='col-10' style={{ textAlign: 'left' }}>
                            <div className='row' style={{ paddingTop: '7px' }}>
                                <div className='col-12' style={{ textAlign: 'left' }}>
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].personalProfesionalData.formation}
                                        ref={newInputvalue}
                                        onKeyUp={(e) => {

                                        }}
                                        style={{
                                            borderColor: newInputError ? 'red' : '',
                                        }}
                                    />
                                    <span style={{ display: 'block', textAlign: 'center' }}>
                                        {newInputError &&
                                            <p style={{
                                                color: 'red',
                                                margin: '0',
                                                textAlign: 'center',
                                                marginTop: '5px',
                                                fontFamily: 'montserratregular',

                                            }}>
                                                {newInputError}
                                            </p>}
                                    </span>
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '7px' }}>
                                <div className='col-12' style={{ textAlign: 'left' }}>
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].personalProfesionalData.location}
                                        ref={newInputLocationValue}
                                        onKeyUp={(e) => {

                                        }}
                                        style={{
                                            borderColor: newInputLocationError ? 'red' : '',
                                        }}
                                    />
                                    <span style={{ display: 'block', textAlign: 'center' }}>
                                        {newInputLocationError &&
                                            <p style={{
                                                color: 'red',
                                                margin: '0',
                                                textAlign: 'center',
                                                marginTop: '5px',
                                                fontFamily: 'montserratregular',

                                            }}>
                                                {newInputLocationError}
                                            </p>}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-2' style={{ textAlign: 'left', display: 'flex', alignItems: 'center', padding: '0px' }}>
                            <a href='#' onClick={saveNewInput} style={{}}>
                                <img
                                    src={newInputSaveSrc}
                                    style={{
                                        width: '20px',

                                        marginTop: '0px'
                                    }}
                                />
                            </a>
                            <a href='#' onClick={delNewInput} style={{}}>
                                <img
                                    src={erase}
                                    style={{
                                        width: '20px',

                                        marginTop: '0px'
                                    }}
                                />
                            </a>
                        </div>
                    </div>

                }
                {showCross &&
                    <div className='row' style={{ paddingTop: '7px' }}>
                    
                        <div className='col-5' style={{ textAlign: 'left' }}>
                            <a href='#' onClick={addInput} style={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
                                <img
                                    src={plus}
                                    style={{
                                        width: '20px',
                                
                                        marginTop: '0px',
                                    }}
                                />
                                <p style={{ margin: '0 0 0 0px', fontSize: '13px', fontFamily: 'montserratregular' }}>
                                    {lang[langCode].personalProfesionalData.add}
                                </p>
                            </a>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default ProfileStudiesEdit;
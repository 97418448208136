import React, { useState, useEffect, useRef } from 'react';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import loader from '../../resources/img/loader.svg';
import erase from '../../resources/icons/delete.png';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useUserData } from '../../components/Contexts/UserData';
import Select from 'react-select'; // Importa Select de react-select
import '../../css/Personal.css';


const ProfilePersonalEdit = ({ userData }) => {

    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [userError, setUserError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [geonamesData, setGeonamesData] = useState([]);
    const [userName, setUserName] = useState(userData.user.name);
    const [inputValue, setInputValue] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [location, setLocation] = useState('');
    const [region, setRegion] = useState('');
    const [country, setCountry] = useState('');
    const { reloadUserDataAsync } = useUserData();
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [years, setYears] = useState([]);
    const surnameRef = useRef(null);
    const nameRef = useRef(null);
    const apiKey = 'wequestz';


    useEffect(() => {
        // Configuramos el valor predeterminado del input una vez que la referencia esté disponible.
        if (surnameRef.current) {
            surnameRef.current.value = userData.user.last_name;
        }
        if (nameRef.current) {
            nameRef.current.value = userData.user.name;
        }
    }, []);


    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 1923; // Cambiar aquí el año de inicio deseado

        const allYears = [];
        for (let year = currentYear; year >= startYear; year--) {
            allYears.push(year);
        }

        setYears(allYears);
    }, []);

    const days = [];
    for (let day = 1; day <= 31; day++) {
        days.push({ value: day.toString(), label: day.toString() });
    }


    useEffect(() => {
        if (userData.user.birth_date === null) {

        }
        else {
            const birthDate = new Date(userData.user.birth_date);
            setSelectedDay(birthDate.getDate())
            setSelectedMonth(birthDate.getMonth() + 1)
            setSelectedYear(birthDate.getFullYear())
        }



    }, []);



    const loadOptions = (inputValue, callback) => {
        if (inputValue) {
            fetch(`https://secure.geonames.org/searchJSON?q=${inputValue}&maxRows=10&username=${apiKey}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('La solicitud no tuvo éxito.');
                    }
                    return response.json();
                })
                .then((data) => {
                    // Procesa los datos de Geonames y establece las opciones en el estado.
                    const options = data.geonames.map((item) => ({
                        value: item,
                        label: `${item.name}, ${item.adminName1}, ${item.countryName} `


                    }));
                    setGeonamesData(options);
                    callback(options);
                })
                .catch((error) => {
                    console.error('Error al obtener datos de Geonames:', error);
                });
        }
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        saveLocation(selectedOption.value)
        setSelectedOption(null)
        // Aquí puedes realizar cualquier acción adicional con el valor seleccionado si es necesario.
    };


    const saveLocation = (selectedLocation) => {


        const token = localStorage.getItem('token');
        fetch("api/public/save-location", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                geonameId: selectedLocation.geonameId,
                name: selectedLocation.name,
                region: selectedLocation.adminName1,
                countryCode: selectedLocation.countryCode,
                countryName: selectedLocation.countryName,
                lat: selectedLocation.lat,
                lng: selectedLocation.lng
            })

        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                reloadData();
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });


    }

    const reloadData = async () => {

        await reloadUserDataAsync();
    }


    const deleteLocation = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        fetch("api/public/delete-location", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                reloadData();
            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });


    }


    const deleteBirth = (e) => {
        e.preventDefault();
        setSelectedDay("")
        setSelectedMonth("")
        setSelectedYear("")
    }


    const saveAllData = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        var isNameOk = false;
        var isSurnameOk = false;
        const emptyPattern = /^\s*$/;
        const lengthPattern = /^.{1,15}$/;
        if (!emptyPattern.test(surnameRef.current.value) && lengthPattern.test(surnameRef.current.value)) {
            setSurnameError('');
            isSurnameOk = true;
        }
        else {
            setSurnameError(lang[langCode].setButtons.surnameValidation);
        }
        if (!emptyPattern.test(nameRef.current.value) && lengthPattern.test(nameRef.current.value)) {
            setUserError('');
            isNameOk = true;
        }
        else {
            setUserError(lang[langCode].setButtons.surnameValidation);
        }

        const date = createMySQLDate()
        console.log(date)

        if (isNameOk == true && isSurnameOk == true) {
            fetch("api/public/update-user", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body:
                    JSON.stringify({
                        "name": nameRef.current.value,
                        "lastName": surnameRef.current.value,
                        "dateofbird": date,
                    }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("La solicitud no fue exitosa");
                    }
                    reloadData();
                })
                .then(data => {

                })
                .catch(error => {
                    // Manejar errores de la petición aquí
                    console.error('Error en la petición fetch:', error);
                });
        }


    }

    const createMySQLDate = (e) => {


        var date = new Date(selectedYear, selectedMonth - 1, selectedDay);
        console.log(date.getYear())
        if (date.getYear() != -1) {
            // La fecha es válida

            var mysqlDate = selectedYear + '-' + selectedMonth + '-' + selectedDay;

            return mysqlDate;
        } else {

            console.error('Fecha inválida');
            return null;
        }

    }



    const customStyles = {
        control: (provided) => ({
            ...provided,

            fontSize: '13px',
            color: 'black',
            width: '100%',
            borderRadius: '5px',
            border: '1px solid #CCC',
            '&:focus-within': {
                borderColor: 'black', // Color de borde al enfocar
            },
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: '13px', // Estilo del texto en el menú
            color: 'black', // Color del texto en el menú
            backgroundColor: 'white', // Fondo del menú
            border: '1px solid #CCC', // Borde del menú
        }),
    };

    return (

        <div className='Personal'>

            <div className='container' style={{padding:'0px'}}>

                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-12' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalDataProfile.name}</h6>
                        <input
                            type="text"
                            placeholder={lang[langCode].registerModalContent.namePlaceholder}
                            ref={nameRef}
                            onChange={(e) => { }}
                            style={{
                                borderColor: userError ? 'red' : '',
                            }}
                        />
                        <span style={{ display: 'block', textAlign: 'center' }}>
                            {userError &&
                                <p style={{
                                    color: 'red',
                                    margin: '0',
                                    textAlign: 'center',
                                    marginTop: '5px',
                                    fontFamily: 'montserratregular',

                                }}>
                                    {userError}
                                </p>}
                        </span>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-12' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalDataProfile.lastName}</h6>
                        <input
                            type="text"
                            placeholder={lang[langCode].registerModalContent.surnamePlaceholder}
                            // defaultValue={userData.user.last_name}
                            ref={surnameRef}
                            onChange={(e) => { }}
                            style={{
                                borderColor: surnameError ? 'red' : '',
                            }}
                        />
                        <span style={{ display: 'block', textAlign: 'center' }}>
                            {surnameError &&
                                <p style={{
                                    color: 'red',
                                    margin: '0',
                                    textAlign: 'center',
                                    marginTop: '5px',
                                    fontFamily: 'montserratregular',

                                }}>
                                    {surnameError}
                                </p>}
                        </span>
                    </div>
                </div>

                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-12' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalDataProfile.locationTitle}</h6>
                        <Select
                            styles={customStyles}
                            options={geonamesData}
                            value={selectedOption}
                            onInputChange={(inputValue) => loadOptions(inputValue, (options) => { })}
                            onChange={handleSelectChange}
                            placeholder={lang[langCode].personalDataProfile.locationPlaceHolder}
                            isClearable
                            isSearchable
                            cacheOptions
                        />

                    </div>
                </div>
                <div className='row' style={{ paddingTop: '20px' }}>
                    <div className='col-6' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalDataProfile.city}</h6>
                        <input
                            type="text"
                            placeholder={lang[langCode].personalDataProfile.city}
                            value={userData.user.location.name}
                            onChange={(e) => {
                            }}
                            style={{ backgroundColor: '#F4F4F4' }}
                        />
                    </div>
                    <div className='col-6' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalDataProfile.region}</h6>
                        <input
                            type="text"
                            placeholder={lang[langCode].personalDataProfile.region}
                            value={userData.user.location.region}
                            onChange={(e) => {
                            }}
                            style={{ backgroundColor: '#F4F4F4' }}

                        />
                    </div>
                    <div className='col-11' style={{ textAlign: 'left', paddingTop: '10px' }}>
                        <h6>{lang[langCode].personalDataProfile.country}</h6>
                        <input
                            type="text"
                            placeholder={lang[langCode].personalDataProfile.country}
                            value={userData.user.location.countryName}
                            onChange={(e) => {
                            }}
                            style={{ backgroundColor: '#F4F4F4' }}

                        />
                    </div>
                    <div className='col-1' style={{ textAlign: 'left', paddingTop: '10px' }}>
                        <a href='#' onClick={deleteLocation} style={{
                            display: 'flex',
                            alignItems: 'flex-end',  // Alineación vertical al fondo
                            justifyContent: 'center', // Alineación horizontal al centro
                            flexDirection: 'column', // Cambia la dirección del eje principal a vertical
                            height: '75px',         // Ajusta la altura para centrar verticalmente
                        }}>
                            <img
                                src={erase}

                                style={{
                                    width: '20px',

                                }}
                            />
                        </a>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-3' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalDataProfile.day}</h6>
                        <select
                            value={selectedDay}
                            onChange={(e) => setSelectedDay(e.target.value)}
                        >
                            <option value="" disabled>{lang[langCode].personalDataProfile.day}</option>
                            {days.map((day) => (
                                <option key={day.value} value={day.value}>
                                    {day.label}
                                </option>
                            ))}
                        </select>

                    </div>

                    <div className='col-4' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalDataProfile.month}</h6>
                        <select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}>

                            <option value="" disabled>{lang[langCode].personalDataProfile.month}</option>
                            {lang[langCode].personalDataProfile.months.map((month, index) => (
                                <option key={index} value={index + 1}>
                                    {month}
                                </option>
                            ))}
                        </select>

                    </div>
                    <div className='col-4' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalDataProfile.year}</h6>

                        <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                            <option value="" disabled>{lang[langCode].personalDataProfile.year}</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-1' style={{ textAlign: 'left',paddingRight:'7px' }}>
                        <a href='#' onClick={deleteBirth} style={{
                            display: 'flex',
                            alignItems: 'flex-end',  // Alineación vertical al fondo
                            justifyContent: 'center', // Alineación horizontal al centro
                            flexDirection: 'column', // Cambia la dirección del eje principal a vertical
                            height: '75px',         // Ajusta la altura para centrar verticalmente
                        }}>
                            <img
                                src={erase}

                                style={{
                                    width: '21px',

                                }}
                            />
                        </a>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-12' style={{ textAlign: 'center'}}>
                        <button style={{width:'60%'}} onClick={saveAllData}>{lang[langCode].generic.save}</button>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default ProfilePersonalEdit;
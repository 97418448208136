import React, { useState, useEffect, useRef } from 'react';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import loader from '../../resources/img/loader.svg';
import PeopleCards from './PeopleCard.js';

const PeopleFollowing = ({ myUserData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [people, setPeople] = useState([]);
    const search = useRef(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [searchParam, setSearchParam] = useState("");
    const page = useRef(1); // Página actual
    let typingTimer;
    const isFetchingData = useRef(false); // Nueva variable de referencia

    useEffect(() => {
        loadMorePeople(); // Cargar los primeros 30 elementos
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        // Este efecto se ejecutará cada vez que newInputError cambie.
    }, [people]);

    useEffect(() => {

            // Si searchParam ha cambiado y no está vacío, ejecuta loadMorePeople
            page.current = 1;
            setPeople([]);
            loadMorePeople();
            window.addEventListener("scroll", handleScroll);
 
    }, [searchParam]);

    const loadMorePeople = async () => {

        if (  !hasMore || isFetchingData.current) {
            return;
        }
        setLoading(true);
        isFetchingData.current = true; // Evitar solicitudes duplicadas
        const token = localStorage.getItem('token');
        try {
            const response = await fetch("api/public/get-people-following?page=" + page.current, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    search: searchParam,
                }),
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();
            const usuarios = data.usuarios;

            if (usuarios.length === 0) {
                setHasMore(false); // No hay más elementos por cargar
                window.removeEventListener("scroll", handleScroll); // Deja de escuchar el evento de desplazamiento
            } else {
                setPeople((prevPeople) => [...prevPeople, ...usuarios]);
                page.current += 1; // Incrementar la página actual
                
            }
        } catch (error) {
            // Manejar errores de la petición aquí
            console.error('Error en la petición fetch:', error);
        } finally {
            isFetchingData.current = false; // Restablecer la bandera de solicitud
            setLoading(false);
        }
    }

    const launchSearch = (event) => {
        clearTimeout(typingTimer);
        const { value } = event.target;
        typingTimer = setTimeout(() => {
            setHasMore(true)
            setSearchParam(search.current.value);
          
        }, 1000); // 1000 milisegundos (1 segundo)
    }

    const handleScroll = () => {
        if (!loading && hasMore && window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
            // Llegamos al final del scroll, carga más personas automáticamente
            loadMorePeople();
        }
    };

    return (
        <div>
            <div className='row' style={{ paddingTop: '20px' }}>
                <div className='col-2'>
                </div>
                <div className='col-8'>
                    <input
                        type="text"
                        placeholder={lang[langCode].mainPeople.lookForPeople}
                        ref={search}
                        onChange={launchSearch}
                    />
                </div>
            </div>
            <div className='row' style={{ paddingTop: '20px' }}>
                {people.map((person, index) => (
                    <div className='col-12' key={person.id}
                        style={{
                            padding: '3px',
                        }}>
                        {index % 4 === 0 && index !== 0 ? (
                            <div>
                                <PeopleCards userData={person} myUserData={myUserData} />
                            </div>
                        ) : (
                            <PeopleCards userData={person} myUserData={myUserData} />
                        )}
                    </div>
                ))}
            </div>
            <div className='Personal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && (
                    <img
                        src={loader}
                        style={{
                            width: '50px',
                            marginTop: '25%',
                            marginBottom: '25%'
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default PeopleFollowing;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import '../../css/Personal.css';
import noPicture from '../../resources/img/no_foto.svg'
import { useNavigate } from 'react-router-dom';


Modal.setAppElement('#root');


const Notifications = ({ isOpen, onClose, notifications, userData }) => {

  const langCode = useLangContext();
  const csrfToken = useCsrfToken();
  const navigate = useNavigate();


  const showPost = (type, elementId, subElementId, postId, e) => {


    console.log(type)
    if (type === "NewLike" || type === "NewComment" || type === "NewTag" || type === "NewSharedPost") {
      navigate('/post', { state: { postId: elementId, userData: userData } });
    }
    else if (type === "NewSubComment" || type === "NewSubCommentLike") {
      navigate('/comment', { state: { commentId: elementId, userData: userData, open: true, subElementId: subElementId, postId: postId } });
    }
    else if (type === "NewCommentLike") {
      navigate('/comment', { state: { commentId: elementId, userData: userData, open: false, postId: postId } });
    }

    onClose()

  }

  const goProfile = (id) =>
  {
    navigate('/profile-view', { state: { personId: id } });
    onClose()
  }


  useEffect(() => {
    if (isOpen) {
      setAsReaded();
    }
  }, [isOpen]);


  const [customStyles, setCustomStyles] = useState({
    content: {
      width: '500px',
      height: '465px',
      margin: 'auto',
    },
  });

  const setAsReaded = (e) => {
    const token = localStorage.getItem('token');
    fetch("api/public/set-notifications-viewed", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Authorization': 'Bearer ' + token
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("La solicitud no fue exitosa");
        }

      })
      .then(data => {

      })
      .catch(error => {
        // Manejar errores de la petición aquí
        console.error('Error en la petición fetch:', error);
      });
  }

  const convertHour = (hour) => {
    const notificationTime = new Date(hour);
    const currentTime = new Date();
    const timeDifferenceInSeconds = (currentTime - notificationTime) / 1000;

    const timeTranslations = lang[langCode].time; // Acceder a las traducciones de tiempo

    if (langCode === 'es') {
      // Si el idioma es español, usa 'hace' al principio
      if (timeDifferenceInSeconds < 60) {
        return `${timeTranslations.hace} ${Math.floor(timeDifferenceInSeconds)} ${timeTranslations.seconds}`;
      } else if (timeDifferenceInSeconds < 3600) {
        const minutes = Math.floor(timeDifferenceInSeconds / 60);
        return `${timeTranslations.hace} ${minutes} ${minutes === 1 ? timeTranslations.minut : timeTranslations.minuts}`;
      } else if (timeDifferenceInSeconds < 86400) {
        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        return `${timeTranslations.hace} ${hours} ${hours === 1 ? timeTranslations.hour : timeTranslations.hours}`;
      } else {
        const days = Math.floor(timeDifferenceInSeconds / 86400);
        return `${timeTranslations.hace} ${days} ${days === 1 ? timeTranslations.day : timeTranslations.days}`;
      }
    } else {
      // Si el idioma es inglés, usa 'ago' al final
      if (timeDifferenceInSeconds < 60) {
        return `${Math.floor(timeDifferenceInSeconds)} ${timeTranslations.seconds} ${timeTranslations.hace}`;
      } else if (timeDifferenceInSeconds < 3600) {
        const minutes = Math.floor(timeDifferenceInSeconds / 60);
        return `${minutes} ${minutes === 1 ? timeTranslations.minut : timeTranslations.minuts} ${timeTranslations.hace}`;
      } else if (timeDifferenceInSeconds < 86400) {
        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        return `${hours} ${hours === 1 ? timeTranslations.hour : timeTranslations.hours} ${timeTranslations.hace}`;
      } else {
        const days = Math.floor(timeDifferenceInSeconds / 86400);
        return `${days} ${days === 1 ? timeTranslations.day : timeTranslations.days} ${timeTranslations.hace}`;
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Renovar Subscripcion"
      style={customStyles}        >
      <div className='row'>
        <div className='col-lg-1' onClick={onClose} style={{ cursor: 'pointer' }}>

          <img
            src={close}
            style={{
              width: '10px',
              marginBottom: '10px'
            }}
          />

        </div>
        <div className='col-lg-10'>

        </div>
      </div>
      <div className='Personal' style={{ backgroundColor: 'white' }}>
        {notifications.map((notification, index) => (
          <div key={index}>


            <div className='row' style={{ paddingTop: '10px', cursor: 'pointer' }} >

              <div className='col-lg-3' style={{ textAlign: 'center' }} onClick={(e) => goProfile(notification.sender.id, e)}>

                {notification.sender.picture === null ? (
                  <img style={{ width: '90px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={noPicture} alt="" />

                ) :

                  <img
                    style={{ width: '90px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }}
                    src={"https://www.skyrushcommunity.com/api/public/user_pictures/" + notification.sender.picture}
                    alt="" />}
              </div>
              <div className='col-lg-7' style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }} onClick={(e) => showPost(notification.type, notification.element_id, notification.sub_element_id, notification.post_id, e)}>
                {notification.pending === 1 ? (

                  <p style={{ fontSize: '13px', margin: '0px' }}>
                    <span>{notification.sender.name} {notification.sender.last_name}</span>
                    {notification.type === "NewFollow" ? (

                      <span style={{ fontFamily: 'montserratregular' }}> {lang[langCode].notifications.startFollow}</span>
                    ) : notification.type === "NewLike" ? (
                      <span style={{ fontFamily: 'montserratregular' }}> {lang[langCode].notifications.newLike}</span>
                    ) : notification.type === "NewComment" ? (
                      <span style={{ fontFamily: 'montserratregular' }}> {lang[langCode].notifications.newComment}</span>
                    ) : notification.type === "NewCommentLike" ? (
                      <span style={{ fontFamily: 'montserratregular' }}> {lang[langCode].notifications.newCommentLike}</span>
                    ) : notification.type === "NewSubComment" ? (
                      <span style={{ fontFamily: 'montserratregular' }}> {lang[langCode].notifications.newSubCommentAnswer}</span>
                    ) : notification.type === "NewSubCommentLike" ? (
                      <span style={{ fontFamily: 'montserratregular' }}> {lang[langCode].notifications.newCommentLike}</span>
                    ) : notification.type === "NewSharedPost" ? (
                      <span style={{ fontFamily: 'montserratregular' }}> {lang[langCode].notifications.sharePost}</span>
                    ) : (
                      <span style={{ fontFamily: 'montserratregular' }}> {lang[langCode].notifications.postTaged}</span>
                    )}
                    <span style={{ fontFamily: 'montserratregular', fontSize: '11px' }}><br></br>{convertHour(notification.created_at)}</span>
                  </p>
                ) :
                  <p style={{ fontSize: '13px', margin: '0px' }}>
                    <span style={{ color: '#959595' }}>{notification.sender.name} {notification.sender.last_name}</span>
                    {notification.type === "NewFollow" ? (

                      <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7' }}> {lang[langCode].notifications.startFollow}</span>
                    ) : notification.type === "NewLike" ? (
                      <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7' }}> {lang[langCode].notifications.newLike}</span>
                    ) : notification.type === "NewComment" ? (
                      <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7' }}> {lang[langCode].notifications.newComment}</span>
                    ) : notification.type === "NewCommentLike" ? (
                      <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7' }}> {lang[langCode].notifications.newCommentLike}</span>
                    ) : notification.type === "NewSubComment" ? (
                      <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7' }}> {lang[langCode].notifications.newSubCommentAnswer}</span>
                    ) : notification.type === "NewSubCommentLike" ? (
                      <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7' }}> {lang[langCode].notifications.newCommentLike}</span>
                    ) : notification.type === "NewSharedPost" ? (
                      <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7' }}> {lang[langCode].notifications.sharePost}</span>
                    ) : (
                      <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7' }}> {lang[langCode].notifications.postTaged}</span>
                    )}
                    <span style={{ fontFamily: 'montserratregular', color: '#A7A7A7', fontSize: '11px' }}><br></br>{convertHour(notification.created_at)}</span>


                  </p>
                }
              </div>
              <div className='col-lg-2' style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                {notification.pending === 1 ? (
                  <div style={{
                    position: 'absolute',
                    width: '15px',
                    height: '15px',
                    background: '#0074EF',
                    borderRadius: '50%',
                  }}></div>
                ) :
                  null
                }

              </div>
            </div>

            <hr style={{ margin: '15px' }}></hr>
          </div>

        ))}

      </div>
    </Modal>
  )

}

export default Notifications;

import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import Cropper from 'react-easy-crop'
import noPicture from '../../resources/img/no_foto.svg';
import loader from '../../resources/img/loader.svg';
import '../../css/Crop.css';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';

Modal.setAppElement('#root');


const PersonalPicture = ({ isOpen, onClose, userData }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [showImageCrop, setShowImageCrop] = useState(false)
    const [showImageControls, setShowImageControls] = useState(false)
    const [showImagePreview, setshowImagePreview] = useState(true)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [personalPicture, setPersonalPicture] = useState('');
    const [croppedImage, setCroppedImage] = useState(null);
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();



    useEffect(() => {
        if (userData.user.picture === null) {

            //  setPersonalPicture(noPicture)
            setShowImageCrop(false)
            setShowImageControls(false)

        } else {
            // setPersonalPicture('https://www.skyrushcommunity.com/api/public/user_pictures/' + userData.user.picture)
        }
    }, [userData]);


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        const canvas = document.createElement('canvas');
        const image = document.createElement('img');
        image.src = personalPicture;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            croppedAreaPixels.x * scaleX,
            croppedAreaPixels.y * scaleY,
            croppedAreaPixels.width * scaleX,
            croppedAreaPixels.height * scaleY,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );

        canvas.toBlob((blob) => {
            const objectUrl = URL.createObjectURL(blob);
            setCroppedImage(objectUrl);
        }, 'image/jpeg');
    }, [personalPicture]);


    const handleImageUpload = (e) => {

        setShowImageCrop(true)
        setShowImageControls(true)
        setShowSaveButton(true)
        setshowImagePreview(false)
        const selectedFile = e.target.files[0]; // Obtiene el archivo seleccionado

        if (selectedFile) {
            const objectUrl = URL.createObjectURL(selectedFile);
            setPersonalPicture(objectUrl); // Actualiza el estado con la URL de la imagen seleccionada
        }
    };


    const handleCloseLoader = () => {

        setShowImageCrop(false)
        setShowImageControls(false)
        setShowSaveButton(false)
        setshowImagePreview(false)
        setShowLoader(true)
        onClose()
    }

    const handleSave = async () => {
        if (croppedImage) {
            try {
                const blob = await fetch(croppedImage).then((r) => r.blob());
                const file = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });

                const formData = new FormData();
                formData.append('croppedImage', file);
                console.log(formData);

                const response = await fetch('api/public/upload-picture', {
                    method: 'POST',
                    body: formData, // Cambia "data" a "body" para enviar el formulario correctamente
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'X-CSRF-TOKEN': csrfToken
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    handleCloseLoader()
                }
            } catch (error) {
                console.error('Error al realizar la petición fetch:', error);
            }
        } else {
            console.error('No se ha seleccionado una imagen recortada.');
        }
    };





    const customStyles = {
        content: {
            width: '700px',
            height: '520px',
            margin: 'auto',

        },
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Cargar Foto"
            style={customStyles}
        >
            <div className='row'>
                <div className='col-lg-1'>
                    <div className="closeBar">
                        <a href='#' onClick={onClose}>
                            <img
                                src={close}
                                style={{
                                    width: '10px',
                                    marginBottom: '10px',
                                    marginLeft: '5px'
                                }}
                            />
                        </a>
                    </div>
                </div>
                <div className='col-lg-11'>

                </div>
            </div>
            {showLoader && (
                <div className='row'>
                <div className='col-lg-12'>
                    <div className="crop-container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: '60px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={loader} alt="" />
                    </div>
                </div>
            </div>

            )}


            {showImageCrop && (
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="crop-container">
                            <Cropper
                                image={personalPicture}
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 4}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>
                    </div>
                </div>
            )}
            {showImagePreview && (
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="crop-container" style={{ border: '2px dashed #000', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <p>{lang[langCode].modifyPersonalPicture.loadPicturePreview}</p>
                        </div>
                    </div>
                </div>
            )}
            {showImageControls && (
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="controls">
                            <input
                                type="range"
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e) => {
                                    setZoom(e.target.value)
                                }}
                                className="zoom-range"
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className='row'>
                <div className='col-lg-12' >
                    <div className="imageInput">
                        <label htmlFor="fileInput" className="custom-file-input">
                            <p>{lang[langCode].modifyPersonalPicture.loadPictureLink}</p>
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload} // Agrega el controlador de eventos
                        />

                    </div>
                </div>
            </div>
            {showSaveButton && (
                <div className='row'>
                    <div className='col-lg-12' >
                        <div className="saveButton">

                            <button onClick={handleSave}>{lang[langCode].generic.save}</button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};



export default PersonalPicture;
import React, { useState, useContext } from "react";

const langContext = React.createContext();
const langToggleContext = React.createContext();

export function useLangContext() {
  return useContext(langContext);
}

export function useLangToggleContext() {
  return useContext(langToggleContext);
}

export function LangProvider({ children }) {
  const userLang = navigator.language || navigator.userLanguage || 'en';
  const initialLang = userLang.startsWith('es') ? 'es' : 'en';

  const [lang, setLang] = useState(localStorage.getItem("language") || initialLang);

  const changeLang = (newLang) => {
    setLang(newLang);
    if (newLang === "auto") {
      localStorage.removeItem("language");
      setLang(initialLang);
    } else {
      localStorage.setItem("language", newLang);
    }
  };

  return (
    <langContext.Provider value={lang}>
      <langToggleContext.Provider value={changeLang}>
        {children}
      </langToggleContext.Provider>
    </langContext.Provider>
  );
}
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import loader from '../../resources/img/loader.svg';


Modal.setAppElement('#root');

const ResetPasswordModal = ({ isOpen, onClose, onResetPassword }) => {
  const langCode = useLangContext();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showInput, setShowInput] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const [showHr, setShowHr] = useState(true);
  const [showLoader, setShowLoader] = useState(false);  
  const [dynamicTitle, setDynamicTitle] = useState(lang[langCode].recoveryPassModal.recoveryTitle);
  const [dynamicBody, setDynamicBody] = useState(lang[langCode].recoveryPassModal.recoverySubtitle);
  const csrfToken = useCsrfToken();

  useEffect(() => {
    setDynamicTitle(lang[langCode].recoveryPassModal.recoveryTitle);
    setDynamicBody(lang[langCode].recoveryPassModal.recoverySubtitle);
  }, [langCode]);

  const resetPassword = () => {

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(email)) {
      setEmailError(lang[langCode].recoveryPassModal.invalidEmail);
    } else {

      fetch("https://www.skyrushcommunity.com/api/public/recovery-mail", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
        },
        body: JSON.stringify({
          email: email,
          language: langCode
        })
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorData => {
              if (errorData.message == "Usuario no encontrado") {

                setEmailError(lang[langCode].sendMail.userNotFound);
              }
            });
          } else {

            setShowInput(false)
            setShowButton(false)
            setShowHr(false)
            setDynamicTitle(lang[langCode].emailSentConfirmModal.confirmTitle)
            setDynamicBody(lang[langCode].emailSentConfirmModal.confirmMessage)
            setShowLoader(true)
            setTimeout(() => {
              window.location.href = 'https://www.skyrushcommunity.com';
            }, 4000); // 3000 milisegundos = 3 segundos
          }

        })
        .then(data => {



        })
        .catch(error => {
          console.log(error)

          alert("Error inesperado");
        });

    }

  };

  const customStyles = {
    content: {
      width: '700px',
      height: '320px',
      margin: 'auto',

    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Restablecer Contraseña"
      style={customStyles}
    >
      <div className='row'>
        <div className='col-lg-1'>
          <a href='#' onClick={onClose}>
            <img
              src={close}
              style={{
                width: '10px',
                marginBottom: '10px'
              }}
            />
          </a>
        </div>
        <div className='col-lg-10'>

        </div>
      </div>
      <div className='container' style={{ padding: '5%', paddingTop: '0px' }}>
        <div className='row'>
          <div className='col-lg-12' style={{ textAlign: 'left' }}>
            <h3>{dynamicTitle}</h3>
          </div>
          <div className='row'>
            <div className='col-lg-12' style={{ textAlign: 'left' }}>
              <p className='grey-font-modal' style={{ margin: '0px' }}>{dynamicBody}</p>
            </div>
          </div>
          {showLoader && (
            <div className='row'>
              <div className='col-lg-12' style={{ textAlign: 'center', paddingTop: '50px' }}>
                <img
                  src={loader}
                  style={{
                    width: '50px',
                    marginBottom: '10px'
                  }}
                />
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-lg-12' style={{ textAlign: 'center' }}>
              {showHr && (
                <hr ></hr>
              )}
            </div>

          </div>
          <div className='row'>
            <div className='col-lg-12' style={{ textAlign: 'center', paddingLeft: '10%', paddingRight: '10%' }}>
              {showInput && (
                <input
                  type="email"
                  placeholder={lang[langCode].recoveryPassModal.recoveryEmailPlaceholder}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    borderColor: emailError ? 'red' : '',
                  }}
                />
              )}
              <span style={{ display: 'block', textAlign: 'center' }}>
                {emailError &&
                  <p style={{
                    color: 'red',
                    margin: '0',
                    textAlign: 'center',
                    marginTop: '5px',
                    fontFamily: 'montserratregular',

                  }}>
                    {emailError}
                  </p>}
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12' style={{ textAlign: 'center', paddingLeft: '30%', paddingRight: '30%', paddingTop: '40px', }}>
              {showButton && (
                <button style={{ fontFamily: 'montserratregular' }} onClick={resetPassword}>{lang[langCode].recoveryPassModal.recoveryButton}</button>
              )}
            </div>
          </div>
        </div>
      </div>

    </Modal>
  );
};

export default ResetPasswordModal;
import React, { useState, useEffect } from 'react';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import StationUnique from './StationSettingSections/StationUnique.js';
import lang from '../../resources/js/dictionary.js';
import loader from '../../resources/img/loader.svg';
import StationCard from './StationCard.js';
import back from '../../resources/icons/back.png';



const StationList = ({activateAdd, deactivateAdd}) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [list, setList] = useState([]);
    const [showList, setShowList] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [showStationUnique, setShowStationUnique] = useState(false);
    const [uniqueStationData, setUniqueStationData] = useState(null);


    useEffect(() => {

        getStationList()

    }, []);

    useEffect(() => {

        setShowList(true)
        setShowLoader(false)


    }, [list]);

    useEffect(() => {

        if (uniqueStationData) {
            setShowList(false);
            setShowLoader(false);
            setShowStationUnique(true);
            deactivateAdd()
        }

    }, [uniqueStationData]);

    const getStationList = async () => {

        const token = localStorage.getItem('token');
        try {


            const response = await fetch("api/public/get-user-stations", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();
            setList(data)
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        } finally {

        }
    };

    const goStation = (data) => {
        setUniqueStationData(data)
    }

    const goList = () => {
        setShowLoader(true)
        setUniqueStationData(null);      
        setShowStationUnique(false)
        activateAdd()
        getStationList()
    }


    return (

        <div>
            <div style={{ backgroundColor: '#FAFAFA' }}>
                <div className='container' style={{ paddingBottom: '30px' }}>
                    {showLoader && (
                        <div className='row' style={{ paddingTop: '40px' }}>
                            <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                <img
                                    src={loader}
                                    style={{
                                        paddingTop: '100px',
                                        width: '50px',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {showList && (
                        <div className='row' style={{ paddingTop: '20px' }}>

                            {list.map((station, index) => (
                                <div className='col-lg-4' style={{paddingTop:'20px'}}>
                                    <div key={index}>

                                        <StationCard data={station} goStation={goStation} />
                                    </div>
                                </div>
                            ))}

                        </div>
                    )}

                    {showStationUnique && uniqueStationData && (
                        <div >
                            <div className='row' style={{ paddingTop: '2px',cursor:'pointer' }}  onClick={goList}>
                                <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <img
                                        style={{ width: '15px', marginRight: '7px' }}
                                        src={back} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                        alt=""
                                    />
                                    <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px', padding: '0px' }}> {lang[langCode].weather.stationList}</p>

                                </div>
                            </div>
                            <StationUnique data={uniqueStationData} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StationList;
import { createContext, useContext, useState, useEffect } from 'react';

const CsrfTokenContext = createContext();

export function CsrfTokenProvider({ children }) {
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    // Realizar la solicitud AJAX aquí para obtener el token CSRF
    fetch("https://www.skyrushcommunity.com/api/public/get-csrf-token", {
      method: "GET",
      dataType: 'json',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("La solicitud no fue exitosa");
        }
        return response.json();
      })
      .then(data => {
        const obtainedCsrfToken = data.csrf_token;
        setCsrfToken(obtainedCsrfToken); // Establecer el valor del token CSRF obtenido
      })
      .catch(error => {
        // Manejar errores de la petición aquí
        console.error('Error en la petición fetch:', error);
      });
  }, []); // El [] indica que esto se ejecutará solo una vez al montar el componente



  return (
    <CsrfTokenContext.Provider value={csrfToken}>
      {children}
    </CsrfTokenContext.Provider>
  );
}

export function useCsrfToken() {
  return useContext(CsrfTokenContext);
}
import React, { useState, useEffect, useRef } from 'react';
import { useLangContext, useLangToggleContext } from '../../Contexts/LangProvider.js';
import { CsrfTokenProvider, useCsrfToken } from '../../Contexts/CsrfTokenContext.js';
import lang from '../../../resources/js/dictionary.js';


const StationHistoricData = ({ data }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();


    return (

        <div>
            <div>
                <div className='container' style={{ height: '220px', backgroundColor: 'white', border: '1px solid #CCCCCC', borderRadius: '5px', paddingBottom: '10px' }}>
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-lg-12' style={{ textAlign: 'left' }}>
                            <h3 style={{ fontSize: '14px' }}> {lang[langCode].weather.historicData}</h3>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default StationHistoricData;
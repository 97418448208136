import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import close from '../../resources/icons/cross.png';
import loader from '../../resources/img/loader.svg';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import noPicture from '../../resources/img/no_foto.svg';
import view from '../../resources/icons/view.png';
import { useNavigate } from 'react-router-dom';
import blueShare from '../../resources/icons/blueShare.png';


Modal.setAppElement('#root');

const PostShared = ({ isOpen, onClose, postData, userData }) => {

    const [customStyles, setCustomStyles] = useState({
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 999,
        },
        content: {
          width: '95%',
          height: 'auto',  // Establecer la altura a 'auto'
          margin: 'auto',
          left: '0',
          right: '0',
          top: '10%',  // Ajustar según sea necesario
          bottom: 'auto', 
          overflow: 'auto',
          padding: '5px',  // O ajustar según sea necesario
          border: 'none',  // O ajustar según sea necesario
          borderRadius: '8px',  // O ajustar según sea necesario
          position: 'fixed',
        },
      });
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(false);
    const hasMoreRef = useRef(true); // Usamos una referencia para seguimiento
    const page = useRef(1);
    const isFetchingData = useRef(false);
    const navigate = useNavigate();




    useEffect(() => {
        if (isOpen) {


            setPeople([]);
            hasMoreRef.current = true;
            page.current = 1;

            const loadAndAddScrollListener = () => {
                const modalContent = document.getElementById('followingModal');
                if (modalContent) {
                    modalContent.addEventListener('scroll', handleScroll);
                    loadMorePeople();
                } else {
                    setTimeout(loadAndAddScrollListener, 100);
                }
            };

            loadAndAddScrollListener();
        }

        return () => {
            const modalContent = document.getElementById('followingModal');
            if (modalContent) {
                modalContent.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isOpen]);

    const handleScroll = () => {
        const modalContent = document.getElementById('followingModal');
        if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 200) {
            loadMorePeople();
        }
    };

    const loadMorePeople = async () => {
        if (!hasMoreRef.current || isFetchingData.current) {
            return;
        }
        setLoading(true);
        isFetchingData.current = true;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`api/public/get-shared?page=${page.current}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`,
                },
                body:
                    JSON.stringify({
                        post_id: postData,
                    }),
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();

            if (data && Array.isArray(data.shares)) {
                const modalContent = document.querySelector('.Personal');
                if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 200) {

                    if (data.shares.length === 0) {
                        hasMoreRef.current = false;
                    } else {
                        setPeople((prevPeople) => [...prevPeople, ...data.shares]);
                        page.current += 1;
                    }
                }
            } else {
                hasMoreRef.current = false;
                console.error('La estructura de la respuesta del servidor es incorrecta');
            }
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        } finally {
            isFetchingData.current = false;
            setLoading(false);
        }
    };

    const viewProfile = (id) => {
        navigate('/profile-view', { state: { personId: id,goBack:true } });
        onClose()
    }

    const viewPost = (id) => {
        console.log(id)
        navigate('/post', { state: { postId: id, userData: userData,goBack:true } });
        onClose()
    }


    return (

        <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Modal Siguiendo" style={customStyles} id="followingModal">
            <div className='row'>
                <div className='col-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px',
                            }}
                        />
                    </a>
                </div>
                <div className='col-10'>
                    {/* Contenido adicional del modal */}
                </div>
            </div>
            <div className='Personal' style={{ backgroundColor: 'white' }}>
                <div className='row' style={{ paddingTop: '0px' }}>
                    <div className='col-12' style={{ paddingLeft: '30px', textAlign: 'center' }}>
                        <h3 style={{fontSize:'13px'}}>{lang[langCode].posts.sharedBy}</h3>
                    </div>
                </div>
          

                <div className='row' style={{ paddingTop: '0px' }}>
                    {people.map((person, index) => (
                        <div key={index}>
               

                            <div className='row' style={{ paddingTop: '5px', paddingBottom: '5px' }}>

                            <div className='col-2' style={{ textAlign: 'center', cursor:'pointer' }} onClick={person.isMe ? null : () => viewProfile(person.id)}>

                                    {person.picture === null ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <img style={{ width: '35px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={noPicture} alt="" />
                                            <img
                                                style={{
                                                    position: 'absolute',
                                                    top: '30px',
                                                    left: '40px',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '30px',
                                                    zIndex: 1,
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    padding:'5px'

                                                }}
                                                src={blueShare}
                                                alt=""
                                            />
                                        </div>

                                    ) :
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <img style={{ width: '35px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={"https://www.skyrushcommunity.com/api/public/user_pictures/" + person.picture} alt="" />
                                            <img
                                                style={{
                                                    position: 'absolute',
                                                    top: '30px',
                                                    left: '40px',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '30px',
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    padding:'5px',
                                                    zIndex: 1,
                                                }}
                                                src={blueShare}
                                                alt=""
                                            />

                                        </div>
                                    }


                                </div>


                                <div className='col-4' style={{ textAlign: 'left', padding: '0px', cursor:'pointer'  }} onClick={person.isMe ? null : () => viewProfile(person.id)}>
                                    <p style={{ margin: '0px', fontFamily: 'montserratbold' }}>{person.name} {person.last_name}</p>
                                </div>
                                <div className='col-1'>
                                </div>
          
                                    <div className='col-4' style={{ textAlign: 'left', padding: '0px' }}>
                                        <a href="#"  onClick={() => viewPost(person.post_id)}  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img
                                                style={{ width: '16px' }}
                                                src={view}
                                                alt=""
                                            />

                                            <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '11px', padding: '0px' }}>
                                                &nbsp;{lang[langCode].posts.viewPost}
                                            </p>
                                        </a>

                                    </div>
                          

                                {/* Agrega más campos según la estructura de tus datos */}

                            </div>

                            <hr style={{ margin: '3px' }}></hr>

                        </div>

                    ))}
                </div>
                {loading && (
                    <div className='row'>
                        <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={loader}
                                style={{
                                    width: '50px',
                                    marginTop: '25%',
                                    marginBottom: '25%',
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Modal >
    );
};

export default PostShared;



import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCsrfToken } from '../Contexts/CsrfTokenContext';
import UniquePost from './UniquePost.js';

const SinglePost = () => {
  const { state } = useLocation();
  const csrfToken = useCsrfToken();
  const [postData, setPostData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await fetch("api/public/get-post", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken,
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            post_id: state.postId,
          }),
        });

        if (!response.ok) {
          throw new Error("La solicitud no fue exitosa");
        }

        const data = await response.json();

        setPostData(data.post);
        setUserData(state.userData);
      } catch (error) {
        console.error('Error en la petición fetch:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [state.postId, csrfToken, state.userData]);

  return (
    <div style={{ paddingTop: '100px', backgroundColor: '#FAFAFA', paddingBottom: '30px' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-2' style={{ textAlign: 'center' }}></div>
          <div className='col-lg-8' style={{ padding: '0px' }}>
            {!loading && postData !== null && (
              <UniquePost postData={postData} userData={userData} withLikes={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import UniquePost from './UniquePost.js';
import { useNavigate } from 'react-router-dom';
import back from '../../resources/icons/back.png';
import lang from '../../resources/js/dictionary.js';

const SinglePost = () => {
  const { state } = useLocation();
  const langCode = useLangContext();
  const csrfToken = useCsrfToken();
  const [postData, setPostData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGoBack, setShowGoBack] = useState(false);
  const navigate = useNavigate();
  const goBackConst = state ? state.goBack : null;

  useEffect(() => {


    if (goBackConst == true) {
      setShowGoBack(true)
    }
    else {
      setShowGoBack(false)
    }
  }, []);

  
  const goBack = () => {
    // Navigating back
    navigate(-1);
  };

  useEffect(() => {
    console.log(state.postId)
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await fetch("api/public/get-post", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken,
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            post_id: state.postId,
          }),
        });

        if (!response.ok) {
          throw new Error("La solicitud no fue exitosa");
        }

        const data = await response.json();

        setPostData(data.post);
        setUserData(state.userData);
      } catch (error) {
        console.error('Error en la petición fetch:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ paddingTop: '10px', backgroundColor: '#FAFAFA', paddingBottom: '70px' }}>
      <div className='container'>
      {showGoBack && (
            <div
              onClick={goBack}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1000,
                backgroundColor: '#ffffff',
                padding: '17px',
                border: 'none',
                cursor: 'pointer',
                color: 'black',
                width: '100%', // Ocupar toda la pantalla horizontalmente
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row', // Cambiado de 'column' a 'row',
                borderBottom: '1px solid #E9E9E9'
              }}
            >
              <img style={{ width: '15px', marginRight: '0px' }} src={back} alt="" />
              <p style={{ margin: 0 }}>{lang[langCode].generic.goBack}</p>
            </div>
          )}
        <div className='row'>

          <div className='col-12' style={{ padding: '0px' }}>
            {!loading && postData !== null && (
              <UniquePost postData={postData} userData={userData} withLikes={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import close from '../../resources/icons/cross.png';
import loader from '../../resources/img/loader.svg';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import noPicture from '../../resources/img/no_foto.svg';
import PeopleButtons from '../People/PeopleButtons';
import view from '../../resources/icons/view.png';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const PeopleFollowed = ({ isOpen, onClose, userData }) => {

    const [customStyles, setCustomStyles] = useState({
        content: {
            width: '700px',
            height: '465px',
            margin: 'auto',
        },
    });
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(false);
    const hasMoreRef = useRef(true); // Usamos una referencia para seguimiento
    const [searchParam, setSearchParam] = useState("");
    const page = useRef(1);
    const isFetchingData = useRef(false);
    const navigate = useNavigate();




    useEffect(() => {
        if (isOpen) {


            setPeople([]);
            hasMoreRef.current = true;
            page.current = 1;

            const loadAndAddScrollListener = () => {
                const modalContent = document.getElementById('followingModal');
                if (modalContent) {
                    modalContent.addEventListener('scroll', handleScroll);
                    loadMorePeople();
                } else {
                    setTimeout(loadAndAddScrollListener, 100);
                }
            };

            loadAndAddScrollListener();
        }

        return () => {
            const modalContent = document.getElementById('followingModal');
            if (modalContent) {
                modalContent.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isOpen]);

    const handleScroll = () => {
        const modalContent = document.getElementById('followingModal');
        if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 200) {
            loadMorePeople();
        }
    };

    const loadMorePeople = async () => {
        if (!hasMoreRef.current || isFetchingData.current) {
            return;
        }
        setLoading(true);
        isFetchingData.current = true;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`api/public/get-followed-data-modal?page=${page.current}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userId: userData,
                }),
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();

            if (data && Array.isArray(data.followers)) {
                const modalContent = document.querySelector('.Personal');
                if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 200) {
                    
                    if (data.followers.length === 0) {
                        hasMoreRef.current = false;
                    } else {
                        setPeople((prevPeople) => [...prevPeople, ...data.followers]);
                        page.current += 1;
                    }
                }
            } else {
                hasMoreRef.current = false;
                console.error('La estructura de la respuesta del servidor es incorrecta');
            }
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        } finally {
            isFetchingData.current = false;
            setLoading(false);
        }
    };

    const viewProfile = (id) =>{
        navigate('/profile-view', { state: {personId:id } });
        onClose()
    }

   


    return (

        <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Modal Siguiendo" style={customStyles} id="followingModal">
            <div className='row'>
                <div className='col-lg-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px',
                            }}
                        />
                    </a>
                </div>
                <div className='col-lg-10'>
                    {/* Contenido adicional del modal */}
                </div>
            </div>
            <div className='Personal' style={{ backgroundColor: 'white' }}>
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-lg-12' style={{ paddingLeft: '30px' }}>
                        <h3>{lang[langCode].personalProfileViewComponent.followingPeople}</h3>
                    </div>
                </div>
                <div className='row' style={{ paddingTop: '10px' }}>
                    {people.map((person, index) => (
                        <div key={index}>
                            <div className='row' >
                                <div className='col-lg-2' style={{ textAlign: 'center' }}>

                                    {person.picture === null ? (
                                        <img style={{ width: '35px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={noPicture} alt="" />

                                    ) :

                                        <img style={{ width: '35px', marginLeft: '5px', borderRadius: '100%', marginRight: '5px' }} src={"https://www.skyrushcommunity.com/api/public/user_pictures/" + person.picture} alt="" />}
                                </div>
                                <div className='col-lg-4' style={{ textAlign: 'left', padding: '0px' }}>
                                    <p style={{ margin: '0px', fontFamily: 'montserratbold' }}>{person.name} {person.last_name}</p>
                                </div>
                                <div className='col-lg-3' style={{ textAlign: 'left', padding: '0px' }}>
                                    {person.isMe === false ? (
                                        <PeopleButtons
                                            youfollow={person.common_following}
                                            heFollow={person.isFollowedByCurrentUser}
                                            userData={person.id}
                                        />
                                    ) :
                                        null}
                                </div>
                                {person.isMe === false ? (
                                    <div className='col-lg-3' style={{ textAlign: 'left', paddingKeft: '10px' }}>
                                        <a href="#"  onClick={() => viewProfile(person.id)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img
                                                style={{ width: '20px' }}
                                                src={view}
                                                alt=""
                                            />
                                            <p style={{ margin: '0px', fontFamily: 'montserratregular', fontSize: '12px', padding: '0px' }}>
                                                &nbsp;{lang[langCode].mainPeople.viewProfile}
                                            </p>
                                        </a>
                                    </div>
                                ) :
                                    null}
                            </div>
                            {/* Agrega más campos según la estructura de tus datos */}
                            <hr style={{ margin: '5px' }}></hr>
                        </div>
                    ))}
                </div>
                {loading && (
                    <div className='row'>
                        <div className='col-lg-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={loader}
                                style={{
                                    width: '50px',
                                    marginTop: '25%',
                                    marginBottom: '25%',
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default PeopleFollowed;



import React, { useState, useEffect, useRef } from 'react';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import loader from '../../resources/img/loader.svg';
import erase from '../../resources/icons/delete.png';
import save from '../../resources/icons/save.svg';
import edit from '../../resources/icons/edit.png';
import plus from '../../resources/icons/plus.png';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext.js';
import { useUserData } from '../../components/Contexts/UserData.js';
import '../../css/Personal.css';


const ProfileProfessional = ({ userData }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const { reloadUserDataAsync } = useUserData();
    const [enabledNameInputs, setEnabledNameInputs] = useState({});
    const [nameErrors, setNameErrors] = useState({});
    const [nameLocationErrors, setNameLocationErrors] = useState({});
    const [nameImageSrcs, setNameImageSrcs] = useState({});
    const [nameDeleteImageSrcs, setNameDeleteImageSrcs] = useState({});
    const [showCross, setShowCross] = useState(true);
    const [showNewInput, setShowNewInput] = useState(false);
    const [newInputError, setNewInputError] = useState('');
    const [newInputLocationError, setNewInputLocationError] = useState('');
    const [nameCount, setNameCount] = useState(userData.user.jobs ? userData.user.jobs.length : 0);
    const newInputvalue = useRef(null);
    const newInputLocationValue = useRef(null);
    const [newInputSaveSrc, setNewInputSaveSrc] = useState(save);
    const [lastNumber, setLastNumber] = useState(null);


    useEffect(() => {
        // Este efecto se ejecutará cada vez que newInputError cambie.

    }, [newInputError]);


    useEffect(() => {


        if (userData.user.jobs == null) {
            setNameCount(0);
            setLastNumber(0);
            setShowNewInput(true)
        }
        else {
            setNameCount(userData.user.jobs.length);
            setLastNumber(getLastNumber());

        }


    }, [userData.user.jobs]);

    useEffect(() => {

        if (nameCount < 5) {
            setShowCross(true)
        }

    }, [nameCount, lastNumber]);


    const getLastNumber = () => {
        const studies = userData.user.jobs;

        if (studies.length > 0) {
            let maxNameNumber = studies[0].job_number;

            for (let i = 1; i < studies.length; i++) {
                if (studies[i].job_number > maxNameNumber) {
                    maxNameNumber = studies[i].job_number;
                }
            }

            // Sumar 1 al máximo encontrado
            return maxNameNumber + 1;
        } else {
            // Si no hay elementos, el número es 1
            return 1;
        }
    };




    const reloadData = async (param) => {

        await reloadUserDataAsync();
        if (param == 'saveNewInput') {
            setNewInputSaveSrc(save)
            newInputvalue.current.value = null;
            newInputLocationValue.current.value = null;
            setShowNewInput(false)
        }

    }



    const editName = (e, inputId) => {
        e.preventDefault();
        setEnabledNameInputs((prevEnabledInputs) => ({
            ...prevEnabledInputs,
            [inputId]: true,
        }));
    }


    const saveName = (e, inputId) => {
        e.preventDefault();
        const name = document.querySelector(`input[name=input_${inputId}`).value;
        const location = document.querySelector(`input[name=location_input_${inputId}`).value;

        var nameOK = false;
        var locationOK = false;
        const emptyPattern = /^\s*$/;
        const lengthPattern = /^.{1,70}$/;
        const lengthLocationPattern = /^.{0,30}$/;
        if (!emptyPattern.test(name) && lengthPattern.test(name)) {
            // Name is valid, clear the error
            setNameErrors((prevErrors) => ({
                ...prevErrors,
                [`name_${inputId}`]: null,
            }));
            nameOK = true;
        } else {
            // Name is invalid, set the error message
            setNameErrors((prevErrors) => ({
                ...prevErrors,
                [`name_${inputId}`]: lang[langCode].personalProfesionalData.limitStudy,
            }));
        }
        if (lengthLocationPattern.test(location)) {
            // Name is valid, clear the error
            setNameLocationErrors((prevErrors) => ({
                ...prevErrors,
                [`location_${inputId}`]: null,
            }));
            locationOK = true;
        } else {
            // Name is invalid, set the error message
            setNameLocationErrors((prevErrors) => ({
                ...prevErrors,
                [`location_${inputId}`]: lang[langCode].personalProfesionalData.limitStudyLocation,
            }));
        }

        if (nameOK == true && locationOK == true) {
            setNameImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [inputId]: loader
            }));
            lauchSaveRequest(name, location, inputId)
        }
    }

    const lauchSaveRequest = (name, location, inputId) => {
        const token = localStorage.getItem('token');
        fetch("api/public/save-job", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    name: name,
                    number: inputId,
                    location: location,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                reloadNameData(inputId, "save");
            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });


    }


    const reloadNameData = async (id, param) => {

        await reloadUserDataAsync();
        if (param == "save") {
            setEnabledNameInputs((prevEnabledInputs) => ({
                ...prevEnabledInputs,
                [id]: false,
            }));

            setNameImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [id]: save
            }));
        }
        else if (param == "delete") {

            setNameDeleteImageSrcs(prevSrcs => ({
                ...prevSrcs,
                [id]: erase
            }));

        }


    }



    const deleteName = (e, inputId) => {
        e.preventDefault();
        setNameDeleteImageSrcs(prevSrcs => ({
            ...prevSrcs,
            [inputId]: loader
        }));
        const token = localStorage.getItem('token');
        fetch("api/public/delete-job", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken,
                'Authorization': 'Bearer ' + token
            },
            body:
                JSON.stringify({
                    number: inputId,
                }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("La solicitud no fue exitosa");
                }
                reloadNameData(inputId, "delete");
                setShowCross(true)
            })
            .then(data => {

            })
            .catch(error => {
                // Manejar errores de la petición aquí
                console.error('Error en la petición fetch:', error);
            });
    }


    const addInput = (e) => {
        e.preventDefault();
        setShowCross(false)
        setShowNewInput(true)
    }


    const delNewInput = (e) => {
        e.preventDefault();
        setShowNewInput(false)
    }
    const saveNewInput = (e) => {
        e.preventDefault();

        const name = newInputvalue.current.value
        const location = newInputLocationValue.current.value

        var nameOK = false;
        var locationOK = false;
        const emptyPattern = /^\s*$/;
        const lengthPattern = /^.{1,70}$/;
        const lengthLocationPattern = /^.{0,30}$/;
        if (!emptyPattern.test(name) && lengthPattern.test(name)) {
            setNewInputError('')
            nameOK = true;
        } else {

            setNewInputError(lang[langCode].personalProfesionalData.limitStudy)

        }
        if (lengthLocationPattern.test(location)) {
            setNewInputLocationError('')
            locationOK = true;
        } else {

            setNewInputLocationError(lang[langCode].personalProfesionalData.limitStudyLocation)

        }


        if (nameOK == true && locationOK == true) {
            setNewInputSaveSrc(loader)
            const token = localStorage.getItem('token');
            fetch("api/public/save-job", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': 'Bearer ' + token
                },
                body:
                    JSON.stringify({
                        name: name,
                        number: lastNumber,
                        location: location,
                    }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("La solicitud no fue exitosa");
                    }
                    reloadData("saveNewInput");
                })
                .then(data => {

                })
                .catch(error => {
                    // Manejar errores de la petición aquí
                    console.error('Error en la petición fetch:', error);
                });

        }


    }


    return (
        <div className='Personal'>
            <div className='container'>
                <div className='row' style={{ paddingTop: '20px' }}>

                    <div className='col-12' style={{ textAlign: 'left' }}>
                        <h3>{lang[langCode].personalProfesionalData.jobProfile}</h3>

                    </div>
                </div>
                <div className='row' style={{ paddingTop: '5px' }}>

                    <div className='col-5' style={{ textAlign: 'left' }}>
                        <h6>{lang[langCode].personalProfesionalData.job}</h6>
                    </div>

                </div>
                {userData.user.jobs ? (
                    userData.user.jobs.map((name, index) => (
                        <div key={name.job_number}>
                            <div className='row' style={{ paddingTop: '10px' }}>
                                <div className='col-10' style={{ textAlign: 'left' }}>
                                    <div className='row' style={{ paddingTop: '0px' }}>
                                        <div className='col-12' style={{ textAlign: 'left' }}>
                                            {/* Renderiza los detalles de cada estudio aquí */}
                                            <input
                                                type="text"
                                                defaultValue={name.name}
                                                name={`input_${name.job_number}`}
                                                disabled={!enabledNameInputs[name.job_number]}
                                                onKeyUp={(e) => {

                                                }}
                                                style={{
                                                    borderColor: nameErrors[`name_${name.job_number}`] ? 'red' : '',
                                                }}
                                            />
                                            {nameErrors[`name_${name.job_number}`] && (
                                                <p style={{
                                                    color: 'red',
                                                    margin: '0',
                                                    textAlign: 'center',
                                                    marginTop: '5px',
                                                    fontFamily: 'montserratregular',
                                                }}>
                                                    {nameErrors[`name_${name.job_number}`]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row' style={{ paddingTop: '5px' }}>
                                        <div className='col-12' style={{ textAlign: 'left' }}>
                                            {/* Renderiza los detalles de cada estudio aquí */}
                                            <input
                                                type="text"
                                                defaultValue={name.location}
                                                name={`location_input_${name.job_number}`}
                                                disabled={!enabledNameInputs[name.job_number]}
                                                onKeyUp={(e) => {

                                                }}
                                                style={{
                                                    borderColor: nameLocationErrors[`location_${name.job_number}`] ? 'red' : '',
                                                }}
                                            />
                                            {nameLocationErrors[`location_${name.job_number}`] && (
                                                <p style={{
                                                    color: 'red',
                                                    margin: '0',
                                                    textAlign: 'center',
                                                    marginTop: '5px',
                                                    fontFamily: 'montserratregular',
                                                }}>
                                                    {nameLocationErrors[`location_${name.job_number}`]}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2' style={{ textAlign: 'left', display: 'flex', alignItems: 'center', padding: '0px' }}>
                                    {enabledNameInputs[name.job_number] ? (
                                        <a href='#' onClick={(e) => saveName(e, name.job_number)} style={{}}>
                                            <img
                                                src={nameImageSrcs[name.job_number] || save}
                                                style={{
                                                    width: '20px',

                                                    marginTop: '0px'
                                                }}
                                            />
                                        </a>
                                    ) : (
                                        <a href='#' onClick={(e) => editName(e, name.job_number)} style={{}}>
                                            <img
                                                src={edit}
                                                style={{
                                                    width: '20px',

                                                    marginTop: '0px'
                                                }}
                                            />
                                        </a>
                                    )}
                                    <a href='#' onClick={(e) => deleteName(e, name.job_number)} style={{}}>
                                        <img
                                            src={nameDeleteImageSrcs[name.job_number] || erase}
                                            style={{
                                                width: '20px',

                                                marginTop: '0px'
                                            }}
                                        />
                                    </a>
                                </div>
                                {/* Agrega más detalles según la estructura de tu objeto 'name' */}

                            </div>
                        </div>
                    ))
                ) : (
                    <div></div>
                )}
                {showNewInput &&
                    <div className='row' style={{ paddingTop: '10px' }}>
                        <div className='col-10' style={{ textAlign: 'left' }}>
                            <div className='row' style={{ paddingTop: '0px' }}>
                                <div className='col-12' style={{ textAlign: 'left' }}>
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].personalProfesionalData.formation}
                                        ref={newInputvalue}
                                        onKeyUp={(e) => {

                                        }}
                                        style={{
                                            borderColor: newInputError ? 'red' : '',
                                        }}
                                    />
                                    <span style={{ display: 'block', textAlign: 'center' }}>
                                        {newInputError &&
                                            <p style={{
                                                color: 'red',
                                                margin: '0',
                                                textAlign: 'center',
                                                marginTop: '5px',
                                                fontFamily: 'montserratregular',

                                            }}>
                                                {newInputError}
                                            </p>}
                                    </span>
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '5px' }}>
                                <div className='col-12' style={{ textAlign: 'left' }}>
                                    <input
                                        type="text"
                                        placeholder={lang[langCode].personalProfesionalData.location}
                                        ref={newInputLocationValue}
                                        onKeyUp={(e) => {

                                        }}
                                        style={{
                                            borderColor: newInputLocationError ? 'red' : '',
                                        }}
                                    />
                                    <span style={{ display: 'block', textAlign: 'center' }}>
                                        {newInputLocationError &&
                                            <p style={{
                                                color: 'red',
                                                margin: '0',
                                                textAlign: 'center',
                                                marginTop: '5px',
                                                fontFamily: 'montserratregular',

                                            }}>
                                                {newInputLocationError}
                                            </p>}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-2' style={{ textAlign: 'left', display: 'flex', alignItems: 'center', padding: '0px' }}>
                            <a href='#' onClick={saveNewInput} style={{}}>
                                <img
                                    src={newInputSaveSrc}
                                    style={{
                                        width: '20px',

                                        marginTop: '0px'
                                    }}
                                />
                            </a>
                            <a href='#' onClick={delNewInput} style={{}}>
                                <img
                                    src={erase}
                                    style={{
                                        width: '20px',

                                        marginTop: '0px'
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                }
                {showCross &&
                    <div className='row' style={{ paddingTop: '7px' }}>

                        <div className='col-5' style={{ textAlign: 'left' }}>
                            <a href='#' onClick={addInput} style={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
                                <img
                                    src={plus}
                                    style={{
                                        width: '20px',

                                        marginTop: '0px',
                                    }}
                                />
                                <p style={{ margin: '0 0 0 0px', fontSize: '13px', fontFamily: 'montserratregular' }}>
                                    {lang[langCode].personalProfesionalData.add}
                                </p>
                            </a>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default ProfileProfessional;
import React from 'react';
import { DivIcon } from 'leaflet';

const CustomIcon = ({ number }) => {
  const num = Number(number);
  const roundedNumber = num.toFixed(1);

  // Define los colores correspondientes a los diferentes rangos de temperaturas
  const colorScale = (temperature) => {
    switch (true) {
      case (temperature <= -40):
        return '#441334'; 
      case (temperature > -40 && temperature <= -35):
        return '#441334'; 
      case (temperature > -35 && temperature <= -30):
        return '#1E0521'; 
      case (temperature > -30 && temperature <= -25):
        return '#BD466A'; 
      case (temperature > -25 && temperature <= -20):
        return '#BD468B'; 
      case (temperature > -20 && temperature <= -15):
        return '#BD46AE'; 
      case (temperature > -15 && temperature <= -10):
        return '#9D46BD'; 
      case (temperature > -10 && temperature <= -5):
        return '#464ABD'; 
      case (temperature > -5 && temperature <= 0):
        return '#467BBD'; 
      case (temperature > 0 && temperature <= 5):
        return '#46AABD'; 
      case (temperature > 5 && temperature <= 10):
        return '#40A957'; 
      case (temperature > 10 && temperature <= 15):
        return '#83A940'; 
      case (temperature > 15 && temperature <= 20):
        return '#BFC442'; 
      case (temperature > 20 && temperature <= 25):
        return '#D9AC3A'; 
      case (temperature > 25 && temperature <= 30):
        return '#D98B3A'; 
      case (temperature > 30 && temperature <= 35):
        return '#D9573A'; 
      case (temperature > 35 && temperature <= 40):
        return '#D93A3A'; 
      case (temperature > 40 && temperature <= 45):
        return '#D93A5F'; 
      case (temperature > 45 && temperature <= 50):
        return '#D93AB3'; 
      default:
        return '#441313'; // Rojo oscuro para temperaturas muy altas
    }
  };

  const iconColor = colorScale(num);

  const iconHtml = `
    <div 
      style="background-color: ${iconColor};
      width: 30px; 
      height: 30px; 
      border-radius: 50%;
      display: flex; 
      justify-content: center; 
      align-items: center;
      color: white; 
      font-weight: bold;">
      ${roundedNumber}
    </div>
  `;

  return new DivIcon({ html: iconHtml, iconSize: [30, 30], className: 'custom-icon' });
};

export default CustomIcon;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext.js';
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';


Modal.setAppElement('#root');


const WeatherlinkDesktopWarning = ({ isOpen, onClose }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();

    const [customStyles, setCustomStyles] = useState({
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 999,
        },
        content: {
            width: '95%',
            height: 'auto',  // Establecer la altura a 'auto'
            margin: 'auto',
            left: '0',
            right: '0',
            top: '10%',  // Ajustar según sea necesario
            bottom: 'auto',
            overflow: 'auto',
            padding: '5px',  // O ajustar según sea necesario
            border: 'none',  // O ajustar según sea necesario
            borderRadius: '8px',  // O ajustar según sea necesario
            position: 'fixed',
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Renovar Subscripcion"
            style={customStyles}        >
            <div className='row'>
                <div className='col-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </a>
                </div>
                <div className='col-10'>

                </div>
            </div>
            <div className='row' style={{ backgroundColor: 'white' }}>
                <div className='col-12'>
                    <div className='row' >
                        <div className='col-12'>
                            <h3 style={{ fontSize: '13px', fontFamily: 'montserratbold', padding: '5px', margin: '0px' }}>{lang[langCode].generic.info}</h3>
                        </div>
                    </div>
                    <div className='row' >
                        <div className='col-12'>
                            <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px' }}>{lang[langCode].weather.wldWarning}</p> 
                        </div>
                    </div>
                    <div className='row' >
                    <div className='col-4'>
                    </div>
                        <div className='col-4'>
                            <a href='https://cdn.shopify.com/s/files/1/0515/5992/3873/files/07395-210_gsg_06510_6555.pdf?v=1623784827' style={{ fontSize: '12px', fontFamily: 'montserratbold', padding: '5px', margin: '0px', color:'black', textDecoration:'underline' }}>{lang[langCode].weather.wldManual}</a>
                        </div>
                    </div>
                    <div className='row' >
                        <div className='col-12'>                    
                            <p style={{ fontSize: '12px', fontFamily: 'montserratregular', padding: '5px', margin: '0px' }}>{lang[langCode].weather.wldWarningEnd}</p>
                        </div>
                    </div>
                    <div className='row' style={{paddingTop:'10px',paddingBottom:'10px'}}>
                        <div className='col-2'>
                        </div>
                        <div className='col-8'>
                            <button
                                style={{ fontFamily: 'montserratregular', fontSize: '13px' }}
                                onClick={onClose}> {lang[langCode].generic.close}
                            </button>
                        </div>
                    </div>
                </div>
            </div>




        </Modal>
    )

}

export default WeatherlinkDesktopWarning;

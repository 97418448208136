const lang = {
    en: {
        generic:
        {
            save: "Save",
            emptyField: "This field cannot be empty",
            close: "Close",
            weatherStationNet: "Weather Station Network",
            memberOfAssociation: "Member of the association ",
            ok: "OK",
            showMore: "Show more",
            showLess: "Show less",
            goBack: "Go back",


        },
        startBody: {
            welcomeTitle: "Skyrush Community",
            welcomeMessage: "Welcome to the community for weather enthusiasts, astronomers, geographers, mountaineers, and more.",
            emailPlaceholder: "Email Address",
            passwordPlaceholder: "Password",
            loginButton: "Log In",
            forgotPasswordLink: "Forgot your password?",
            registerButton: "Create Account",
            otherLoginOption: "Or with",
        },
        registerModalContent: {
            registerTitle: "Sign Up",
            registerSubtitle: "It's easy and fast",
            namePlaceholder: "Name",
            surnamePlaceholder: "Last Name",
            registerEmailPlaceholder: "Email Address",
            registerPasswordPlaceholder: "Password",
            registerConditionsLink: "Terms & Conditions",
            registerConditionsMessage: "By clicking Register, you agree to our", // Traducción en inglés para el mensaje de condiciones en el formulario de registro
            conditions: "Terms & Conditions.",
            registerButton: "Sign Up",
        },
        verifiedModal: {
            verificationSuccessTitle: "Your email address has been successfully verified",
            verificationSuccessMessage: "Thank you for joining the Skyrush community!",
            verificationErrorTitle: "An error occurred during verification.",
            verificationErrorMessage: "This link may have already been used or is incorrect. If you continue to experience issues, please reach out to our support team at support@Skyrush.com",
            closeButton: "Close",
        },
        recoveryPassModal: {
            recoveryTitle: "Enter your email address",
            recoverySubtitle: "We'll send you an email with instructions",
            recoveryEmailPlaceholder: "Email Address",
            recoveryButton: "Recover Password",
            invalidEmail: "The email address is not valid.",
        },
        newPasswordModal: {
            newPasswordTitle: "Enter a new password",
            newPasswordSubtitle: "Just one more step",
            newPasswordPlaceholder: "New Password",
            resetPasswordButton: "Reset Password",
            passwordRequirements: "The password must contain at least eight characters, including lowercase, uppercase, and special characters.",
        },
        emailSentConfirmModal: {
            confirmTitle: "Check your email",
            confirmMessage: "We've sent you an email with instructions to reset your password. You will be redirected to the home page in a few seconds.",
        },
        passChangeConfirmModal: {
            changeSuccessTitle: "Password updated successfully",
            changeSuccessMessage: "You will be redirected to the home page in a few seconds.",
        },
        registerConfirmationModal: {
            registerSuccessTitle: "Registration completed successfully",
            registerSuccessMessage: "We've sent you an email to confirm your email address.",
            registerEmailExistsTitle: "An account is already associated with this email.",
            registerEmailExistsMessage: "Please register with a different email address. If the problem persists, you can contact us at support@Skyrush.com.",
            registerErrorTitle: "An unidentified error occurred.",
            registerErrorMessage: "Please try registering again in a moment. If the problem persists, you can contact us at support@Skyrush.com.",
            registerCloseButton: "Close",
        },
        setButtons: {
            forgot: "Forgot your password?",
            mailValidation: "The mail field cannot be empty",
            passwordValidation: "The password field cannot be empty",
            registerPasswordValidation: "Password must include at least eight characters, lowercase, uppercase, and special characters",
            nameValidation: "The name field must have a length between 1 and 15 characters.",
            surnameValidation: "The surname field must have a length between 1 and 15.",
            emailValidation: "The email address is not valid",
        },
        login: {
            loginFailed: "Credentials are incorrect.",
            userNotVerified: "You haven't verified your email address yet.",
            unexpectedError: "An unexpected error occurred during login.",

        },
        sendMail: {
            userNotFound: "There is no user associated with this email address.",
        },
        footer: {
            needHelp: "Need help?",
            madeBy: "Made by Fernando Cabeza 2023. All rights reserved.",
            dataPrivacy: "Data Privacy",
            contact: "Contact",
            englishVersion: "English Version",
            spanishVersion: "Spanish Version",
            autoLanguage: "Auto detect language",
        },
        modifyPersonalPicture: {
            imageAltText: "Profile Picture",
            loadPictureLink: "Upload Picture",
            loadPicturePreview: "Load a picture",
            erasePictureLink: "Erase Picture"
        },
        personalProfile: {
            personalDataTitle: "Personal Data",
            editLabel: "Edit",
            viewLabel: "View",
            logoutLabel: "Logout"

        },
        personalDataProfile: {
            name: "Name*",
            lastName: "Last Name*",
            locationTitle: "Type and select a location from the list",
            locationPlaceHolder: "Location",
            city: "City/Town",
            region: "Region",
            country: "Country",
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            month: "Month",
            day: "Day",
            year: "Year",
        },
        personalProfesionalData: {
            personalProfesionalDataTitle: "Professional Data",
            formation: "Education",
            location: "Educational Institution",
            schooling: "Studies",
            add: "Add New",
            jobProfile: "Job Profile",
            generalDescription: "Main Description",
            projects: "Projects",
            project: "Project",
            job: "Position",
            company: "Company",
            limit: "The maximum length of the description is 120 characters",
            limitStudy: "The field must have between 1 and 70 characters"
        },
        skyrushProfileData: {
            title: "Skyrush Profile",
            notVerified: "This profile is not verified by Skyrush",
            verified: "Profile verified by Skyrush",
            basicProfile: "Basic Profile",
            premiumProfile: "Advanced Profile",
            basicProfileStations: "You cannot access audits to receive quality certificates for meteorological stations.",
            premiumProfileStations: "You can request audits to receive quality certificates for your meteorological stations.",
            asociationBasic: "You cannot belong to any association.",
            asociationPremiumNoAssociation: "This profile does not belong to any association",
            asociationPremium: "This profile belongs to the association ",
            goBasicButton: "Go back to Basic profile",
            goPremiumButton: "Advanced Profile",
            expirationDate: "This subscription expires on ",
            renewButton: "Renew subscription",
            renewModalTitle: "Renew your subscription",
            renewModalContent: "Your subscription expires on ",
            renewModalContentPartII: "If you don't renew it, starting from that date, you won't be able to enjoy: ",
            verifiedProfileText: "• Profile verified by Skyrush with a blue checkmark.",
            AuditoriesText: "• Your stations will lose their quality certificate. When you have a subscription again, you will automatically regain it without the need for a new audit.",
            AssoctionBelongText: "• You won't be able to belong to any association.",
            renewRequestSentTitle: "Request sent",
            renewRequestSentText: "We will contact you to provide renewal instructions",
            goAdvancedModalTitle: "Advanced Profile",
            goAdvancedModalText: "The Advanced profile includes the following benefits: ",
            goAvancedContentTextI: "• Profile verified by Skyrush with a blue checkmark.",
            goAvancedContentTextII: "• Access to quality audits for all your meteorological stations. If the station meets the <a href='' style='color:black;text-decoration: underline'>specified requirements</a>, you will receive the corresponding quality certificate.",
            goAvancedContentTextIII: "• If you are a member of any Skyrush partner association, your profile can be associated with that association. The logo will be displayed both on your profile and on your meteorological stations.",
            goAdvanceContentFoot: "We understand that before upgrading to Advanced, you want to know if your profile would be eligible for the blue checkmark verification and if your stations would pass the quality audit. Don't worry; we'll take care of verifying it. By clicking on <font style='color:#0074EF'>verify profile</font>, we will review both your account and your stations and inform you of the result. At that point, you can decide whether you want to purchase an Advanced account or stick with the Basic plan.",
            verifyMyProfileButton: "Verify profile",
            goAdvancedRequestSentTitle: "Request sent",
            goAdvancedRequestSentText: "We will send you an email with the verification result.",
        },
        personalProfileViewComponent: {
            relatedPeople: "People related to ",
            youNotFollow: "You don't follow ",
            youFollow: "You follow ",
            heNotFollow: "and he/she doesn't follow you.",
            heFollow: "and he/she follows you.",
            following: "Following",
            followers: "Followers",
            onCommon: "In common",
            bothFollows: "Both follow",
            followingPeople: "People following"
        },
        mainPeople: {
            followers: "Followers",
            following: "Following",
            lookForPeople: "Search people",
            alsoFollowsI: "Also follows ",
            alsoFollowsII: " and ",
            alsoFollowsIII: " more people",
            alsoFollow: "Follow also",
            follow: "Follow",
            stopFollow: "Stop following",
            viewProfile: " View profile"
        },
        notifications: {
            startFollow: "has started following you",
            postTaged: "tagged you in a post",
            newLike: "liked your post",
            newComment: "commented on your post",
            newCommentLike: "liked your comment",
            newSubCommentAnswer: "replied to your comment",
            seePost: "See full post",
            sharePost: "Shared your post"
        },
        posts: {
            video: "Video",
            photo: "Photo",
            text: "Text",
            newPost: "Add a new post!",
            publishedBy: "Published by",
            createPost: "Create post",
            editPost: "Edit post",
            loadPhotos: "Load photos",
            loadTheVideo: "Load video",
            loadFourPhotos: "You can load up to four photos",
            loadVideo: "Your video will be displayed here when you upload it",
            errorVideo: "Unsupported video format",
            errorVideoSize: "Video size cannot exceed 30 MB",
            textAreaPlaceHolder: "Write your post",
            addLocation: "Add location",
            addPeople: "Tag People",
            tagPeople: "You can tag up to five people",
            publish: "Publish",
            share: "Share",
            maxTags: "The limit of 5 elements has been reached",
            upToDate: "You are up to date",
            iLike: "Like",
            dislike: "Dislike",
            comments: "comments",
            OneComment: "comment",
            writeComment: "Write a comment...",
            youAnd: "You and",
            youAndPartII: "more people",
            likedBy: "Liked by",
            sharedBy: "Shared by",
            comment: "Comment",
            share: 'Share',
            report: 'Report',
            reported: 'Reported!',
            edit: 'Edit',
            delete: 'Delete',
            timesShared: 'times shared',
            viewPost: 'View post',
            answer: 'Answer'
        },
        time: {
            hace: 'ago',
            seconds: 'seconds',
            minuts: 'minutes',
            minut: 'minute',
            hours: 'hours',
            hour: 'hour',
            days: 'days',
            day: 'day'
        },
        weather: {
            map: 'Map',
            data: 'Data',
            myStations: 'My Stations',
            addDevice: 'Add Device',
            modifyLocation: 'Set New Location',
            basicInfo: 'Basic Information',
            location: 'Location',
            name: 'Name*',
            namePlaceholder: 'Name',
            brand: 'Brand*',
            brandPlaceHolder: '--Select Brand--',
            model: 'Model*',
            modelPlaceHolder: '--Select Model--',
            connection: 'Connection*',
            connectionPlaceHolder: '--Select Connection--',
            description: 'Description',
            characterExceed: 'Text cannot exceed 100 characters',
            nameExceed: 'Name cannot exceed 30 characters',
            altitude: 'Altitude*',
            altitudePlaceHolder: 'Altitude',
            altitudeError: 'Altitude must be an integer.',
            status: 'Status',
            draft: 'Draft',
            online: 'Online',
            offline: 'Offline',
            warning: 'Erroneous Data Detected',
            warningInstructións: 'Skyrush has a meteorological data validation system and has detected that this station has sent data out of range. Please check your station and correct it. Once the data is correct again, the station will automatically become active. If you have any doubts, you can write to us at info@skyrushcommunity.com',
            stationList: 'Station List',
            draftInstruccions: 'This station is still in draft mode. To activate it, please configure your WS View application, following the instructions below. Once you have completed the process, if you have followed the steps correctly, the station will automatically become active in a couple of minutes.',
            onlineInstruccions: 'The station emits correct data and was last updated at ',
            oflineInstruccions: 'The station has been offline for more than 15 minutes. It was last updated at ',
            draftInstruccionsWLLive: 'This station is still in draft mode. To activate it, please enter the Api Key v2 data that you will find within your Weatherlink.com account',
            wlliveKey: 'Key*',
            wlliveSecret: 'Secret*',
            wlliveKeyPH: 'Key',
            wlliveSecretPH: 'Secret',
            wlliveID: 'ID*',
            wlliveIDPH: 'ID',
            findWlliveKey: 'Find Parameters',
            findWlliveSecret: 'Find Secret',
            wsviewExample: 'Connection Example',
            modifyName: 'Modify Name',
            noModifyModel: '*The model and connection type cannot be changed manually. If you need to make any changes, please send us an email at info@skyrushcommunity.com.',
            photo: 'Photo',
            qualityCert: 'Quality Certificate',
            noCertName: 'No certificate',
            termoCertName: 'Thermal Certificate',
            termoRainCertName: 'Thermopluviometric Certificate',
            termoWindCertName: 'Thermoeolic Certificate',
            avancedCertName: 'Advanced Quality Certificate',
            warningCert: 'Station under observation',
            noCert: 'Skyrush does not guarantee that the emitted data is reliable.',
            termoCert: ' Skyrush certifies that the temperature data is of quality.',
            termoRainCert: 'Skyrush certifies that the temperature and rain data are of quality.',
            termoWindCert: 'Skyrush certifies that the temperature and wind data are of quality.',
            avancedCert: 'Skyrush certifies that all data from this weather station is of quality.',
            warningCert: 'This station has reported some erroneous data. If you have doubts, contact Skyrush at info@skyrushcommunity.com',
            avancedUser: 'Your user profile is Basic, so you cannot access audits to receive quality certificates. You can get more information about the advanced profile by clicking on the button below.',
            getAudit: "You can request a quality audit for this station at any time. Check the <a href='' style='color:black;text-decoration: underline;font-size:12px'>established requirements</a>",
            getAuditButton: 'Request Audit',
            AdvanceButton: 'Advanced Profile',
            auditRequested: 'Audit requested. We will contact you through your email.',
            historicData: 'Historical Data',
            wllIDInstructionTitle: 'Device ID',
            wllIDInstruction: 'It is the identifier of your station on Weatherlink.com. You can find it in your weatherlink.com profile in the section of the icon with the wrench. In the following image, you can see marked in red the same data but from another station as an example:',
            wllKeyInstructionTitle: 'Api Key V2',
            wllKeyInstruction: 'It is the key that Skyrush will use to access the information. You can find it in your user profile of Weatherlink.com in the Api V2 section. In the following image, you can see marked in red the same data but from another station as an example (The content has been crossed out to preserve the privacy of the data):',
            wllSecretInstructionTitle: 'Api V2 Secret',
            wllSecretInstruction: 'You can find it in your user profile of Weatherlink.com in the Api V2 section. In the following image, you can see marked in red the same data but from another station as an example (The content has been crossed out to preserve the privacy of the data):',
            connectionText: 'Connection Test',
            editConnectionText: 'Modify Data',
            twelveChars: 'This parameter must have 12 characters',
            thertyTwoChars: 'This parameter must have 32 characters',
            wllerror: 'We have not managed to connect with your station. One or more of the parameters you entered are incorrect.'
        }
    },
    es: {
        generic:
        {
            save: "Guardar",
            emptyField: "Este campo no puede estar vacío",
            close: "Cerrar",
            weatherStationNet: "Red de estaciones meteorológicas",
            memberOfAssociation: "Miembro de la asociación ",
            ok: 'Listo',
            showMore: 'Mostrar más',
            showLess: 'Mostrar menos',
            goBack: "Atrás",
            userPosts:"Posts de usuarios seguidos",
            TrendPosts:"Posts destacados",
            info:"Información",
        },
        startBody: {
            welcomeTitle: "Comunidad Skyrush",
            welcomeMessage: "Bienvenido a la comunidad para aficionados a la meteorología, astrónomos, geógrafos, montañeros y más.",
            emailPlaceholder: "Dirección de correo electrónico",
            passwordPlaceholder: "Contraseña",
            loginButton: "Iniciar sesión",
            forgotPasswordLink: "¿Olvidaste tu contraseña?",
            registerButton: "Crear cuenta",
            otherLoginOption: "O bien con",
        },
        registerModalContent: {
            registerTitle: "Registrarse",
            registerSubtitle: "Es fácil y rápido",
            namePlaceholder: "Nombre",
            surnamePlaceholder: "Apellidos",
            registerEmailPlaceholder: "Dirección de correo electrónico",
            registerPasswordPlaceholder: "Contraseña",
            registerConditionsLink: "Términos y condiciones",
            registerConditionsMessage: "Al hacer clic en Registrarte, aceptas nuestras", // Traducción en español para el mensaje de condiciones en el formulario de registro
            conditions: "Condiciones",
            registerButton: "Registrarse",
        },
        verifiedModal: {
            verificationSuccessTitle: "Tu dirección de correo electrónico ha sido verificada correctamente",
            verificationSuccessMessage: "¡Gracias por unirte a la comunidad Skyrush!",
            verificationErrorTitle: "Ha ocurrido un error en la verificación.",
            verificationErrorMessage: "Puede ser porque este enlace ya haya sido utilizado o que sea incorrecto. Si sigues teniendo problemas, puedes ponerte en contacto con info@skyrushcommunity.com",
            closeButton: "Cerrar",
        },
        recoveryPassModal: {
            recoveryTitle: "Introduce tu dirección de correo electrónico",
            recoverySubtitle: "Te mandaremos un correo con las instrucciones",
            recoveryEmailPlaceholder: "Dirección de correo electrónico",
            recoveryButton: "Recuperar contraseña",
            invalidEmail: "La dirección de correo electrónico no es válida.",
        },
        newPasswordModal: {
            newPasswordTitle: "Introduce una nueva contraseña",
            newPasswordSubtitle: "Solo un paso más",
            newPasswordPlaceholder: "Nueva contraseña",
            resetPasswordButton: "Restablecer contraseña",
            passwordRequirements: "La contraseña debe contener al menos ocho caracteres, incluyendo minúsculas, mayúsculas y caracteres especiales.",
        },
        emailSentConfirmModal: {
            confirmTitle: "Revisa tu correo",
            confirmMessage: "Te hemos enviado un correo con instrucciones para restablecer tu contraseña. En unos segundos serás redirigido a la página de inicio.",
        },
        passChangeConfirmModal: {
            changeSuccessTitle: "Contraseña actualizada con éxito",
            changeSuccessMessage: "En unos segundos serás redirigido a la página de inicio.",
        },
        registerConfirmationModal: {
            registerSuccessTitle: "Registro completado correctamente",
            registerSuccessMessage: "Te hemos enviado un correo electrónico para confirmar tu dirección de correo electrónico.",
            registerEmailExistsTitle: "Ya existe una cuenta asociada a este correo electrónico.",
            registerEmailExistsMessage: "Por favor, regístrate con una dirección de correo electrónico diferente. Si el problema persiste, puedes contactarnos en support@Skyrush.com.",
            registerErrorTitle: "Ha ocurrido un error no identificado.",
            registerErrorMessage: "Por favor, inténtalo de nuevo en un momento. Si el problema persiste, puedes contactarnos en support@Skyrush.com.",
            registerCloseButton: "Cerrar",
        },
        setButtons: {
            forgot: "¿Olvidaste tu contraseña?",
            mailValidation: "El campo correo electrónico no puede estar vacío",
            passwordValidation: "El campo contraseña no puede estar vacío",
            registerPasswordValidation: "La contraseña debe incluir al menos ocho caracteres, minúsculas, mayúsculas y caracteres especiales",
            nameValidation: "El campo nombre debe tener una longitud de entre 1 y 15 caracteres",
            surnameValidation: "El campo apellido debe tener una longitud de entre 1 y 15 caracteres",
            emailValidation: "La dirección de correo electrónico no es válida",
        },
        login: {
            loginFailed: "Las credenciales son incorrectas.",
            userNotVerified: "Aún no has verificado tu dirección de correo electrónico.",
            unexpectedError: "Se produjo un error inesperado durante el inicio de sesión.",

        },
        sendMail: {
            userNotFound: "No existe ningún usuario asociado a esta dirección de correo electrónico.",
        },
        footer: {
            needHelp: "¿Necesitas ayuda?",
            madeBy: "Hecho por Fernando Cabeza 2023. Todos los derechos reservados.",
            dataPrivacy: "Privacidad de los datos",
            contact: "Contacto",
            englishVersion: "Versión en inglés",
            spanishVersion: "Versión en español",
            autoLanguage: "Auto detectar idioma",
        },
        modifyPersonalPicture: {
            imageAltText: "Imagen de perfil",
            loadPictureLink: "Cargar Foto",
            loadPicturePreview: "Carga una foto",
            erasePictureLink: "Eliminar foto"
        },
        personalProfile: {
            personalDataTitle: "Datos personales",
            editLabel: "Editar",
            viewLabel: "Ver",
            logoutLabel: "Cerrar Sesión",
        },
        personalDataProfile:
        {
            name: "Nombre*",
            lastName: "Apellidos*",
            locationTitle: "Escribe y selecciona una ubicación de la lista",
            locationPlaceHolder: "Ubicación",
            city: "Ciudad/Localidad",
            region: "Región",
            country: "País",
            months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            month: "Mes",
            day: "Dia",
            year: "Año",
        },
        personalProfesionalData: {
            personalProfesionalDataTitle: "Datos profesionales",
            formation: "Formación",
            location: "Centro de estudios",
            schooling: "Estudios",
            add: "Añadir nuevo",
            jobProfile: "Perfil laboral",
            generalDescription: "Descripción principal",
            projects: "Proyectos",
            project: "Proyecto",
            job: "Puesto",
            company: "Compañía",
            limit: "La longitud máxima de la descripción es de 120 caracteres",
            limitStudy: "El campo debe tener entre 1 y 70 caracteres",
            limitStudyLocation: "Máximo 30 caracteres"

        },
        skyrushProfileData: {
            title: "Perfil Skyrush",
            notVerified: "Este perfil no está verificado por Skyrush",
            verified: "Perfil verificado por Skyrush",
            basicProfile: "Perfil Basic",
            premiumProfile: "Perfil Advanced",
            basicProfileStations: "No puede acceder a las auditorias para recibir certificados de calidad de las estaciones meteorológicas.",
            premiumProfileStations: "Puede solicitar auditorias para recibir certificados de calidad de sus estaciones meteorológicas.",
            asociationBasic: "No puede pertenecer a ninguna asociación.",
            asociationPremiumNoAssociation: "Este perfil no pertenece a ninguna asociación",
            asociationPremium: "Este perfil pertenece a la asociación ",
            goBasicButton: "Volver a perfil Basic",
            goPremiumButton: "Perfil Advanced",
            expirationDate: "Esta subscripción expira el ",
            renewButton: "Renovar subscripción",
            renewModalTitle: "Renueva tu subscripción",
            renewModalContent: "Tu subscripción expira el ",
            renewModalContentPartII: "Si no la renuevas, a partir de esa fecha ya no podras disfrutar de: ",
            verifiedProfileText: "• Perfil verificado por Skyrush con tic azul.",
            AuditoriesText: "• Tus estaciones perderán el certificado de calidad. Cuando vuelvas a tener una subscripción los recuperaras automáticamente sin necesidad de nueva auditoría.",
            AssoctionBelongText: "• No podrás pertenecer a ninguna asociación.",
            renewRequestSentTitle: "Solicitud enviada",
            renewRequestSentText: "Nos pondremos en contacto contigo para faciliarte las instrucciones de renovación.",
            goAdvancedModalTitle: "Perfil Advanced",
            goAdvancedModalText: "El perfil Advanced incluye los siguientes beneficios: ",
            goAvancedContentTextI: "• Perfil verificado por Skyrush con tic azul.",
            goAvancedContentTextII: "• Acceso a las auditorias de calidad para todas tus estaciones meteorológicas. Si la estación cumple los<a href ='' style='color:black;text-decoration: underline' >requisitos estipulados</a> recibirás el certificado de calidad correspondiente.",
            goAvancedContentTextIII: "• Si eres miembro de alguna asociación colaboradora de Skyrush, tu perfil podrá asociarse a dicha asociación. El logo se mostrará tanto en tu perfil, como en tus estaciones meteorológicas.",
            goAdvanceContentFoot: "Como sabemos que antes de pasar a Advanced te interesa saber tanto si tu perfil sería apto recibir la verificación de tic azul, como si tus estaciones pasarían la auditoría de calidad, no te preocupes, nos encargamos de verficarlo. Al pinchar sobre <font style='color:#0074EF'>verificar perfil</font> revisaremos tanto tu cuenta, como tus estaciones y te comunicaremos el resultado. En ese punto podrás decidir si quieres adquirir una cuenta Advanced o seguir en el plan Basic.",
            verifyMyProfileButton: "Verficar perfil",
            goAdvancedRequestSentTitle: "Solicitud enviada",
            goAdvancedRequestSentText: "Te enviaremos un correo electrónico con el resultado de la verificación.",
        },
        personalProfileViewComponent: {
            relatedPeople: "Personas relacionadas con ",
            youNotFollow: "No sigues a ",
            youFollow: "Sigues a ",
            heNotFollow: "y él/ella no te sigue.",
            heFollow: "y él/ella te sigue.",
            following: "Siguiendo",
            followers: "Seguidores",
            onCommon: "En común",
            bothFollows: "Ambos seguís a",
            followingPeople: "Personas a las que sigue"

        },
        mainPeople: {
            followers: "Seguidores",
            following: "Siguiendo",
            lookForPeople: "Buscar personas",
            alsoFollowsI: "También sigue a ",
            alsoFollowsII: " y a ",
            alsoFollowsIII: " personas más",
            alsoFollow: "Seguir también",
            follow: "Seguir",
            stopFollow: " Dejar de seguir",
            viewProfile: " Ver perfil"

        },
        notifications:
        {
            startFollow: "ha comenzado a seguirte",
            postTaged: "te ha etiquetado en una publiación",
            newLike: "ha dado me gusta a tu publicación",
            newComment: "ha comentado tu publicación",
            newCommentLike: "ha dado me gusta a tu comentario",
            newSubCommentAnswer: "ha respondido a tu comentario",
            seePost: "Ver publicación completa",
            sharePost: "Ha compartido tu post"
        },
        posts:
        {
            video: "Video",
            photo: "Foto",
            text: "Texto",
            newPost: "¡Añade un nuevo post!",
            publishedBy: "Publicaciones de",
            publishes: "Publicaciones",
            createPost: "Crear publicación",
            editPost: "Editar publicación",
            loadPhotos: "Cargar fotos",
            loadPTheVideo: "Cargar vídeo",
            loadFourPhotos: "Puedes cargar hasta cuatro fotos",
            loadVideo: "Aquí se vera tu vídeo cuando lo cargues",
            errorVideo: "Formato de video no compatible",
            errorVideoSize: "El tamaño del vídeo no puede exceder los 30 mbs",
            textAreaPlaceHolder: "Escribe tu post",
            addLocation: "Añadir ubicación",
            addPeople: "Etiquetar Personas",
            tagPeople: "Puedes etiquetar hasta cinco personas",
            publish: "Publicar",
            share: "Compartir",
            maxTags: "Se ha alcanzado el límite de 5 elementos",
            upToDate: "Estás al día",
            iLike: "Me gusta",
            dislike: "Ya no me gusta",
            comments: "comentarios",
            OneComment: "comentario",
            writeComment: "Escribe un comentario...",
            youAnd: "Tu y",
            youAndPartII: "personas más",
            likedBy: "Le gusta a",
            sharedBy: "Compartido por",
            comment: "Comentar",
            share: 'Compartir',
            report: 'Denunciar',
            reported: '¡Denunciado!',
            edit: 'Editar',
            delete: 'Eliminar',
            timesShared: 'veces compartido',
            viewPost: 'Ver publicación',
            answer: 'Responder'
        },
        time:
        {
            hace: 'hace',
            seconds: 'segundos',
            minuts: 'minutos',
            minut: 'minuto',
            hours: 'horas',
            hour: 'hora',
            days: 'dias',
            day: 'dia'

        },
        weather:
        {
            time:'Horario*',
            reset:'Resteo lluvia*',
            selectHour: '--Selecciona horario--',
            local: 'Hora local',
            map: 'Mapa',
            data: 'Datos',
            myStations: 'Mis estaciones',
            addDevice: 'Añadir dispositivo',
            modifyLocation: 'Establecer nueva ubicación',
            basicInfo: 'Información básica',
            location: 'Ubicación',
            name:'Nombre*',
            namePlaceholder:'Nombre',
            brand:'Marca*',
            brandPlaceHolder:'--Selecciona Marca--',
            model:'Modelo*',
            modelPlaceHolder:'--Selecciona Modelo--',
            connection:'Conexión*',
            connectionPlaceHolder:'--Selecciona Conexión--',
            description:'Descripción',
            characterExceed:'El texto no puede exceder los 100 caracteres',
            nameExceed:'El nombre no puede exceder los 30 caracteres',
            altitude:'Altitud*',
            altitudePlaceHolder:'Altitud',
            altitudeError:'La altitud debe ser un número entero.',
            status:'Estado',
            draft:'Borrador',
            online:'Online',
            offline:'Offline',
            warning:'Dato erróneo detectado',
            warningInstructións:'Skyrush tiene un sistema de validación de datos meteórologicos y ha detectado que esta estación ha enviado un dato fuera de rango. Por favor, revisa tu estación y corrígelo. Una vez los datos vuelvan a ser correctos la estación pasará a activa automáticamente. Si tienes dudas puedes escribirnos a info@skyrushcommunity.com',
            stationList:'Lista de estaciones',
            draftInstruccions:'Esta estación todavía está en modo borrador. Para activarla, por favor, configura tu aplicación WS View, siguiendo las instrucciones que verás a continuación. Una vez hayas finalizado el proceso, si has seguido los pasos correctamente, la estación pasará a activa automáticamente en un par de minutos.',
            onlineInstruccions:'La estación emite datos correctos y se ha actualizado por última vez a las ',
            oflineInstruccions:'La estación lleva más de 15 minutos sin comunicarse con Skyrush. Se actualizó por última vez a las ',
            draftInstruccionsWLLive:'Esta estación todavía está en modo borrador. Para activarla, por favor introduce los datos de Clave Api v2 que encontrarás dentro de tu cuenta de Weatherlink.com',
            draftInstruccionsWunApi:'Esta estación todavía está en modo borrador. Para activarla, por favor introduce los el ID y el Api Key que encontrarás dentro de tu cuenta de Wunderground',
            draftInstruccionsWLDesktop:'Esta estación todavía está en modo borrador. Para activarla, por favor introduce los datos de Api Token v1 que encontrarás dentro de tu cuenta de Weatherlink.com',
            wlliveKey:'Clave*',
            wlliveSecret:'Secreto*',
            wunApiKey:'Api Key*',
            wunApiKeyPH:'Api Key',
            wlliveKeyPH:'Clave',
            wlliveSecretPH:'Secreto',
            wlliveID:'ID*',
            wlliveIDPH:'ID',
            findWlliveKey:'Encuentra los parámetros',
            findWlliveSecret:'Encuentra el secreto',
            wsviewExample:'Ejemplo de conexión',
            modifyName:'Modificar Nombre',
            noModifyModel:'*El modelo, el tipo de conexión y el horario de reseteo de lluvia no se pueden cambiar manualmente. Si necesitas hacer alguna modificación, por favor, envianos un mail a info@skyrushcommunity.com.',
            photo: 'Foto',
            qualityCert: 'Certificado de Calidad',
            noCertName:'Sin certificado',
            termoCertName:'Certificado térmico',
            termoRainCertName:'Certificado termopluviométrico',
            termoWindCertName:'Certificado termoeólico',
            avancedCertName:'Certificado de calidad avanzada',
            warningCert:'Estación en observación',
            noCert: 'Skyrush no garantiza que los datos que emite sean fiables.',
            termoCert: ' Skyrush certifica que los datos de temperatura son de calidad.',
            termoRainCert: 'Skyrush certifica que los datos de temperatura y lluvía son de calidad.',
            termoWindCert: 'Skyrush certifica que los datos de temperatura y viento son de calidad.',
            avancedCert: 'Skyrush certifica que todos los datos de esta estación meteorológica son de calidad.',
            warningCert:'Está estación ha reportado algún dato erróneo. Si tienes dudas, ponte en contacto con Skyrush a través de info@skyrushcommunity.com',
            avancedUser: 'Tu perfil de usuario es Basic, por lo tanto no puedes acceder a las auditorias para recibir certificados de calidad. Puedes obtener más información sobre el perfil advanced pulsando en el siguiente botón.',
            getAudit: "Puedes solicitar una auditoría de calidad para esta estación en cualquier momento. Consulta los<a href ='' style='color:black;text-decoration: underline;font-size:12px' >requisitos estipulados</a>",
            getAuditButton:'Solicitar auditoria',
            AdvanceButton: 'Perfil Advance',
            auditRequested: 'Auditoría solicitada. Nos pondremos en contacto contigo a través de tu correo electrónico.',
            historicData: 'Datos históricos',
            wllIDInstructionTitle:'ID de dispositivo',
            wunApiIDInstruction:'Es el identificador de tu estación en Wunderground. Podrás en encontrarlo en la pestaña my devices de tu perfil de Wunderground. En la siguiente imagen podrás ver marcado en rojo el mismo dato pero de otra estación como ejemplo:',
            wllIDInstruction:'Es el identificador de tu estación en Weatherlink.com. Podrás en encontrarlo en tu perfil de weatherlink.com en la sección del icono con la llave inglesa. En la siguiente imagen podrás ver marcado en rojo el mismo dato pero de otra estación como ejemplo:',
            wllKeyInstructionTitle:'Clave Api V2',
            wunApiKeyInstructionTitle:'Api Key',
            wllKeyInstruction:'Es la llave que usará Skyrush para acceder a la información. La puedes encontrar en tu perfil de usuario de Weatherlink.com en el apartado de Api V2. En la siguiente imagen podrás ver marcado en rojo el mismo dato pero de otra estación como ejemplo (Se ha tachado el contenido para preservar la privacidad del dato):',
            wunApiKeyInstruction:'Es la llave que usará Skyrush para acceder a la información. La puedes encontrar en la pestaña api keys de tu perfil de Wunderground. En la siguiente imagen podrás ver marcado en rojo el mismo dato pero de otra estación como ejemplo (Se ha tachado el contenido para preservar la privacidad del dato):',
            wllSecretInstructionTitle:'Secreto Api V2',
            wllSecretInstruction:'Lo puedes encontrar en tu perfil de usuario de Weatherlink.com en el apartado de Api V2. En la siguiente imagen podrás ver marcado en rojo el mismo dato pero de otra estación como ejemplo (Se ha tachado el contenido para preservar la privacidad del dato):',
            connectionText:'Prueba de conexión',
            editConnectionText:'Modificar datos',
            twelveChars:'Este parámetro debe tener 12 caracteres',
            sixChars:'Este parámetro debe tener más de 6 caracteres',
            thertyTwoChars:'Este parámetro debe tener 32 caracteres',
            wllerror:'No hemos logrado conectar con tu estación. Uno o varios de los parámetros que has introducidos son erróneos.',
            wldWarning:'Para poder utilizar este sistema, es necesario que tu weatherlink USB esté emitiendo datos a Weatherlink.com. Si todavía no tienes esta configuración hecha puedes seguir el manual oficial de Davis en el siguiente enlace (Página 12):',
            wldManual:'Manual Davis',
            wldWarningEnd: '*Es necesario tener instalada la última versión de Weatherlink USB.',
            wldPasswordTitle:'Tu contraseña',
            wldPasswordInfo:'Este dato es la misma contraseña que utilizas para acceder a tu cuenta de weatherlink.com. No te preocupes, la contraseña se guarda encriptada en la base de datos de Skyrush y nadie podrá verla.',
            wldTokenTitle:'Token',
            wldTokenInfo:'Es la llave que usará Skyrush para acceder a la información. La puedes encontrar en tu el perfil de usuario de Weatherlink.com. En la siguiente imagen podrás ver marcado en rojo el mismo dato pero de otra estación como ejemplo (Se ha tachado el contenido para preservar la privacidad del dato):',
         
        },
        stations:
        {
            stations:'Estaciones',
            dataError:'Estación offline',
            lastUpdate:'Act.',
            stationAdded: "Estación añadida correctamente",
            stationAddedDesc: 'La estación se ha añadido correctamente. Se ha creado con estado "borrador". Para poder activarla, por favor, entra en el panel de configuración de la estación desde la lista de estaciones en "Mis estaciones" y sigue las instrucciones.',
            currentCoditions: "Condiciones actuales"
        }
    },
};

export default lang
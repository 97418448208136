import React, { useState, useEffect } from 'react';
import ProfilePictureChange from '../Profile/ProfilePictureChange.js';
import ProfilePersonalEdit from '../Profile/ProfilePersonalEdit.js';
import ProfileSkyrush from '../Profile/ProfileSkyrush.js';
import ProfileProfessionalEdit from '../Profile/ProfileProfessionalEdit.js';
import ProfileStudiesEdit from '../Profile/ProfileStudiesEdit.js';
import ProfileProjectsEdit from '../Profile/ProfileProjectsEdit.js';
import { useUserData } from '../../components/Contexts/UserData';


const ProfileEdit = () => {
    const { userData } = useUserData();


    return (

        <div>

            <div className='container' style={{ paddingBottom: '30px'}}>
                <div className='row' style={{ paddingTop: '10px' }}>
                    <div className='col-lg-2'>
                        <ProfilePictureChange />
                    </div>
                    <div className='col-lg-10'>
                        <ProfilePersonalEdit userData={userData} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <hr></hr>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <ProfileSkyrush userData={userData} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <hr></hr>
                    </div>
                </div>
                <div className='row'>
                  
                        <ProfileStudiesEdit userData={userData} />
               
                </div>
                <div className='row'>
                    <ProfileProfessionalEdit userData={userData} />
                </div>
                <div className='row'>
                    <ProfileProjectsEdit userData={userData} />
                </div>
            </div>
        </div>
    );
};

export default ProfileEdit;
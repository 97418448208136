
import React from 'react'
import { useLangContext, useLangToggleContext } from '../../components/Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import { SingInComponent } from './SingInComponent';
import '../../css/Main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import StandarFooter from '../Footers/StandarFooter.js';

export const StartBodyComponent = () => {



  const langCode = useLangContext();

  console.log(langCode)

  const centerVertically = {
    minHeight: '80vh', // Cambiado a 100vh para ocupar toda la altura de la pantalla
    display: 'flex',
    flexDirection: 'column', // Centra verticalmente el contenido
    alignItems: 'center', // Centra horizontalmente el contenido
    justifyContent: 'center', // Centra horizontalmente el contenido
  };


  return (
    <div style={{ backgroundColor: '#FAFAFA', display: 'flex', alignItems: 'center', paddingTop: '0%' }}>
      <div className='container' >
        <div className='row'>
          <div style={{ textAlign: 'left', marginTop: '12%' }} className='col-12'>

        

            <div className='col-12'>
              <h2 style={{ fontSize: '14px' }}>{lang[langCode].startBody.welcomeMessage}</h2>
            </div>

          </div>
          <div className='col-12'>

            <SingInComponent />

          </div>



        </div>
        <div className='row'>
          <div className='col-12'>
            <StandarFooter />
          </div>
        </div>


      </div>
    </div>
  );
};
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CsrfTokenProvider, useCsrfToken } from '../../../components/Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../../../components/Contexts/LangProvider.js';
import lang from '../../../resources/js/dictionary.js';
import red_light from '../../../resources/icons/red_light.png';
import green_light from '../../../resources/icons/green_light.png';
import yellow_light from '../../../resources/icons/yellow_light.png';
import warning_cert from '../../../resources/img/certs/warning_cert.png';
import SkyConditions from '../StationView/SkyConditions.js';
import rain from '../../../resources/weather_icons/fixed/rain.svg';
import wind from '../../../resources/weather_icons/fixed/wind.png';
import wind_dir from '../../../resources/weather_icons/fixed/wind_dir.png';
import humidity from '../../../resources/weather_icons/fixed/humidity.png';

const StationMain = () => {
    const { stationName } = useParams();
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [stationData, setStationData] = useState(null);
    const [stationNumber, setStationNumber] = useState(null);
    const [place, setPlace] = useState(null);
    const [ligth, setLight] = useState(null);
    const [status, setStatus] = useState(null);
    const [region, setRegion] = useState(null);
    const [cardinalDirection, setCardinalDirection] = useState('');


    const determineCardinalDirection = (degrees) => {
      const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
      const index = Math.round(degrees / 45) % 8;
      setCardinalDirection(directions[index]);
    };
  
    const getRotationAngle = () => {
      // Calculate the rotation angle based on the cardinal direction
      const directionAngles = {
        N: 180,
        NE: 225,
        E: 270,
        SE: 315,
        S: 0,
        SW: 45,
        W: 90,
        NW: 135,
      };
   

      return directionAngles[cardinalDirection] || 0;
    };


    useEffect(() => {
        // Verifica si la estaciónName tiene un guion "-"
        const dashIndex = stationName.indexOf('-');
        if (dashIndex !== -1) {
            // Extrae la parte del número después del guion
            const numberAfterDash = stationName.substring(dashIndex + 1);
            // Convierte el número a entero y actualiza el estado
            setStationNumber(parseInt(numberAfterDash, 10));
        }
    }, [stationName]);

    useEffect(() => {
        // Verifica si la estaciónName tiene un guion "-"

        if (stationNumber !== null) {
            getStation();
        }
    }, [stationNumber]);

    useEffect(() => {
        // Verifica si la estaciónName tiene un guion "-"
        if (stationData !== null) {
         
            determineCardinalDirection(stationData.current_weather_data.wind_dir)
            if (stationData.location.city !== null) {
                setPlace(stationData.location.city);
            } else if (stationData.location.town !== null && stationData.location.city === null) {
                setPlace(stationData.location.town);
            } else if (stationData.location.town === null && stationData.location.village !== null && stationData.location.city === null) {
                setPlace(stationData.location.village);
            } else if (stationData.location.town === null && stationData.location.village === null && stationData.location.hamlet !== null && stationData.location.city === null) {
                setPlace(stationData.location.hamlet);
            }

            if (stationData.location.region !== null) {
                setRegion(stationData.location.region);
            } else if (stationData.location.state !== null && stationData.location.region === null) {
                setRegion(stationData.location.state);
            } else if (stationData.location.state_district !== null && stationData.location.region === null && stationData.location.state === null) {
                setRegion(stationData.location.state_district);
            }

            if (stationData.status === 'draft') {
                setLight(yellow_light);
                setStatus(lang[langCode].weather.draft);
            } else if (stationData.status === 'online') {
                setLight(green_light);
                setStatus(lang[langCode].weather.online);
            } else if (stationData.status === 'offline') {
                setLight(red_light);
                setStatus(lang[langCode].weather.offline);
            } else if (stationData.status === 'warning') {
                setLight(warning_cert);
                setStatus(lang[langCode].weather.warning);
            }
        }
    }, [stationData]);

    const getStation = async () => {
        try {
            const response = await fetch("/api/public/get-station-current-conditions", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                },
                body: JSON.stringify({
                    stationId: stationNumber,
                }),
            });

            if (!response.ok) {
                throw new Error("La solicitud no fue exitosa");
            }

            const data = await response.json();
            setStationData(data);
        } catch (error) {
            console.error('Error en la petición fetch:', error);
        }
    };

    const transformAndRound = (station) => {
        const numericData = Number(station);
        if (!isNaN(numericData)) {
            const roundedData = Math.round(numericData * 10) / 10;
            return roundedData;
        } else {
            return station;
        }
    };


    const transformAndRoundZero = (station) => {
        const numericData = Number(station);

        if (!isNaN(numericData)) {
            const roundedData = Math.round(numericData);
            return roundedData;
        } else {
            return station;
        }
    };

    return (
        <div className='container' style={{ position: 'absolute', top: '53px', left: '0', zIndex: '3', paddingTop: '10px', height: '90vh', backgroundColor: '#F4F4F4' }}>
            {stationData !== null && (
                <div>
                    {stationData.status === 'online' ? (
                        <div>
                            <div className='row'>
                                <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <img
                                        style={{ width: '16px' }}
                                        src={green_light} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                        alt=""
                                    />
                                    <h3 style={{ fontSize: '14px', margin: '0px', marginLeft: '5px' }}  > {stationData.name} - {stationData.location.altitude} m</h3>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '7px' }}>
                                <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <img
                                        style={{ width: '20px' }}
                                        src={require(`../../../resources/country_flag/${stationData.location.country_iso.toLowerCase()}.svg`)} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                        alt=""
                                    />
                                    <h3 style={{ fontSize: '11px', fontFamily: 'montserratregular', padding: '0px', paddingLeft: '5px', margin: '0px' }}>{place}, {region}, {stationData.location.country}</h3>
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: '3px', paddingBottom: '0px', backgroundColor: 'white', marginTop: '10px' }}>
                                <div className='col-12'>
                                    <div className='row' style={{ paddingTop: '0px' }}>
                                        <div className='col-12' style={{ textAlign: 'left ', paddingTop: '0px' }}>
                                            <h3 style={{ fontSize: '12px', margin: '0px' }}  >{lang[langCode].stations.currentCoditions}</h3>
                                        </div>
                                    </div>
                                    <div className='row' style={{ paddingTop: '0px', paddingLeft: '5%' }}>
                                        <div className='col-3' style={{ textAlign: 'center ', padding: '0px' }}>
                                            <SkyConditions data={stationData} imgSize={110} />
                                            <div className='row' style={{ paddingTop: '0 px' }}>
                                                <div className='col-12' style={{ textAlign: 'center ', padding: '0px' }}>
                                                    <h3 style={{ fontSize: '17px', margin: '0px' }}> {transformAndRound(stationData.current_weather_data.temp)}°</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3 d-flex flex-column align-items-center justify-content-center' style={{ textAlign: 'center' }}>
                                            <div className='row' style={{ paddingTop: '0px' }}>
                                                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                                    <p style={{ fontSize: '18px', margin: '0px', color: 'red' }}>{transformAndRound(stationData.current_weather_data.temp_max)}°</p>
                                                </div>
                                            </div>
                                            <div className='row' style={{ paddingTop: '10px' }}>
                                                <div className='col-12' style={{ textAlign: 'center' }}>
                                                    <p style={{ fontSize: '18px', margin: '0px', color: '#1273EB' }}>{transformAndRound(stationData.current_weather_data.temp_min)}°</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 d-flex align-items-center justify-content-center' style={{ textAlign: 'center', borderLeft: '1px solid #E9E9E9' }}>
                                            <div className='row' style={{ paddingTop: '0px' }}>
                                                <div className='col-6' style={{ textAlign: 'center', padding: '0px' }}>
                                                    <img
                                                        style={{ width: '70px' }}
                                                        src={rain}
                                                        alt=""
                                                    />
                                                    <p style={{ fontSize: '13px', margin: '0px' }}>{transformAndRound(stationData.current_weather_data.daily_rain)} mm</p>
                                                </div>
                                                <div className='col-6' style={{ textAlign: 'center', padding: '0px' }}>
                                                    <div >
                                                        <img
                                                            style={{ width: '70px', marginTop: '7px' }}
                                                            src={wind}
                                                            alt=""
                                                        />
                                                        <p style={{ fontSize: '13px', margin: '0px' }}>{transformAndRound(stationData.current_weather_data.wind_speed)} km/h</p>
                                                    </div>
                                                </div>
                                                <div className='col-6' style={{ textAlign: 'center', padding: '0px' }}>
                                                    <div >
                                                        <img
                                                            style={{
                                                                width: '50px',
                                                                transform: `rotate(${getRotationAngle()}deg)`,
                                                                marginTop: '10px',
                                                      

                                                            }}
                                                            src={wind_dir}
                                                            alt="Wind Direction"
                                                        />
                                                        <p style={{ fontSize: '13px', marginTop: '10px', marginLeft: '5px' }}>{cardinalDirection}</p>
                                                    </div>
                                                </div>
                                                <div className='col-6' style={{ textAlign: 'center', padding: '0px' }}>
                                                    <div >
                                                        <img
                                                            style={{ width: '50px', marginTop: '10px' }}
                                                            src={humidity}
                                                            alt=""
                                                        />
                                                        <p style={{ fontSize: '13px', margin: '0px' }}>{transformAndRoundZero(stationData.current_weather_data.humidity)} %</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='row'>
                                <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <img
                                        style={{ width: '16px' }}
                                        src={red_light} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                        alt=""
                                    />
                                    <h3 style={{ fontSize: '14px', margin: '0px', marginLeft: '5px' }}  > {stationData.name} - {stationData.location.altitude} m</h3>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '7px' }}>
                                <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <img
                                        style={{ width: '20px' }}
                                        src={require(`../../../resources/country_flag/${stationData.location.country_iso.toLowerCase()}.svg`)} // Reemplaza 'ruta/a/la/carpeta/' con la ruta real
                                        alt=""
                                    />
                                    <h3 style={{ fontSize: '11px', fontFamily: 'montserratregular', padding: '0px', paddingLeft: '5px', margin: '0px' }}>{place}, {region}, {stationData.location.country}</h3>
                                </div>
                            </div>
                            <div className='row' style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h3 style={{ fontSize: '14px', margin: '0px', marginLeft: '5px' }}> {lang[langCode].stations.dataError} </h3>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default StationMain;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CsrfTokenProvider, useCsrfToken } from '../../components/Contexts/CsrfTokenContext';
import UniquePost from './UniquePost.js';
import { useNavigate } from 'react-router-dom';


const InsideSharedPost = ({postDataId,userDataParam}) => {

  const csrfToken = useCsrfToken();
  const [postData, setPostData] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getPost();

    setUserData(userDataParam)

  }, []);

  const getPost = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch("api/public/get-post", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrfToken,
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          post_id: postDataId,
        }),
      });

      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }

      const data = await response.json();

      setPostData(data.post);
    } catch (error) {
      console.error('Error en la petición fetch:', error);
    }
  };


  const goPost = (e) =>
  {
    navigate('/post', { state: { postId: postDataId, userData: userData,goBack:true } });
  }


  return (
    <div style={{  paddingBottom: '10px', cursor:'pointer' }} onClick={goPost}>
      <div className='container'>
        <div className='row'>
         
          <div className='col-lg-12' style={{ padding:'0px' }}>
            {postData !== null && <UniquePost postData={postData} userData={userData}   withLikes={false}/>}
          </div>

        </div>
      </div>
    </div>
  );
};

export default InsideSharedPost;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CsrfTokenProvider, useCsrfToken } from '../Contexts/CsrfTokenContext';
import { useLangContext, useLangToggleContext } from '../Contexts/LangProvider.js';
import lang from '../../resources/js/dictionary.js';
import close from '../../resources/icons/cross.png';
import arrowLeft from '../../resources/icons/arrow-left.png';
import arrowRight from '../../resources/icons/arrow-right.png';
import '../../css/Personal.css';

const ViewPhotoDetails = ({ isOpen, onClose, photoPaths }) => {
    const langCode = useLangContext();
    const csrfToken = useCsrfToken();
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    const handlePrevPhoto = (e) => {
        e.preventDefault();
        if (photoPaths && photoPaths.length > 0) {
            setCurrentPhotoIndex((prevIndex) => (prevIndex === 0 ? photoPaths.length - 1 : prevIndex - 1));
        }
    };

    const handleNextPhoto = (e) => {
        e.preventDefault();
        if (photoPaths && photoPaths.length > 0) {
            setCurrentPhotoIndex((prevIndex) => (prevIndex === photoPaths.length - 1 ? 0 : prevIndex + 1));
        }
    };

    const [customStyles, setCustomStyles] = useState({
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 999,
        },
        content: {
          width: '95%',
          height: '60%',  // Establecer la altura a 'auto'
          margin: 'auto',
            
          left: '0',
          right: '0',
          top: '10%',  // Ajustar según sea necesario
          bottom: 'auto', 
          overflow: 'auto',
          padding: '5px',  // O ajustar según sea necesario
          border: 'none',  // O ajustar según sea necesario
          borderRadius: '8px',  // O ajustar según sea necesario
          position: 'fixed',
        },
      });

    // Función para personalizar el estilo de la imagen después de que el modal esté abierto
    const onAfterOpen = () => {
        // Obtener el tamaño del modal de manera segura
        const modalContent = document.querySelector('.ReactModal__Content');
        if (modalContent) {
            const modalWidth = modalContent.offsetWidth * 0.85; // 85% del ancho
            const modalHeight = modalContent.offsetHeight * 0.85;

            // Aplicar el tamaño como maxWidth y maxHeight para la imagen
            const imageStyle = {
                maxWidth: `${modalWidth}px`,
                maxHeight: `${modalHeight}px`,
                width: 'auto',
                height: 'auto',
                objectFit: 'contain',
                display: 'block',
                margin: '0 auto',
                overflow: 'hidden',
 
         
            };

            setImageStyle(imageStyle);
        }
    };

    const [imageStyle, setImageStyle] = useState({});

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={onAfterOpen} // Personalizar el estilo de la imagen después de que el modal esté abierto
            onRequestClose={onClose}
            contentLabel="Renovar Subscripcion"
            style={customStyles}
        >
            <div className='row'>
                <div className='col-lg-1'>
                    <a href='#' onClick={onClose}>
                        <img
                            src={close}
                            style={{
                                width: '10px',
                                marginBottom: '10px',
                            }}
                        />
                    </a>
                </div>
                <div className='col-lg-10' >
                    {photoPaths && photoPaths.length > 0 && (
                        <div>
                            <div style={{ position: 'relative', textAlign: 'center',paddingTop: '6%' }}>
                                <img
                                    src={"https://www.skyrushcommunity.com/api/public/storage/posts/" + photoPaths[currentPhotoIndex]}
                                    alt={`Photo ${currentPhotoIndex + 1}`}
                                    style={imageStyle} // Aplicar el estilo aquí
                                />
                                <a
                                    href='#'
                                    onClick={handlePrevPhoto}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        display: 'inline-block',
                                        width: '40px',
                                        left: '0px',
                                    }}
                                >
                                    <img
                                        src={arrowLeft}
                                        style={{
                                            width: '40px',
                                            height: 'auto',
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        }}
                                    />
                                </a>
                                <a
                                    href='#'
                                    onClick={handleNextPhoto}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        display: 'inline-block',
                                        width: '40px',
                                        right: '0px',
                                    }}
                                >
                                    <img
                                        src={arrowRight}
                                        style={{
                                            width: '40px',
                                            height: 'auto',
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        }}
                                    />
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ViewPhotoDetails;